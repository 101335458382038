// Dependencies
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useFormik } from 'formik';
import { useHistory, useLocation } from 'react-router-dom';
import {Form, Row, Container, Col, Card} from 'react-bootstrap';
import {useMutation, useQuery} from '@apollo/client';

// Components
import Larky from 'components/Larky';
import { useNotification } from 'components/Notification/Notification';

// Services
import UserService from 'services/UserService';

import { getUser } from 'Utils';

// GraphQL
import {
  NEW_PASSWORD_MUTATION,
  UPDATE_PASSWORD_MUTATION,
} from 'graphql/mutations/users';

import {GET_PASSWORD_RULES, VALIDATE_PASSWORD} from "../../graphql/queries/users";

import { UPDATE_PASSWORD_LOGIN_DISPATCHER } from 'Constants';

// Contexts
import { useAuthMachineValue } from '../../contexts/auth-machine.context.ts';

import validationSchema from './UpdatePassword.validator';
import styles from "../MyAccount/MyAccount.module.scss";

function UpdatePasswordForm({ newPassword, callback, id }) {
  const history = useHistory();
  const location = useLocation();
  const notification = useNotification();

  const [updatePasswordMutation] = useMutation(UPDATE_PASSWORD_MUTATION);
  const [newPasswordMutation] = useMutation(NEW_PASSWORD_MUTATION);

  const getValidatePasswordQuery = useQuery(VALIDATE_PASSWORD, { skip: true });
  const getPasswordRulesQuery = useQuery(GET_PASSWORD_RULES, { skip: true });

  const [passwordInputInFocus, setPasswordInputInFocus] = useState(false);

  const [passwordValidationMessage, setPasswordValidationMessage] = useState(null);
  const [isPasswordValid, setIsPasswordValid] = useState(false);

  const [passwordRules, setPasswordRules] = useState(null);
  const [passwordRulesIncorrectMessage, setPasswordRulesIncorrectMessage] = useState(null);

  const [, send] = useAuthMachineValue();

  const params = new URLSearchParams(location.search);
  const forgotPasswordIdentifier = decodeURIComponent(params.get('forgot_password_identifier') || '');

  const funcCallback = () => {
    if (callback) return callback()
    return history.goBack();
  }

  const formik = useFormik({
    initialValues: {
      newPassword,
      currentPassword: '',
      password: '',
      confirmPassword: '',
    },
    validateOnChange: false,
    validationSchema,
    onSubmit: async ({ currentPassword, password, confirmPassword }) => {
      try {
        let msg;

        if (newPassword) {
          await UserService.newPassword(newPasswordMutation, {
            forgotPasswordIdentifier,
            password,
            confirmPassword,
          });
          msg = 'Password reset successfully';
        } else {
          const { email } = getUser();
          await UserService.updatePassword(updatePasswordMutation, {
            email,
            currentPassword,
            password,
            confirmPassword,
            id,
          });

          await UserService.logout(false, false);
          send('LOGOUT');

          msg = 'Password updated successfully';
        }

        notification.alert(msg, 'success');
        history.push(`/login?dispatcher=${UPDATE_PASSWORD_LOGIN_DISPATCHER}`);
      } catch (err) {
        notification.alert(err.message, 'danger');
      }
    },
  });

  const isUpdateBtnDisabled = () => {
    const formIsEmpty = newPassword ? !!formik.values.confirmPassword && !!formik.values.password && isPasswordValid : !!formik.values.currentPassword && !!formik.values.confirmPassword && !!formik.values.password && isPasswordValid;
    return !(formik.isSubmitting || formIsEmpty);
  }

  useEffect(async () => {
    const userPasswordRules = await UserService.getPasswordRules(getPasswordRulesQuery);
    // todo: clean this up (font size and linting error on ruleDesc)
    const passwordRulesMap = userPasswordRules.map((rule) => <h6 className={styles['password-rules-font']} key={rule.ruleID}><li>{rule.ruleDesc}</li></h6>);
    setPasswordRules(passwordRulesMap);
  }, []);

  const determinePasswordValidation = async (password) => {
      const passwordValidationResponse = await UserService.validatePassword(
        getValidatePasswordQuery,
        password,
        null,
        null,
        null,
        id ? String(id) : null
      );

      if (passwordValidationResponse.length
      // TODO add a loop to check if ruleID !== -1 and add a conditional message
      ) {
        const passwordRulesNotMetMessage = 'The password you entered does not meet the criteria shown. Please try again!';
        setPasswordRulesIncorrectMessage(passwordRulesNotMetMessage);
      }
      if (!passwordValidationResponse.length) {
        setPasswordRulesIncorrectMessage(null);
      }
  };
  useEffect(async () => {
    if (passwordInputInFocus === 'out' && formik.values.password) {
      await determinePasswordValidation(formik.values.password);
    }
  }, [passwordInputInFocus]);

  useEffect(async () => {
    if (passwordValidationMessage === 'Password Valid') {
      setIsPasswordValid(true);
    } else {
      setIsPasswordValid(false);
    }
  }, [passwordValidationMessage]);

  useEffect(async () => {
    if (!passwordRulesIncorrectMessage) {
      setIsPasswordValid(true);
    } else {
      setIsPasswordValid(false);
    }
  }, [passwordRulesIncorrectMessage]);

  return (
    <>
    <Container className={styles['invite-user-container']}>
      <Form onSubmit={formik.handleSubmit}>
        <Row>
            <Col className={styles['my-account-col-flex']}>
        {!newPassword && (
          <Larky.Form.Input
            type="password"
            name="currentPassword"
            title="Current password"
            placeholder="Current password"
            value={formik.values.currentPassword}
            onChange={formik.handleChange}
            error={formik.errors.currentPassword}
          />
        )}
        <Larky.Form.Input
          type="password"
          name="password"
          title="New password"
          placeholder="New password"
          value={formik.values.password}
          onChange={formik.handleChange}
          error={!isPasswordValid ? passwordRulesIncorrectMessage : formik.errors.password}
                    setIsFocusedCallback={(boolVal) => {
                      const isInFocus = boolVal ? 'in' : 'out';
                      setPasswordInputInFocus(isInFocus);
                    }}
        />
        <Larky.Form.Input
          type="password"
          name="confirmPassword"
          title="Confirm new password"
          placeholder="Confirm new password"
          value={formik.values.confirmPassword}
          onChange={formik.handleChange}
          error={formik.errors.confirmPassword}
        />
        </Col>
        <Col className={styles['my-account-col-flex']}>
          <Card className={styles['invite-user-card']}>
            <Card.Body>
              <h2>Password Rules</h2>
              {passwordRules}
            </Card.Body>
          </Card>
        </Col>
        </Row>
        <Form.Group as={Container} className="mt-4 mb-0">
          <Row className="justify-content-center">
            <Larky.Button
              outlined
              onClick={() => funcCallback()}
            >
              Cancel
            </Larky.Button>
            <Larky.Button
              type="submit"
              disabled={isUpdateBtnDisabled()}
            >
              Update password
            </Larky.Button>
          </Row>
        </Form.Group>
        </Form>
    </Container>
    </>
  );
}

UpdatePasswordForm.propTypes = {
  newPassword: PropTypes.bool,
  callback: PropTypes.func,
};

UpdatePasswordForm.defaultProps = {
  newPassword: false,
  callback: null,
};

export default UpdatePasswordForm;
