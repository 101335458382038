/**
 * CampaignGallery.jsx
 *
 * Component that displays Campaigns that are predefined for clients.
 *
 */
import React from 'react';
import PropTypes from 'prop-types';

// styles

import styles from '../../../Campaigns/components/CampaignGallery/CampaignGallery.module.scss';
import adoptionOverviewStyles from './AdoptionOverview.module.scss'
import specificCardStyles from './AdoptionOverview.module.scss'

// components
import { Col, Container, Row } from 'react-bootstrap';
// icons
import trendUpALot from 'assets/img/trend_up_a_lot.png';
import trendDownALot from 'assets/img/trend_down_a_lot.png';
import warningIcon from 'assets/img/warning-icon.png'
import toolTipIcon from "assets/img/tooltipIcon.png";
import {formatNumber} from "../../../../Utils";

const AdoptionOverview = ({ title, description, tableData}) => {

  const mapTableData = () => {
    tableData.map((row) => {
      return (
          <>
            <Row>
              <h8>
            {row.title}
          </h8>
           <td className={styles['numbers-messaging']}>{formatNumber(tableData.percentage)} </td>
            {row.trend}
            </Row>
          </>
      )
    })
  }

  return (
    <>
      <article className={adoptionOverviewStyles['card-adoption-overview']}
        onClick={() => onClick()}
      >
        <div className={styles['card-content']}>
          <div className={adoptionOverviewStyles['card-adoption-overview-header']}>
            <h4>Adoption Overview</h4>
          </div>
          <div className={adoptionOverviewStyles['card-body-adoption-overview']}>
            <Col>
              <Row>
              <h8>Notification Permission Rate</h8>
            </Row>
              <Row>
              <h8>Location Permission Rate</h8>
            </Row>
            </Col>
          </div>
        </div>
      </article>
    </>
  );
};

AdoptionOverview.propTypes = {
  id: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  cadence: PropTypes.string,
  notes: PropTypes.string,
  campaignId: PropTypes.number,
  editable: PropTypes.bool,
  onEditClick: PropTypes.func,
  onCreateClick: PropTypes.func,
  active: PropTypes.bool.isRequired,
  handleActivateOrDeactivate: PropTypes.func.isRequired,
  isTemplateCreator: PropTypes.bool.isRequired,
  userId: PropTypes.string.isRequired,
  hasWritePermission: PropTypes.bool.isRequired,
};

AdoptionOverview.defaultProps = {
  id: null,
  title: '',
  description: '',
  cadence: '',
  notes: '',
  campaignId: null,
  editable: false,
  onEditClick: () => null,
  onCreateClick: () => null,
  active: false,
  handleActivateOrDeactivate: () => null,
  isTemplateCreator: false,
  userId: '',
  hasWritePermission: false,
};

export default AdoptionOverview;
