import React from 'react';
import PropTypes from 'prop-types';

import {printPrettyDateFromDatetime, formatNumber} from 'Utils';

import RowActionsDropdown from '../../../../components/CustomTable/RowActionsDropdown/RowActionsDropdown';

function SegmentRow({
                        data,
                        handleDeleteRow,
                        handleRefreshRow,
                        hasEditPermission
                    }) {
    const createSegmentOptionList = () => {
        const listOfOptions = [];

        // Check if the segment type is NOT 'trellance', 'deeptarget', or 'hubspot'
        if (data.segmentType.toLowerCase() !== 'trellance' &&
            data.segmentType.toLowerCase() !== 'hubspot' &&
            data.segmentType.toLowerCase() !== 'deeptarget') {

            // Show View/Edit option only if the segment type is not one of the restricted types
            listOfOptions.push({
                label: hasEditPermission ? 'View/Edit segment' : 'View segment',
                value: 'edit'
            });

            // Show Delete option only if the segment type is not one of the restricted types
            if (handleDeleteRow && hasEditPermission) {
                listOfOptions.push({ label: '', value: '', isLineBreak: true });
                listOfOptions.push({ label: 'Delete segment', value: 'delete' });
            }
        }

        // Handle Refresh segment if needed (check if refresh row handling is allowed)
        if (handleRefreshRow && hasEditPermission && data.segmentType.toLowerCase() !== 'file') {
            listOfOptions.push({ label: '', value: '', isLineBreak: true });
            listOfOptions.push({ label: 'Refresh segment', value: 'refresh' });
        }

        return listOfOptions;
    };


    return (
        <tr>
            {/*<td>{data.segmentStatus}</td>*/}
            <td>{`${data.computeType[0].toUpperCase()}${data.computeType.slice(1)}`}</td>
            <td>{data.segmentName}</td>
            <td>{formatNumber(data.memberCounts)}</td>
            <td>{printPrettyDateFromDatetime(data.created)}</td>
            <td>{printPrettyDateFromDatetime(data.updated)}</td>
            {/*<td>{data.nudgesUtilizing >= 1 ? 'Yes' : 'No'}</td>*/}
            <td>
                <RowActionsDropdown
                    editRowPath={`/segments/edit/${data.id}`}
                    onDeleteRow={handleDeleteRow}
                    onRefreshRow={handleRefreshRow}
                    hasEditPermission={hasEditPermission}
                    options={createSegmentOptionList()}
                    placeholder="Actions..."
                />
            </td>
        </tr>
    );
}

SegmentRow.propTypes = {
    data: PropTypes.shape({
        id: PropTypes.oneOfType([
            PropTypes.number, PropTypes.string,
        ]).isRequired,
        active: PropTypes.bool.isRequired,
        created: PropTypes.string.isRequired,
        updated: PropTypes.string.isRequired,
        segmentName: PropTypes.string.isRequired,
        segmentDesc: PropTypes.string.isRequired,
        segmentType: PropTypes.string.isRequired,
        segmentStatus: PropTypes.string.isRequired,
        memberCounts: PropTypes.number,
        nudgesUtilizing: PropTypes.number.isRequired,
        computeType: PropTypes.string.isRequired,
    }).isRequired,
    handleSelect: PropTypes.func.isRequired,
    handleDeleteRow: PropTypes.func.isRequired,
    handleRefreshRow: PropTypes.func.isRequired,
    isSelectable: PropTypes.bool,
    hasEditPermission: PropTypes.bool,
};

SegmentRow.defaultProps = {
    isSelectable: false,
    hasEditPermission: false,
};

export default SegmentRow;
