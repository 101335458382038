import { gql } from '@apollo/client';

export const GET_SEGMENT = gql`
  query GetSegmentQuery($segmentId: Int!) {
    getSegment(segmentId: $segmentId) {
      id
      segmentName
      segmentDesc
      segmentType
      segmentStatus
      memberCounts
      totalMemberCounts
      membershipLimit
      nudgesUtilizing
      active
      created
      updated
      computeType
    }
  }
`;

export const GET_USER_SEGMENT_BY_PARENT_ID = gql`
query GetUserSegmentByParentIdQuery($parentId: Int!) {
    getUserSegmentByParentId(parentId: $parentId) {
      id
      operation
      parentId
      memberSegmentId
      segmentName
    }
  }
`;

export const GET_SEGMENTS = gql`
  query GetSegmentsQuery($searchParam: SearchParamInput) {
    getSegments(searchParam: $searchParam) {
      hits {
        id
        segmentName
        segmentDesc
        segmentType
        segmentStatus
        memberCounts 
        nudgesUtilizing
        active
        created
        updated
        computeType
      }
      totalResults
    }
  }
`;

export const SEARCH_SEGMENTS = gql`
  query SearchSegmentsQuery (
    $orderBy: String!
    $sortOrder: String!
    $rows: Int!
    $start: Int!
    $searchParam: SearchParamInput
  ) {
    searchSegments(
      orderBy: $orderBy
      sortOrder: $sortOrder
      rows: $rows
      start: $start
      searchParam: $searchParam
    ) {
      hits {
        id
        segmentName
        segmentDesc
        segmentType
        segmentStatus
        memberCounts
        nudgesUtilizing
        active
        created
        updated
        computeType
      }
      totalResults
    } 
  }
`;

export const CALCULATE_USER_SEGMENT = gql`
  query CalculateUserSegmentQuery(
    $organizationId: Int!
    $userId: String!
    $membershipLimit: Float
    $segmentIncluded: [Int]
    $segmentExcluded: [Int]
  ) {
    calculateUserSegment(
      organizationId: $organizationId
      userId: $userId
      membershipLimit: $membershipLimit
      segmentIncluded: $segmentIncluded
      segmentExcluded: $segmentExcluded
    ) {
      count
    }
  }
`;

export const CHECK_WHO_IS_USING_SEGMENT = gql`
  query whoIsUsingSegmentQuery(
    $segmentId: Int!
  ) {
    checkWhoIsUsingSegmentById(
      segmentId: $segmentId
    ) {
      segmentUserId
      segmentUserType
      segmentUserName
    }
  }
  `;

export const CALCULATE_SYSTEM_SEGMENT = gql`
  query CalculateSystemSegmentQuery(
    $organizationId: Int!
    $userId: String!
    $messageId: Int
    $nudgeActivity: String
    $timeframe: Int
  ) {
    calculateSystemSegment(
      organizationId: $organizationId
      userId: $userId
      messageId: $messageId
      nudgeActivity: $nudgeActivity
      timeframe: $timeframe
    ) {
      count
    }
  }
`;

export const REFRESH_USER_SEGMENT = gql`
  query RefreshUserSegmentQuery(
    $segmentId: ID
  ) {
    refreshUserSegment(
      segmentId: $segmentId
    ) {
      active
      updatedBy
      segmentName
      memberCounts
      segmentStatus
      id
      membershipLimit
      segmentDesc
      created
      organizationId
      inclusionLogic
      nudgesUtilizing
      lastComputedOn
      computeType
      exclusionLogic
      isNewSegment
      updated
      segmentType

    }
  }
`;

export const COUNT_ALL_SEGMENTS = gql`
  query CountAllSegmentsQuery(
    $organizationId: Int!
    $userId: String!
    $nudgeActivity: String!
    $messageId: Int    
    $timeframe: Int    
  ) {
    countAllSegments(
      organizationId: $organizationId
      userId: $userId
      nudgeActivity: $nudgeActivity
      messageId: $messageId      
      timeframe: $timeframe
    ) {
      count
    }
  }
`;

export const CALCULATE_FILE_SEGMENT = gql`
  query CalculateSegmentFileDataQuery(
    $organizationId: Int!
    $userId: String!
    $federationId: [String]!
    $membershipLimit: Float
  ) {
    calculateFileSegment(
      organizationId: $organizationId
      userId: $userId
      federationId: $federationId
      membershipLimit: $membershipLimit
    ) {
      count
      segmentStats {
        totalEntries
        uniqueEntries
        matchedEntries
        unmatchedEntries
      }   
    }
  }
`;
