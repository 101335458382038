/* eslint-disable react/button-has-type */
import React from 'react';
import PropTypes from 'prop-types';
import ReactTooltip from 'react-tooltip';

import { Spinner } from 'react-bootstrap';

import styles from './CustomButton.module.scss';

function CustomButton({
  autoMargin,
  type,
  text,
  onClick,
  size,
  variant,
  disabled,
  className,
  color,
  isLoading,
  toolTipText,
}) {
  return (
    <div data-tip={toolTipText || null} style={{ float: 'right', overflow: 'hidden', margin: '.5rem' }}>
      <button
        type={type}
        className={`${styles[size]} ${styles[variant]} ${styles[color]} ${className}`}
        style={{ margin: autoMargin ? 'auto' : '' }}
        onClick={onClick}
        disabled={disabled}
      >
        {isLoading ? (
          <Spinner
            as="span"
            animation="border"
            size="sm"
            role="status"
            aria-hidden="true"
          />
        ) : text}
      </button>
      {toolTipText ? (
        <ReactTooltip />
      ) : null}
    </div>
  );
}

CustomButton.propTypes = {
  autoMargin: PropTypes.bool,
  text: PropTypes.string.isRequired,
  type: PropTypes.string,
  onClick: PropTypes.func,
  size: PropTypes.string,
  variant: PropTypes.string,
  disabled: PropTypes.bool,
  className: PropTypes.string,
  color: PropTypes.string,
  isLoading: PropTypes.bool,
};

CustomButton.defaultProps = {
  autoMargin: false,
  type: 'button',
  onClick: null,
  size: '',
  variant: '',
  disabled: false,
  className: '',
  color: 'primary',
  isLoading: false,
};

export default CustomButton;
