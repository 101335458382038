// Dependencies
import React, {
  Children,
  isValidElement,
  cloneElement,
} from 'react';
import PropTypes from 'prop-types';

// Styles
import NudgeTwoLineLogo from 'assets/img/nudge-two-line-logo.svg';

import {
  Row, Col, Container, Card,
} from 'react-bootstrap';
import Larky from 'components/Larky';
import styles from './CustomDialogCard.module.scss';

const getCols = (size) => {
  switch (size) {
    case 'sm': {
      return { lg: 5, xl: 6 };
    }
    case 'md': {
      return { lg: 7, xl: 8 };
    }
    case 'lg': {
      return { lg: 8, xl: 9 };
    }
    case 'xl': {
      return { lg: 9, xl: 10 };
    }
    default: {
      return { lg: 5, xl: 6 };
    }
  }
};

function CustomDialogCard({
  className,
  title,
  subtitle,
  content,
  children,
  size,
  isForm,
  showLogo,
  style,
  primaryBtnText,
  primaryBtnClick,
  type,
  italicizeTitle,
  background,
}) {
  const { lg, xl } = getCols(size);
  const childrenWithProps = Children.map(children, (child) => {
    if (isValidElement(child)) {
      return cloneElement(child, {
        className: `${child.props.className || ''} d-flex mx-auto ${isForm ? ' flex-column' : 'flex-row flex-wrap'}`,
      });
    }

    return child;
  });
  const getTitle = () => {
    if (title) {
      return (
        italicizeTitle ? (<i><h2 className="text-center">{title}</h2></i>) : (<h2 className="text-center">{title}</h2>)
      );
    }
    return null;
  };
  const getContent = () => (
    <>
      <Container>
        <Row className="align-items-center justify-content-center mb-4">
          <Col xs="12" md="auto">
            {getTitle()}
          </Col>
        </Row>
        <Row className="align-items-center justify-content-center mb-5">
          <Col xs="12" md="auto">
            <h4 className="text-center"><strong>{subtitle}</strong></h4>
          </Col>
          {(primaryBtnText && primaryBtnClick) && (
          <Larky.Button
            className={styles.button}
            onClick={primaryBtnClick}
          >
            {primaryBtnText}
          </Larky.Button>
          )}
        </Row>
      </Container>
      {content
        && (
        <h5 className={`${type === 'alert' ? styles.alert : ''} ${styles.content} mb-5`}>
          <strong>{content}</strong>
        </h5>
        )}
      {childrenWithProps}
    </>
  );

  const getCardWithStyle = () => {
    if (background) {
      return <Card style={{ background }}>{getContent()}</Card>;
    }
    return <Card className={`py-5 px-4 bg-tiny ${styles.card} ${className}`}>{getContent()}</Card>;
  };

  return (
    <div
      className="vw-100 min-vh-100 d-flex flex-column justify-content-center align-items-center py-2 px-0 bg-dark"
      style={{ ...style }}
    >

      <Container>
        {showLogo && (
          <Row className={`justify-content-center ${styles.logo}`}>
            <img
              src={NudgeTwoLineLogo}
              alt="Larky Nudge logo"
            />
          </Row>
        )}

        <Row className="justify-content-center">
          <Col xs="12" sm="8" lg={lg} xl={xl}>
            {getCardWithStyle()}
          </Col>
        </Row>
      </Container>
    </div>
  );
}

CustomDialogCard.propTypes = {
  className: PropTypes.string,
  style: PropTypes.object,
  title: PropTypes.string,
  subtitle: PropTypes.string,
  content: PropTypes.string,
  children: PropTypes.node.isRequired,
  size: PropTypes.string,
  isForm: PropTypes.bool,
  showLogo: PropTypes.bool,
  primaryBtnText: PropTypes.string,
  primaryBtnClick: PropTypes.func,
  type: PropTypes.string,
  italicizeTitle: PropTypes.bool,
};

CustomDialogCard.defaultProps = {
  className: '',
  style: {},
  title: null,
  subtitle: null,
  content: null,
  size: 'sm',
  isForm: false,
  showLogo: true,
  primaryBtnText: null,
  type: null,
  primaryBtnClick: null,
  italicizeTitle: false,
};

export default CustomDialogCard;
