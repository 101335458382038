/**
 * MyAccount
 *
 * Component that contains user account information
 *
 */
// Dependencies
import React, { useEffect, useState } from 'react';

import { useHistory, useParams } from 'react-router-dom';
import * as Yup from 'yup';
import { useFormik } from 'formik';
import { useMutation, useQuery } from '@apollo/client';
import {
  Card, Col, Container, Form, Row, Spinner,
} from 'react-bootstrap';
// import moment from 'moment';
import moment from 'moment-timezone';
import NoPhotoIcon from 'assets/img/no-photo-icon.svg';
import Larky from 'components/Larky';
import { useNotification } from '../../components/Notification/Notification';
import { useAuthMachineValue } from '../../contexts/auth-machine.context';
import UserService from '../../services/UserService';
import { ACTIVATE_OR_DEACTIVATE_OTHER_USER_MUTATION, UPDATE_OTHER_USER_MUTATION } from '../../graphql/mutations/users';
import { GET_USER } from '../../graphql/queries/users';
import styles from '../MyAccount/MyAccount.module.scss';
import CustomCard from '../../components/CustomCard/CustomCard';
import CustomField from '../../components/CustomField/CustomField.tsx';
import CustomInput from '../../components/CustomInput/CustomInput';
import {
  REQUIRED_FIELD, ROUTES, VIEW_ONLY_OR_EDITOR_OPTIONS, ACTIVE_USER_OPTIONS,
} from '../../Constants';
import { getUser, parseRole } from '../../Utils';
import ChangeOrganizationModal from '../../components/Modal/ManageUser/ChangeOrganizationModal/ChangeOrganizationModal';
import ResetPasswordModal from '../../components/Modal/ManageUser/ResetPasswordModal/ResetPasswordModal';
import Reset2faModal from '../../components/Modal/ManageUser/Reset2faModal/Reset2faModal';
import CustomCheckbox from '../../components/CustomCheckbox/CustomCheckbox';
import CustomRadioButton from '../../components/CustomRadioButton/CustomRadioButton';

const validationSchema = () => Yup.object({
  firstName: Yup.string().trim().required(REQUIRED_FIELD),
  lastName: Yup.string().trim().required(REQUIRED_FIELD),
  // role: Yup.string()
  //   .when('isAdmin', (val, schema) => (val ? schema : schema.trim().required(REQUIRED_FIELD))),
});

// Function to convert UTC timestamp to local time
function convertUTCDateToLocalDate(utcDateString) {
  // Parse the UTC date string into a Moment object
  const utcDate = moment.utc(utcDateString, 'YYYY-MM-DD HH:mm:ss');
  // Convert the UTC date to the local time zone
  const localDate = utcDate.tz(moment.tz.guess());
  return localDate.format('MM/DD/YYYY hh:mm A');
}

const getUserTimezone = () => {
  return Intl.DateTimeFormat().resolvedOptions().timeZone;
};

const getDisplayTimezoneName = (timezone) => {
  const offset = moment.tz(timezone).format('Z');
  const abbreviation = moment.tz(timezone).format('z');
  // Create a more user-friendly name
  const timezoneMap = {
    'EDT': 'ET',
    'EST': 'ET',
    'CDT': 'CT',
    'CST': 'CT',
    'MDT': 'MT',
    'MST': 'MT',
    'PDT': 'PT',
    'PST': 'PT',
    // Add more mappings as needed
  };
  return timezoneMap[abbreviation] || `UTC${offset}`; // Default to UTC offset if no mapping found
};

const timezone = getUserTimezone();
const TIME_ZONE = getDisplayTimezoneName(timezone);



function ManageUser({ organizationId }) {
  const history = useHistory();
  const notification = useNotification();
  const [uploadProfilePicture, setUploadProfilePicture] = useState(false);
  const [showPasswordModal, setShowPasswordModal] = useState(false);
  const [show2faModal, setShow2faModal] = useState(false);
  const [showOrgModal, setShowOrgModal] = useState(false);
  const [showActivationModal, setShowActivationModal] = useState(false);
  const [showProfileModal, setShowProfileModal] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [radioOptions, setRadioOptions] = useState(VIEW_ONLY_OR_EDITOR_OPTIONS);
  const [radioOptionsStatus, setRadioOptionsStatus] = useState(ACTIVE_USER_OPTIONS);
  const [activationSubmission, setActivationSubmission] = useState(false);
  const [userInfoSubmission, setUserInfoSubmission] = useState(false);

  const [current, send] = useAuthMachineValue();

  const [user, setUser] = useState({
    id: null,
    firstName: '',
    lastName: '',
    email: '',
    profilePicture: '',
    currentOrganization: {
      id: '',
      name: '',
    },
    organizations: [],
    role: '',
  });


  // const [isAdmin, setIsAdmin] = useState(UserService.isAdmin());
  const [isActiveCheck, setIsActiveCheck] = useState(false);

  const [updateUserMutation] = useMutation(UPDATE_OTHER_USER_MUTATION);
  const [activateOrDeactivateOtherUser] = useMutation(ACTIVATE_OR_DEACTIVATE_OTHER_USER_MUTATION);

  const params = useParams();
  const id = params.userId || current.context.user.id;

  const getUserQuery = useQuery(GET_USER, { skip: true });

  const currentUser = getUser();

  useEffect(async () => {
    const userInfo = await UserService.getOtherUser(getUserQuery, id);
    // need to call a user info query here
    setUser(userInfo);
  }, []);

  const [activeOptionsStatus, setActiveOptionsStatus] = useState(
    [{ value: 1, label: 'View-Only', selected: user.portalRole == '1' },
      { value: 2, label: 'Editor', selected: user.portalRole == '2' }],
  );

  const [enabledOptions, setEnabledOptions] = useState(
    [{ value: true, label: 'Enabled - Can login to the portal', selected: user.active },
      { value: false, label: 'Deactivated - Cannot login to the portal', selected: !user.active }],
  );

  const activeStatusButtonsInput = () => activeOptionsStatus.map((option) => (
    <CustomRadioButton
      key={option.value}
      name="userStatus"
      value={option.value}
      onChange={(value) => handleActiveStatusButtonChange(value)}
      option={option}
      disabled={false}
    />
  ));

  const enabledOptionsButtonsInput = () => enabledOptions.map((option) => (
    <CustomRadioButton
      key={option.value}
      name="userStatus"
      value={option.value}
      onChange={(value) => handleEnabledStatusButtonChange(value)}
      option={option}
      disabled={false}
      size="sm"
    />
  ));

  const {
    firstName,
    lastName,
    email,
    currentOrganization,
    permissions,
  } = user;

  const parsedRole = parseRole(user.role);

  const formik = useFormik({
    initialValues: {
      firstName: user.firstName,
      lastName: user.lastName,
      role: { value: parsedRole.key, label: parsedRole.value },
      isAdmin: user.adminUser,
      email: user.email,
      portalRole: user.portalRole,
      active: user.active,
      organizationId: currentUser.currentOrganization.id,
    },
    validateOnChange: false,
    enableReinitialize: true,
    validationSchema,
    onSubmit: async (values) => {
      try {
        const input = {
          firstName: values.firstName,
          lastName: values.lastName,
          email: values.email,
          loggedInUserid: currentUser.id,
          portalRole: values.portalRole,
          isAdmin: values.isAdmin,
          id: user.id,
          organizationId: currentUser.currentOrganization.id,
          // active: values.active
        };
        if (activationSubmission) {
          input.active = values.active;
          const newUser = await UserService.updateOtherUser(updateUserMutation, id, { active: input.active, id });
          send('SET_USER', { user: newUser });
          setActivationSubmission(false);
          notification.alert('User updated successfully', 'success');
          return;
        }
        const newUser = await UserService.updateOtherUser(updateUserMutation, id, input);
        send('SET_USER', { user: newUser });
        notification.alert('User updated successfully', 'success');
      } catch ({ message }) {
        notification.alert(message, 'danger');
      }
    },
  });

  const radioButtonsInput = () => radioOptions.map((option) => (
    <CustomRadioButton
      key={option.value}
      name="tapBehavior"
      value={option.value}
      onChange={(value) => handleRadioButtonChange(value)}
      option={option}
      disabled={false}
    />
  ));

  const handleActiveStatusButtonChange = (value) => {
    formik.setFieldValue('portalRole', value);
    const newActiveOptionsStatus = activeOptionsStatus.map((option) => {
      if (option.value == value) {
        option.selected = true;
      }
      if (option.value != value) {
        option.selected = false;
      }
      return option;
    });
    setActiveOptionsStatus(newActiveOptionsStatus);
  };

  const handleEnabledStatusButtonChange = (value) => {
    formik.setFieldValue('active', value);
    return enabledOptions.map((option) => {
      if (option.value === value) {
        option.selected = true;
      }
      if (option.value !== value) {
        option.selected = false;
      }
      return option;
    });
  };

  useEffect(() => {
    handleActiveStatusButtonChange(user.portalRole);
  }, [user.portalRole]);

  useEffect(() => {
    handleEnabledStatusButtonChange(user.active);
  }, [user.active]);

  const adminStatusButtonsInput = () => activeOptionsStatus.map((option) => (
    <CustomRadioButton
      key={option.value}
      name="userStatus"
      value={option.value}
      onChange={(value) => handleActiveStatusButtonChange(value)}
      option={option}
      disabled={false}
    />
  ));

  const showProfilePopup = () => {
    setShowProfileModal(true);
    setShowOrgModal(false);
    setShowPasswordModal(false);
  };
  const showOrgPopup = () => {
    setShowOrgModal(true);
    setShowProfileModal(false);
    setShowPasswordModal(false);
  };
  const showPasswordPopup = () => {
    setShowPasswordModal(true);
    setShowOrgModal(false);
    setShowProfileModal(false);
  };
  const show2faPopup = () => {
    setShow2faModal(true);
    setShowPasswordModal(false);
    setShowOrgModal(false);
    setShowProfileModal(false);
  };
  const showActivationChangePopup = () => {
    setShowActivationModal(true);
    setShow2faModal(false);
    setShowPasswordModal(false);
    setShowOrgModal(false);
    setShowProfileModal(false);
  };
  const hideActivationChangeModalCallback = () => {
    setShowActivationModal(false);
  };
  const hidePasswordModalCallback = () => {
    setShowPasswordModal(false);
  };
  const hide2faModalCallback = () => {
    setShow2faModal(false);
  };
  const hideOrgModalCallback = () => {
    setShowOrgModal(false);
  };
  const hideProfileModalCallback = () => {
    setShowProfileModal(false);
  };


  return (
    <CustomCard
      title="Manage a User"
      size="xxl"
      isDarkBackground
      isForm
      // isFullScreen
    >
      <Container className={styles['invite-user-container']}>
        {isLoading ? (
          <Spinner
            style={{ width: '4rem', height: '4rem', margin: '12px auto' }}
            animation="border"
            role="status"
          />
        ) : (
          <>
            <Row style={{ marginBottom: '5%' }}>
              <Col>
                <Card className={styles['invite-user-card']}>
                  <Card.Body>
                    <Form
                      onSubmit={(e) => formik.handleSubmit(e)}
                    >
                      <h2>User Information</h2>
                      <Larky.Form.Input
                        type="text"
                        name="firstName"
                        title="First name"
                        placeholder="User first name"
                        value={formik.values.firstName}
                        onChange={formik.handleChange}
                        error={formik.errors.firstName?.toString()}
                      />
                      <Larky.Form.Input
                        type="text"
                        title="Last name"
                        name="lastName"
                        placeholder="User last name"
                        value={formik.values.lastName}
                        onChange={formik.handleChange}
                        error={formik.errors.lastName?.toString()}
                      />
                      <Larky.Form.Input
                        type="text"
                        size="sm"
                        name="email"
                        title="Email"
                        placeholder="User Email"
                        value={formik.values.email}
                        onChange={formik.handleChange}
                        error={formik.errors.email?.toString()}
                      />
                      <br/>
                      <h2>Permissions</h2>
                        <h5 className={styles['small-header']}>Admin Permission</h5>
                        <h6 className={styles['invite-user-span']}>
                          Admins can add new users, deactivate old users, and assign permission
                        </h6>
                        <CustomCheckbox
                          title="This user is an admin"
                          name="permissions"
                          value={formik.values.isAdmin}
                          onChange={() => formik.setFieldValue(
                            'isAdmin', !formik.values.isAdmin,
                          )}
                          size={20}
                          disabled={false}
                        />
                      <br/>
                        <h5 className={styles['small-header']}>Editing Permission</h5>
                        <h6 className={styles['invite-user-span']}>
                          Users can either have view-only permission, or be able to create/edit new nudges, campaigns and other elements
                        </h6>
                        {adminStatusButtonsInput()}
                      <Col
                        className={`${styles['profile-picture-container']} d-flex flex-column align-items-center justify-content-center`}
                        xs="12"
                        md="auto"
                      >
                        <Larky.Button
                          
                          disabled={formik.isSubmitting && !activationSubmission}
                          onClick = {async () => {
                            await setUserInfoSubmission(true);
                            await formik.submitForm();
                            await setUserInfoSubmission(false);}}
                        >

                          Save Changes
                        </Larky.Button>
                      </Col>
                    </Form>
                  </Card.Body>
                </Card>
              </Col>
              <Col>
                <Card className={styles['manage-user-card']}>
                  <Card.Body>
                    <h2>Status</h2>
                    <h6>Determines if the user can login to the portal or not</h6>
                      {enabledOptionsButtonsInput()}
                      <br/>
                    <Col
                      className={`${styles['profile-picture-container']} d-flex flex-column align-items-center justify-content-center`}
                      xs="12"
                      md="auto"
                    >
                      <Larky.Button
                        disabled={formik.isSubmitting && !userInfoSubmission}

                        onClick={async () => {
                          await setActivationSubmission(true);
                          await formik.submitForm();
                          await setActivationSubmission(false);
                        }}
                      >
                        Save Changes
                      </Larky.Button>
                    </Col>
                  </Card.Body>
                </Card>
                <Row>
                  <Col>
                    <Card style={{ background: '#f8f8f8' }}>
                      <Card.Body>
                        <h2>Authentication</h2>
                        <label className="text-primary font-weight-bold h5 mb-1 form-label">Last Login</label>
                        <span className={styles['left-margin-field']}>{user.lastPortalloginUtctimestamp ? `${convertUTCDateToLocalDate(user.lastPortalloginUtctimestamp)} ${TIME_ZONE}` : null}</span>
                        <Col
                          className={`${styles['profile-picture-container']} d-flex flex-column align-items-center justify-content-center`}
                          xs="12"
                          md="auto"
                        >
                          <br/>
                          <Larky.Button
                            // onClick={() => history.push('/update-password')}
                            onClick={showPasswordPopup}
                          >
                            Reset Password
                          </Larky.Button>
                          <Larky.Button
                            // onClick={() => history.push('/update-password')}
                            onClick={show2faPopup}
                          >
                            Reset Two Factor Authentication
                          </Larky.Button>
                        </Col>
                      </Card.Body>
                    </Card>
                  </Col>
                </Row>
              </Col>
            </Row>
            <Row
              style={{ marginTop: '5%' }}
              className={`${styles['profile-picture-container']} d-flex flex-column align-items-center justify-content-center`}
              xs="12"
              md="auto"
            >
              <Larky.Button
                outlined
                onClick={() => history.push(ROUTES.USERS.ORGANIZATION)}
              >
                Close
              </Larky.Button>
            </Row>
          </>
        )}
      </Container>
      <ChangeOrganizationModal
        show={showOrgModal}
        user={user}
        callback={hideOrgModalCallback}
      />
      <ResetPasswordModal
        show={showPasswordModal}
        callback={hidePasswordModalCallback}
        id={id}
        loggedInUserid={currentUser.id}
      />
      <Reset2faModal
        show={show2faModal}
        callback={hide2faModalCallback}
        id={id}
        loggedInUserid={currentUser.id}
        organizationId={currentUser.currentOrganization.id}
      />
    </CustomCard>
  );
}

export default ManageUser;
