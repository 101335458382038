/**
 * CampaignMessageModal
 *
 * Modal component that contains form to create/add a Nudge(message) to a Campaign
 *
 */
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';

import moment from 'moment-timezone';

import {
  Col, Modal, Row,
} from 'react-bootstrap';
import { useFormik } from 'formik';
import NotificationPreview from 'components/NotificationPreview/NotificationPreview';

import { useAuthMachineValue } from 'contexts/auth-machine.context.ts';

// Components
import { useNotification } from 'components/Notification/Notification';
import CustomSection from 'components/CustomSection/CustomSection';
import CustomInput from 'components/CustomInput/CustomInput';
import PageHeader from 'components/PageHeader/PageHeader';
import SelectableFilter from 'components/SelectableFilter/SelectableFilter';
import NudgeScore from 'components/NudgeScore/NudgeScore';

// Constants
import {
  ENVIRONMENTS,
  rfc1738UrlRegex,
  TIME_HOUR,
  TIME_MINUTE,
  TIME_TYPE,
  CADENCE_OPTIONS,
  NON_PRODUCTION_CADENCE_OPTIONS,
  WEEKDAY_OPTIONS, MESSAGE_LINK_OPTIONS,
} from 'Constants';

// Styles

// Services
import CampaignService from 'services/CampaignService';
import NudgeService from 'services/NudgeService';

// GraphQL
import { CREATE_CAMPAIGN_NUDGE_MUTATION, UPDATE_CAMPAIGN_NUDGE_MUTATION } from 'graphql/mutations/campaigns';
import { GET_NUDGE_ANALYTICS, GET_NUDGE_SCORE } from 'graphql/queries/nudges';
import { useMutation, useQuery } from '@apollo/client';
// import moment from 'moment';
import { getUserFeature } from 'Utils';
import styles from '../../../Nudges/components/CreateOrUpdateNudge/CreateOrUpdateNudge.module.scss';
import AnalyticsModal from '../../../Nudges/components/AnalyticsModal/AnalyticsModal';
import CustomButton from '../../../../components/CustomButton/CustomButton';
import validationSchema from './CampaignMessageModal.validator';
import Larky from '../../../../components/Larky';
import NudgeAnalyticsData from '../../../Nudges/components/NudgeAnalyticsData/NudgeAnalyticsData';

// TODO move the following code to a common file, so it can be access from other components
const TimeLabel = ({ title, style, children }) => (
  <div className={styles['time-label']} style={style}>
    <h6>{title}</h6>
    {children}
  </div>
);

const getUserTimezone = () => {
  return Intl.DateTimeFormat().resolvedOptions().timeZone;
};

const getDisplayTimezoneName = (timezone) => {
  const offset = moment.tz(timezone).format('Z');
  const abbreviation = moment.tz(timezone).format('z');
  // Create a more user-friendly name
  const timezoneMap = {
    'EDT': 'Eastern Time',
    'EST': 'Eastern Time',
    'CDT': 'Central Time',
    'CST': 'Central Time',
    'MDT': 'Mountain Time',
    'MST': 'Mountain Time',
    'PDT': 'Pacific Time',
    'PST': 'Pacific Time',
    // Add more mappings as needed
  };
  return timezoneMap[abbreviation] || `UTC${offset}`; // Default to UTC offset if no mapping found
};

const timezone = getUserTimezone();
// const displayTimezoneName = getDisplayTimezoneName(timezone);

const TIME_ZONE = getDisplayTimezoneName(timezone);


TimeLabel.propTypes = {
  title: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  style: PropTypes.object,
};

TimeLabel.defaultProps = {
  style: {},
};

function CampaignMessageModal({
  organizationId,
  campaignId,
  sequenceNumMax,
  onSuccessfulSubmit,
  fetchMessages,
  callback,
  show,
  setShow,
  selectedNudge,
  campaignNudges,
  setSelectedNudge,
  showAnalytics,
  readOnly,
  readOnlyUser,
  isArchived,
}) {
  const [current] = useAuthMachineValue();
  const ENVIRONMENT = current.context.user.environment;
  const notification = useNotification();
  const nudgeService = new NudgeService();

  const [initialValues, setInitialValues] = useState({});
  const [confirmationEnabled, setConfirmationEnabled] = useState(true);
  const nudgeType = 2; // time-based
  const [createCampaignMutation] = useMutation(CREATE_CAMPAIGN_NUDGE_MUTATION);
  const [updateCampaignMutation] = useMutation(UPDATE_CAMPAIGN_NUDGE_MUTATION);
  const getNudgeScoreQuery = useQuery(GET_NUDGE_SCORE, { skip: true });
  const getNudgeAnalyticsQuery = useQuery(GET_NUDGE_ANALYTICS, { skip: true });
  const [showModal, setShowModal] = useState(false);
  const [userHasMLFeatureFlag, setUserHasMLFeatureFlag] = useState(null);
  const [titleInputInFocus, setTitleInputInFocus] = useState(false);
  const [messageInputInFocus, setMessageInputInFocus] = useState(false);
  const [nudgeScoreCalculating, setNudgeScoreCalculating] = useState(false);
  const [nudgeScoreData, setNudgeScoreData] = useState(null);
  const [nudgeAnalyticsData, setNudgeAnalyticsData] = useState(null);
  const [nudgeAnalyticsDataLoading, setNudgeAnalyticsDataLoading] = useState(false);
  const [nudgeAnalyticsDataError, setNudgeAnalyticsDataError] = useState(false);
  const [userHasDeepLink, setUserHasDeepLink] = useState(false);
  const [messageLinkOptions,setmessageLinkOptions] = useState([]);

  const isDisabled = () => readOnly;

  useEffect(async () => {
        const hasdeeplink = await getUserFeature('Deeplink');
        await setUserHasDeepLink(hasdeeplink);
        await setmessageLinkOptions(MESSAGE_LINK_OPTIONS)
    }, []);


  const userIsDeeplink = () => userHasDeepLink

  const disableIsDeeplink = (disableddeeplink) => {
        if (!userIsDeeplink()) {
            return true
        }
        if (isDisabled()) {
            return true
        }
        return disableddeeplink;
        }

  useEffect(async () => {
    const hasMLScoreFeatureView = await getUserFeature('ml_nudge_score_model');
    await setUserHasMLFeatureFlag(hasMLScoreFeatureView);
  }, []);

  const cadenceOptions = () => {
    // ENVIRONMENTS[1] = production
    if (ENVIRONMENT.toLowerCase() === ENVIRONMENTS[1].value || ENVIRONMENT.toLowerCase() === 'prod') {
      return CADENCE_OPTIONS;
    }
    return NON_PRODUCTION_CADENCE_OPTIONS.concat(CADENCE_OPTIONS)
      .sort(
        (a, b) => ((a.value > b.value) ? 1 : -1),
      );
  };

  const formikDefaultValues = {
    environment: ENVIRONMENTS[1],
    nudgeType,
    name: '',
    description: '',
    title: '',
    body: '',
    url: '',
    urlLinkType: {value: 'Weblink', label: 'Web Link'},
    daysDelay: null,
    sequenceNum: null,
    scheduleTimeHour: { value: 10, label: '10' },
    scheduleTimeMinute: { value: 15, label: '15' },
    scheduleTimeType: TIME_TYPE[0], // AM
    selectedWeekDays: [],
    selectedWeekDaysEveryDay: false,
  };

  const formik = useFormik({
    initialValues: {
      ...formikDefaultValues,
      ...initialValues,
    },
    validateOnChange: false,
    enableReinitialize: true,
    validationSchema,
    // eslint-disable-next-line consistent-return
    onSubmit: async (values) => {
      try {
        let { url } = values;
        let telephoneUrl = false;
        const valid = url && url.match(/^https?:\/\//);

        if (url) {
                    const telephoneUrlStart = 'tel:';
                    if (url.slice(0, 4) === telephoneUrlStart) {
                        values.url = url;
                        telephoneUrl = true;
                    }
                    if (!valid && !telephoneUrl && values.urlLinkType.value === 'Weblink') {
                        url = `http://${values.url}`;

                        if (!rfc1738UrlRegex.test(url)) {
                            throw new Error('Please enter a valid URL');
                        }

                        values.url = url;
                    }
        }

        // start_delivery_time
        const startDeliveryDatetime = moment().toDate();
        // eslint-disable-next-line max-len

        let timeHour = values.scheduleTimeHour.value;
        // converts to 24H format if type PM
        if (values.scheduleTimeType.value.toLowerCase() === 'p' && Number(formik.values.scheduleTimeHour.value) < 12) {
          timeHour = Number(values.scheduleTimeHour.value) + 12;
        }
        if (values.scheduleTimeType.value.toLowerCase() === 'a' && Number(formik.values.scheduleTimeHour.value) > 12) {
          timeHour = Number(values.scheduleTimeHour.value) - 12;
        }
        startDeliveryDatetime.setHours(timeHour, values.scheduleTimeMinute.value, 0, 0);
        const startDeliveryTimeUTC = `${startDeliveryDatetime.getUTCHours()}:${values.scheduleTimeMinute.value}`;

        const campaignNudgePayload = {
          name: values.name,
          description: values.description,
          title: values.title,
          body: values.body,
          url: !values.url ? null : values.url,
          urlLinkType: !values.urlLinkType.value ? 'Weblink' :values.urlLinkType.value,
          daysDelay: values.daysDelay.value,
          sequenceNum: sequenceNumMax + 1,
          startDeliveryTime: startDeliveryTimeUTC,
          environment: values.environment.value,
          organizationId,
          messageId: values.messageId,
          actionId: values.actionId,
          onMon: 0,
          onTue: 0,
          onWed: 0,
          onThu: 0,
          onFri: 0,
          onSat: 0,
          onSun: 0,
        };

        if (values.selectedWeekDays && values.selectedWeekDays.length) {
          values.selectedWeekDays.forEach((weekday) => {
            if (weekday.value === 1) {
              campaignNudgePayload.onMon = 1;
            }
            if (weekday.value === 2) {
              campaignNudgePayload.onTue = 1;
            }
            if (weekday.value === 3) {
              campaignNudgePayload.onWed = 1;
            }
            if (weekday.value === 4) {
              campaignNudgePayload.onThu = 1;
            }
            if (weekday.value === 5) {
              campaignNudgePayload.onFri = 1;
            }
            if (weekday.value === 6) {
              campaignNudgePayload.onSat = 1;
            }
            if (weekday.value === 7) {
              campaignNudgePayload.onSun = 1;
            }
          });
        }

        if (selectedNudge) {
          await CampaignService.updateCampaignNudge(
            updateCampaignMutation,
            campaignId,
            campaignNudgePayload,
          );
        } else {
          await CampaignService.createCampaignNudge(
            createCampaignMutation,
            campaignId,
            campaignNudgePayload,
          );
        }

        notification.alert('Campaign nudge created successfully', 'success');
        formik.resetForm();
        return true;
      } catch (e) {
        notification.alert(e.message, 'danger');
      }
    },
  });

  const determineNudgeScore = async (field) => {
    if (field === 'out' || !field) {
      setNudgeScoreCalculating(true);
      // This will call on updating of the body/title fields to set up nudge ML score info
      if (formik.values.body || formik.values.title) {
        const nudgeScoreResponse = await NudgeService.getNudgeScore(
          getNudgeScoreQuery,
          formik.values.body || ' ',
          formik.values.title || ' ',
        );
        setNudgeScoreData(nudgeScoreResponse);
        setNudgeScoreCalculating(false);
        return null;
      }

      // This will call on loading of the page to set up nudge ML score info
      if (selectedNudge) {
        if (selectedNudge.message || selectedNudge.title) {
          const nudgeScoreResponse = await NudgeService.getNudgeScore(
            getNudgeScoreQuery,
            selectedNudge.message || ' ',
            selectedNudge.title || ' ',
          );
          setNudgeScoreData(nudgeScoreResponse);
          setNudgeScoreCalculating(false);
          return null;
        }
      }

      // This will call when initially creating a nudge to set up nudge ML score info
      const nudgeScoreResponse = await NudgeService.getNudgeScore(
        getNudgeScoreQuery,
        formik.values.body || ' ',
        formik.values.title || ' ',
      );
      setNudgeScoreData(nudgeScoreResponse);
      setNudgeScoreCalculating(false);
      return null;
    }
    return null;
  };

  const retrieveNudgeAnalytics = async (actionId) => {
    const nudgeAnalyticsDataResponse = await NudgeService.getNudgeAnalytics(
      getNudgeAnalyticsQuery,
      actionId,
    );
    await setNudgeAnalyticsData(nudgeAnalyticsDataResponse);
    return null;
  };

  useEffect(async () => {
    if (selectedNudge) {
      await setNudgeAnalyticsDataLoading(true);
      try {
        await retrieveNudgeAnalytics(selectedNudge.actionId);
      } catch (e) {
        setNudgeAnalyticsDataError(true);
      }
      await setNudgeAnalyticsDataLoading(false);
      return () => {};
    }
  }, [selectedNudge]);

  useEffect(async () => {
    await determineNudgeScore(titleInputInFocus);
  }, [titleInputInFocus]);

  useEffect(async () => {
    await determineNudgeScore(messageInputInFocus);
  }, [messageInputInFocus]);

  useEffect(async () => {
    await determineNudgeScore();
  }, [selectedNudge]);
  useEffect(() => {
    if (!selectedNudge) {
      setInitialValues({ ...formikDefaultValues });
    }
    let fullNudgeData = null;

    if (campaignNudges.hits && selectedNudge) {
      campaignNudges.hits.forEach((nudge) => {
        if (nudge.actionId === selectedNudge.actionId) {
          fullNudgeData = nudge;
        }
      });

      const daysDelayFormat = () => {
        const allCadenceOptions = cadenceOptions();
        let correctFormattedCadence = null;
        for (let i = 0; i < allCadenceOptions.length; i++) {
          if (allCadenceOptions[i].value === parseInt(fullNudgeData.daysDelay)) {
            correctFormattedCadence = allCadenceOptions[i];
          }
        }
        return correctFormattedCadence;
      };

      const localTimeConversionFromUTC = (deliveryTime) => {
        const cdt = moment(deliveryTime, 'HH:mm');
        const inputTimeFormatted = cdt.format('YYYY/MM/DD HH:mm');
        const localDateTimeFromUTC = new Date(`${inputTimeFormatted} UTC`);
        const momentDateConversion = moment(localDateTimeFromUTC, 'HH:mm');
        const localTime = momentDateConversion.format('HH:mm');
        const localTimeHour = localTime.split(':')[0];
        const localTimeMinute = localTime.split(':')[1];
        const amOrPmCheck = localTimeHour >= 12 ? { value: 'p', label: 'PM' } : { value: 'a', label: 'AM' };

        const amOrPmLabelAndValueDetermination = (time) => {
          if (time === '00' || time === '12') {
            return { value: '00', label: '12' };
          }

          return { value: time, label: time > 12 ? (time - 12).toString() : time.toString() };
        };

        return {
          hour: amOrPmLabelAndValueDetermination(localTimeHour),
          minute: {
            value: localTimeMinute,
            label: localTimeMinute
              ? localTimeMinute.toString() : initialValues.scheduleTimeMinute.label,
          },
          type: amOrPmCheck,
        };
      };

      const convertedTimeHourMinuteType = localTimeConversionFromUTC(
        fullNudgeData.startDeliveryTime,
      );

      const convertWeekdaysSelected = () => {
        const weekDayConversionPayload = [];
        if (fullNudgeData && fullNudgeData.onMon) {
          weekDayConversionPayload.push(WEEKDAY_OPTIONS[0]);
        }
        if (fullNudgeData && fullNudgeData.onTue) {
          weekDayConversionPayload.push(WEEKDAY_OPTIONS[1]);
        }
        if (fullNudgeData && fullNudgeData.onWed) {
          weekDayConversionPayload.push(WEEKDAY_OPTIONS[2]);
        }
        if (fullNudgeData && fullNudgeData.onThu) {
          weekDayConversionPayload.push(WEEKDAY_OPTIONS[3]);
        }
        if (fullNudgeData && fullNudgeData.onFri) {
          weekDayConversionPayload.push(WEEKDAY_OPTIONS[4]);
        }
        if (fullNudgeData && fullNudgeData.onSat) {
          weekDayConversionPayload.push(WEEKDAY_OPTIONS[5]);
        }
        if (fullNudgeData && fullNudgeData.onSun) {
          weekDayConversionPayload.push(WEEKDAY_OPTIONS[6]);
        }
        return weekDayConversionPayload;
      };

      const selectedNudgeData = {
        name: fullNudgeData.name,
        body: fullNudgeData.message,
        sequenceNum: fullNudgeData.sequenceNum,
        title: fullNudgeData.title,
        daysDelay: daysDelayFormat(),
        description: fullNudgeData.description,
        url: fullNudgeData.url,
        urlLinkType: MESSAGE_LINK_OPTIONS.find(
                            ({value}) => value === fullNudgeData.urlLinkType
                        ),
        startDeliveryTime: fullNudgeData.startDeliveryTime,
        messageId: fullNudgeData.messageId,
        actionId: fullNudgeData.actionId,
        scheduleTimeHour: convertedTimeHourMinuteType.hour,
        scheduleTimeMinute: convertedTimeHourMinuteType.minute,
        scheduleTimeType: convertedTimeHourMinuteType.type,
        selectedWeekDays: convertWeekdaysSelected(),
      };
      setInitialValues({ ...selectedNudgeData });
    }
  }, [selectedNudge]);

  const handleClose = () => {
    setShow(false);
    setSelectedNudge(null);
    formik.resetForm();
  };

  const handleCloseWithConfirmation = async () => {
    // check if values are not deeply equal from initial values
    if (!formik.dirty) {
      setConfirmationEnabled(true);
      handleClose();
    } else if (confirmationEnabled) {
      // prevents creating multiple instances of fullScreenDialogCardConfirm if the user
      // presses the `esc` key
      setConfirmationEnabled(false);
      const res = await notification.fullScreenDialogCardConfirm(
        '',
        'Are you sure you want to lose changes to this nudge?',
        '',
        'Lose Changes',
      );
      setConfirmationEnabled(true);
      if (res) {
        handleClose();
      }
    }
  };

  const handleSubmitForm = async () => {
    // Formik tip, helpful for debugging
    //   .catch(function(e) {
    //     console.log(e);
    //   });
    const resp = await formik.submitForm();
    if (resp) {
      // run only if submission was successful
      onSuccessfulSubmit(true);
      fetchMessages(true);
      handleClose();
    }
  };

  const showAnalyticsPopup = () => {
    setShowModal(true);
  };
  const hideModalCallback = () => {
    setShowModal(false);
  };

  // TODO: Take these funcs + line 604 and 703 of create nudge and break out into helper and import into both
  const isSelectedWeekDaysEveryDay = formik.values.selectedWeekDays
    && formik.values.selectedWeekDays.length === WEEKDAY_OPTIONS.length;

  const handleChangeOptionalValue = (name, value, fieldName, fieldValue) => {
    formik.setFieldValue(name, value);
    formik.setFieldValue(fieldName, value ? fieldValue : []);
  };

  const nudgeName = formik.values.name;
  const showAnalyticsButton = () => {
    if (selectedNudge && showAnalytics) {
      return (
        <div className={styles.showAnalyticsBtn}>
          <Larky.Button
            size="lg"
            onClick={showAnalyticsPopup}
          >
            Show Full Analytics
          </Larky.Button>
        </div>
      );
    }
    return <></>;
  };
  const getTitle = () => {
    if (readOnly) return 'View nudge for this campaign';
    return `${selectedNudge ? 'Edit' : 'Create'} a nudge for this campaign`;
  };

  const recheckscore = async (values) => {
    setNudgeScoreCalculating(true);

    // This will call on updating of the body/title fields to set up nudge ML score info
    if (formik.values.body || formik.values.title) {
        const nudgeScoreResponse = await NudgeService.getNudgeScore(
          getNudgeScoreQuery,
          formik.values.body || ' ',
          formik.values.title || ' ',
        );
        setNudgeScoreData(nudgeScoreResponse);
        setNudgeScoreCalculating(false);
        return null;
    }

    //This will call on loading of the page to set up ML nudge score info
    if (selectedNudge) {
        if (selectedNudge.message || selectedNudge.title) {
          const nudgeScoreResponse = await NudgeService.getNudgeScore(
            getNudgeScoreQuery,
            selectedNudge.message || ' ',
            selectedNudge.title || ' ',
          );
          setNudgeScoreData(nudgeScoreResponse);
          setNudgeScoreCalculating(false);
          return null;
        }
      }

    // This will call when initially creating a nudge to set up nudge ML score info
    const nudgeScoreResponse = await NudgeService.getNudgeScore(
          getNudgeScoreQuery,
          formik.values.body || ' ',
          formik.values.title || ' '
        );
    setNudgeScoreData(nudgeScoreResponse)
    setNudgeScoreCalculating(false)
    return null;
  };

  const handleFormikAddEmoji = async (valueToChange, newValue) => {
    const newVal = formik.values[valueToChange].concat(newValue);
    await formik.setFieldValue(valueToChange, newVal);
  };

  return (
    <>
      <Modal dialogClassName={styles['modal-xl']} show={show} size="xl" onHide={handleCloseWithConfirmation} animation={false} backdrop="static">
        <Modal.Header closeButton>
          <Row style={{ paddingLeft: '3em' }}>
            <PageHeader
              title={getTitle()}
              subtitle=""
            />
          </Row>
        </Modal.Header>
        <Modal.Body>
          <Row>
            <Col style={{ flex: 7 }}>
              <Row className={styles.section}>
                <CustomSection
                  title="Message details"
                >
                </CustomSection>
              </Row>
                  <Row className={styles.section}>
                    <CustomSection title="Organize your nudge">
                   <h6 style={{marginLeft: 3}}>None of this will be visible to your recipients</h6>
                  <CustomInput
                    type="text"
                    name="name"
                    title="Name your message"
                    placeholder="e.g. Auto loan promo"
                    value={formik.values.name}
                    onChange={formik.handleChange}
                    error={formik.errors.name}
                    disabled={isDisabled()}
                  />
                  <CustomInput
                    type="textarea"
                    name="description"
                    title="Give your message a description"
                    placeholder="e.g. Promote low interest rates"
                    value={formik.values.description}
                    onChange={formik.handleChange}
                    error={formik.errors.description}
                    disabled={isDisabled()}
                  />
                 </CustomSection>
                 </Row>
                 <Row className={styles.section}>
                  <CustomSection title="Write your nudge">
                  <CustomInput
                    type="text"
                    name="title"
                    title="Give your nudge a title"
                    placeholder="e.g. Don’t let these great rates pass you by!"
                    value={formik.values.title}
                    onChange={formik.handleChange}
                    error={formik.errors.title}
                    disabled={isDisabled()}
                    showEmoji
                    emojiCallback={handleFormikAddEmoji}
                    fieldToEdit="title"
                    setIsFocusedCallback={(boolVal) => {
                      const isInFocus = boolVal ? 'in' : 'out';
                      setTitleInputInFocus(isInFocus);
                    }}

                  />
                  <CustomInput
                    type="textarea"
                    name="body"
                    title="Write your nudge message"
                    placeholder="e.g. Finance your next new or used car with our low interest rates! Tap to learn more"
                    value={formik.values.body}
                    onChange={formik.handleChange}
                    error={formik.errors.body}
                    disabled={isDisabled()}
                    showEmoji
                    emojiCallback={handleFormikAddEmoji}
                    fieldToEdit="body"
                    inModal
                    setIsFocusedCallback={(boolVal) => {
                      const isInFocus = boolVal ? 'in' : 'out';
                      setMessageInputInFocus(isInFocus);
                    }}
                  />
                  <div style={{ display: 'flex', alignItems: 'center', gap: '10px' }}>
                  <div style={{ flexGrow: 1 }}>
                      <CustomInput
                          type="text"
                          name="url"
                          title="Provide the destination for when the nudge is tapped"
                          description=""
                          placeholder="e.g. mainstreetsavings.com or https://mainstreetsavings.com/loans?utm_source=nudge"
                          value={formik.values.url}
                          onChange={formik.handleChange}
                          error={formik.errors.url}
                          disabled={isDisabled()}
                      />
                  </div>



                  <div style={{ flexBasis: '20%', alignSelf: 'flex-end' }}>
                      <CustomInput
                        type="select"
                        id="urlLinkType"
                        name="urlLinkType"
                        title=""
                        onChange={(value) => formik.setFieldValue('urlLinkType', value)}
                        disabled={disableIsDeeplink()}
                        value={formik.values.urlLinkType}
                        options={messageLinkOptions}
                        align={formik.errors.url ? 1: null}
                      />

                  </div>
              </div>
                  </CustomSection>
                  </Row>

              <Row className={styles.section}>
                <CustomSection title="Delivery details">
                  <h4 style={{ marginBottom: '1em' }}>Choose the cadence for when this nudge will be sent to the recipient</h4>
                  <CustomInput
                    type="select"
                    name="selectCadence"
                    title="Choose how many days after last nudge this nudge should be sent"
                    placeholder="Select..."
                    description="Note: for the very first nudge, this is the number of days after the campaign start date"
                    onChange={(daysDelay) => formik.setFieldValue('daysDelay', daysDelay)}
                    value={formik.values.daysDelay}
                    error={formik.errors.daysDelay}
                    options={cadenceOptions()}
                    disabled={isDisabled()}
                  />
                  <div className={styles['week-days-wrapper']} style={{ flexWrap: 'wrap' }}>
                    <CustomInput
                      type="select"
                      name="selectedWeekDays"
                      title="Choose days of the week when this nudge can be delivered"
                      placeholder="Select..."
                      options={WEEKDAY_OPTIONS}
                      onChange={(values) => formik.setFieldValue('selectedWeekDays', values)}
                      value={formik.values.selectedWeekDays}
                      error={formik.errors.selectedWeekDays}
                      disabled={isDisabled()}
                      isMulti
                      style={{ flex: 1 }}
                    />
                    <SelectableFilter
                      className={styles.label}
                      label="Every day"
                      name="selectedWeekDaysEveryDay"
                      value={Boolean(
                        formik.values.selectedWeekDaysEveryDay
                          || isSelectedWeekDaysEveryDay,
                      )}
                      onChange={(value) => handleChangeOptionalValue(
                        'selectedWeekDaysEveryDay',
                        value,
                        'selectedWeekDays',
                        WEEKDAY_OPTIONS,
                      )}
                      disabled={isDisabled()}
                    />
                  </div>
                  <h4 className={styles['form-question-header-with-margin']}>
                    Choose time of day when nudge should be sent
                  </h4>
                  <div className={styles.horizontal} style={{ display: 'flex', flexWrap: 'nowrap', width: '50%', alignItems: 'center' }}>
                  <TimeLabel title="Starting:" style={{ marginRight: 12 }}>
                    <CustomInput
                      type="select"
                      name="scheduleTimeHour"
                      title=""
                      placeholder="10"
                      onChange={(hour) => { formik.setFieldValue('scheduleTimeHour', hour); }}
                      value={formik.values.scheduleTimeHour}
                      error={formik.errors.scheduleTimeHour}
                      options={TIME_HOUR}
                      disabled={isDisabled()}
                    />
                    <CustomInput
                      type="select"
                      name="scheduleTimeMinute"
                      title=""
                      placeholder="00"
                      onChange={(minute) => formik.setFieldValue('scheduleTimeMinute', minute)}
                      value={formik.values.scheduleTimeMinute}
                      error={formik.errors.scheduleTimeMinute}
                      options={TIME_MINUTE}
                      disabled={isDisabled()}
                    />
                    <CustomInput
                      type="select"
                      name="scheduleTimeType"
                      title=""
                      placeholder={TIME_TYPE[0].label}
                      onChange={(type) => formik.setFieldValue('scheduleTimeType', type)}
                      value={formik.values.scheduleTimeType}
                      error={formik.errors.scheduleTimeType}
                      options={TIME_TYPE}
                      disabled={isDisabled()}
                    />
                  </TimeLabel>
                  <div style={{ marginLeft: 'auto', paddingTop: 7, whiteSpace: 'nowrap' }}>
                    {TIME_ZONE}
                  </div>
                </div>

                </CustomSection>
              </Row>
              <Row className={styles.section}>
                <CustomSection header>
                  <Row style={{ justifyContent: 'center' }}>
                    <CustomButton
                      variant="outlined"
                      size="sm"
                      text="Cancel"
                      onClick={() => {
                        handleCloseWithConfirmation();
                      }}
                    />
                    {!readOnly
                    && (
                    <CustomButton
                      variant="contained"
                      size="sm"
                      text="Save"
                      onClick={() => {
                        callback(true);
                        handleSubmitForm();
                      }}
                      disabled={formik.isSubmitting || isDisabled()}
                    />
                    )}
                  </Row>
                </CustomSection>
              </Row>
            </Col>
            {/* NOTIFICATION PREVIEW */}
            <Col className={styles.floating} style={{ flex: 3 }}>
              <CustomSection className={styles['custom-card']}>
                {selectedNudge && showAnalytics ? (
                    <>
                    <NudgeAnalyticsData
                      nudgeAnalyticsData={nudgeAnalyticsData}
                      loading={nudgeAnalyticsDataLoading}
                      error={nudgeAnalyticsDataError}
                    />
                    {showAnalyticsButton()}
                  </>
                ) : null}
              {(!isDisabled() && userHasMLFeatureFlag)
                || (readOnlyUser && userHasMLFeatureFlag && !isArchived) ? (
                  <>
                    <NudgeScore
                      title={formik.values.title}
                      message={formik.values.body}
                      isFloating
                      loading={nudgeScoreCalculating}
                      nudgeScoreData={nudgeScoreData}
                      toolTipText={(true) ? 'Your implementation doesn’t include Nudge Score. Please contact support@larky.com for more information.' : null}
                      onButtonClick={recheckscore}
                      featureflag={userHasMLFeatureFlag}
                    />
                  </>
                  ) : null}
                <NotificationPreview
                  title={formik.values.title}
                  message={formik.values.body}
                  isFloating
                />
              </CustomSection>
            </Col>
          </Row>
        </Modal.Body>
      </Modal>
      {selectedNudge && showAnalytics ? (
        <AnalyticsModal
          organizationId={organizationId}
          show={showModal}
          callback={hideModalCallback}
          nudgeId={selectedNudge ? selectedNudge.messageId : null}
          actionId={selectedNudge ? selectedNudge.actionId : null}
          title="Campaign Nudge Analytics"
          text={nudgeName}
          disableAnalyticsExport
        />
      ) : null}
    </>
  );
}

CampaignMessageModal.propTypes = {
  organizationId: PropTypes.number.isRequired,
  campaignId: PropTypes.oneOfType([
    PropTypes.number,
    PropTypes.string,
  ]).isRequired,
  sequenceNumMax: PropTypes.number,
  onSuccessfulSubmit: PropTypes.func.isRequired,
  fetchMessages: PropTypes.func.isRequired,
  callback: PropTypes.func.isRequired,
  show: PropTypes.bool.isRequired,
  setShow: PropTypes.func.isRequired,
  showAnalytics: PropTypes.bool.isRequired,
  selectedNudge: PropTypes.shape({
    messageId: PropTypes.string,
    actionId: PropTypes.string,
  }),
  campaignNudges: PropTypes.shape({}),
  setSelectedNudge: PropTypes.func,
  readOnly: PropTypes.bool,
};

CampaignMessageModal.defaultProps = {
  sequenceNumMax: 0,
  selectedNudge: { messageId: null, actionId: null },
  campaignNudges: null,
  setSelectedNudge: null,
  readOnly: false,
};

export default CampaignMessageModal;
