// import React, { useEffect } from 'react';
//
// function openCenteredPopup(url, width, height) {
//   const screenWidth = window.innerWidth;
//   const screenHeight = window.innerHeight;
//   const left = (screenWidth - width) / 2 + window.screenX;
//   const top = (screenHeight - height) / 2 + window.screenY;
//
//   return window.open(url, `hubspot-popup-${Date.now()}`, `width=${width},height=${height},left=${left},top=${top}`);
// }
//
// const HubSpotModal = ({ isOpen, onRequestClose, hubspoturl }) => {
//   useEffect(() => {
//     if (isOpen) {
//       const popup = openCenteredPopup(hubspoturl, 800, 800);
//
//       const interval = setInterval(() => {
//         if (popup.closed) {
//           clearInterval(interval);
//           onRequestClose();
//         }
//       }, 1000);
//
//       return () => {
//         clearInterval(interval);
//       };
//     }
//   }, [isOpen, hubspoturl, onRequestClose]);
//
//   return isOpen ? (
//     <div className="modal">
//       <button onClick={onRequestClose}>Close</button>
//     </div>
//   ) : null;
// };
//
// export default HubSpotModal;


import React, { useEffect } from 'react';

const HubSpotModal = ({ isOpen, onRequestClose, hubspoturl }) => {
  useEffect(() => {
    if (isOpen) {
      // Redirect to the HubSpot URL
      window.location.href = hubspoturl;
    }
  }, [isOpen, hubspoturl]);

  return (
    isOpen ? (
      <div className="modal">
        <button onClick={onRequestClose}>Close</button>
        {/* Optionally display a loading message or spinner */}
      </div>
    ) : null
  );
};

export default HubSpotModal;
