/* eslint-disable jsx-a11y/label-has-associated-control */
import React from 'react';
import PropTypes from 'prop-types';

import styles from './CustomRadioButton.module.scss';

function CustomRadioButton({
  name,
  option,
  title,
  description,
  onChange,
  disabled,
  size,
}) {
  return (
    <div className={`${styles.root} ${styles[size]}`}>
      {title && <h5>{title}</h5>}
      {description && <h6>{description}</h6>}
      <div className={styles.radioInput}>
        <input
          className={styles.input}
          type="radio"
          name={name}
          value={option.value}
          onChange={() => onChange(option.value)}
          checked={option.selected}
          disabled={disabled}
        />
        <label className={styles.label}>{option.label}</label>
      </div>
    </div>
  );
}

const optionItemShape = PropTypes.shape(
  { label: PropTypes.string, value: PropTypes.value, selected: PropTypes.bool },
);

CustomRadioButton.propTypes = {
  name: PropTypes.string,
  option: optionItemShape.isRequired,
  title: PropTypes.string,
  description: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  size: PropTypes.string,
  disabled: PropTypes.bool,
};

CustomRadioButton.defaultProps = {
  name: '',
  title: null,
  description: null,
  size: 'lg',
  disabled: false,
};

export default CustomRadioButton;
