import React, {
  useEffect,
  Children,
  isValidElement,
  cloneElement,
} from 'react';

import PropTypes from 'prop-types';

import { useQuery } from '@apollo/client';

import { Spinner } from 'react-bootstrap';
import { useNotification } from 'components/Notification/Notification';

import Analytics from 'libs/Analytics';
import { getUser } from 'Utils';

import { CHECK_ORG_KEY } from 'graphql/queries/organizations';

import OrganizationService from 'services/OrganizationService';

const FILE_NAME = 'ApiKeyCheck.jsx';

function ApiKeyGuard({ check, children }) {
  const notification = useNotification();
  const user = getUser();

  const checkOrgKeyQuery = useQuery(CHECK_ORG_KEY, { skip: true });

  useEffect(() => {
    async function checkOrganizationId() {
      const res = await OrganizationService.checkOrgKey(
        checkOrgKeyQuery,
        user.currentOrganization.id,
      );
      if (!res) {
        return () => {};
      }
      Analytics.trackApplicationError(res, FILE_NAME, 'ApiKeyGuard');
      notification.fullscreenAlert(
        'Inactive key',
        'Your organization does not have an active API key. Nudges will NOT be sent. Please contact your administrator.',
      );
    }
    if (check && user) {
      checkOrganizationId();
    }
  }, [check, user, checkOrgKeyQuery, notification]);

  if (!check) {
    return children;
  }

  const childrenWithProps = Children.map(children, (child) => {
    if (isValidElement(child) && user && user.currentOrganization.id) {
      return cloneElement(child, { organizationId: user.currentOrganization.id });
    }

    return (
      <div style={{ width: '100%', display: 'flex', justifyContent: 'center' }}>
        <Spinner
          style={{ width: '5rem', height: '5rem', margin: '64px auto' }}
          animation="border"
          role="status"
        >
          <span className="sr-only">Loading...</span>
        </Spinner>
      </div>
    );
  });

  return childrenWithProps;
}

ApiKeyGuard.propTypes = {
  check: PropTypes.bool,
  children: PropTypes.node.isRequired,
};

ApiKeyGuard.defaultProps = {
  check: false,
};

export default ApiKeyGuard;