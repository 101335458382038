import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import * as Yup from 'yup';
import { useParams, useHistory } from 'react-router-dom';
import {
  Col,
  Container,
  Form,
  Row,
} from 'react-bootstrap';
import { useFormik } from 'formik';

import { useMutation, useQuery } from '@apollo/client';

import {
  ROUTES,
  REQUIRED_FIELD,
} from 'Constants';

import PageHeader from 'components/PageHeader/PageHeader';
import CustomSection from 'components/CustomSection/CustomSection';
import Larky from 'components/Larky';
import { useNotification } from 'components/Notification/Notification';

// Utils
import {
  formatNumber, getUserFeature,
} from 'Utils';

import Analytics from 'libs/Analytics';
import LocationService from 'services/LocationService';

// GraphQL
import {
  CREATE_LOCATION_MUTATION,
  UPDATE_LOCATION_MUTATION,
} from 'graphql/mutations/locations';
import { GET_LOCATION } from 'graphql/queries/locations';

import styles from './CreateOrUpdateLocation.module.scss';
import {useInitialFocus} from "../../../../hooks/useInitialFocus";

const ANALYTICS_PAGE_CREATE = 'Create a Location';
const ANALYTICS_PAGE_UPDATE = 'Update a Location';
const FILE_NAME = 'locationform.jsx';
const FLOAT_ROUND_VALUE = 100000;

function handleAddressComponents(addressComponents, formValues) {
  const routeType = 'route';
  const stateType = 'administrative_area_level_1';
  const typeToFormField = {
    country: 'country',
    street_number: 'address',
    postal_town: 'city',
    locality: 'city',
    postal_code: 'zip',
  };
  Object.assign(typeToFormField, {
    [routeType]: 'address',
    [stateType]: 'state',
  });
  addressComponents.forEach((component) => {
    // GOOGLE'S EXAMPLE DOES types[0]!!!: https://developers.google.com/maps/documentation/javascript/examples/places-autocomplete-addressform
    const type = component.types[0];
    const formField = typeToFormField[type];
    if (typeof formField !== 'undefined') {
      switch (type) {
        case routeType:
          Object.assign(formValues, { [formField]: `${formValues[formField] === undefined ? '' : formValues[formField]} ${component.long_name}` });
          break;
        case stateType:
          Object.assign(formValues, { [formField]: component.short_name });
          break;
        default:
          Object.assign(formValues, { [formField]: component.long_name });
          break;
      }
    }
  });

  return formValues;
}

const validationSchema = () => Yup.object({
  autocomplete: Yup.string()
    .when('isUpdate', (val, schema) => (val ? schema : schema.trim().required(REQUIRED_FIELD))),
  name: Yup.string().trim().required(REQUIRED_FIELD),
  description: Yup.string().trim(),
  latitude: Yup.number().required(REQUIRED_FIELD),
  longitude: Yup.number().required(REQUIRED_FIELD),
});

const geoParams = [
  { key: 'address', title: 'Street Address:' },
  { key: 'city', title: 'City:' },
  { key: 'state', title: 'State:' },
  { key: 'zip', title: 'Zip:' },
  { key: 'country', title: 'Country:' },
  { key: 'latitude', title: 'Latitude:' },
  { key: 'longitude', title: 'Longitude:' },
];

function CreateOrUpdateLocation({ organizationId }) {
  const history = useHistory();
  const notification = useNotification();
  const locationService = new LocationService();
  useInitialFocus()

  const { id } = useParams();
  const locationId = id;

  const isUpdate = typeof locationId !== 'undefined';

  const [createLocationMutation] = useMutation(CREATE_LOCATION_MUTATION);
  const [updateLocationMutation] = useMutation(UPDATE_LOCATION_MUTATION);

  const getLocationQuery = useQuery(GET_LOCATION, { variables: { id: locationId }, skip: true });

  const [isAutocompleteInit, setIsAutocompleteInit] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [isLocationGroup, setIsLocationGroup] = useState(false);
  const [locationDetails, setLocationDetails] = useState({});

  const [userHasLocationEditPermission, setUserHasLocationEditPermission] = useState(null);

  useEffect(async () => {
    const hasLocationEditPermission = await getUserFeature('geolocations', 2);
    setUserHasLocationEditPermission(hasLocationEditPermission);
  }, []);

  const formik = useFormik({
    initialValues: {
      autocomplete: '',
      name: '',
      description: '',
      latitude: null,
      longitude: null,
      address: null,
      city: null,
      state: null,
      zip: null,
      country: null,
      isUpdate,
    },
    validateOnChange: false,
    enableReinitialize: true,
    validationSchema,
    onSubmit: async ({
      name,
      description,
      latitude,
      longitude,
      address,
      city,
      state,
      zip,
      country,
    }) => {
      try {
        const locationPayload = {
          name,
          description,
          latitude,
          longitude,
          address,
          city,
          state,
          zip,
          country,
        };

        let response;
        if (isUpdate) {
          response = await LocationService.update(
            updateLocationMutation,
            locationId,
            locationPayload,
          );
        } else {
          Object.assign(locationPayload, { organizationId });
          response = await LocationService.create(createLocationMutation, locationPayload);
        }

        const eventName = isUpdate ? Analytics.UPDATED_LOCATION : Analytics.CREATED_LOCATION;
        Analytics.track(eventName, Analytics.locationToAnalyticsProperties(
          locationPayload,
          response,
        ));

        history.push(`${ROUTES.LOCATIONS.MAIN}${isUpdate ? '' : '?autoupdate=true'}`);
      } catch (err) {
        notification.alert(err.message, 'danger');
        Analytics.trackApplicationError(err.message, FILE_NAME, 'onSubmit');
      }
    },
  });

  const isDisabled = () => {
    if (!userHasLocationEditPermission) {
      return true
    }
    if (formik.isSubmitting) {
      return true;
    }
    return false;
  }

  const locationButtons = () => {
    if (isLocationGroup) {
      return (
        <Larky.Button
          className="mr-2"
          outlined
          size="xl"
          onClick={() => history.goBack()}
        >
          Close
        </Larky.Button>
      );
    }
    return (
      <>
        <Larky.Button
          className="mr-2"
          outlined
          size="xl"
          onClick={() => history.goBack()}
        >
          Cancel
        </Larky.Button>

        <Larky.Button
          size="xl"
          disabled={isDisabled()}
          onClick={() => formik.submitForm()}
          className={formik.isSubmitting || (isDisabled()) ? styles['disable-type-button'] : ''}
        >
          {`${isUpdate ? 'Update' : 'Add'} Location`}
        </Larky.Button>
      </>
    );
  };

  const sidebarBox = () => {
    if (isLocationGroup) {
      const locationCount = (locationDetails.address && !Number.isNaN(locationDetails.address)) ? formatNumber(locationDetails.address) : '';
      return (
        <div>
          <h6>{'Location Count: '}</h6>
          <span>{`${locationCount}`}</span>
        </div>
      );
    }
    return (
      geoParams.map(({ key, title }) => (
        <div key={key}>
          <h6>{title}</h6>
          <span>{formik.values[key]}</span>
        </div>
      ))
    );
  };

  useEffect(() => {
    function initializeGoogleAutocomplete() {
      if (window.google && !isAutocompleteInit) {
        const UNITED_STATES_BOUNDS = new window.google.maps.LatLngBounds(
          new window.google.maps.LatLng(31.321967, -119.824831),
          new window.google.maps.LatLng(65.351378, -25.647274),
        );
        const autocomplete = new window.google.maps.places.Autocomplete(document.getElementById('autocomplete'), {
          bounds: UNITED_STATES_BOUNDS,
        });
        window.google.maps.event.addListener(autocomplete, 'place_changed', () => {
          const gLocation = autocomplete.getPlace();
          if (gLocation.geometry) {
            const newFormValues = handleAddressComponents(
              gLocation.address_components,
              {
                autocomplete: gLocation.formatted_address,
                latitude: Math.round(gLocation.geometry.location.lat() * FLOAT_ROUND_VALUE)
                  / FLOAT_ROUND_VALUE,
                longitude: Math.round(gLocation.geometry.location.lng() * FLOAT_ROUND_VALUE)
                  / FLOAT_ROUND_VALUE,
              },
            );
            Object.keys(newFormValues)
              .forEach((key) => formik.setFieldValue(key, newFormValues[key]));
          }
        });
        setIsAutocompleteInit(true);
        Analytics.trackPageView(isUpdate ? ANALYTICS_PAGE_UPDATE : ANALYTICS_PAGE_CREATE);
      }
    }
    async function fetch() {
      if (isUpdate && formik.values.latitude === null && formik.values.longitude === null) {
        const data = await LocationService.index(getLocationQuery, locationId);
        setIsLocationGroup(data.isLocationGroup);
        setLocationDetails(data);
        formik.setValues({ ...formik.values, ...data });
      }
      setIsLoading(false);
    }
    fetch();
    initializeGoogleAutocomplete();
  }, [formik, isAutocompleteInit, locationId, locationService, isUpdate, getLocationQuery]);

  return (
    <Container className="p-sm-5 p-3" fluid>
      <PageHeader
        title={`${isUpdate ? 'Update' : 'Add'} a Location`}
        subtitle="Create and reuse locations to be used by your nudges and campaigns"
      />

      <Row>
        <Col className="col-12">
          <Form onSubmit={formik.handleSubmit}>
            <Container fluid>
              <Row>
                <Col className="mb-4" md={7}>
                  <CustomSection>
                    {!isLocationGroup && (
                      <Larky.Form.Input
                        id="autocomplete"
                        type="text"
                        name="autocomplete"
                        title="Type your location"
                        placeholder="Enter a location..."
                        size="lg"
                        value={formik.values.autocomplete}
                        onChange={formik.handleChange}
                        error={formik.errors.autocomplete}
                        disabled={isLocationGroup}
                      />
                    )}
                    <Larky.Form.Input
                      type="text"
                      name="name"
                      title="Name your location"
                      placeholder="Type..."
                      size="lg"
                      value={formik.values.name}
                      onChange={formik.handleChange}
                      error={formik.errors.name}
                      onKeyPress={({ key }) => { if (key === 'Enter') formik.submitForm(); }}
                    />
                    <Larky.Form.Input
                      name="description"
                      title="Give your location a description"
                      description="Only you will see this"
                      placeholder="Type..."
                      textarea
                      size="lg"
                      value={formik.values.description}
                      onChange={formik.handleChange}
                      error={formik.errors.description}
                    />
                  </CustomSection>
                </Col>
                <Col md={5}>
                  <CustomSection className={styles['custom-card']}>
                    { !isLoading && sidebarBox() }
                  </CustomSection>
                </Col>
              </Row>

              <Row className="justify-content-center py-5">
                { !isLoading && locationButtons() }
              </Row>

            </Container>
          </Form>
        </Col>
      </Row>
    </Container>
  );
}

CreateOrUpdateLocation.propTypes = {
  organizationId: PropTypes.number,
};

CreateOrUpdateLocation.defaultProps = {
  organizationId: null,
};

export default CreateOrUpdateLocation;
