import { Machine, interpret, assign } from 'xstate';

const defaultContext = {
  message: '',
  type: '',
  variant: 'primary',
  delay: 5,
};

export const notificationMachine = Machine(
  {
    id: 'notification',
    initial: 'hidden',
    context: defaultContext,
    states: {
      hidden: {
        on: {
          DISPLAY: {
            target: 'displayed',
            actions: assign<any>({
              message: (ctx: any, evt: any) => evt.message,
              type: (ctx: any, evt: any) => evt.type,
              delay: (ctx: any, evt: any) => evt.delay,
              variant: (ctx: any, evt: any) => evt.variant,
            }),
          },
        },
      },
      displayed: {
        on: {
          HIDE: {
            target: 'displayed',
            actions: assign<any>({
              variant: (ctx: any, evt: any) => evt.variant,
            }),
          },
        },
        after: { HIDDE: 'hidden' },
        exit: assign({ ...defaultContext }),
      },
    },
  },
  {
    delays: {
      HIDDE: (ctx: any, evt: any) => Number(ctx.delay || evt.delay) * 1000,
    },
  },
);

export const notificationService = interpret(notificationMachine).start();
