import React, { useEffect, useState } from 'react';
import { Row, Form, Spinner } from 'react-bootstrap';
import PropTypes from 'prop-types';
import CustomSection from 'components/CustomSection/CustomSection';
import { useNotification } from 'components/Notification/Notification';
import Analytics from 'libs/Analytics';
import SegmentService from 'services/SegmentService';
import { useQuery } from '@apollo/client';
import styles from '../CreateOrUpdateSegment/CreateOrUpdateSegment.module.scss';
import validateFederationCSVFile from './FileUpload.validator';
import { CALCULATE_FILE_SEGMENT } from '../../../../graphql/queries/segments';
import FileUploadErrors from './FileUploadErrors';
import ImportSegmentFileSummary from './ImportSegmentFileSummary';

const FILE_NAME = 'FileUpload.jsx';

function FileUpload({
  organizationId,
  userId,
  setFileDataToUpload,
  setMembershipTotalCount,
  setIsFileSelected,
  title,
  header,
  disabled,
  error,
  selectFileWarning,
}) {
  const notification = useNotification();
  const [selectedFile, setSelectedFile] = useState({});
  const [segmentStats, setSegmentStats] = useState({});
  const [displaySummary, setDisplaySummary] = useState(false);
  const [fileHasError, setFileHasError] = useState(false);
  const [fileErrors, setFileError] = useState([]);
  const [isLoading, setIsLoading] = useState(false); // Internal isLoading state
  const calculateSegmentFilDataQuery = useQuery(CALCULATE_FILE_SEGMENT, { skip: true });

  useEffect(() => {
    if (error) {
      setFileHasError(true);
      setFileError([{ detail: error }]);
    }
  }, [error]);

  const handleFileInput = async (file) => {
    if (!file) {
      // File input is undefined or null, reset the state
      setFileHasError(false);
      setSelectedFile({});
      setIsLoading(false);
      setSegmentStats({});
      setDisplaySummary(false);
      setFileError([]);
      setIsFileSelected(false);
      setFileDataToUpload(null);
      return;
    }

    // reset file errors and selected file
    setFileHasError(false);
    setSelectedFile({});
    setIsLoading(true); // Start loading

    // handle validations
    const result = await validateFederationCSVFile(file);
    const hasError = Object.prototype.hasOwnProperty.call(result, 'error');
    if (hasError) {
      setFileHasError(true);
      setFileError([{ detail: result.error }]);
      setIsLoading(false); // Stop loading on error
    } else {
      // Try sending file info to the backend
      const { federationIds } = result;
      try {
        const calcSegmentResponse = await SegmentService.calculateSegmentFileData(
          calculateSegmentFilDataQuery,
          organizationId,
          userId,
          federationIds,
        );
        setSegmentStats(calcSegmentResponse.segmentStats);
        setMembershipTotalCount(calcSegmentResponse.count);
        setDisplaySummary(true);
        setIsFileSelected(true);
        setFileDataToUpload(federationIds);
        setIsLoading(false); // Stop loading after success
      } catch (err) {
        Analytics.trackApplicationError(err.message, FILE_NAME, 'handleFileInput');
        notification.alert('Failed to upload file', 'danger');
        const errorMessage = JSON.parse(err.message);
        setFileHasError(true);
        setIsFileSelected(false);
        setFileError([...fileErrors, ...errorMessage.errors]);
        setIsLoading(false); // Stop loading on error
      }
    }
  };

  return (
    <div className={styles.root}>
      <CustomSection title={title}>
        <h4>{header}</h4>
        <Row style={{ paddingLeft: 25, paddingTop: 10 }}>
          <Form.Group controlId="formFile" className="mb-3">
            <Form.Control
              onChange={(e) => {
                const file = e.target.files[0] || null;
                setSelectedFile(file);
                handleFileInput(file);
              }}
              type="file"
              accept=".csv"
              disabled={disabled}
            />
          </Form.Group>
        </Row>
        {isLoading && (
          <Row className="justify-content-center">
            <Spinner animation="border" role="status">
              <span className="sr-only">Loading...</span>
            </Spinner>
          </Row>
        )}
        {fileHasError && !isLoading && (
          <Row>
            <FileUploadErrors errors={fileErrors} />
          </Row>
        )}
        {!fileHasError && displaySummary && !isLoading && (
          <Row>
            <ImportSegmentFileSummary segmentSummary={segmentStats} isLoading={isLoading} />
          </Row>
        )}
       {selectFileWarning ? (<p className={styles.alert}>{selectFileWarning}</p>) : (null)}
      </CustomSection>
    </div>
  );
}

FileUpload.propTypes = {
  organizationId: PropTypes.number,
  userId: PropTypes.string,
  header: PropTypes.string,
  title: PropTypes.string,
  setFileDataToUpload: PropTypes.func,
  setMembershipTotalCount: PropTypes.func,
  setIsFileSelected: PropTypes.func,
  disabled: PropTypes.bool,
  error: PropTypes.string,
};

FileUpload.defaultProps = {
  organizationId: null,
  userId: null,
  header: null,
  title: null,
  setFileDataToUpload: null,
  setMembershipTotalCount: null,
  setIsFileSelected: null,
  disabled: false,
  error: null,
};

export default FileUpload;
