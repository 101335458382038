import * as Yup from 'yup';

import {
  REQUIRED_FIELD,
} from 'Constants';

const validationSchema = () => Yup.object({
  description: Yup.string()
    .trim()
    .required(REQUIRED_FIELD),
  title: Yup.string()
    .trim()
    .required(REQUIRED_FIELD),
  cadence: Yup.string()
    .trim()
    .required(REQUIRED_FIELD),
  notes: Yup.string()
    .trim()
    .required(REQUIRED_FIELD)
});

export default validationSchema;
