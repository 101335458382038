import React from 'react';
import PropTypes from 'prop-types';

import Select from 'react-select';

import {useHistory} from 'react-router-dom';
import styles from './RowActionsDropdown.module.scss';

const RowActionsDropdown = ({
                                value,
                                placeholder,
                                onArchiveRow,
                                onDeleteRow,
                                onRefreshRow,
                                onInviteUser,
                                editRowPath,
                                onCloneRow,
                                onEditRow,
                                onShowAnalyticsRow,
                                options,
                                isMulti,
                                error,
                                size,
                                className,
                            }) => {
    const history = useHistory();
    const onChange = (event) => {
        if (event.value === 'archive') {
            onArchiveRow();
        }
        if (event.value === 'edit') {
            // eslint-disable-next-line no-unused-expressions
            onEditRow && !editRowPath ? onEditRow() : history.push(editRowPath);
        }
        if (event.value === 'clone') {
            onCloneRow();
        }
        if (event.value === 'delete') {
            onDeleteRow();
        }
        if (event.value === 'refresh') {
            onRefreshRow();
        }
        if (event.value === 'invite') {
            onInviteUser();
        }
        if (event.value === 'analytics') {
            onShowAnalyticsRow();
        }
    };


    const colourStyles = {
        control: (provided) => ({
            ...provided,
            borderRadius: '.5em',
            borderColor: '#2FBFFE',
            color: '#343231',
        }),
        option: (styles, { data }) => {
            return {
                ...styles,
                backgroundColor: data && data.isLineBreak ? '#ced4da' : null,
                borderColor: '#2FBFFE',
                color: '#343231',
                cursor: data && data.isLineBreak ? null : 'pointer'
            };
        },
        input: styles => ({...styles}),
        placeholder: styles => ({...styles}),
        singleValue: (styles, {data}) => ({...styles}),
    };


    return (
        <div className={`${styles.root} ${styles[size]}`}>

            <Select
                className={`${styles.children} ${className}`}
                isMulti={isMulti}
                placeholder={placeholder}
                value={value}
                onChange={onChange}
                options={options}
                closeMenuOnSelect={!isMulti}
                isSearchable={false}
                styles={colourStyles}
            />
            {error && <span className={styles.error}>{error}</span>}
        </div>
    );
};

const optionItemShape = PropTypes.shape({label: PropTypes.string, value: PropTypes.value});

RowActionsDropdown.propTypes = {
    value: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.number,
        optionItemShape,
        PropTypes.arrayOf(optionItemShape),
    ]),
    placeholder: PropTypes.string,
    onEditRow: PropTypes.func,
    options: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.number,
        optionItemShape,
        PropTypes.arrayOf(optionItemShape),
    ]),
    isMulti: PropTypes.bool,
    error: PropTypes.string,
    size: PropTypes.string,
    className: PropTypes.string,
    onArchiveRow: PropTypes.func,
    onDeleteRow: PropTypes.func,
    onRefreshRow: PropTypes.func,
    onInviteUser: PropTypes.func,
    editRowPath: PropTypes.string,
    onCloneRow: PropTypes.func,
    onShowAnalyticsRow: PropTypes.func,
};

RowActionsDropdown.defaultProps = {
    value: null,
    placeholder: null,
    onEditRow: () => null,
    options: [],
    isMulti: false,
    error: null,
    size: 'lg',
    className: '',
    onArchiveRow: () => null,
    onDeleteRow: () => null,
    onRefreshRow: () => null,
    onInviteUser: () => null,
    editRowPath: '',
    onCloneRow: () => null,
    onShowAnalyticsRow: () => null,
};

export default RowActionsDropdown;
