import React from 'react';
import PropTypes from 'prop-types';

import { Button, Spinner } from 'react-bootstrap';

interface Props {
  className?: string;
  children: any;
  disabled?: boolean;
  outlined?: boolean;
  danger?: boolean;
  isLoading?: boolean;
  size?: 'sm' | 'lg';
  type?: string;
  dataCy: string;
  onClick?: any;
  [prop: string]: any;
}

const LButton = ({
  className,
  children,
  disabled,
  outlined,
  danger,
  isLoading,
  size,
  type,
  dataCy,
  onClick,
  ...props
}: Props) => {
  let btnClass = `${className} font-weight-bold px-4 m-1 flex-grow-1 flex-md-grow-0`;
  if (danger) {
    btnClass += ' bg-white btn-danger text-white';  
  } else if (outlined) {
    btnClass += ' text-primary bg-white';
  } else {
    btnClass += ' text-white'
  }

  return (
    <Button
      data-cy={`${dataCy}-button`}
      className={btnClass}
      disabled={disabled}
      size={size}
      onClick={onClick}
      type={type}
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...props}
    >
      {isLoading ? (
        <Spinner as="span" animation="border" size="sm" aria-hidden="true" />
      ) : (
          children
        )}
    </Button>
  );
};

LButton.propTypes = {
  className: PropTypes.string,
  disabled: PropTypes.bool,
  isLoading: PropTypes.bool,
  children: PropTypes.node,
  outlined: PropTypes.bool,
  danger: PropTypes.bool,
  size: PropTypes.string,
  type: PropTypes.string,
  dataCy: PropTypes.string,
  onClick: PropTypes.func,
};

LButton.defaultProps = {
  className: '',
  disabled: false,
  isLoading: false,
  outlined: false,
  danger: false,
  children: '',
  size: '',
  type: '',
  dataCy: '',
  onClick: null,
};

export default LButton;
