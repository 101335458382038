import React, { useState, useEffect } from 'react';
import { useFormik } from 'formik';
import UserService from 'services/UserService';
import { Col, Row } from 'react-bootstrap';
import { REGISTER_USER_PHONE_NUMBER, VERIFY_SMS_CODE } from 'graphql/mutations/users';
import { useMutation } from '@apollo/client';
import PropTypes from 'prop-types';
import useAuthorization from '../../../hooks/useAuthentication';
import styles from './TwoFactorAuth.module.scss';
import CustomInput from '../../../components/CustomInput/CustomInput';
import Larky from '../../../components/Larky';
import CustomCheckbox from 'components/CustomCheckbox/CustomCheckbox';
import CustomButton from "../../../components/CustomButton/CustomButton";
import {ROUTES, TOKEN_EXPIRED_LOGIN_DISPATCHER} from "../../../Constants";
import {useHistory} from "react-router-dom";

const TwoFactorAuthConfirmationSms = ({ displayCallback, userInfo }) => {
  const history = useHistory();
  const [verificationSendWrongMessage, setVerificationSendWrongMessage] = useState(null);
  const [verificationSubmitWrongMessage, setVerificationSubmitWrongMessage] = useState(null);
  const [phoneConfirmation, setPhoneConfirmation] = useState(false);
  const [registerPhoneNumberMutation] = useMutation(REGISTER_USER_PHONE_NUMBER);
  const [verifySmsCodeMutation] = useMutation(VERIFY_SMS_CODE);
  const { rsaPhone } = userInfo;

  const handleClose = () => {
  window.location.href = `/login?dispatcher=${TOKEN_EXPIRED_LOGIN_DISPATCHER}`;
  };

  const formik = useFormik({
    initialValues: {
      phoneNumber: rsaPhone || '',
      phoneNumberVerificationCode: '',
      rememberMe: false,
    },
    validateOnChange: false,
    enableReinitialize: true,
    // eslint-disable-next-line consistent-return
  });

  const handleRequestCode = async () => {
    try {
      const registerPhoneNumberInput = {
        userId: userInfo.id,
        phoneNumber: formik.values.phoneNumber,
        existingConfirmation: true,
      };
      const phoneNumberSent = await UserService.registerUserPhoneNumber(
        registerPhoneNumberMutation, registerPhoneNumberInput,
      );
      if (phoneNumberSent) {
        setPhoneConfirmation(true);
      }
    // await formik.submitForm();
    } catch (e) {
      setVerificationSendWrongMessage('An error has occurred, please contact us by clicking the support link below');
    }
  };

  const handleRememberMe = async () => {
    await formik.setFieldValue('rememberMe', !formik.values.rememberMe);
  };

  const handleVerifyCode = async () => {
    try {
      const verifySmsCodeInput = {
        userId: userInfo.id,
        otp: formik.values.phoneNumberVerificationCode,
        phoneNumber: formik.values.phoneNumber,
        existingConfirmation: true,
        rememberMe: formik.values.rememberMe,
      };
      const smsCodeVerification = await UserService.verifyUserSmsCode(
        verifySmsCodeMutation, verifySmsCodeInput,
      );
      if (smsCodeVerification && smsCodeVerification.ok) {
        await useAuthorization.setIsAuthorized();
        const userSet = { ...userInfo, isAuthorized: true };
        displayCallback(userSet, false);
      }
      if (smsCodeVerification && !smsCodeVerification.ok) {
        setVerificationSubmitWrongMessage('Something is not right. Please check the code or click the link below to contact support for further assistance');
      }
    } catch (e) {
      setVerificationSubmitWrongMessage('Something is not right. Please check the code or click the link below to contact support for further assistance.');
    }
  };

  return (
    <div style={{ width: '100%' }}>
      <form>
        <h4>{phoneConfirmation ? `We sent an authentication code to the number ending in ${rsaPhone.slice(-4)}` : `We'll send an authentication code to the number ending in ${rsaPhone.slice(-4)}`}</h4>
        <Row className="align-items-center justify-content-center mb-4">
          <Col className="col-auto">
            <Larky.Button
              type="button"
              onClick={handleRequestCode}
            >
              {' '}
              {phoneConfirmation ? 'Resend Code' : 'Send Confirmation Code'}
            </Larky.Button>
          </Col>
          {verificationSendWrongMessage ? <div className={styles['verification-wrong-message']}>{verificationSendWrongMessage}</div> : null}
        </Row>
        {phoneConfirmation ? (
          <>
            <h4>Enter the code sent to the device to continue:</h4>
            <CustomInput
              type="text"
              name="phoneNumberVerificationCode"
              placeholder="Input Verification Code"
              value={formik.values.phoneNumberVerificationCode}
              onChange={formik.handleChange}
              onKeyPress={(e) => {
                if (e.key === 'Enter') {
                  e.preventDefault();
                  handleVerifyCode();
                }
              }}
            />
            <Row className="align-items-center justify-content-center mb-4">
              {verificationSubmitWrongMessage
                ? <div className={styles['verification-wrong-message']}>{verificationSubmitWrongMessage}</div> : null}
              <Col className="col-auto">
                <CustomCheckbox
                  value={formik.values.rememberMe}
                  onChange={() => handleRememberMe()}
                  title="Don't require SMS authentication for the rest of the day"
                />
              </Col>
              <Col className="col-auto">

               <Larky.Button
                      type="button"
                      outlined
                      onClick={() => {
                        handleClose();
                      }} > {' '} Cancel
               </Larky.Button>

                <Larky.Button
                  type="button"
                  onClick={handleVerifyCode}
                >
                  {' '}
                  Verify Code
                </Larky.Button>
              </Col>
            </Row>
          </>
        ) : null}
        <Row className="align-items-center justify-content-center mb-4">
          Need help?
          <Col className="col-auto">
            <a data-cy="contact-support" href="mailto:support@larky.com">Contact Support</a>
          </Col>
        </Row>
      </form>
    </div>
  );
};

TwoFactorAuthConfirmationSms.propTypes = {
  displayCallback: PropTypes.func,
  userInfo: PropTypes.shape({
    id: PropTypes.string.isRequired,
    rsaPhone: PropTypes.string.isRequired,
  }),
};

TwoFactorAuthConfirmationSms.defaultProps = {
  displayCallback: () => null,
  userInfo: {
    id: '',
    rsaPhone: '',
  },
};

export default TwoFactorAuthConfirmationSms;
