import React from 'react';
import PropTypes from 'prop-types';

import RowBadge from 'components/CustomTable/RowBadge/RowBadge';
import { colorCampaignStatusAlignment } from 'Utils';
import moment from 'moment';
import RowActionsDropdown from '../../../../components/CustomTable/RowActionsDropdown/RowActionsDropdown';
import styles from '../../../Nudges/components/NudgeRow/NudgeRow.module.scss';

function CampaignRow({
  data,
  handleArchiveRow,
  handleDeleteRow,
  hasEditPermission,
  handleShowAnalytics,
}) {
  const getDescription = () => (data.description.length > 100 ? data.description.slice(0, 100).concat(' ...') : data.description);
  const getCreated = () => (moment(data.created).format('MM/DD/YYYY'));
  const getStartDateDeliveryDetails = () => (data.startDate ? `Start Date: ${moment(data.startDate).format('MM/DD/YYYY')} ` : 'Start Date: Not Set ');
  const getEndDateDeliveryDetails = () => (data.endDate ? `End Date: ${moment(data.endDate).format('MM/DD/YYYY')}` : 'End Date: Not Set');

  const getDeliveryDetailsMessages = () => (`Total Nudges: ${data.totalMessages}`);
  const archivedStatus = !!(data.statusDescription === 'Archived' || data.archived);
  // TODO: determine correct data.status number
  const pendingStatus = !!(data.statusDescription === 'Pending' || data.status === 1);
  const createCampaignOptionList = () => {
    const listOfOptions = [];
    listOfOptions.push({
      label: hasEditPermission && !archivedStatus ? 'View/Edit campaign' : 'View campaign',
      value: 'edit',
    });
    if (handleArchiveRow && hasEditPermission) {
      listOfOptions.push({ label: null, value: null, isLineBreak: true });
      listOfOptions.push({ label: archivedStatus ? 'Unarchive campaign' : 'Archive campaign', value: 'archive' });
    }
    if (handleDeleteRow && hasEditPermission) {
      listOfOptions.push({ label: 'Delete campaign', value: 'delete' });
    }
    return listOfOptions;
  };

  return (
    <tr>
      <td className={styles.customCell}>
        {data.statusDescription
                    && (
                    <RowBadge color={colorCampaignStatusAlignment(data.statusDescription)}>
                      {data.statusDescription}
                    </RowBadge>
                    )}
      </td>
      <td>{data.campaignCategoryValue}</td>
      <td>{data.name}</td>
      <td>{getDescription()}</td>
      <td>{getCreated()}</td>
      <td>
        {getStartDateDeliveryDetails()}
        <br />
        {getEndDateDeliveryDetails()}
        <br />
        {getDeliveryDetailsMessages()}
      </td>
      <td>
        <RowActionsDropdown
          editRowPath={`/campaigns/edit/${data.id}`}
          onDeleteRow={handleDeleteRow}
          archived={archivedStatus}
          onArchiveRow={handleArchiveRow}
          hasEditPermission={hasEditPermission}
          onShowAnalyticsRow={handleShowAnalytics}
          options={createCampaignOptionList()}
          placeholder="Actions..."
        />
      </td>
    </tr>
  );
}

CampaignRow.propTypes = {
  data: PropTypes.shape({
    id: PropTypes.oneOfType([
      PropTypes.number, PropTypes.string,
    ]).isRequired,
    name: PropTypes.string.isRequired,
    description: PropTypes.string.isRequired,
    statusDescription: PropTypes.string.isRequired,
    totalMessages: PropTypes.number.isRequired,
    created: PropTypes.string.isRequired,
    updated: PropTypes.string,
    activeRecipients: PropTypes.string,
    statsNumerator30days: PropTypes.string,
    statsDenominator30days: PropTypes.string,
    statsPercent30days: PropTypes.string,
    status: PropTypes.number,
    archived: PropTypes.bool,
  }).isRequired,
  handleShowAnalytics: PropTypes.func,
  handleArchiveRow: PropTypes.func.isRequired,
  handleDeleteRow: PropTypes.func,
  hasEditPermission: PropTypes.bool,
};

CampaignRow.defaultProps = {
  handleDeleteRow: null,
  hasEditPermission: false,
  handleShowAnalytics: () => null,
};

export default CampaignRow;
