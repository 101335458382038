import { gql } from '@apollo/client';

export const CREATE_CAMPAIGN_MUTATION = gql`
  mutation CreateCampaignMutation($input: CampaignInput!) {
    createCampaign(input: $input) {
      id
    }
  }
`;

export const UPDATE_CAMPAIGN_MUTATION = gql`
    mutation UpdateCampaignMutation($campaignId: ID!,$campaignInput: CampaignInput!){
        updateCampaign(campaignId: $campaignId, campaignInput: $campaignInput){
            ok
        }
    }
`;

export const ACTIVATE_CAMPAIGN_MUTATION = gql`
    mutation UpdateCampaignMutation($campaignId: ID!,$campaignInput: CampaignInput!){
        updateCampaign(campaignId: $campaignId, campaignInput: $campaignInput){
            ok
        }
    }
`;

export const CREATE_CAMPAIGN_NUDGE_MUTATION = gql`
    mutation CreateCampaignNudgeMutation($campaignId: ID!,$campaignNudgeInput: CampaignNudgeInput!){
        createCampaignNudge(campaignId: $campaignId, campaignNudgeInput: $campaignNudgeInput){
            ok
        }
    }
`;

export const UPDATE_CAMPAIGN_NUDGE_MUTATION = gql`
    mutation CreateCampaignNudgeMutation($campaignId: ID!,$campaignNudgeInput: CampaignNudgeInput!){
        updateCampaignNudge(campaignId: $campaignId, campaignNudgeInput: $campaignNudgeInput){
            ok
        }
    }
`;

export const REORDER_CAMPAIGN_NUDGES_MUTATION = gql`
    mutation ReorderCampaignNudgesMutation($campaignId: ID!,$reorderCampaignNudgesInput: ReorderCampaignNudgesInput!){
        reorderCampaignNudges(campaignId: $campaignId, campaignOrder: $reorderCampaignNudgesInput){
            ok
        }
    }
`;

export const ARCHIVE_CAMPAIGN_MUTATION = gql`
  mutation ArchiveCampaignMutation($id: ID!, $input: ArchiveCampaignInput!) {
    archiveCampaign(id: $id, input: $input) {
      ok
    }
  }
`;

export const UPDATE_CAMPAIGN_TEMPLATE = gql`
  mutation UpdateCampaignTemplateMutation($templateId: ID!, $campaignTemplateInput: CampaignTemplateInput!) {
    updateCampaignTemplate(id: $templateId, campaignTemplateInput: $campaignTemplateInput) {
      ok
    }
  }
`;

export const CREATE_TEMPLATE_FROM_CAMPAIGN_MUTATION = gql`
  mutation CreateTemplateFromCampaignMutation($campaignId: ID!, $campaignTemplateInput: CampaignTemplateInput!) {
    createTemplateFromCampaign(id: $campaignId, campaignTemplateInput: $campaignTemplateInput) {
      ok
    }
  }
`;

export const CREATE_CAMPAIGN_FROM_TEMPLATE_MUTATION = gql`
  mutation CreateCampaignFromTemplateMutation($templateId: ID!, $createCampaignFromTemplateInput: CreateCampaignFromTemplateInput!) {
    createCampaignFromTemplate(id: $templateId, createCampaignFromTemplateInput: $createCampaignFromTemplateInput) {
      campaignId
    }
  }
`;

export const UPDATE_CAMPAIGN_ORIGINAL_TEMPLATE_MUTATION = gql`
    mutation UpdateCampaignOriginalTemplateMutation($templateId: ID!, $updateTemplateFromCampaignInput: UpdateTemplateFromCampaignInput!){
        updateCampaignOriginalTemplate(id: $templateId, updateTemplateFromCampaignInput: $updateTemplateFromCampaignInput){
            ok
        }
    }
`;
