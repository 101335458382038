import React from 'react';
import PropTypes from 'prop-types';

import { Form } from 'react-bootstrap';

const Header = ({ label, title, description }) => {
  let titleComponent = null;

  if (title) {
    titleComponent = (
      <Form.Label className="text-primary font-weight-bold h5 mb-1">
        {title}
      </Form.Label>
    );
  }

  if (label) {
    titleComponent = (
      <Form.Label className="text-primary font-weight-bold">
        {label}
      </Form.Label>
    );
  }

  return (
    <>
      {titleComponent}
      {
        description && (
          <>
            <br />
            <Form.Label className="text-primary h5">
              {description}
            </Form.Label>
          </>
        )
      }
    </>
  );
};

Header.propTypes = {
  label: PropTypes.string,
  title: PropTypes.string,
  description: PropTypes.string,
};

Header.defaultProps = {
  label: '',
  title: '',
  description: '',
};

export default Header;
