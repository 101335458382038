/**
 * CampaignGallery.jsx
 *
 * Component that displays Campaigns that are predefined for clients.
 *
 */
import React, {useEffect, useState} from 'react';
import { useHistory } from 'react-router-dom';
import PropTypes from 'prop-types';

// styles
import styles from '../CampaignGallery/CampaignGallery.module.scss';
import {Col, Container, Row} from "react-bootstrap";
import Larky from "../../../../components/Larky";

//service
import CampaignService from 'services/CampaignService';

//constants
import {ROUTES} from 'Constants';

//GraphQL
import {GET_CAMPAIGN_TEMPLATES} from '../../../../graphql/queries/campaigns'
import {
  UPDATE_CAMPAIGN_TEMPLATE,
  CREATE_CAMPAIGN_FROM_TEMPLATE_MUTATION,
  CREATE_TEMPLATE_FROM_CAMPAIGN_MUTATION
} from '../../../../graphql/mutations/campaigns'

// components
import InitialLoader from 'components/InitialLoader/InitialLoader';
import PageHeader from 'components/PageHeader/PageHeader';
import CustomSection from 'components/CustomSection/CustomSection';
import CustomButton from 'components/CustomButton/CustomButton';
import CustomInput from 'components/CustomInput/CustomInput';
import CampaignTemplateModalEdit from './CampaignTemplateEditModal/CampaignTemplateEditModal'
import { useNotification } from 'components/Notification/Notification';
import CampaignGalleryCard from './CampaignGalleryCard/CampaignGalleryCard';
import {useMutation, useQuery} from "@apollo/client";

import { useAuthMachineValue } from 'contexts/auth-machine.context.ts';
import {useInitialFocus} from "../../../../hooks/useInitialFocus";
import {getUserFeature} from "../../../../Utils";


const CampaignGallery = ({}) => {
  const [currentAuth] = useAuthMachineValue();
  const { user } = currentAuth.context;
  const isTemplateCreator = user && user.featureFlags && user.featureFlags.template_creator > 0;
  const history = useHistory();
  useInitialFocus()


  const getCampaignTemplatesQuery = useQuery(GET_CAMPAIGN_TEMPLATES, {skip:true})
  const [createCampaignFromTemplateMutation] = useMutation(CREATE_CAMPAIGN_FROM_TEMPLATE_MUTATION);
  const [updateCampaignTemplateMutation] = useMutation(UPDATE_CAMPAIGN_TEMPLATE);

  const [campaignTemplates, setCampaignTemplates] = useState(null)
  const [showModal, setShowModal] = useState(false)
  const [modalBeingEdited, setModalBeingEdited] = useState(null)
  const [userHasCampaignCreateFeature, setUserHasCampaignCreateFeature] = useState(false)

  const createCampaignFromTemplateInput = {
    updatedBy: user.id
  }

  useEffect(async () => {
    const hasCampaignTemplateFeature = await getUserFeature('campaign_templates', 2);
    const hasCampaignGalleryFeature = await getUserFeature('campaign_gallery', 2);
    await setUserHasCampaignCreateFeature(hasCampaignTemplateFeature || hasCampaignGalleryFeature);
  }, []);

  useEffect(() => {
    const getCampaignTemplate = async () => {
      const campaignTemplateData = await CampaignService.getCampaignTemplates(
        getCampaignTemplatesQuery,
        isTemplateCreator ? 1 : 0
      );
    setCampaignTemplates(campaignTemplateData)
    }
    getCampaignTemplate()
  }, [modalBeingEdited])

  const editModalCallback = (id) => {
    setShowModal(true);
    setModalBeingEdited(id)
  }

  const createCampaignFromTemplate = async (id) => {
    const newCampaignId = await CampaignService.createCampaignFromTemplate  (
       createCampaignFromTemplateMutation, id, createCampaignFromTemplateInput
    );
    if (newCampaignId) {
      history.push(`${ROUTES.CAMPAIGNS.EDIT}/${newCampaignId.campaignId}`);
    }
  }

  const activateCampaignTemplate = async (id, campaignTemplateInput ) => {
    try {
      let resp = null;
      let campaignTemplateActiveInput = {...campaignTemplateInput, isDraft: !campaignTemplateInput.isDraft}
      resp = await CampaignService.updateCampaignTemplate(
        updateCampaignTemplateMutation, id, campaignTemplateActiveInput, campaignTemplateInput.isDraft ? 'Publish' : 'Un-publish', user.id
      );
      if (resp) {
        const campaignTemplateSwitchActivation = campaignTemplates.hits.find(campaign => campaign.id === id)
        const activatedHit = campaignTemplates.hits.map((template) => {
          if (template.id === id) {
            return {...template, isDraft: !template.isDraft}
          }
          else {
            return template
          }
        })
        setCampaignTemplates({...campaignTemplates, hits: activatedHit})
      }
    }
    catch (e) {
      console.log(e)
    }
  }

  return ( campaignTemplates && campaignTemplates.hits ? (
    <>
    <div className={styles.root}>
      <Row>
          <Col style={{ flex: 7 }}>
            {showModal ? (
              <CampaignTemplateModalEdit
                show={showModal}
                setShow={setShowModal}
                templateInfo={modalBeingEdited}
                callback={setModalBeingEdited}
                updatedBy={user.id}
              />
              ) : null
            }
            <Row className={styles.section}>
              <CustomSection header>
                <PageHeader
                  title={'Campaign Gallery'}
                  subtitle="Organize a set of nudges together in one coordinated campaign"
                  subtitle2={''}
                />
                <Col xs={12} sm="auto">
                  <Row className={styles.container}>
                    <section className={styles.cards}>
                      {
                        campaignTemplates.hits.map((campaignTemplate, index) => (
                          <CampaignGalleryCard
                            key={campaignTemplate.id}
                            id={campaignTemplate.id}
                            title={campaignTemplate.title}
                            description={campaignTemplate.description}
                            cadence={campaignTemplate.cadence}
                            notes={campaignTemplate.notes}
                            campaignId={campaignTemplate.campaignId}
                            editable={isTemplateCreator}
                            onEditClick={() => editModalCallback(campaignTemplate)}
                            updatedBy={user.id}
                            onCreateClick={() => createCampaignFromTemplate(campaignTemplate.id)}
                            isDraft={campaignTemplate.isDraft}
                            handleActivateOrDeactivate={
                              activateCampaignTemplate
                            }
                            isTemplateCreator={isTemplateCreator}
                            userId={user.id}
                            hasWritePermission={userHasCampaignCreateFeature}
                          />
                        ))
                      }
                    </section>
                  </Row>
                </Col>
              </CustomSection>
            </Row>
          </Col>
        </Row>
    </div>
    </>
  ): null)
};

CampaignGallery.propTypes = {};

CampaignGallery.defaultProps = {
};

export default CampaignGallery;
