import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router-dom';

import { Col, Row, Container } from 'react-bootstrap';
import { ROUTES, PAGE_HEADER_BUTTONS } from 'Constants';
import Larky from '../Larky';

function PageHeader({
  title,
  subtitle,
  subtitle2,
  hasEditPermission
}) {
  const [mainButton, setMainButton] = useState(null);
  const [buttons, setButtons] = useState([]);
  const history = useHistory();
  const displaySubtitle2 = () => {
    if (subtitle2) {
      return (<h4 data-cy="page-header-subtitle">{subtitle2}</h4>);
    }
    return (<></>);
  };

  useEffect(() => {
    let mainBtn = null;
    const btns = [];
    const { pathname } = history.location;

    const isInNudgesPages = [
      ROUTES.NUDGES.MAIN,
      ROUTES.NUDGES.ARCHIVED,
      ROUTES.NUDGES.DRAFTS,
    ].includes(pathname);

    const isInLocationsPages = [
      ROUTES.LOCATIONS.MAIN,
      ROUTES.LOCATIONS.ARCHIVED,
    ].includes(pathname);

    const isInUsersPages = [
      ROUTES.USERS.MAIN,
    ].includes(pathname);

    const isInSegmentPages = [
      ROUTES.SEGMENTS.MAIN,
      ROUTES.SEGMENTS.BASE,
    ].includes(pathname);

    const isInCampaignPages = [
      ROUTES.CAMPAIGNS.MAIN,
      ROUTES.CAMPAIGNS.ARCHIVED,
      ROUTES.CAMPAIGNS.BASE,
    ].includes(pathname);

    const updateButtons = (pageName) => Object.keys(PAGE_HEADER_BUTTONS[pageName])
      .filter((name) => name !== 'CREATE')
      .forEach((name) => {
        const page = PAGE_HEADER_BUTTONS[pageName][name];
        if (page.path !== pathname) {
          btns.push(page);
        }
      });

    if (isInNudgesPages) {
      if (!hasEditPermission) {
        return null;
      }
      mainBtn = (PAGE_HEADER_BUTTONS.NUDGES.CREATE);
      updateButtons('NUDGES');
    }
    if (isInLocationsPages) {
      if (!hasEditPermission) {
        return null;
      }
      mainBtn = (PAGE_HEADER_BUTTONS.LOCATIONS.CREATE);
      updateButtons('LOCATIONS');
    }
    if (isInUsersPages) {
      mainBtn = (PAGE_HEADER_BUTTONS.USERS.CREATE);
      updateButtons('USERS');
    }
    if (isInSegmentPages) {
      if (!hasEditPermission) {
        return null;
      }
      mainBtn = (PAGE_HEADER_BUTTONS.SEGMENTS.CREATE);
      updateButtons('SEGMENTS');
    }
    if (isInCampaignPages) {
      if (!hasEditPermission) {
        return null;
      }
      mainBtn = (PAGE_HEADER_BUTTONS.CAMPAIGNS.CREATE);
      updateButtons('CAMPAIGNS');
    }

    setMainButton(mainBtn);
    setButtons(btns);
  }, [history, hasEditPermission]);

  return (
    <Row className="mb-4 justify-content-between">
      <Col xs={12} md="auto">
        <h1 data-cy="page-header-title">{title}</h1>
        <h4 data-cy="page-header-subtitle">{subtitle}</h4>
        {displaySubtitle2()}
      </Col>

      <Col xs={12} sm="auto">
        <Container fluid>
          <Row className="justify-content-md-end">
            {mainButton && (
              <Larky.Button
                data-cy="page-header-mainbutton"
                size="sm"
                onClick={() => history.push(mainButton.path)}
              >
                {mainButton.text}
              </Larky.Button>
            )}
          </Row>
          <Row className="justify-content-md-end">
            {buttons.length > 0 && (
              buttons.map((button, index) => (
                <Larky.Button
                  data-cy={`page-header-button${index}`}
                  key={button.path}
                  size="sm"
                  onClick={() => history.push(button.path)}
                >
                  {button.text}
                </Larky.Button>
              ))
            )}
          </Row>

        </Container>
      </Col>
    </Row>
  );
}

PageHeader.propTypes = {
  title: PropTypes.string.isRequired,
  subtitle: PropTypes.string.isRequired,
  subtitle2: PropTypes.string,
  hasEditPermission: PropTypes.bool
};

PageHeader.defaultProps = {
  subtitle2: null,
  hasEditPermission: false
};

PageHeader.defaultProps = {};

export default PageHeader;
