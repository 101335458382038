import UserService from '../services/UserService';
import { getUser } from '../Utils';

const isAuthorized = async () => {
  const userInfo = getUser();
  if (userInfo) {
    if (!userInfo.mfaResult) {
      return true;
    }
    if (userInfo.isAuthorized) {
      return true;
    }
    return false;
  }
};

const setIsAuthorized = () => {
  UserService.updateSessionStorageUser('isAuthorized', true);
};

const useAuthorization = {
  isAuthorized,
  setIsAuthorized,
};
export default useAuthorization;
