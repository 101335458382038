import React, { useEffect, useState } from 'react'
import Picker from 'emoji-picker-react';
import styles from '../EmojiTextInput/EmojiTextInput.scss'


const EmojiTextInput = ({callback, valueToChange, displayBoardCallback, displayBoard, inModal}) => {
  const [chosenEmoji, setChosenEmoji] = useState(null);
  const [showModal, setShowModal] = useState(true)

  const onEmojiClick = (event, emojiObject) => {
    setChosenEmoji(emojiObject);
    callback(emojiObject.emoji)
  };

  const handleClose = () => {
    setShowModal(false);
    displayBoardCallback(!displayBoard)
  };

  return <Picker pickerStyle={{ width: inModal ? '50%' : '40%' }} onEmojiClick={onEmojiClick}/>
}

export default EmojiTextInput;