import React from 'react';

import { Row, Col } from 'react-bootstrap';

import styles from './Navbar.module.scss';
import Logo from './components/Logo/Logo';

function HeaderPublic() {
  return (
    <div className={styles['public-header']}>
      <Row>
        <Col xs={12}>
          <h1 className={styles['public-header']}>
            <Logo />
          </h1>
        </Col>
      </Row>
    </div>
  );
}

export default HeaderPublic;
