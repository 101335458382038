import React from 'react';
import PropTypes from 'prop-types';

import { useService } from '@xstate/react';

import { notificationService } from 'machines/notifications.machine';

import { CustomAlert } from 'components/Notification/Notification';

function NotificationsProvider({ children }) {
  const [notiState] = useService(notificationService);
  const showNotification = notiState.matches('displayed');

  return (
    <>
      {children}
      {showNotification && (
        <CustomAlert
          message={notiState.context.message}
          variant={notiState.context.variant}
          delay={notiState.context.delay}
        />
      )}
    </>
  )
}

NotificationsProvider.propTypes = {
  children: PropTypes.node.isRequired,
}

export default NotificationsProvider;