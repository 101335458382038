/**
 * NudgeAnalyticsData
 *
 * Displays analytics for specific time based nudges
 *
 */
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

// Components
import { div } from 'react-bootstrap';
import NudgeAnalyticsLoadingCard from '../CreateOrUpdateNudge/NudgeAnalyticsLoadingCard';

// Styles
import styles from './NudgeAnalyticsData.module.scss';
import { formatNumberPercentage, formatNumber } from '../../../../Utils';

function NudgeAnalyticsData({
  nudgeAnalyticsData,
  loading,
  error,
}) {
  if (error) {
    return (
      <div className={styles['time-based-analytics-container']}>
        <h6 style={{ color: 'red' }}>
          There was an issue loading analytics. Please refresh your browser to try again.
        </h6>
      </div>
    );
  }

  const {
    day30AnalyticsFlag,
    lastMonthTotalImpressions,
    lastMonthEngagements,
    lastMonthEngagementsRateTrend,
    impressions,
    engagements,
    engagementRate,
  } = nudgeAnalyticsData || {};

  return (loading || nudgeAnalyticsData === null) ? (
    <NudgeAnalyticsLoadingCard />
  ) : (
    <>
      <div className={styles['time-based-analytics-container']}>
        <h2>{day30AnalyticsFlag ? '30-Day Analytics' : 'Analytics'}</h2>
        <div className={styles['time-based-analytics-row']}>
          <h4>Impressions</h4>
          <h5>
            {
              day30AnalyticsFlag
                ? formatNumber(lastMonthTotalImpressions)
                : formatNumber(impressions)
            }
          </h5>
        </div>
        <div className={styles['time-based-analytics-row']}>
          <h4>Engagements</h4>
          <h5>
            {
              day30AnalyticsFlag
                ? formatNumber(lastMonthEngagements)
                : formatNumber(engagements)
            }
          </h5>
        </div>
        <div className={styles['time-based-analytics-row']}>
          <h4>Engagement Rate</h4>
          <h5>
            {
              day30AnalyticsFlag
                ? formatNumberPercentage(lastMonthEngagementsRateTrend)
                : formatNumberPercentage(engagementRate)
            }
          </h5>
        </div>
      </div>
    </>
  );
}

NudgeAnalyticsData.propTypes = {
  loading: PropTypes.bool,
  error: PropTypes.bool,
  nudgeAnalyticsData: PropTypes.shape(),
};

NudgeAnalyticsData.defaultProps = {
  nudgeAnalyticsData: null,
  loading: true,
  error: false,
};

export default NudgeAnalyticsData;
