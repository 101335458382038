// Dependencies
// eslint-disable-next-line no-use-before-define
import React, { createRef, useState, useEffect } from 'react';
import PropTypes from 'prop-types';

import { useMutation, useQuery } from '@apollo/client';

import { UPDATE_ORGANIZATION_PROFILE_PICTURE_MUTATION, DELETE_ORGANIZATION_PROFILE_PICTURE_MUTATION } from 'graphql/mutations/organizations';

// Components
import { Col, Container, Row } from 'react-bootstrap';
import Larky from 'components/Larky';
import NoPhotoIcon from 'assets/img/no-photo-icon.svg';

import CustomCard from 'components/CustomCard/CustomCard';
import { useNotification } from 'components/Notification/Notification';

// Services
import OrganizationService from 'services/OrganizationService';

// Utilities
import { fileToUrl } from 'Utils';
import styles from './OrganizationProfilePicture.module.scss';
import organizationService from "../../../services/OrganizationService";

function OrganizationProfilePicture({ organization, goBack, setProfilePicture }) {
  const notification = useNotification();

  const [updateOrganizationProfilePictureMutation] = useMutation(UPDATE_ORGANIZATION_PROFILE_PICTURE_MUTATION);
  const [deleteOrganizationProfilePictureMutation] = useMutation(DELETE_ORGANIZATION_PROFILE_PICTURE_MUTATION);

  const inputRef = createRef(null);
  const [imgURL, setImgURL] = useState(organization.profilePicture || NoPhotoIcon);
  const [imgFile, setImgFile] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [renderDeleteButton, setRenderDeleteButton] = useState(false);

  const handleSubmit = async () => {
    try {
      const stringifyOrgId = organization.id.toString()
      setIsLoading(true);
      if (imgFile) {
        const profilePicture = await organizationService.updateOrganizationProfilePicture(
          updateOrganizationProfilePictureMutation,
          stringifyOrgId,
          imgFile,
        );
        setProfilePicture(profilePicture);
        notification.alert('Organization updated successfully', 'success');
      }
      setIsLoading(false);
      goBack();
    } catch (err) {
      console.log(err)
      notification.alert(err.message, 'danger');
    }
  };

  const handleDelete = async () => {
    try {
      setIsLoading(true);
      if (imgURL && imgURL !== NoPhotoIcon) {
        await OrganizationService.deleteOrganizationProfilePicture(
          deleteOrganizationProfilePictureMutation,
          organization.profilePicture
        );
        setProfilePicture(NoPhotoIcon);
        notification.alert('Organization profile picture deleted successfully', 'success');
      }
      setIsLoading(false);
      goBack();
    } catch (err) {
      setIsLoading(false);
      notification.alert(err.message, 'danger');
    }
  };

  return (
    <CustomCard title="Manage Profile Image" size="sm">
      <Container fluid>
        <input
          type="file"
          style={{ display: 'none' }}
          ref={inputRef}
          onChange={async ({ target: { validity, files } }) => {
            const file = files ? files[0] : null;
            if (!file || !validity.valid) {
              return;
            }
            if (
              !['image/jpeg', 'image/png'].some((elem) => elem === file.type)
            ) {
              return;
            }
            setImgFile(file);
            setImgURL(await fileToUrl(file));
          }}
        />

        <Row className="justify-content-center">
          <Col className="text-center" xs="12" lg="auto">
            <img
              className={styles['profile-pic']}
              src={imgURL}
              alt="My account"
            />
          </Col>

          <Col className="text-center" xs="12" lg="auto">
            {/*
              // @ts-ignore */}
            <Larky.Button
              onClick={() => (inputRef && inputRef.current ? inputRef.current.click() : null)}
            >
              Choose File
            </Larky.Button>
          </Col>
        </Row>

        <Row className={`mt-5 align-items-center ${styles['full-width']}`}>
          <Col>
            {/*
              // @ts-ignore */}
            <Larky.Button
              className="w-100"
              dataCy="cancel"
              size="lg"
              outlined
              onClick={() => goBack()}
            >
              Cancel
            </Larky.Button>
          </Col>
          {(renderDeleteButton &&
            <Col>
              {/*
                // @ts-ignore */}
              <Larky.Button
                className="w-100"
                dataCy="delete"
                size="lg"
                danger
                onClick={() => handleDelete()}
              >
                Delete
              </Larky.Button>
            </Col>
          )}
          <Col>
            {/*
              // @ts-ignore */}
            <Larky.Button
              className="w-100"
              dataCy="save"
              size="lg"
              type="submit"
              onClick={handleSubmit}
              disabled={isLoading}
            >
              Save
            </Larky.Button>
          </Col>
        </Row>
      </Container>
    </CustomCard>
  );
}

OrganizationProfilePicture.propTypes = {
  organization: PropTypes.shape({
    id: PropTypes.number.isRequired,
    profilePicture: PropTypes.string.isRequired,
  }).isRequired,
  goBack: PropTypes.func.isRequired,
  setProfilePicture: PropTypes.func.isRequired
};

export default OrganizationProfilePicture;
