import React from 'react';
import PropTypes from 'prop-types';

import { Form } from 'react-bootstrap';

import CustomCheckbox from 'components/CustomCheckbox/CustomCheckbox';

import styles from './SelectableFilter.module.scss';

const SelectableFilter = ({
  label,
  value,
  onChange,
  className,
  disabled,
}) => (
  <div className={`${styles.root} ${className}`}>
    <CustomCheckbox value={value} onChange={(onChange)} size={18} disabled={disabled}/>
    <Form.Label>{label}</Form.Label>
  </div>
);

SelectableFilter.propTypes = {
  label: PropTypes.string.isRequired,
  value: PropTypes.bool.isRequired,
  onChange: PropTypes.func.isRequired,
  className: PropTypes.string,
  disabled: PropTypes.bool
};

SelectableFilter.defaultProps = {
  className: '',
  disabled: false,
};

export default SelectableFilter;
