// Dependencies
import React, {
  Children,
  isValidElement,
  cloneElement,
} from 'react';
import PropTypes from 'prop-types';

// Styles
import NudgeTwoLineLogo from 'assets/img/nudge-two-line-logo.svg';

import {
  Row, Col, Container, Card,
} from 'react-bootstrap';
import Larky from 'components/Larky';
import styles from './CustomCard.module.scss';

const getCols = (size) => {
  switch (size) {
    case 'sm': {
      return { lg: 5, xl: 6 };
    }
    case 'md': {
      return { lg: 7, xl: 8 };
    }
    case 'lg': {
      return { lg: 8, xl: 9 };
    }
    case 'xxl': {
      return { lg: 12, xl: 12 };
    }
    default: {
      return { lg: 5, xl: 6 };
    }
  }
};

function CustomCard({
  className,
  title,
  subtitle,
  children,
  size,
  isForm,
  showLogo,
  style,
  primaryBtnText,
  primaryBtnClick,
  type,
  italicizeTitle,
  isDarkBackground,
  isFullScreen,
  isLarge
}) {
  const { lg, xl } = getCols(size);

  const childrenWithProps = Children.map(children, (child) => {
    if (isValidElement(child)) {
      return cloneElement(child, {
        className: `${child.props.className || ''} d-flex mx-auto ${isForm ? ' flex-column' : 'flex-row flex-wrap'}`,
      });
    }

    return child;
  });

  const getCardClassName = () => {
    const darkBackground = `py-5 px-4 ${styles.card} ${className}`;
    const lightBackground = `py-5 px-4 ${styles.card} ${className}`;
    return isDarkBackground ? darkBackground : lightBackground;
  }

  const getContainerClassName = () => {
    const containerEightyFive = isLarge ? `${styles['container-eighty-five']}` : null;
    const containerFullScreen = `${styles['container-full-screen']}`;
    return isFullScreen ? containerFullScreen : containerEightyFive;
  }

  return (
    <div className=" vw-100 min-vh-100 d-flex flex-column justify-content-center align-items-center py-2 px-0 bg-dark" style={{ ...style }}>

      <Container className={getContainerClassName()}>
        {showLogo && (
          <Row className={`justify-content-center ${styles.logo}`}>
            <img
              src={NudgeTwoLineLogo}
              alt="Larky Nudge logo"
            />
          </Row>
        )}

        <Row className="justify-content-center">
          <Col xs="8" sm="12" lg={lg} xl={xl}>
            <Card className={getCardClassName()}>
              <Container>
                <Row className="align-items-center justify-content-center mb-4">
                  <Col xs="12" md="auto">
                    {italicizeTitle ? (<i><h2 className="text-center">{title}</h2></i>):
                        (<h2 className="text-center">{title}</h2>) }
                  </Col>
                  {(primaryBtnText && primaryBtnClick) && (
                    <Larky.Button
                      className={styles.button}
                      onClick={primaryBtnClick}
                    >
                      {primaryBtnText}
                    </Larky.Button>
                  )}
                </Row>
              </Container>
              {subtitle && <h4 className={type === 'alert' ? styles.alert : ''}>{subtitle}</h4>}
              {childrenWithProps}
            </Card>
          </Col>
        </Row>
      </Container>
    </div>
  );
}

CustomCard.propTypes = {
  className: PropTypes.string,
  style: PropTypes.object,
  title: PropTypes.string.isRequired,
  subtitle: PropTypes.string,
  children: PropTypes.node.isRequired,
  size: PropTypes.string,
  isForm: PropTypes.bool,
  showLogo: PropTypes.bool,
  primaryBtnText: PropTypes.string,
  primaryBtnClick: PropTypes.func,
  type: PropTypes.string,
  italicizeTitle: PropTypes.bool,
  isDarkBackground: PropTypes.bool,
  isFullScreen: PropTypes.bool,
  isLarge: PropTypes.bool,
};

CustomCard.defaultProps = {
  className: '',
  style: {},
  subtitle: null,
  size: 'sm',
  isForm: false,
  showLogo: true,
  primaryBtnText: null,
  type: null,
  primaryBtnClick: null,
  italicizeTitle: false,
  isDarkBackground: false,
  isFullScreen: false,
  isLarge: false,
};

export default CustomCard;
