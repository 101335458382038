import { gql } from '@apollo/client';

export const LOGIN_MUTATION = gql`
  mutation Login($input: UserInput!) {
    login(input: $input) {
      authResponse {
        coreToken
        pushToken
        user {
          id
          firstName
          lastName
          email
          role
          profilePicture
          created
          federationId
          mfaResult
          mfaMethod
          rsaPhone
          rememberMe
          isMfaAuthenticationValid
          adminUser
          organizations {
            id
            name
            organizationType {
                isProduction
            }
          }
        }
        environment
        coreUrl
      }
    }
  }
`;

export const UPDATE_USER_MUTATION = gql`
  mutation UpdateUser($input: UserInput!, $id: ID!) {
    updateUser(input: $input, id: $id) {
      user {
        id
        firstName
        lastName
        email
        role
        profilePicture
        organizations {
          id
          name
        }
      }
    }
  }
`;

export const UPDATE_PROFILE_PICTURE_MUTATION = gql`
  mutation UpdateProfilePictureMutation($id: ID!, $file: Upload!) {
    updateProfilePicture(id: $id, file: $file) {
      fileResponse {
        url
      }
    }
  }
`;

export const DELETE_PROFILE_PICTURE_MUTATION = gql`
  mutation deleteProfilePictureMutation($fileUrl: String!) {
    deleteProfilePicture(fileUrl: $fileUrl) {
      ok
    }
  }
`;

export const UPDATE_PASSWORD_MUTATION = gql`
  mutation UpdatePassword($input: UpdatePasswordInput!) {
    updatePassword(input: $input) {
      ok
    }
  }
`;

export const FORGOT_PASSWORD_MUTATION = gql`
  mutation ForgotPassword($input: UserInput!) {
    forgotPassword(input: $input) {
      text
    }
  }
`;

export const FORGOT_OTHER_USER_PASSWORD_MUTATION = gql`
  mutation ForgotOtherUserPassword($input: UserInput!) {
    forgotOtherUserPassword(input: $input) {
      ok
    }
  }
`;

export const ACTIVATE_OR_DEACTIVATE_OTHER_USER_MUTATION = gql`
  mutation ActivateOrDeactivateOtherUser($input: UserInput!) {
    activateOrDeactivateOtherUser(input: $input) {
      ok
    }
  }
`;

export const RESET_2FA_MUTATION = gql`
  mutation Reset2fa($input: UserInput!) {
    reset2fa(input: $input) {
      ok
    }
  }
`;

export const UPDATE_OTHER_USER_MUTATION = gql`
  mutation UpdateOtherUser($input: UserInput!) {
    updateOtherUser(input: $input) {
      ok
    }
  }
`;

export const NEW_PASSWORD_MUTATION = gql`
  mutation NewPassword($input: NewPasswordInput!) {
    newPassword(input: $input) {
      ok
    }
  }
`;

export const INVITE_USER_MUTATION = gql`
  mutation InviteUser($input: UserInput!) {
    inviteUser(input: $input) {
      ok
    }
  }
`;

export const INVITE_SIGNUP_MUTATION = gql`
  mutation InviteSignup($input: InviteSignupInput!) {
    inviteSignup(input: $input) {
      ok
    }
  }
`;

export const DELETE_USER_MUTATION = gql`
  mutation deleteUserMutation($id: ID!) {
    deleteUser(id: $id) {
      ok
    }
  }
`;

export const REGISTER_USER_PHONE_NUMBER = gql`
  mutation RegisterUserPhoneNumberMutation($input: RegisterUserPhoneNumberInput!) {
    registerUserPhoneNumber(input: $input) {
      ok
    }
  }
`;

export const REGISTER_USER_MFA_METHOD = gql`
  mutation RegisterUserMfaMethodMutation($input: RegisterUserMfaMethodInput!) {
    registerUserMfaMethod(input: $input) {
      ok
    }
  }
`;

export const VERIFY_SMS_CODE = gql`
  mutation VerifySmsCodeMutation($input: VerifySmsCodeInput!) {
    verifySmsCode(input: $input) {
      ok
    }
  }
`;

export const VERIFY_AUTHENTICATOR_CODE = gql`
  mutation VerifyAuthenticatorCodeMutation($input: VerifyAuthenticatorCodeInput!) {
    verifyAuthenticatorCode(input: $input) {
      ok
    }
  }
`;

export const VERIFY_HUBSPOT_CALLBACK = gql`
  mutation VerifyHubspotCallbackMutation($input: VerifyHubspotCallbackInput!) {
    verifyHubspotCallback(input: $input) {
      ok
    }
  }
`;