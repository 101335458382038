import React, { useState } from 'react';

import * as Yup from 'yup';
import { useFormik } from 'formik';
import { useHistory } from 'react-router-dom';
import { useMutation } from '@apollo/client';

import UserService from 'services/UserService';
import { REQUIRED_FIELD, EMAIL_VALIDATION } from 'Constants';

import styles from './ForgotPassword.module.scss'

import {
  Form,
  Container,
  Row,
  Col,
} from 'react-bootstrap';
import Larky from 'components/Larky';

// GraphQL
import { FORGOT_PASSWORD_MUTATION } from 'graphql/mutations/users';
import { useNotification } from 'components/Notification/Notification';

import CustomCard from 'components/CustomCard/CustomCard';

const validationSchema = () => Yup.object({
  email: Yup.string().email(EMAIL_VALIDATION).required(REQUIRED_FIELD),
});

function ForgotPassword({id}) {
  const history = useHistory();

  const [forgotPasswordMutation] = useMutation(FORGOT_PASSWORD_MUTATION);
  const notification = useNotification();

  const [passwordResetSent, setPasswordResetSent] = useState(false);
  const [userPasswordResetText, setUserPasswordResetText] = useState(false);

  const formik = useFormik({
    initialValues: {
      email: '',
      id: id || '',
    },
    validateOnChange: false,
    enableReinitialize: true,
    validationSchema,
    onSubmit: async ({ email }) => {
      try {
        const userPasswordReset = await UserService.forgotPassword(forgotPasswordMutation, { email, id });
        setUserPasswordResetText(userPasswordReset.text)
        setPasswordResetSent(true)
      } catch (err) {
        notification.alert(err.message, 'danger');
      }
    },
  });

  return (
    <CustomCard title={passwordResetSent ? "Thanks" : "Forgot password?"} isForm>
      {passwordResetSent ? (
          <>
          <Col>
              <Row className="align-items-center justify-content-center">
                <div className={styles['message-container']}>{userPasswordResetText}</div>
                <Larky.Button
                    className="w-100"
                    outlined
                    size="lg"
                    onClick={() => history.goBack()}
                >
                  Close
                </Larky.Button>
            </Row>
            </Col>
          </>
      ) : (
          <Form onSubmit={formik.handleSubmit}>
        <Larky.Form.Input
          type="email"
          name="email"
          title="Your Email Address"
          placeholder="example@gmail.com"
          value={formik.values.email}
          onChange={formik.handleChange}
          error={formik.errors.email}
        />

        <Form.Group as={Container} className="mt-4">
          <Row className="align-items-center justify-content-center">
            <Col>
              <Larky.Button
                className="w-100"
                outlined
                size="lg"
                onClick={() => history.goBack()}
              >
                Cancel
              </Larky.Button>
            </Col>
            <Col>
              <Larky.Button
                dataCy="submit"
                className="w-100"
                type="submit"
                size="lg"
                disabled={formik.isSubmitting}
              >
                Reset password
              </Larky.Button>
            </Col>
          </Row>
        </Form.Group>
      </Form>
        )}
    </CustomCard>
  );
}

export default ForgotPassword;
