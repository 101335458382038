import {useAuthMachineValue} from "../../contexts/auth-machine.context";
import {useEffect} from "react";
import { Userpilot } from 'userpilot';

function Pilot() {
  const [current, send] = useAuthMachineValue();
  const user = current.context.user;
  const currentOrganization = current.context.user.currentOrganization;

  useEffect(() => {
        if (user) {
          Userpilot.identify(
            user.id,
                {
                  name: `${user.firstName} ${user.lastName}`,
                  email: user.email,
                  created_at: user.created, // ISO8601 Date,
                  company:  // optional
                  {
                    id: currentOrganization.id, // Company Unique ID
          //           created_at: company.created_at // ISO8601 Date
                  },
          // Additional user properties
                  role: user.role,
          //         // plan: user.plan,
          //         // trialEnds: user.trial_ends ISO8601 Date
                }
            )
        }
    }, [user, currentOrganization]);

  return null;
}

export default Pilot;