/**
 * NudgeAssistModal
 *
 * Modal component for NudgeAssist
 *
 */
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import {Container, Form, Modal, Row, Table} from 'react-bootstrap';
import { useQuery } from '@apollo/client';
import CustomButton from '../../../../components/CustomButton/CustomButton';
import UserService from '../../../../services/UserService';
import {getUser, getUserFeature} from '../../../../Utils';
import Larky from '../../../../components/Larky';
import { GET_ALL_FEATURE_FLAGS_WITH_USER_ID } from '../../../../graphql/queries/organizations';
import styles from './NudgeAssistModal.module.scss';
import CustomInput from "../../../../components/CustomInput/CustomInput";
import CustomSection from "../../../../components/CustomSection/CustomSection";
import CustomRadioButton from "../../../../components/CustomRadioButton/CustomRadioButton";
import NudgeService from "../../../../services/NudgeService";
import {GET_NUDGE_ASSIST} from "../../../../graphql/queries/nudges";
import Analytics from "../../../../libs/Analytics";
import * as Yup from "yup";
import {REQUIRED_FIELD} from "../../../../Constants";
import {Link} from "react-router-dom";
import CustomCard from "../../../../components/CustomCard/CustomCard";
import {useFormik} from "formik";

const FILE_NAME = 'NudgeAssistModal.jsx';

const validationSchema = () => Yup.object({
  text: Yup.string().trim().required(REQUIRED_FIELD)
});

function NudgeAssistModal({
  organizationId,
  currentnudgetitle,
  currentnudgebody,
  show,
  title,
  callback,
  onOk
}) {
  const user = getUser();
  const nudgeService = new NudgeService();
  const getAllFeatureFlagsQuery = useQuery(GET_ALL_FEATURE_FLAGS_WITH_USER_ID, { skip: true });
  const [options, setOptions] = useState([]);
  const [inputValue, setInputValue] = useState('');
  const getNudgeAssistQuery = useQuery(GET_NUDGE_ASSIST, {skip: true});
  const [categoryWarning, setCategoryWarning] = useState(false)

  const currenttitle = (currentnudgetitle ? currentnudgetitle :'');
  const currentbody = (currentnudgebody ? currentnudgebody :'');

  const [selectedOption1, setSelectedOption1] = useState(currenttitle);
  const [selectedOption2, setSelectedOption2] = useState(currentbody);

  //const defaultdata = {'rlist':[{ 'id': 0, 'label1': currenttitle, 'label2': currentbody }]}
  //const [options, setOptions] = useState(defaultdata.rlist);

  const formik = useFormik({
    initialValues: {
      text: '',

    },
    validateOnChange: false,
    validationSchema,
    onSubmit: async ({ text }) => {
      try {
         setCategoryWarning(false);
         if(text) {
          const nudgeAssistResponse = await NudgeService.getNudgeAssist(getNudgeAssistQuery, formik.values.text);
          setOptions(nudgeAssistResponse.rlist);
          setCategoryWarning(false);
         }

     }
      catch (e) {
        // Analytics.trackApplicationError(e.message, FILE_NAME, 'NudgeAssistDetails');
        // notification.alert(e.message, 'danger');
        console.log(e)  ;
        setSelectedOption1(currenttitle);
        setSelectedOption2(currentbody);
        setOptions([]);
        setCategoryWarning(e.message);
        return;
        }
      },
  });

  const handleRadioButtonChange = (value) => {
        formik.setFieldValue('nudgeassist', value);
        const updatedRadioOptions = radioOptions.map((option) => {
            if (option.value === value) {
                option.selected = true;
            }
            if (option.value !== value) {
                option.selected = false;
            }
            return option;
        });
        setRadioOptions(updatedRadioOptions)
    };


  const handleOptionChange1 = (e) => {
    setSelectedOption1(e.target.value);
  };

  const handleOptionChange2 = (e) => {
    setSelectedOption2(e.target.value);
  };

  const handleOkClick = () => {
    onOk({ option1: selectedOption1, option2: selectedOption2 });
    callback();
  };



  return (
    <>
      <Modal
        show={show}
        size="xl"
        onHide={callback}
        animation={false}
        backdrop="static"
        className={styles['modal-dialog']}
      >
        <Modal.Body>
          <Container fluid>
            <Modal.Header closeButton>
              <Row>
                <h1>
                  {' '}
                  {'Nudge Assist'}
                  {' '}
                </h1>
              </Row>
            </Modal.Header>
            <Row className={styles['row-header']}>
              <CustomSection title="Enter a topic for your nudge">
                <h6 style={{marginLeft: 3}}>Examples include <i>Increase deposits</i>, <i>Promote card usage</i> and <i>Fraud alert</i></h6>
                <Form onSubmit={formik.handleSubmit}>
                    <Larky.Form.Input
                      type="text"
                      name="text"
                      title="Nudge Topic"
                      placeholder="Enter your topic"
                      value={formik.values.text}
                      onChange={formik.handleChange}
                      error={formik.errors.text}
                    />

                    <Larky.Button
                          dataCy="login"
                          type="submit"
                          size="lg"
                          disabled={formik.isSubmitting}
                          isLoading={formik.isSubmitting}
                        >
                          Go!
                    </Larky.Button>

                    {categoryWarning ? (<p className={styles.alert}>{categoryWarning}</p>) : (null)}



                </Form>

              </CustomSection>
          </Row>
          </Container>
          <div className={styles['row-header']}>
              {(options.length > 0) ? (
            <CustomSection title="Select your nudge content">
              <h6 style={{marginLeft: 3}}>Pick a title and body for your nudge</h6>
              <Table className={styles.table} style={{backgroundColor: '#f8f8f8',borderBottom:'none',borderTop:'none'}}>
              <thead className={styles.table} style={{backgroundColor: '#f8f8f8',borderBottom:'none',borderTop:'none'}}>
                <tr className={styles.table} style={{backgroundColor: '#f8f8f8',borderBottom:'none',borderTop:'none'}}>
                  <td className={styles.table} style={{backgroundColor: '#f8f8f8',borderBottom:'none',borderTop:'none'}}>
                  <h5 style={{fontSize: '1.25rem'}}><b>Title</b></h5>
                </td>
                <td className={styles.table} style={{backgroundColor: '#f8f8f8',borderBottom:'none',borderTop:'none'}}>
                  <h5 style={{fontSize: '1.25rem'}}><b>Body</b></h5>
                </td>
                </tr>
              </thead>
              <tbody className={styles.table} style={{backgroundColor: '#f8f8f8',borderBottom:'none',borderTop:'none'}}>
              {/*<tr key={'0'}>*/}
              {/*    <td className={`${styles.rowInfo} ${styles['row-header-messaging-reach']}`} style={{width: "40%"}}>*/}
              {/*        <span style={{color:'#2FBFFE'}}><b><i>Current:</i></b></span>*/}
              {/*        <br></br>*/}
              {/*        <div className="custom-label">*/}
              {/*          <input*/}
              {/*            type="radio"*/}
              {/*            value={currenttitle}*/}
              {/*            onChange={handleOptionChange1}*/}
              {/*            checked={selectedOption1 === currenttitle}*/}
              {/*          />*/}
              {/*          <label><span style={{ display: 'inline-block', verticalAlign: 'baseline',marginLeft:'5px' }}>{currenttitle ? currenttitle :'(empty)'}</span></label>*/}
              {/*        </div>*/}
              {/*    </td>*/}
              {/*    <td className={`${styles.rowInfo} ${styles['row-header-messaging-reach']}`} style={{width: "60%"}}>*/}
              {/*         <span style={{color:'#2FBFFE'}}><b><i> </i></b></span>*/}
              {/*        <br></br>*/}
              {/*        <div className="custom-label">*/}
              {/*          <input*/}
              {/*            type="radio"*/}
              {/*            value={currentbody}*/}
              {/*            onChange={handleOptionChange2}*/}
              {/*            checked={selectedOption2 === currentbody}*/}
              {/*          />*/}
              {/*          <label><span style={{ display: 'inline-block', verticalAlign: 'baseline',marginLeft:'5px' }}>{currentbody ? currentbody :'(empty)'}</span></label>*/}
              {/*        </div>*/}
              {/*    </td>*/}
              {/*</tr>*/}
                {options.length > 0 && options.map((option) => (
                  <tr key={option.id} className={styles.table} style={{backgroundColor: '#f8f8f8',borderBottom:'none',borderTop:'none'}}>
                    <td className={styles.table} style={{backgroundColor: '#f8f8f8',borderBottom:'none',borderTop:'none'}}>
                      <div className="custom-label">
                        <input
                          type="radio"
                          value={option.title}
                          checked={selectedOption1 === option.title}
                          onChange={handleOptionChange1}
                        />
                        <span style={{ marginLeft:'5px'}}>{option.title}</span>
                      </div>
                    </td>
                    <td className={styles.table} style={{backgroundColor: '#f8f8f8',borderBottom:'none',borderTop:'none'}}>
                      <div className="custom-label">
                        <input
                          type="radio"
                          value={option.description}
                          checked={selectedOption2 === option.description}
                          onChange={handleOptionChange2}
                        />
                        <span style={{ marginLeft:'5px'}}>{option.description}</span>
                      </div>
                    </td>
                  </tr>
                ))}
              </tbody>
            </Table>
            </CustomSection>
              ):null}
      </div>
            <Row style={{ justifyContent: 'center', width: '100%', paddingTop: '20px' }}>
            <CustomButton
              dataCy="primary"
              variant="outlined"
              size="sm"
              text="Cancel"
              onClick={() => callback()}
            />
            { (options.length > 0 ) ? (
            <CustomButton
              dataCy="primary"
              variant="contained"
              size="sm"
              text="OK"
              onClick={handleOkClick}
            />):null}

          </Row>
        </Modal.Body>
      </Modal>
    </>
  );
}

export default NudgeAssistModal;
