import React from 'react';
import ContentLoader from 'react-content-loader';
import styles from '../NudgeAnalyticsData/NudgeAnalyticsData.module.scss';

const NudgeAnalyticsLoadingCard = (props) => (
  <>
    <h2 className={styles['time-based-analytics-container']}>
      Analytics
    </h2>
    <ContentLoader
      backgroundColor="#949494"
      foregroundColor="#c2c2c2"
      viewBox="0 0 400 200"
      height={200}
      width={400}
      {...props}
      style={{ width: '100%' }}
    >
      <rect x="100" y="0" rx="0" ry="0" width="200" height="30" />
      <rect x="0" y="50" rx="" ry="50" width="400" height="30" />
      <rect x="0" y="100" rx="" ry="0" width="400" height="30" />
      <rect x="0" y="150" rx="" ry="0" width="400" height="30" />
    </ContentLoader>
  </>

);

export default NudgeAnalyticsLoadingCard;
