import PropTypes from 'prop-types';
import { Form } from 'react-bootstrap';
import React from 'react';

function FileUploadErrors({ errors }) {
  const displayErrors = () => errors.map((err, key) => <p key={key}>{err.detail}</p>);

  return (
    <Form.Control.Feedback type="invalid">{displayErrors()}</Form.Control.Feedback>
  );
}
FileUploadErrors.propTypes = {
  errors: PropTypes.instanceOf(Array),
};

FileUploadErrors.defaultProps = {
  errors: [],
};

export default FileUploadErrors;
