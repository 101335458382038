import React, { useEffect, useState } from "react";
import ReactDOM from "react-dom";
import { Pie } from "react-chartjs-2";
import { colors } from "./chartColors";

import styles from '../PieChart/PieChart.module.scss'
import PropTypes from "prop-types";
import PieChartLoadingCard from "../PieChart/PieChartLoadingCard";

const pieOptions = {
  legend: {
    display: false,
    position: "right",
  },
  elements: {
    arc: {
      borderWidth: 0
    }
  },
  plugins: {
    tooltip: {
      callbacks: {
        label: (context) => {
            let label = "";
            if (context.parsed) {
              label = context.parsed + "%"
            }
            return label;
          }
      }
    }
  }
};



const PieChart = ({chartData, loading}) => {
  let chartInstance = null;
  const pieChartData = {
      maintainAspectRatio: false,
      responsive: false,
      labels: chartData.labels,
      datasets: [
        {
          data: chartData.values,
          backgroundColor: colors,
          hoverBackgroundColor: '#2FBFFE'
        }
      ]
    }



  return (
    <div className={styles['pie-container']}>
      <h4 className={styles['pie-title']}>Category Overview</h4>
        <div>
          {
            loading ? (
                <PieChartLoadingCard />
            ) : (
                <Pie
            data={pieChartData}
            options={pieOptions}
            ref={input => {
              chartInstance = input;
            }}
          />
            )
          }
        </div>
    </div>
  );
}

PieChart.propTypes = {
  chartData: PropTypes.shape().isRequired,
  loading: PropTypes.bool.isRequired,
};

PieChart.defaultProps = {
  chartData: {labels: ['No activity'], values: [100]},
  loading: true,
};


export default PieChart;