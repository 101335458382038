import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { Spinner } from 'react-bootstrap';
import { isNumber } from '../../Constants';

import ToolTipIcon from '../ToolTipIcon/ToolTipIcon';

import styles from './NudgeScore.module.scss';
import Larky from 'components/Larky';
import {getUser, getUserFeature} from "../../Utils";
import UserService from "../../services/UserService";
import {useQuery} from "@apollo/client";
import {GET_ALL_FEATURE_FLAGS_WITH_USER_ID} from "../../graphql/queries/organizations";
// import ReactTooltip from 'react-tooltip';

const NudgeScore = ({ loading, nudgeScoreData,onButtonClick,featureflag }) => {
  const notificationDiv = useRef();
  const titleDiv = useRef();
  const messageDiv = useRef();
  const [error, setError] = useState();
  const [userHasMLFeatureFlag, setUserHasMLFeatureFlag] = useState(featureflag);

  const user = getUser();
  const getAllFeatureFlagsQuery = useQuery(GET_ALL_FEATURE_FLAGS_WITH_USER_ID, { skip: true });
  const [MLScoreToolTipText, setMLScoreToolTipText] = useState(false);
  const [userHasMLScore, setUserHasMLScore] = useState(false);
  const [MLScoreUrl, setMLScoreUrl] = useState(false);

  useEffect(async () => {
    const hasMLScore = await getUserFeature('ml_nudge_score');
    await setUserHasMLScore(!!hasMLScore);
  }, [user.featureFlags]);

  useEffect(async () => {
    const MLScoreFeatureInfo = await UserService.getFeatureFlagInfoWithUserId(getAllFeatureFlagsQuery, user.id, 'ml_nudge_score');
    setMLScoreToolTipText(MLScoreFeatureInfo.tooltip);
    setMLScoreUrl(MLScoreFeatureInfo.tooltipUrl);
  }, [user.id]);

  //const createButtonToolTipText = (textBody, url) => `${MLScoreToolTipText} Click to learn more!`;

  const isUserOnNudgeBasic = () => {
    if (nudgeScoreData) {
      const { nudgeScore } = nudgeScoreData;
      const isNudgeScoreNonNumber = !isNumber(nudgeScore);
      if (isNudgeScoreNonNumber) {
        return nudgeScore;
      }
    }
    return null;
  };

  const showNudgeScore = () => {
    if (nudgeScoreData) {
      const { nudgeScoreText, nudgeScore } = nudgeScoreData;
      if (isUserOnNudgeBasic()) {
        return nudgeScore;
      }
      if (nudgeScore === '0') {
        return nudgeScoreText;
      }
      return `${nudgeScore} - ${nudgeScoreText}`;
    }
  };

  const showNudgeScoreDetail = () => {
    if (nudgeScoreData) {
      return nudgeScoreData && nudgeScoreData.nudgeScoreHelperText && nudgeScoreData.nudgeScore !== '(unavailable)' ? `${nudgeScoreData.nudgeScoreHelperText} ` : (
        <div>
          {`${nudgeScoreData.nudgeScoreHelperText}`}
          {' '}
          <a target="_blank" href={nudgeScoreData.nudgeScoreLink} rel="noreferrer">Click here</a>
          {' '}
          to learn more.
        </div>
      );
    }
    return (
      <h6 style={{ color: 'red' }}>
        There was an issue loading nudge score. Please refresh your browser to try again.
      </h6>
    );
  };



  const handleButtonClick = () => {
    // Call the function defined in the main page
    onButtonClick();
    if (!userHasMLScore) {
            window.open(MLScoreUrl, '_blank');
        }
  };

  return (
    <div className={styles['preview-style']}>
      <>
        <div
          className={styles['score-container']}
          ref={notificationDiv}
        >
          <div className={styles['text-container']}>
            <div className={styles['preview-header']}>
              nudge Score
              { !isUserOnNudgeBasic() ? (
                  <ToolTipIcon classNameIcon="tooltip-icon-alt"
                               className="tool-tip-container-nudge"
                               url={nudgeScoreData ? nudgeScoreData.nudgeScoreLink : null}
                               toolTipMessage="Click for best practices in creating highest engagement."
                               flag={0}>
                  </ToolTipIcon>) : null }
            </div>

            <div className={styles['preview-sub-header']}>Use machine learning to predict how your nudge will perform</div>
          </div>
          <div
            className={styles['notification-title']}
            ref={titleDiv}
          />
          {loading ? (
            <div style={{ margin: '0 40%' }}>
              <Spinner
                style={{ width: '4rem', height: '4rem' }}
                animation="border"
                role="status"
              />
            </div>
          ) : (
            <>
              <div
                style={{ color: nudgeScoreData && nudgeScoreData.nudgeScoreColor ? `rgb(${nudgeScoreData.nudgeScoreColor})` : null }}
                ref={messageDiv}
                className={styles['msg-score']}
              >
                {showNudgeScore()}
              </div>
              <div className={styles['preview-footer']}>
                {showNudgeScoreDetail()}
                { !isUserOnNudgeBasic() ?
                <div>
                  <Larky.Button
                  onClick={() => handleButtonClick()}
                  // data-tip={!userHasMLScore ? createButtonToolTipText() : null}
                  // className={!userHasMLScore ? styles['disabled-button'] : null}
                >
                 Recheck Score
                </Larky.Button>
                {/*{!userHasMLScore ? (*/}
                {/*  <ReactTooltip />*/}
                {/*) : null}*/}
                </div>:null}


              </div>
            </>
          )}
        </div>
        <div className="error">{error}</div>
      </>
    </div>
  );
};

NudgeScore.propTypes = {
  title: PropTypes.string,
  message: PropTypes.string,
};

export default NudgeScore;
