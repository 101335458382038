import React, { useEffect, useState } from 'react';
import ReactDOM from 'react-dom';
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect,
} from 'react-router-dom';

import { notificationService } from 'machines/notifications.machine.ts';
import { removeDOMElement } from 'Utils';

import Larky from 'components/Larky';

import CustomInput from 'components/CustomInput/CustomInput';
import CustomButton from 'components/CustomButton/CustomButton';

import { useFormik } from 'formik';
import { Row, Col } from 'react-bootstrap';
import * as Yup from 'yup';
import { REQUIRED_FIELD } from 'Constants';

// Service
import NudgeService from 'services/NudgeService';
import SegmentService from 'services/SegmentService';

// GraphQL
import { SEARCH_NUDGES } from 'graphql/queries/nudges';
import { CREATE_SYSTEM_SEGMENT_MUTATION } from 'graphql/mutations/segments';
import { useQuery, useMutation } from '@apollo/client';
import ApolloClientProvider from 'providers/ApolloClientProvider';

import { CALCULATE_SYSTEM_SEGMENT } from 'graphql/queries/segments';
import TwoFactorAuth from '../../pages/Login/TwoFactorAuth/TwoFactorAuth';
import style from './Notification.module.scss';
import FullscreenAlert from './components/FullscreenAlert';
import FullscreenDialog from './components/FullscreenDialog';
import CustomDialog from './components/CustomDialog';
import CustomAlert from './components/CustomAlert';
import { getOrgFromDropdownOptions } from '../../Utils';

function useNotification() {
  /**
   * Show alert
   * @param {String} message
   * @param {String?} variant
   * @param {Number?} delay in seconds
   */
  function alert(message, variant = 'primary', delay = 4) {
    notificationService.send('DISPLAY', {
      message,
      delay,
      variant,
    });
  }
  function hideAlert() {
    notificationService.send('HIDE', { show: false });
  }

  /**
   * Show dialog
   * @param {String} title
   * @param {String} body
   * @param {String} primaryBtnText
   * @param {String} secondBtnText
   * @returns {Promise}
   */
  function dialog(title, body, primaryBtnText, secondBtnText) {
    const el = document.createElement('div');

    return new Promise((resolve) => {
      ReactDOM.render(
        <CustomDialog
          title={title}
          body={body}
          handleClose={(value) => {
            resolve(value);
            removeDOMElement(el);
          }}
          primaryBtnText={primaryBtnText}
          secondBtnText={secondBtnText}
        />,
        document.body.appendChild(el),
      );
    });
  }

  /**
   * Show confirm dialog
   * @param {String} title
   * @param {String} body
   * @param {Function} callback
   */
  function confirm(title = 'Confirm Logout?', body = 'Are you sure you want to logout from the portal?') {
    return dialog(title, body, 'Logout', 'Cancel');
  }

  /**
   * Show full screen dialog
   * @param {String} title
   * @param {String} subtitle
   * @param {String} primaryBtnText
   * @param {Function} primaryBtnOnClick
   * @param {String} secondaryBtnText
   * @param {Function} secondaryBtnOnClick
   * @param {Boolean} fullDialog
   */
  function fullscreenDialog(
    title,
    subtitle,
    content,
    primaryBtnText,
    primaryBtnOnClick,
    secondaryBtnText = null,
    secondaryBtnOnClick = null,
    fullDialog = false,
    italicizeTitle = false,
  ) {
    const el = document.createElement('div');
    document.querySelector('body').style.overflow = 'hidden';

    ReactDOM.render(
      <FullscreenDialog
        title={title}
        subtitle={subtitle}
        content={content}
        primaryBtnOnClick={() => {
          primaryBtnOnClick();
          removeDOMElement(el);
        }}
        primaryBtnText={primaryBtnText}
        secondaryBtnText={secondaryBtnText}
        secondaryBtnOnClick={() => {
          if (secondaryBtnOnClick) secondaryBtnOnClick();
          removeDOMElement(el);
        }}
        fullDialog={fullDialog}
        italicizeTitle={italicizeTitle}
      />,
      document.body.appendChild(el),
    );
  }

  function SelectOrganization({ organizations, callback, currentOrganization }) {
    const getDropdownOptions = () => organizations.map((org) => (
      { label: org.name, value: org }));
    const [orgOptions, setOrgOptions] = useState(getDropdownOptions(organizations));
    const getInitialOption = () => {
      const result = getOrgFromDropdownOptions(currentOrganization, orgOptions);
      return result || orgOptions[0];
    };
    const [selectedOrg, setSelectedOrg] = useState(getInitialOption);

    const submitSelectedOrg = () => {
      callback(selectedOrg.value);
    };
    return (
      <div style={{ width: '100%' }}>
        <CustomInput
          type="select"
          name="selectOrganization"
          placeholder="Select..."
          onChange={(org) => {
            setSelectedOrg(org);
          }}
          value={selectedOrg}
          options={orgOptions}
        />
        <Row className="align-items-center justify-content-center mb-4">
          <Col className="col-auto">
            <Larky.Button
              className={style['org-option']}
              type="button"
              onClick={() => submitSelectedOrg()}
            >
              Continue
            </Larky.Button>
          </Col>
        </Row>
      </div>
    );
  }
  /**
   * Select an organization dialog
   * @param {Object[]} organizations
   * @param {Function} callback
   * @param {Object} currentOrganization
   */
  function selectOrganizationDialog(organizations, callback, currentOrganization = null) {
    // if the number of organizations is greater than 5 a dropdown component will be use for the org selection
    const el = document.createElement('div');
    document.querySelector('body').style.overflow = 'hidden';

    const selectAnOrg = (org) => {
      callback(null, org);
      removeDOMElement(el);
    };

    ReactDOM.render(
      <FullscreenDialog
        title="Select an organization to continue"
      >
        {displayOrgSelection(organizations, selectAnOrg, currentOrganization)}
      </FullscreenDialog>,
      document.body.appendChild(el),
    );
  }

  function displayOrgSelection(organizations, callback, currentOrganization) {
    if (organizations && organizations.length > 5) {
      return (
        <SelectOrganization
          organizations={organizations}
          callback={callback}
          currentOrganization={currentOrganization}
        />
      );
    }
    return organizations.map((org) => (
      <Larky.Button
        className={style['org-option']}
        type="button"
        key={org.id}
        onClick={() => callback(org)}
      >
        {org.name}
      </Larky.Button>
    ));
  }

  /**
   * Select an organization dialog
   * @param {Object[]} organizations
   * @param {Function} callback
   * @param {Object} mfaResult
   */
  function confirm2faDialog(organizations, callback, user = null) {
    const el = document.createElement('div');
    document.querySelector('body').style.overflow = 'hidden';

    const callbackFunc = (user, err) => {
      callback(err, user);
      removeDOMElement(el);
    };

    ReactDOM.render(
      <FullscreenDialog
        title="Two-Factor Authentication"
      >
        <ApolloClientProvider>
          <Router>
            <TwoFactorAuth
              callback={callbackFunc}
            />
          </Router>
        </ApolloClientProvider>
      </FullscreenDialog>,
      document.body.appendChild(el),
    );
  }

  function fullscreenDialogConfirm(title, primaryBtnText = 'Accept') {
    return new Promise((resolve) => fullscreenDialog(
      title,
      null,
      null,
      primaryBtnText,
      () => resolve(true),
      'Cancel',
      () => resolve(false),
    ));
  }


  function fullScreenDialogCardConfirm(title, subtitle, content, primaryBtnText = 'Accept') {
    return new Promise((resolve) => fullscreenDialog(
      title,
      subtitle,
      content,
      primaryBtnText,
      () => resolve(true),
      'Cancel',
      () => resolve(false),
      true,
    ));
  }

  // Dialog Popup for Deletion of Campaign and Campaign Nudges
  function fullscreenDialogConfirmCampaignDelete(title, subtitle, primaryBtnText = 'Accept') {
    return new Promise((resolve) => fullscreenDialog(
      title,
      subtitle,
      null,
      primaryBtnText,
      () => resolve(true),
      'Cancel',
      () => resolve(false),
      true,
      true,

    ));
  }

  /**
   *
   * @param {string} title
   * @param {string} subtitle
   */
  function fullscreenAlert(title, subtitle) {
    const el = document.createElement('div');
    document.querySelector('body').style.overflow = 'hidden';

    return new Promise((resolve) => {
      ReactDOM.render(
        <FullscreenAlert
          title={title}
          subtitle={subtitle}
          handleClose={() => {
            resolve();
            removeDOMElement(el);
          }}
        />,
        document.body.appendChild(el),
      );
    });
  }

  /**
   * Show system segment screen diaalog
   * @param {Int} organizationId
   * @param {String} userId
   */
  function fullscreenSystemSegmentDialog(
    organizationId,
    userId,
    callback,
  ) {
    const el = document.createElement('div');
    document.querySelector('body').style.overflow = 'hidden';

    const leavePopUp = (response) => {
      removeDOMElement(el);
      callback(response);
    };

    ReactDOM.render(
      <ApolloClientProvider>
        <FullscreenDialog
          style={{ margin: 'left', position: 'relative', overflow: 'scroll' }}
        >
          <Row style={{ width: '120%' }}>
            <h1 style={{ marginTop: '-60px' }}>Select System Group</h1>
            <h4>System Groups are automatically defined</h4>
            <h4> by the system based on existing nudge</h4>
            <h4> engagement details</h4>

            <SystemSegmentForm
              organizationId={organizationId}
              userId={userId}
              callback={leavePopUp}
            />
          </Row>

        </FullscreenDialog>
      </ApolloClientProvider>,
      document.body.appendChild(el),
    );
  }

  return {
    alert,
    hideAlert,
    dialog,
    confirm,
    fullscreenDialog,
    fullscreenDialogConfirm,
    fullScreenDialogCardConfirm,
    fullscreenAlert,
    selectOrganizationDialog,
    fullscreenSystemSegmentDialog,
    fullscreenDialogConfirmCampaignDelete,
    confirm2faDialog,
    displayOrgSelection,
  };
}

export {
  CustomAlert,
  useNotification,
};

// System Segment Formik Popup begins here

const activityOptions = [
  { value: 'Received', label: 'Received' },
  { value: 'Tapped', label: 'Tapped' },
];

const timeFrameOptions = [
  { value: '30', label: 'Last 30 Days' },
  { value: '60', label: 'Last 60 Days' },
  { value: '90', label: 'Last 90 Days' },
  { value: null, label: 'Ever' },
];

const newSystemSegment = {

};

const SystemSegmentForm = ({ userId, organizationId, callback }) => {
  const searchNudgesQuery = useQuery(SEARCH_NUDGES, { skip: true });
  const [nudges, setNudges] = useState(null);
  const [totalMembers, setTotalMembers] = useState(null);
  const notification = useNotification();

  const [createSystemSegmentMutation] = useMutation(CREATE_SYSTEM_SEGMENT_MUTATION);

  const calculateSystemSegmentQuery = useQuery(CALCULATE_SYSTEM_SEGMENT, { skip: true });

  useEffect(async () => {
    async function fetchNudges(orderBy, sortOrder, start, params, rows, label) {
      try {
        const nudgesResponse = await NudgeService.all(
          searchNudgesQuery, orderBy, sortOrder, start, params, rows,
        );

        const nudgeList = () => nudgesResponse.hits.map(({ messageId, name }) => ({
          value: messageId,
          label: name,
        }));
        return {
          label,
          options: nudgeList(),
        };
      } catch {
        notification.alert('Failed to fetch segments', 'danger');
      }
    }

    const allNonArchivedNudges = await fetchNudges('name', 'asc', 0, {
      isDraft: false,
      organizationId,
      archived: false,
    }, 10000, 'Active Nudges');

    const allArchivedNudges = await fetchNudges('name', 'asc', 0, {
      isDraft: false,
      organizationId,
      archived: true,
    }, 10000, 'Archived Nudges');
    const setNudgesWithBreaks = () => {
      const allNudges = [];
      allNudges.push(allNonArchivedNudges);
      allNudges.push(allArchivedNudges);
      setNudges(allNudges);
    };

    setNudgesWithBreaks();
  }, [

    searchNudgesQuery,
  ]);

  const validationSchema = () => Yup.object({
    baseMembership: Yup.object().nullable(),
    nudgeActivity: Yup.object().required(REQUIRED_FIELD),
    timeFrame: Yup.object().nullable(),
  });

  const systemFormik = useFormik({
    initialValues: {
      baseMembership: '',
      nudgeActivity: { value: 'Received', label: 'Received' },
      timeFrame: { value: '30', label: 'Last 30 Days' },
      updatedBy: userId,
    },
    validateOnChange: false,
    validationSchema,
    onSubmit: async ({
      baseMembership,
      nudgeActivity,
      timeFrame,
      updatedBy,
    }) => {
      try {
        const systemSegmentPayload = {
          messageId: baseMembership.value,
          nudgeActivity: nudgeActivity.value,
          timeframe: timeFrame.value,
          updatedBy,
          organizationId,
        };

        const response = await SegmentService.createSystemSegment(
          createSystemSegmentMutation,
          systemSegmentPayload,
        );
        callback(response);
      } catch {
        console.log('Error! Failed to submit System Segment.');
      }
    },
  });

  async function calculateMembership() {
    const validationErrors = await systemFormik.validateForm();
    if (Object.keys(validationErrors).length) return;

    const messageId = systemFormik.values.baseMembership.value;
    const nudgeActivity = systemFormik.values.nudgeActivity.value || 'ALL';
    const timeframe = systemFormik.values.timeFrame.value || null;

    const membershipTotal = await SegmentService.calculateSystemSegment(
      calculateSystemSegmentQuery,
      organizationId,
      userId,
      messageId,
      nudgeActivity,
      timeframe,
    );

    setTotalMembers(membershipTotal.count);
  }

  return (
    <div>
      <Col style={{ padding: 0, width: '200%' }}>
        <Col xs={15} style={{ paddingRight: '100px' }}>

          <form onSubmit={systemFormik.handleSubmit}>
            <CustomInput
              type="select"
              name="baseMembership"
              placeholder="All nudges"
              title="Select the base membership"
              options={nudges}
              value={systemFormik.values.baseMembership}
              onChange={(value) => systemFormik.setFieldValue('baseMembership', value)}
              error={systemFormik.errors.baseMembership}
            />

            <CustomInput
              type="select"
              name="nudgeActivity"
              placeholder="Received or Tapped"
              title="Select the nudge activity"
              options={activityOptions}
              value={systemFormik.values.nudgeActivity}
              onChange={(value) => systemFormik.setFieldValue('nudgeActivity', value)}
              error={systemFormik.errors.nudgeActivity}
            />

            <CustomInput
              type="select"
              name="timeFrame"
              placeholder="Last 30 Days..."
              title="Select time frame"
              options={timeFrameOptions}
              value={systemFormik.values.timeFrame}
              onChange={(value) => systemFormik.setFieldValue('timeFrame', value)}
              error={systemFormik.errors.timeFrame}
            />
          </form>

          <Row style={{ justifyContent: 'center', alignItems: 'center', width: '120%' }}>
            <Row style={{ marginTop: '7px' }}>
              <h6>
                {(totalMembers !== null
                  ? `This System Segment contains ${totalMembers} members`
                  : 'Tap Refresh to view membership total')}
              </h6>
            </Row>
            <Row style={{ marginLeft: '20px' }}>
              <CustomButton
                variant="contained"
                size="xs"
                text="Refresh"
                onClick={() => calculateMembership()}
              />
            </Row>
          </Row>

        </Col>

      </Col>

      <Row style={{ justifyContent: 'center', width: '100%', paddingTop: '20px' }}>
        <Col xs={6} md="6">
          <CustomButton
            variant="outlined"
            size="lg"
            text="Cancel"
            onClick={() => callback(false, null)}
          />
        </Col>
        <Col xs={6} md="6">
          <CustomButton
            variant="contained"
            size="lg"
            text="Select"
            onClick={() => systemFormik.submitForm()}
          />
        </Col>
      </Row>
    </div>

  );
};
