import {Col, Container, Modal, Row} from "react-bootstrap";
import PageHeader from "../../../../components/PageHeader/PageHeader";
import React from "react";
import ProfilePicture from "../ProfilePicture/ProfilePicture";
import UserService from "../../../../services/UserService";
import {useAuthMachineValue} from "../../../../contexts/auth-machine.context";
import {useParams} from "react-router-dom";

function ProfilePictureModal({show, user, callback}) {
  const [current, send] = useAuthMachineValue();
  const params = useParams();
  const id = params.userId || current.context.user.id;

  const setProfilePicture = (profilePicture) => {
    if (current.context.user.id === id) {
      UserService.updateSessionStorageUser('profilePicture', profilePicture);
      send('SET_USER', { user: { profilePicture } });
    }
    Object.assign(user, {
      profilePicture,
    });
  };
  return (
    <>
      <Modal show={show} size="md" onHide={callback} animation={false} backdrop="static" style={{top: '10%'}}>
        <Modal.Header closeButton style={{ paddingTop: 0, paddingBottom: 0 }}>
          <Row style={{ paddingLeft: '1em' }}>
            <PageHeader
              title="Manage Profile Picture"
              subtitle=""
            />
          </Row>
        </Modal.Header>
        <Modal.Body>
          <Row className="justify-content-center">
            <Col xs="12" sm="8" lg={8} xl={9} style={{paddingBottom: '5%'}}>
              <ProfilePicture
                user={{
                  ...user,
                  id,
                }}
                goBack={() => callback()}
                setProfilePicture={setProfilePicture}
              />
            </Col>
          </Row>
        </Modal.Body>
      </Modal>
    </>
  )
}

export default ProfilePictureModal;
