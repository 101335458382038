// Dependencies
// eslint-disable-next-line no-use-before-define
import React, { createRef, useState, useEffect } from 'react';
import PropTypes from 'prop-types';

import { useMutation, useQuery } from '@apollo/client';

import { UPDATE_PROFILE_PICTURE_MUTATION, DELETE_PROFILE_PICTURE_MUTATION } from 'graphql/mutations/users';

// Components
import { Col, Container, Row } from 'react-bootstrap';
import Larky from 'components/Larky';
import NoPhotoIcon from 'assets/img/no-photo-icon.svg';

import CustomCard from 'components/CustomCard/CustomCard';
import { useNotification } from 'components/Notification/Notification';

// Services
import UserService from 'services/UserService';

// Utilities
import { fileToUrl } from 'Utils';
import styles from './ProfilePicture.module.scss';

interface Props {
  user: {
    id: string,
    profilePicture: string,
    email: string
  };
  goBack(): void,
  setProfilePicture(profilePicture: string): void
}

function ProfilePicture({ user, goBack, setProfilePicture }: Props): JSX.Element {
  const notification = useNotification();

  const [updateProfilePictureMutation] = useMutation(UPDATE_PROFILE_PICTURE_MUTATION);
  const [deleteProfilePictureMutation] = useMutation(DELETE_PROFILE_PICTURE_MUTATION);

  const inputRef = createRef<HTMLInputElement>();
  const [imgURL, setImgURL] = useState<string | undefined>(user.profilePicture || NoPhotoIcon);
  const [imgFile, setImgFile] = useState<File>();
  const [isLoading, setIsLoading] = useState(false);
  const [deletePermission, setDeletePermission] = useState(false)

  const handleSubmit = async () => {
    try {
      setIsLoading(true);
      if (imgFile) {
        const profilePicture = await UserService.updateProfilePicture(
          updateProfilePictureMutation,
          user.id,
          imgFile,
        );
        setProfilePicture(profilePicture);
        notification.alert('User updated successfully', 'success');
      }
      setIsLoading(false);
      goBack();
    } catch (err) {
      // @ts-ignore
      notification.alert(err.message, 'danger');
    }
  };

  const handleDelete = async () => {
    try {
      setIsLoading(true);
      if (imgURL && imgURL !== NoPhotoIcon) {
        await UserService.deleteProfilePicture(
          deleteProfilePictureMutation, 
          user.profilePicture
        );
        setProfilePicture(NoPhotoIcon);
        notification.alert('Profile picture deleted successfully', 'success');
      }
      setIsLoading(false);
      goBack();
    } catch (err) {
      setIsLoading(false);
      // @ts-ignore
      notification.alert(err.message, 'danger');
    }
  };

  // Evaluate DeleteButton feature flag
  const [renderDeleteButton, setRenderDeleteButton] = useState(false);
  const flagName = 'OrganizationProfilePicture.DeleteButton';

  return (
    <>
      <input
        type="file"
        style={{ display: 'none' }}
        ref={inputRef}
        onChange={async ({ target: { validity, files } }) => {
          const file = files ? files[0] : null;
          if (!file || !validity.valid) {
            return;
          }
          if (
            !['image/jpeg', 'image/png'].some((elem) => elem === file.type)
          ) {
            return;
          }
          setImgFile(file);
          setImgURL(await fileToUrl(file));
        }}
      />

      <Row className="justify-content-center">
        <Col className="text-center" xs="12" lg="auto">
          <img
            className={styles['profile-pic']}
            src={imgURL}
            alt="My account"
          />
        </Col>

        <Col className="text-center" xs="12" lg="auto">
          {/*
            // @ts-ignore */}
          <Larky.Button
            onClick={() => (inputRef && inputRef.current ? inputRef.current.click() : null)}
          >
            Choose File
          </Larky.Button>
        </Col>
      </Row>

      <Row className="mt-5 align-items-center">
        <Col>
          {/*
            // @ts-ignore */}
          <Larky.Button
            className="w-100"
            dataCy="cancel"
            size="lg"
            outlined
            onClick={() => goBack()}
          >
            Cancel
          </Larky.Button>
        </Col>
        {
          deletePermission ? (
              <Col>
            {/*
              // @ts-ignore */}
            <Larky.Button
              className="w-100"
              dataCy="delete"
              size="lg"
              danger
              onClick={() => handleDelete()}
            >
              Delete
            </Larky.Button>
          </Col>
          ) : null
        }
        <Col>
          {/*
            // @ts-ignore */}
          <Larky.Button
            className="w-100"
            dataCy="save"
            size="lg"
            type="submit"
            onClick={handleSubmit}
            disabled={isLoading}
          >
            Save
          </Larky.Button>
        </Col>
      </Row>
    </>
  );
}

ProfilePicture.propTypes = {
  user: PropTypes.shape({
    id: PropTypes.string,
    profilePicture: PropTypes.string.isRequired,
  }).isRequired,
  goBack: PropTypes.func.isRequired,
  setProfilePicture: PropTypes.func.isRequired
};

export default ProfilePicture;
