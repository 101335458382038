import React from 'react';
import styled from 'styled-components';
import { useTable } from 'react-table';
import { DndProvider, useDrag, useDrop } from 'react-dnd';
import { TouchBackend } from 'react-dnd-touch-backend';
import { HTML5Backend } from 'react-dnd-html5-backend';
import update from 'immutability-helper';
import {
  Table as ReactTable,
  Card,
} from 'react-bootstrap';

import PropTypes from 'prop-types';
import dragdropicon from './dragdropicon.svg';
import styles from '../../../../components/CustomTable/CustomTable.module.scss';

const Styles = styled.div`

  table {
    width: 97%;
    margin: 24px auto;
    table-layout: fixed;

    tr {
      :last-child {
        td {
          border-bottom: 0;
        }
      }
    }
    th { 
      background: #6e7c8b;
      color: white;
    }
    th,
    td {
      margin: 0;
      padding: 0.5rem;

      :last-child {
        border-right: 0;
      }
    }
  }
`;

const Table = ({ columns, records, setRecords }) => {
  const getRowId = React.useCallback((row) => row.actionId, []);

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
  } = useTable({
    data: records,
    columns,
    getRowId,
  });

  const moveRow = (dragIndex, hoverIndex) => {
    const dragRecord = records[dragIndex];
    setRecords(
      update(records, {
        $splice: [
          [dragIndex, 1],
          [hoverIndex, 0, dragRecord],
        ],
      }),
    );
  };

  const opts = {
    enableMouseEvents: true,
  };

  return (
    <DndProvider
      className={styles.table}
      backend={HTML5Backend}
      options={opts}
    >
      <ReactTable striped hover responsive {...getTableProps()}>
        <thead>
          {headerGroups.map((headerGroup) => (
            <tr>
              {headerGroup.headers.map((column) => (
                <th {...column.getHeaderProps()}>{column.render('Header')}</th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody {...getTableBodyProps()}>
          {rows.map(
            (row, index) => (prepareRow(row) || (
              <Row
                index={index}
                row={row}
                moveRow={moveRow}
                {...row.getRowProps()}
              />
            )),
          )}
        </tbody>
      </ReactTable>
    </DndProvider>
  );
};

const DND_ITEM_TYPE = 'row';

const Row = ({ row, index, moveRow }) => {
  const dropRef = React.useRef(null);
  const dragRef = React.useRef(null);

  const [, drop] = useDrop({
    accept: DND_ITEM_TYPE,
    hover(item, monitor) {
      if (!dropRef.current) {
        return;
      }
      const dragIndex = item.index; // newSequenceNum
      const hoverIndex = index; // sequenceNum
      // Don't replace items with themselves
      if (dragIndex === hoverIndex) {
        return;
      }
      // Determine rectangle on screen
      const hoverBoundingRect = dropRef.current.getBoundingClientRect();
      // Get vertical middle
      const hoverMiddleY = (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2;
      // Determine mouse position
      const clientOffset = monitor.getClientOffset();
      // Get pixels to the top
      const hoverClientY = clientOffset.y - hoverBoundingRect.top;
      // Only perform the move when the mouse has crossed half of the items height
      // When dragging downwards, only move when the cursor is below 50%
      // When dragging upwards, only move when the cursor is above 50%
      // Dragging downwards
      if (dragIndex < hoverIndex && hoverClientY < hoverMiddleY) {
        return;
      }
      // Dragging upwards
      if (dragIndex > hoverIndex && hoverClientY > hoverMiddleY) {
        return;
      }
      // Time to actually perform the action
      moveRow(dragIndex, hoverIndex);
      // Note: we're mutating the monitor item here!
      // Generally it's better to avoid mutations,
      // but it's good here for the sake of performance
      // to avoid expensive index searches.
      item.index = hoverIndex;
    },
  });

  const [{ isDragging }, drag, preview] = useDrag({
    item: { type: DND_ITEM_TYPE, index },
    type: DND_ITEM_TYPE,
    // item: () => ({ index }),
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
    }),
  });

  const opacity = isDragging ? 0.75 : 1;

  preview(drop(dropRef));
  drag(dragRef);

  return (
    <tr ref={dropRef} style={{ opacity }}>
      {row.cells.map((cell) => (
        <td ref={dragRef} style={{ cursor: 'grab', userSelect: 'none' }} {...cell.getCellProps()}>
          <img height={16} src={dragdropicon} style={{ paddingRight: '7px' }} />
          {cell.render('Cell')}
        </td>
      ))}
    </tr>
  );
};

function CampaignMessagesReorder({ tableData }) {
  const columns = React.useMemo(
    () => [
      {
        Header: 'Nudge',
        accessor: 'name',
      },
    ],
    [],
  );

  return (
    <Styles>
      <Card className="table-view">
        <Table
          columns={columns}
          records={tableData.records}
          setRecords={tableData.setRecords}
        />
      </Card>
    </Styles>
  );
}

CampaignMessagesReorder.propTypes = {
  tableData: PropTypes.shape({}).isRequired,
};

export default CampaignMessagesReorder;
