// Components
import { useNotification } from 'components/Notification/Notification';

class OrganizationService {
  /**
   * Check if org has valid key
   * @param {Object} query
   * @param {String} id Organization Id
   */
  static async checkOrgKey(query, id) {
    try {
      await query.refetch({ id });
      return null;
    } catch (err) {
      return err;
    }
  }

  /**
   * Get all devices from the same organization
   * @param {Object} query
   * @param {String} id Organization Id
   * @param {Object} params {'onlyTotal': "true", 'anotherKey': "value"} <OPTIONAL>
   */
  static async getDevices(query, id, queryParameters = []) {
    try {
      const params = Object.keys(queryParameters).map((key) => `${key}=${queryParameters[key]}`).join('&');

      const { data } = await query.refetch({ id, params });
      return data.getOrganizationDevices;
    } catch (err) {
      return err;
    }
  }

  /**
   * Update the Organization's Message Capping settings
   * @param mutation name of mutation being used
   * @param organizationId org ID of organization's limit that is being updated
   * @param input payload of info to update message capping limit with
   */
  static async updateMessageCapping(mutation, organizationId, input) {
    const { data } = await mutation({ variables: { organizationId, input } });
    return data.updateMessageCapping;
  }

  /**
   * Get an organization's message capping limits
   * @param query the name of the query being used
   * @param organizationId org ID user wants to get message capping limit for
   */
  static async getMessageCappingLimit(query, organizationId) {
    const { data } = await query.refetch({ organizationId });
    return data.getMessageCappingLimit;
  }

  /**
   * Get an organization's feature flag by name
   * @param query the name of the query being used
   * @param organizationId org ID user wants to get message capping limit for
   * @param featureName feature name
   */
  static async getFeatureFlag(query, organizationId, featureName) {
    const { data } = await query.refetch({ organizationId, featureName });
    return data.getFeatureFlag;
  }

  /**
   * Get all organization's feature flags
   * @param query the name of the query being used
   * @param organizationId org ID user wants to get message capping limit for
   */
  static async getAllFeatureFlags(query, organizationId) {
    const { data } = await query.refetch({ organizationId });
    return data.getAllFeatureFlags;
  }

  /**
   * Get an organization's message capping limits
   * @param query the name of the query being used
   * @param organizationId org ID user wants to get organization for
   */
  static async getOrganization(query, organizationId) {
    const { data } = await query.refetch({ organizationId });
    return data.getOrganization;
  }

  static async getOrganizations(query, organizationId) {
    const { data } = await query.refetch({ organizationId });
    return data.getOrganizations;
  }

  static async getMessagingReach(query, organizationId) {
    const { data } = await query.refetch({ organizationId });
    if (data.getMessagingReach.reach) {
      return data.getMessagingReach.reach[0];
    }
    return data.getMessagingReach;
  }

  static async getOrganizationCategoryData(query, organizationId) {
    const { data } = await query.refetch({ organizationId });
    if (data.getOrganizationCategoryData.categories) {
      return data.getOrganizationCategoryData.categories.reduce((seed, categoryData) => {
        seed.labels.push(categoryData.categoryName);
        seed.values.push(categoryData.categoryPercentValue)
        return seed;
      }, {labels: [], values: []})
    }
    return data.getOrganizationCategoryData;
  }

  static async getOrganizationInsightsData(query, organizationId) {
    const { data } = await query.refetch({ organizationId });
    if (data.getOrganizationInsightsData.insights) {
      return data.getOrganizationInsightsData.insights
    }
    return data.getOrganizationInsightsData;
  }

  static async getOrganizationCategories(query) {
    const { data } = await query.refetch();
    const allFormatted = data.getOrganizationCategories.categories.reduce((seed, category) => {
      const formattedCat = {value: category.id, label: category.displayName};
      seed.push(formattedCat)
      return seed
    }, []);
    return allFormatted
  };

  static async getOrganizationProfilePicture(query, organizationId) {
    const { data } = await query.refetch({ organizationId });
    return data.getOrganizationProfilePicture;
  }

  static async updateOrganizationProfilePicture(mutation, organizationId, file) {
    const { data } = await mutation({ variables: { organizationId, file } });
    const { UrlResponse } = data.updateOrganizationProfilePicture;
    return UrlResponse.url;
  }

  /**
   * Delete User profile picture
   * @param {Object} mutation
   * @param {String} fileUrl
   */
  static async deleteOrganizationProfilePicture(mutation, fileUrl) {
    const notification = useNotification();
    const des = await notification.fullscreenDialogConfirm(
      'Are you sure you want to delete this profile picture?',
      'Delete Picture',
    );

    if (des) {
      const { data } = await mutation({ variables: { fileUrl } });
      return data.deleteOrganizationProfilePicture;
    }

    return null;
  }

  /**
   * Create Organization
   * @param {Object} mutation
   * @param {Object} payload
   */
  static async createOrganization(mutation, payload) {
    const notification = useNotification();
    const des = await notification.fullscreenDialogConfirm(
      'Are you sure you want to create this organization',
      'Create Organization',
    );

    if (des) {
      const { data } = await mutation({ variables: { input: payload } });
      return data.createOrganization;
    }

    return null;
  }

  /**
   * Update Organization
   * @param {Object} mutation
   * @param {Object} payload
   */
  static async updateOrganization(mutation, payload) {
    const notification = useNotification();
    const des = await notification.fullscreenDialogConfirm(
      'Are you sure you want to update this organization',
      'Update Organization',
    );

    if (des) {
      const { data } = await mutation({ variables: { input: payload } });
      return data.updateOrganization;
    }

    return null;
  }
}



export default OrganizationService;
