import * as Yup from 'yup';

import {
  REQUIRED_FIELD,
  EMAIL_VALIDATION,
  PASSWORD_VALIDATION,
} from 'Constants';

const stringRequiredOnSignup = (val, schema) => (val ? schema.trim().required(REQUIRED_FIELD) : schema);

const validationSchema = (inviteSignup) => Yup.object({
  firstName: Yup.string().when('inviteSignup', {
    is: true,
    then: () => stringRequiredOnSignup(inviteSignup, Yup.string()).required(REQUIRED_FIELD),
    otherwise: () =>  Yup.string().nullable().default(null),
  }),
  lastName: Yup.string().when('inviteSignup', {
    is: true,
    then: () =>  stringRequiredOnSignup(inviteSignup, Yup.string()).required(REQUIRED_FIELD),
    otherwise: () => Yup.string().nullable().default(null),
  }),
  email: Yup.string().when('inviteSignup', {
    is: true,
    then: () => Yup.string().nullable().default(null),
    otherwise: () => Yup.string().email(EMAIL_VALIDATION).required(REQUIRED_FIELD),
  }),
  password: Yup.string().when('inviteSignup', {
    is: true,
    then: () => stringRequiredOnSignup(inviteSignup, Yup.string()).required(REQUIRED_FIELD),
    otherwise: () => Yup.string().nullable().default(null),
  }),
  confirmPassword: Yup.string().when('inviteSignup', {
    is: true,
    then: () => Yup.string()
      .oneOf([Yup.ref('password')], PASSWORD_VALIDATION)
      .required(REQUIRED_FIELD),
    otherwise: () => Yup.string().nullable().default(null),
  }),
});

export default validationSchema;
