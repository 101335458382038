import React, { useState, useEffect } from 'react';
import { useFormik } from 'formik';
import { Col, Row } from 'react-bootstrap';
import { useMutation, useQuery } from '@apollo/client';
import PropTypes from 'prop-types';
import styles from './TwoFactorAuth.module.scss';
import CustomInput from '../../../components/CustomInput/CustomInput';
import Larky from '../../../components/Larky';
import { GET_QRCODE } from '../../../graphql/queries/users';
import {VERIFY_AUTHENTICATOR_CODE} from 'graphql/mutations/users';
import UserService from 'services/UserService';
import useAuthorization from "../../../hooks/useAuthentication";
import {TOKEN_EXPIRED_LOGIN_DISPATCHER} from "../../../Constants";

function cleanSVG(svgString) {
  // Remove unwanted characters and decode if necessary
  return svgString.replace(/^b'|\\x27/g, '').replace(/\\n/g, '\n').replace(/\\'/g, "'");
}


const TwoFactorAuthRegisterAuthenticator = ({ displayCallback, userInfo }) => {
  const [verifyAuthenticatorCode] = useMutation(VERIFY_AUTHENTICATOR_CODE);
  const [verificationSubmitWrongMessage, setVerificationSubmitWrongMessage] = useState(null);

  const handleClose = () => {
  window.location.href = `/login?dispatcher=${TOKEN_EXPIRED_LOGIN_DISPATCHER}`;
  };

  const { data, loading, error } = useQuery(GET_QRCODE, {
    variables: { email: userInfo.email, id: userInfo.id }, // Pass the email as a variable
    skip: !userInfo.email, // Skip the query if email is not available
  });
  const [qrCodeSVG, setQrCodeSVG] = useState('');


  useEffect(() => {
  if (loading) return; // Skip if loading
  if (error) {
    console.error('Error fetching QR code:', error.message);
    return;
  }
  if (data && data.getQrcode) {
    let { image } = data.getQrcode;

    if (image) {
      // Clean SVG string
      image = cleanSVG(image);
      setQrCodeSVG(image);
    } else {
      console.warn('No QR code image available');
    }
  } else {
      console.warn('No QR code image data available or incorrect data structure');
    }
  }, [data, loading, error]);



  const formik = useFormik({
    initialValues: {
      authenticatorVerificationCode: '',
    },
    validateOnChange: false,
    enableReinitialize: true,
    onSubmit: async (values) => {
      try {
        const AuthenticatorCodeInput = {
          userId: userInfo.id,
          authenticatorCode: formik.values.authenticatorVerificationCode,
          rememberMe: false
        };
        const authenticatorCodeSent = await UserService.verifyUserAuthenticatorCode(
          verifyAuthenticatorCode,
          AuthenticatorCodeInput
        );

         if (authenticatorCodeSent && authenticatorCodeSent.ok) {
          await useAuthorization.setIsAuthorized();
          const userSet = { ...userInfo, isAuthorized: true };
          displayCallback(userSet, false);
        }
        if (authenticatorCodeSent && !authenticatorCodeSent.ok) {
          setVerificationSubmitWrongMessage('Something is not right. Please check the code or click the link below to contact support for further assistance.');
        }
      } catch (e) {
        setVerificationSubmitWrongMessage('Something is not right. Please check the code or click the link below to contact support for further assistance.');
      }
    },
  });

  const handleSubmitForm = async () => {
    await formik.submitForm();
  };

  return (
    <div style={{ width: '100%' }}>
      <form>
        {/*<h4>Enable Authenticator App</h4>*/}
        {/*<br />*/}
        <h4 style={{ textAlign: 'left', fontSize: '1.25rem', marginTop: '-22px', marginBottom: '15px' }}>
          If you don't have one installed already, please go to the appropriate app store and download an authenticator app now.
        </h4>
        <Row className="align-items-center justify-content-center mb-4">
          <Col className="col-auto">
            {/* Render SVG content */}
            <div
              id="qrcode"
              style={{ width: '100%', height: 'auto', border: '1px solid #ccc' }} // Add a border to check visibility
              dangerouslySetInnerHTML={{ __html: qrCodeSVG }}
            />
          </Col>
        </Row>
        <br />
        <h4 style={{ textAlign: 'left', fontSize: '1.25rem', marginTop: '-22px', marginBottom: '15px' }}>
          Open the app, scan the QR code above, and enter the six-digit code that is generated by the app:
        </h4>
        <CustomInput
          type="text"
          name="authenticatorVerificationCode"
          placeholder="Input Verification Code"
          value={formik.values.authenticatorVerificationCode}
          onChange={formik.handleChange}
          error={formik.errors.authenticatorVerificationCode}
          onKeyPress={(e) => {
                if (e.key === 'Enter') {
                  e.preventDefault();
                  handleSubmitForm();
                }
              }}
        />
        {verificationSubmitWrongMessage ? <div className={styles['verification-wrong-message']}>{verificationSubmitWrongMessage}</div> : null}
        <Row className="align-items-center justify-content-center mb-4">
          <Col className="col-auto">

            <Larky.Button
                      type="button"
                      outlined
                      onClick={() => {
                        handleClose();
                      }} > {' '} Cancel
            </Larky.Button>

              <Larky.Button
                  type="button"
                  onClick={handleSubmitForm}
                  isLoading={formik.isSubmitting}
                >
                  {' '}
                  Verify 2FA Code
            </Larky.Button>
          </Col>
        </Row>
        <Row className="align-items-center justify-content-center mb-4">
          Need help?
          <Col className="col-auto">
            <a data-cy="contact-support" href="mailto:support@larky.com">Contact Support</a>
          </Col>
        </Row>
      </form>
    </div>
  );
};

TwoFactorAuthRegisterAuthenticator.propTypes = {
  displayCallback: PropTypes.func,
  userInfo: PropTypes.shape({
    id: PropTypes.string.isRequired,
  }),
};

TwoFactorAuthRegisterAuthenticator.defaultProps = {
  displayCallback: () => null,
  userInfo: {},
};

export default TwoFactorAuthRegisterAuthenticator;
