import React from 'react';

import { Route, Switch } from 'react-router-dom';

import { useMutation, useQuery } from '@apollo/client';

// Libs
import Analytics from 'libs/Analytics';

// Components
import CustomTable from 'components/CustomTable/CustomTable';
import PageHeader from 'components/PageHeader/PageHeader';
import { useNotification } from 'components/Notification/Notification';

// Services
import UserService from 'services/UserService';

// Utils
import { deleteRow } from 'Utils';

// GraphQL
import { DELETE_USER_MUTATION } from 'graphql/mutations/users';
import { GET_USERS } from 'graphql/queries/users';

// Constants
import { ROUTES } from 'Constants';
import UserRow from './components/UserRow/UserRow';

// Styles
import styles from './Users.module.scss';

function Users() {
  const notification = useNotification();

  const [deleteUserMutation] = useMutation(DELETE_USER_MUTATION);
  const getUsersQuery = useQuery(GET_USERS, { skip: true });

  const getData = async (
    orderBy,
    sortOrder,
    start,
    params,
  ) => UserService.all(getUsersQuery, start + 1);

  const routes = [
    {
      path: ROUTES.USERS.MAIN,
      title: 'Manage users',
      subtitle: 'Manage all nudge users.',
    },
  ];

  return (
    <Switch>
      {routes.map(({ path, title, subtitle }) => (
        <Route key={path} path={path}>
          <div className={styles.root}>
            <PageHeader
              title={title}
              subtitle={subtitle}
            />
            <CustomTable
              getData={getData}
              customRow={(data, handleSelect, updateCallback) => (
                <UserRow
                  key={data.id}
                  data={data}
                  handleSelect={handleSelect}
                  handleDeleteRow={() => deleteRow(
                    UserService,
                    notification,
                    deleteUserMutation,
                    data.id,
                    () => {
                      Analytics.trackDeleteLocation(data);
                      updateCallback();
                    },
                    'User',
                  )}
                />
              )}
              columns={['Role', 'Name', 'email', 'Creation date']}
              isPaginable
            />
          </div>
        </Route>
      ))}
    </Switch>
  );
}

export default Users;
