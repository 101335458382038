const CSV_MIME_TYPES = [
  'text/plain',
  'text/x-csv',
  'application/vnd.ms-excel',
  'application/csv',
  'application/x-csv',
  'text/csv',
  'text/comma-separated-values',
  'text/x-comma-separated-values',
  'text/tab-separated-values',
];

/**
 * gets csv values from a csv file line.
 * IMPORTANT: It assumes no commas in the values names.
 */
function getCsvValuesFromLine(line) {
  return line[0].split(',');
}

/**
 * process csv file
 * IMPORTANT: It assumes no commas in the values names.
 */
function processData(csv) {
  const allTextLines = csv.split(/\r\n|\n/);
  const lines = allTextLines.map((data) => data.split(';'));
  const federationIds = [];

  lines.forEach((data, idx) => {
    const csvFromLine = getCsvValuesFromLine(data);
    // if (csvFromLine.length > 1) {
    //   throw 'File contains more than 1 column.';
    // }
    if (csvFromLine.length > 0 && csvFromLine[0]) {
      federationIds.push(csvFromLine[0]);
    }
  });
  return federationIds;
}

/**
 * extracts from the CSV file the federation ids as an array of strings
 * If the file cannot be read or something unexpected happens while processing
 * the file, an error is thrown.
 */
const getFederationIds = async (fileToRead) => new Promise((res, rej) => {
  const reader = new FileReader();
  reader.onload = (event) => {
    const csv = event.target.result;
    try {
      res(processData(csv));
    } catch (e) {
      rej(e);
    }
  };
  reader.onerror = (event) => {
    if (event.target.error.name === 'NotReadableError') {
      return 'Cannot read file!';
    }
    return 'There was an error while trying to open the file';
  };
  reader.readAsText(fileToRead);
});

const validateFederationCSVFile = async (file) => {
  let isError = false;
  let errorMessage = '';
  let federationIds;

  if (CSV_MIME_TYPES.includes(file.type)) {
    await getFederationIds(file)
      .then((value) => {
      //  Validate file is not empty, if empty let the user know.
        if (value.length < 1) {
          isError = true;
          errorMessage = 'The file you submitted is empty';
        } else {
          isError = false;
          federationIds = value;
        }
      })
      .catch((err) => {
      // Make sure the file opens.
        isError = true;
        errorMessage = err;
      });
  } else {
    isError = true;
    errorMessage = ' File must be a CSV';
  }

  if (isError) {
    return { error: errorMessage };
  }
  return { federationIds };
};
export default validateFederationCSVFile;
