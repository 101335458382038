import React from 'react';
import PropTypes from 'prop-types';

import {printPrettyDateFromDatetime, formatNumber} from 'Utils';
import CustomCheckbox from 'components/CustomCheckbox/CustomCheckbox';
import RowActionsDropdown from '../../../../components/CustomTable/RowActionsDropdown/RowActionsDropdown';

function LocationRow({
                         data,
                         handleSelect,
                         handleArchiveRow,
                         handleDeleteRow,
                         isSelectable,
                         hasEditPermission,
                     }) {
    const createLocationOptionList = () => {
        const listOfOptions = [];
        listOfOptions.push({
            label: hasEditPermission && !data.archived ? 'View/Edit location' : 'View location',
            value: 'edit'
        });

        if (handleArchiveRow && hasEditPermission) {
            listOfOptions.push({label: null, value: null, isLineBreak: true});
            listOfOptions.push({label: !data.archived ? 'Archive location' : 'Unarchive location', value: 'archive'});
        }
        if (handleDeleteRow && hasEditPermission) {
            listOfOptions.push({label: null, value: null, isLineBreak: true});
            listOfOptions.push({label: 'Delete location', value: 'delete'});
        }
        return listOfOptions;
    };
    // When showing entries that have is_location_group = 1, don’t show anything under City or State
    // and under Street Address show the count of linked locations formatted as “1,234 locations”
    const getAddress = () => {
        if (data.isLocationGroup) {
            const locationCount = (data.address && !Number.isNaN(data.address)) ? formatNumber(data.address) : '';
            const locationText = locationCount === '1' ? 'location' : 'locations';
            return `${locationCount} ${locationText}`;
        }
        return data.address;
    };
    const getCity = () => (data.isLocationGroup ? '' : data.city);
    const getZip = () => (data.isLocationGroup ? '' : data.zip);


    return (
        <tr>
            {isSelectable && (
                <td style={{minWidth: 10}}>
                    <CustomCheckbox
                        value={data.selected}
                        onChange={(value) => handleSelect(data.id, value)}
                    />
                </td>
            )}
            <td>{data.name}</td>
            <td>{data.description}</td>
            <td>{printPrettyDateFromDatetime(data.created)}</td>
            {data.isLocationGroup && (
                <td>{getAddress()}</td>
            )}
            {!data.isLocationGroup && (
                <td>
                    {getAddress() && (<div>{`Address: ${getAddress()}`}</div>)}
                    {getCity() && (<span>{`City: ${getCity()}`}</span>)}
                    {getZip() && (<span>{` Zip: ${getZip()}`}</span>)}
                </td>
            )}
            <td>
                <RowActionsDropdown
                    editRowPath={`/locations/edit/${data.id}`}
                    archived={data.archived}
                    onArchiveRow={handleArchiveRow}
                    onDeleteRow={handleDeleteRow}
                    hasEditPermission={hasEditPermission}
                    options={createLocationOptionList()}
                    placeholder="Actions..."
                />
            </td>
        </tr>
    );
}

LocationRow.propTypes = {
    data: PropTypes.shape({
        id: PropTypes.oneOfType([
            PropTypes.number, PropTypes.string,
        ]).isRequired,
        name: PropTypes.string.isRequired,
        description: PropTypes.string,
        created: PropTypes.string.isRequired,
        address: PropTypes.string.isRequired,
        city: PropTypes.string.isRequired,
        zip: PropTypes.string,
        archived: PropTypes.bool.isRequired,
        selected: PropTypes.bool.isRequired,
        isLocationGroup: PropTypes.bool.isRequired,
    }).isRequired,
    handleSelect: PropTypes.func.isRequired,
    handleArchiveRow: PropTypes.func.isRequired,
    handleDeleteRow: PropTypes.func.isRequired,
    isSelectable: PropTypes.bool,
    hasEditPermission: PropTypes.bool,
};

LocationRow.defaultProps = {
    isSelectable: false,
    hasEditPermission: false,
};

export default LocationRow;
