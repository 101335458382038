/**
 * CampaignGallery.jsx
 *
 * Component that displays Campaigns that are predefined for clients.
 *
 */
import React from 'react';
import PropTypes from 'prop-types';

// styles
import CustomButton from 'components/CustomButton/CustomButton';
import CustomCheckbox from 'components/CustomCheckbox/CustomCheckbox';
import styles from '../CampaignGallery.module.scss';
import editPencil from '../../../../../assets/img/edit-pencil.png';

// components

const CampaignGalleryCard = ({
  id, title, description, cadence, notes, editable, onEditClick, onCreateClick, isDraft, handleActivateOrDeactivate, isTemplateCreator, userId, hasWritePermission,
}) => {
  const campaignTemplateInput = {
    title, description, cadence, notes, isDraft, updatedBy: userId,
  };

  return (
    <>
      <article className={styles.card}>
        <div className={styles['card-content']}>
          <div className={styles['card-header']}>
            <h2>{title}</h2>
            <p>{description}</p>
          </div>
          <div className={styles['card-body']}>
            <div className={styles['card-detail']}>
              <div className={styles['card-detail-header']}>Cadence: </div>
              <div className={styles['card-detail-text']}>{cadence}</div>
            </div>
            <div className={styles['card-detail']}>
              <div className={styles['card-detail-header']}>Notes: </div>
              <div className={styles['card-detail-text']}>{notes}</div>
            </div>
          </div>
          <div className={isTemplateCreator ? styles['card-footer-creator'] : styles['card-footer']}>
            {editable && hasWritePermission ? (
              <>
                <div>
                  <img
                    onClick={() => {
                      onEditClick();
                    }}
                    className={styles.edit}
                    src={editPencil}
                    alt="Edit Template"
                  />
                  <label>
                    Edit title, etc.
                  </label>
                </div>
                {isTemplateCreator
                  ? (
                    <CustomCheckbox
                      value={isDraft !== true}
                      onChange={() => handleActivateOrDeactivate(id, campaignTemplateInput)}
                      title={isDraft === true ? 'Not Published' : 'Published'}
                    />
                  ) : null}
              </>
            ) : null}
            {
                hasWritePermission ? (
                  <CustomButton
                    onClick={() => {
                      onCreateClick();
                    }}
                    size="sm"
                    variant="contained"
                    text={editable
                      ? 'Open Template' : 'Create Campaign'}
                  />
                ) : null
              }
          </div>
        </div>
      </article>
    </>
  );
};

CampaignGalleryCard.propTypes = {
  id: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  cadence: PropTypes.string,
  notes: PropTypes.string,
  campaignId: PropTypes.number,
  editable: PropTypes.bool,
  onEditClick: PropTypes.func,
  onCreateClick: PropTypes.func,
  active: PropTypes.bool.isRequired,
  handleActivateOrDeactivate: PropTypes.func.isRequired,
  isTemplateCreator: PropTypes.bool.isRequired,
  userId: PropTypes.string.isRequired,
  hasWritePermission: PropTypes.bool.isRequired,
};

CampaignGalleryCard.defaultProps = {
  id: null,
  title: '',
  description: '',
  cadence: '',
  notes: '',
  campaignId: null,
  editable: false,
  onEditClick: () => null,
  onCreateClick: () => null,
  active: false,
  handleActivateOrDeactivate: () => null,
  isTemplateCreator: false,
  userId: '',
  hasWritePermission: false,
};

export default CampaignGalleryCard;
