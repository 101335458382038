import React, {useEffect, useState} from 'react';
import moment from 'moment';
import {printPrettyDateFromDatetime} from 'Utils';
import RowActionsDropdown from '../../../../components/CustomTable/RowActionsDropdown/RowActionsDropdown';

function PartnerManagementRow({
                                  data,
                                  handleManageOrganization
                              }) {
    const createPartnerManagementOptionList = () => {
        const listOfOptions = [];
        listOfOptions.push({label: 'Manage Organization', value: 'edit'});

        return listOfOptions;
    };

    const [nudgeTier, setNudgeTier] = useState("");
    const [nudgeGeoAddOn, setNudgeGeoAddOn] = useState("");

    // useEffect to display nudge tier name and if org has geo add on
    useEffect(async () => {
        function determineTier(tierName) {
            if (tierName === 1) {
                setNudgeTier("Base")
            }
            if (tierName === 2) {
                setNudgeTier("Engage")
            }
        };

        function determineGeoAddOn(geoEntry) {
            if (geoEntry === 1) {
                setNudgeGeoAddOn("Yes")
            }
            if (geoEntry === 0) {
                setNudgeGeoAddOn("No")
            }
        };

        if (data.featureTier !== null) {
            determineTier(data.featureTier);
        }
        if (data.nudgeGeo !== null) {
            determineGeoAddOn(data.nudgeGeo);
        }

    }, [data.featureTier, data.nudgeGeo]);

    return (
        <tr>

            <td>{`${data.name}`}</td>
            <td>{`${data.id}`}</td>
            <td>{`${data.organizationType && data.organizationType.name}`}</td>
            <td>{`${data.launchDate ? printPrettyDateFromDatetime(data.launchDate) : ""}`}</td>
            <td>{(data.userCount).toLocaleString()}</td>
            <td>{nudgeTier}</td>
            <td>{nudgeGeoAddOn}</td>
            <td>{data.apnsConfigure ? "Yes" : "No"}</td>
            <td>{data.useSandbox ? "Sandbox" : "Production"}</td>
            <td>
                <RowActionsDropdown
                    hasEditPermission
                    onEditRow={handleManageOrganization}
                    options={createPartnerManagementOptionList()}
                    placeholder="Actions..."
                />
            </td>
        </tr>
    );
}

export default PartnerManagementRow;
