import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';

import notiPreviewLogo from 'assets/img/noti-preview-logo.png';
import notiPreview from 'assets/img/noti-preview.png';

import styles from './NotificationPreview.module.scss';
import CustomInput from "../CustomInput/CustomInput";
import { getUser } from "../../Utils";
import OrganizationService from "../../services/OrganizationService";
import { useQuery } from "@apollo/client";
import { GET_ORGANIZATION_PROFILE_PICTURE } from "../../graphql/queries/organizations";

function NotificationPreview({ title, message }) {
  const notificationDiv = useRef();
  const [error, setError] = useState('');
  const [orgProfilePicture, setOrgProfilePicture] = useState(null);
  const [user, setUser] = useState(null);
  const getOrganizationPictureQuery = useQuery(GET_ORGANIZATION_PROFILE_PICTURE, { skip: true });
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    async function getUserById() {
      setIsLoading(true);
      const localUser = await getUser();
      setUser(localUser);
      const { currentOrganization } = localUser;
      const stringifyOrgId = currentOrganization.id.toString() || '';
      const profilePicture = await OrganizationService.getOrganizationProfilePicture(
        getOrganizationPictureQuery, stringifyOrgId,
      );
      setOrgProfilePicture(profilePicture.url);
      setIsLoading(false);
    }

    getUserById();
  }, [getUser, orgProfilePicture]);

  const isEmojiCharacter = (char) => {
  return Array.from(char).length > 1;
};

const truncateString = (str, maxLength) => {
  if (!str || str.length <= maxLength) return str;

  let truncated = '';
  let charCount = 0;

  for (const char of str) {
    if (isEmojiCharacter(char)) {
      if (charCount + 2 <= maxLength) {
        truncated += char;
        charCount += 2; // Emojis count as two characters
      }
    } else {
      if (charCount + 1 <= maxLength) {
        truncated += char;
        charCount++;
      }
    }

    if (charCount >= maxLength) break;
  }

  if (charCount < str.length) {
    truncated += '...';
  }

  return truncated;
};

  // Truncate title and message
  const truncatedTitle = truncateString(title, 30);
  const truncatedMessage = truncateString(message, 157);

  return (
    <div className={styles['preview-style']}>
      <h6>PREVIEW</h6>
      <img
        src={notiPreview}
        className="rounded mx-auto d-block"
        alt="Preview your notification"
        width="400"
      />

      <div className={styles['notification-div']} ref={notificationDiv}>
        {orgProfilePicture ? (
          <img src={orgProfilePicture} alt="logo" style={{ height: '40px', width: '40px' }} />
        ) : <img src={notiPreviewLogo} alt="logo" />}
        <div className={styles['notification-wrapper']} style={{ paddingLeft: '8px' }}>
          <div className={styles['custom-section']}>
            <strong>{truncatedTitle}</strong>
            <div className={styles['notification-msg']}>{truncatedMessage}</div>
          </div>
        </div>
      </div>

      <div className="error">{error}</div>
    </div>
  );
}

NotificationPreview.propTypes = {
  title: PropTypes.string,
  message: PropTypes.string,
};

export default NotificationPreview;
