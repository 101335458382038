// Dependencies
import React, {useEffect, useState} from 'react';
import PropTypes from 'prop-types';
import { useQuery, useMutation } from '@apollo/client';
import {
  Redirect, Route, Switch, useHistory, useLocation,
} from 'react-router-dom';

// Components
import CustomTable from 'components/CustomTable/CustomTable';
import PageHeader from 'components/PageHeader/PageHeader';
import { useNotification } from 'components/Notification/Notification';
import AnalyticsModal from '../Nudges/components/AnalyticsModal/AnalyticsModal';
import { useAuthMachineValue } from 'contexts/auth-machine.context.ts';

// Services
import CampaignService from 'services/CampaignService';
import NudgeService from 'services/NudgeService';

// GraphQL
import { ARCHIVE_CAMPAIGN_MUTATION } from 'graphql/mutations/campaigns';
import { GET_CAMPAIGN, GET_CAMPAIGN_MESSAGES } from 'graphql/queries/campaigns';
import {
  SEARCH_NUDGES,
  GET_NUDGE,
  GET_NUDGES_RECEIVED,
  GET_NUDGES_TAPPED,
  GET_NUDGES_HEADERS
} from 'graphql/queries/nudges';

// Utils
import {archiveManyRows, archiveRow, getUserFeature} from 'Utils';

// Constants
import { ROUTES } from 'Constants';

import CampaignRow from './components/CampaignRow/CampaignRow';
import { GET_CAMPAIGNS } from '../../graphql/queries/campaigns';

import './components/CreateOrUpdateCampaign/CreateOrUpdateCampaign.module.scss';
import styles from '../Nudges/Nudges.module.scss';
import campaignService from '../../services/CampaignService';
import {useInitialFocus} from "../../hooks/useInitialFocus";
import {NUMBER_OF_DAYS_TO_SHOW_ON_DOWNLOAD} from "../../Constants";
import {GET_NUDGE_AND_CAMPAIGN_CATEGORIES} from "../../graphql/queries/nudges";

function Campaigns({ organizationId }) {
  const notification = useNotification();
  const history = useHistory();
  const location = useLocation();
  const getCampaignsQuery = useQuery(GET_CAMPAIGNS, { skip: true });
  const getCampaignQuery = useQuery(GET_CAMPAIGN, { skip: true });
  const [archiveCampaignMutation] = useMutation(ARCHIVE_CAMPAIGN_MUTATION);
  const [hasCampaignEditPermission, setHasCampaignEditPermission] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [currentCampaign, setcurrentCampaign] = useState(null);
  const [displayBulkAction, setDisplayBulkAction] = useState(false);

  const getNudgeMessageCategories = useQuery(GET_NUDGE_AND_CAMPAIGN_CATEGORIES, { skip: true })
  const [messageCategoryOptions, setMessageCategoryOptions] = useState([]);

  useInitialFocus()

  const getData = async (orderBy, sortOrder, start, params) => CampaignService.getCampaigns(
    getCampaignsQuery,
    organizationId,
    orderBy,
    sortOrder,
    start,
    params,
  );

  useEffect(async () => {
    const userHasCampaignEditPermission = await getUserFeature('campaigns', 2);
    setHasCampaignEditPermission(userHasCampaignEditPermission);
  }, []);

  const selectActions = [];

  // This logic will choose and display the analytics modal in the dropdown menu for a campaign
  const currentCampaignName = currentCampaign ? currentCampaign.name : null;

  // menu item modal selection logic
  const [currentAuth] = useAuthMachineValue();
  const ENVIRONMENT = currentAuth.context.user.environment;
  const FILE_NAME = currentCampaign ? `Campaign_Analytics_${currentCampaign.id}.jsx` : 'Campaign_Analytics.jsx';

  useEffect(async () => {
    const campaignDetails = currentCampaign ? await CampaignService.getCampaign(getCampaignQuery, organizationId, currentCampaign.id) : null;
    setcurrentCampaign(campaignDetails);
  }, []);

  const hideModalCallback = () => {
    setShowModal(false);
  };

  const getNudgesReceivedQuery = useQuery(GET_NUDGES_RECEIVED, { skip: true });
  const getNudgesTappedQuery = useQuery(GET_NUDGES_TAPPED, { skip: true });
  const getNudgesHeadersQuery = useQuery(GET_NUDGES_HEADERS, { skip: true });

  const getAnalyticsModalParams = () => {
    const downloadAnalyticsParams = {
      ENVIRONMENT,
      FILE_NAME,
      organizationId,
      notification,
      getNudgesReceivedQuery,
      getNudgesTappedQuery,
      getNudgesHeadersQuery,
      NUMBER_OF_DAYS_TO_SHOW_ON_DOWNLOAD,
    };
    if (currentCampaignName) {
      return {
        id: currentCampaign.id || '',
        name: currentCampaignName,
        analyticsParams: downloadAnalyticsParams,
        type: 'Campaign',
      };
    }
    return { id: '', name: '' };
  };

  const routes = [
    {
      path: ROUTES.CAMPAIGNS.MAIN,
      title: 'My campaigns',
      subtitle: 'View all of your campaigns.',
    },
    {
      path: ROUTES.CAMPAIGNS.ARCHIVED,
      title: 'Archived campaigns',
      subtitle: 'View all of your archived campaigns.',
    },
  ];

  if (history.location.pathname === ROUTES.CAMPAIGNS.MAIN) {
    selectActions.push({
      key: 'archive_selected',
      text: 'Archive selected',
      action: (data, updateCallback) => {
        archiveManyRows(CampaignService,
          notification,
          archiveCampaignMutation,
          data,
          updateCallback,
          'Campaigns');
      },
    });
  }

  if (history.location.pathname === ROUTES.CAMPAIGNS.ARCHIVED) {
    selectActions.push({
      key: 'unarchive_selected',
      text: 'Unarchive selected',
      action: (data, updateCallback) => {
        archiveManyRows(CampaignService,
          notification,
          archiveCampaignMutation,
          data,
          updateCallback,
          'Campaigns',
          false);
      },
    });
  }
  const determineCampaignColumns = () => {
    const campaignColumns = ['Status', 'Category', 'Name', 'Description', 'Created', 'Delivery Details']
    if (hasCampaignEditPermission && displayBulkAction) {
      campaignColumns.splice(0, 0, 'Select')
    }
    return campaignColumns
  }

  const getEnabledFilters = () => location.pathname === ROUTES.CAMPAIGNS.MAIN ? ['sortFilter', 'searchQuery', 'statusFilter', 'categoryTypeFilter'] : ['sortFilter', 'searchQuery', 'categoryTypeFilter'];

    // Collect message Categories
  useEffect(async() => {
    const getMessageCategories = async () => {
      try {
        const nudgeCategoryResponse = await NudgeService.getNudgeAndCampaignCategories(getNudgeMessageCategories);
        const filterOptions = {value: 0, label: "All"}
        nudgeCategoryResponse.splice(0, 0, filterOptions)
        setMessageCategoryOptions(nudgeCategoryResponse)
      } catch (e) {
        console.log(e.message);
      }
    }
    await getMessageCategories()
  }, [])

  return (
    <Switch>
      {routes.map(({
        path,
        title,
        subtitle,
      }) => (
        <Route key={path} path={path}>
          <div className={styles.root}>
            <AnalyticsModal
              organizationId={organizationId}
              campaignId={getAnalyticsModalParams().id}
              downloadAnalyticsParams={getAnalyticsModalParams()}
              show={showModal}
              callback={hideModalCallback}
              title="Campaign Analytics"
              text={currentCampaign ? currentCampaign.name : null}
            />
            <PageHeader title={title} subtitle={subtitle} hasEditPermission={hasCampaignEditPermission} />
            <CustomTable
              getData={getData}
              messageCategoryFilters={messageCategoryOptions}
              customRow={(data, handleSelect, updateCallback) => (
                <CampaignRow
                  key={data.id}
                  data={data}
                  handleSelect={handleSelect}
                  handleArchiveRow={() => archiveRow(
                    campaignService,
                    notification,
                    archiveCampaignMutation,
                    data,
                    updateCallback,
                    'Campaign',
                  )}
                  handleShowAnalytics={() => {
                    // set the parent selected nudge data coming from data.id
                    setcurrentCampaign(data);
                    setShowModal(true);
                  }}
                  handleRefreshRow={() => {
                    // for now refresh is disabled for File-based segments
                    if (data.segmentType === 'file') {
                      return () => {};
                    }
                  }}
                  hasEditPermission={hasCampaignEditPermission}
                  isSelectable={displayBulkAction}
                />
              )}
              availableTypes={['archived']}
              columns={determineCampaignColumns()}
              searchKeys={['sort', 'filterKeyword', 'filterStatus', 'category_key']}
              enabledFilters={getEnabledFilters()}
              filterAttrs={['created', 'name']}
              selectActions={selectActions}
              statusType="campaigns"
              isFilterable
              isPaginable
              displayBulkActionCallback={setDisplayBulkAction}
              displayBulkAction={displayBulkAction}
            />
          </div>
        </Route>
      ))}
      <Route render={() => <Redirect to="/" />} />
    </Switch>
  );
}
Campaigns.propTypes = {
  organizationId: PropTypes.number,
};

Campaigns.defaultProps = {
  organizationId: null,
};

export default Campaigns;
