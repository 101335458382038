import { gql } from '@apollo/client';

export const GET_LOCATION = gql`
  query GetLocationQuery($id: ID!) {
    getLocation(id: $id) {
      id
      name
      description
      latitude
      longitude
      address
      state
      city
      country
      zip
      organization {
        id
        name
        website
        federationUrl
        larkyOrganizationId
        larkyEmail
        larkyPassword
        active
        created
        updated
      } 
      active
      archived
      created
      updated
      isLocationGroup
      locationGroupId
    } 
  }
`;

export const GET_LOCATIONS = gql`
  query GetLocationsQuery($organizationId: ID) {
    getLocations(organizationId: $organizationId) {
      id
      name
      description
      latitude
      longitude
      address
      state
      city
      country
      zip
      organization {
        id
        name
        website
        federationUrl
        larkyOrganizationId
        larkyEmail
        larkyPassword
        active
        created
        updated
      } 
      active
      archived
      created
      updated
      isLocationGroup
      locationGroupId
    } 
  }
`;

export const SEARCH_LOCATIONS = gql`
  query SearchLocationsQuery(
    $orderBy: String!
    $sortOrder: String!
    $rows: Int!
    $start: Int!
    $searchParam: SearchParamInput
    $dbSearch: Boolean
  ) {
    searchLocations(
      orderBy: $orderBy
      sortOrder: $sortOrder
      rows: $rows
      start: $start
      searchParam: $searchParam
      dbSearch: $dbSearch
    ) {
      hits {
        id
        hash
        name
        description
        address
        state
        zip
        archived
        city
        coordinates
        created
        updated
        isLocationGroup
      }
      totalResults
    } 
  }
`;
