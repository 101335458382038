import React, { useEffect, useState } from 'react';

// Context
import PropTypes from 'prop-types';
import { Bar } from 'react-chartjs-2';
import { Col, Spinner } from 'react-bootstrap';
import { useQuery } from '@apollo/client';
import useQuickAnalytics from './useQuickAnalytics';
import NudgeService from '../../../services/NudgeService';
import CampaignService from '../../../services/CampaignService';

// Styles
import styles from './QuickviewAnalyticsSection.module.scss';

// Components
import MessagingReach from './MessagingReach/MessagingReach';
import CampaignMessagingReach from '../../Campaigns/components/CampaignAnalytics/CampaignMessagingReach';

// Queries
import { GET_NUDGE_ANALYTICS } from '../../../graphql/queries/nudges';
import { GET_CAMPAIGN_ANALYTICS } from '../../../graphql/queries/campaigns';

const barGraphOptions = {
  responsive: true,
  plugins: {
    legend: {
      display: false,
      reverse: false,
    },
    tooltips: {
      enabled: false,
    },
  },
  scales: {
    y: {
      id: 'y',
      type: 'linear',
      position: 'right',
      beginAtZero: true,
      // offset: true,
      grid: {
        color: 'rgba(0, 0, 0, 0)',
      },
      title: {
        display: true,
        text: 'Engagements',
        color: 'rgba(98, 217, 10, 1)',
        font: {
          size: 24,
          weight: 'bold',
        },
      },
      ticks: {
        stepSize: 1,
        // forces step size to be 1 units
        // display: false,
      },
    },
    y2: {
      id: 'y2',
      type: 'linear',
      position: 'left',
      beginAtZero: true,
      title: {
        display: true,
        text: 'Impressions',
        color: 'rgba(41, 150, 229, 1)',
        font: {
          size: 24,
          weight: 'bold',
        },
      },
      ticks: {
        stepSize: 1,
        // forces step size to be 1 units
        // display: false,
      },
    },
    xAxes: {
      stacked: true,
    },
  },
  maintainAspectRatio: true,
  animation: true,
};

/**
 * Component that displays stats about nudges or a specific nudge
 *
 * @param {String} organizationId
 * @param {String} nudgeId
 * @param {String} campaignId
 * @param {Boolean} isModal - adjusts quick analytics to be displayed in a modal by modifying
 * the styling. e.g. reducing fonts
 */
function QuickviewAnalyticsSection({
  organizationId,
  nudgeId,
  actionId,
  campaignId,
  hasAnalytics,
  toolTipMessage,
  url,
  exportAnalyticsLoading,
  tableHeaders,
}) {
  const { graphData, isLoading } = useQuickAnalytics(
    organizationId, nudgeId, campaignId,
  );

  const getMessagingReachQuery = useQuery(GET_NUDGE_ANALYTICS, { skip: true });
  const getCampaignReachQuery = useQuery(GET_CAMPAIGN_ANALYTICS, { skip: true });

  const [campaignTableData, setCampaignTableData] = useState({});
  const [campaignTableDataLoading, setCampaignTableDataLoading] = useState(false);
  const [nudgeTableData, setNudgeTableData] = useState({});
  const [nudgeTableDataLoading, setNudgeTableDataLoading] = useState(false);

  useEffect(async () => {
    if (nudgeId) {
      // fetch nudge table data
      await setNudgeTableDataLoading(true);
      const messagingReachDataResponse = await NudgeService.getNudgeAnalytics(getMessagingReachQuery, actionId);
      await setNudgeTableData(messagingReachDataResponse);
      await setNudgeTableDataLoading(false);
    }
    if (campaignId) {
      // fetch campaign table data
      await setCampaignTableDataLoading(true);
      const campaignReachDataResponse = await CampaignService.getCampaignAnalytics(getCampaignReachQuery, campaignId);
      await setCampaignTableData(campaignReachDataResponse);
      await setCampaignTableDataLoading(false);
    }
  }, [campaignId, nudgeId]);

  return (
    <>
      {isLoading || exportAnalyticsLoading ? (
        <div style={{ margin: '0 50%' }}>
          <Spinner
            style={{ width: '4rem', height: '4rem' }}
            animation="border"
            role="status"
          />
        </div>
      ) : campaignId && !nudgeId ? (
        <Col className={styles['overflow-col']}>
          <CampaignMessagingReach
            tableData={campaignTableData}
            isLoading={campaignTableDataLoading}
            exportAnalyticsLoading={false}
            tableHeaders={tableHeaders}
            hasAnalytics={hasAnalytics}
            trendToolTipMessage={toolTipMessage}
            trendToolTipUrl={url}
          />
        </Col>
      ) : (
        <>
          <Col className={styles['overflow-col']}>
            <MessagingReach
              tableData={nudgeTableData}
              isLoading={nudgeTableDataLoading}
              exportAnalyticsLoading={exportAnalyticsLoading}
              tableHeaders={tableHeaders}
              hasAnalytics={hasAnalytics}
              trendToolTipMessage={toolTipMessage}
              trendToolTipUrl={url}
            />
          </Col>
          <Bar className={styles.graph} data={graphData} options={barGraphOptions} />
        </>
      )}
    </>
  );
}

QuickviewAnalyticsSection.propTypes = {
  organizationId: PropTypes.number,
  nudgeId: PropTypes.string,
  campaignId: PropTypes.string,
  isModal: PropTypes.bool,
  toolTipMessage: PropTypes.string,
  hasAnalytics: PropTypes.bool,
  url: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.bool,
  ]),
  exportAnalyticsLoading: PropTypes.bool,
};

QuickviewAnalyticsSection.defaultProps = {
  organizationId: null,
  nudgeId: null,
  campaignId: null,
  isModal: false,
  toolTipMessage: null,
  hasAnalytics: false,
  url: null,
  exportAnalyticsLoading: false,
};

export default QuickviewAnalyticsSection;
