import React, { useState, useEffect } from 'react';
import { useFormik } from 'formik';
import PropTypes from 'prop-types';
import { Col, Row } from 'react-bootstrap';
import 'react-phone-number-input/style.css';
import PhoneInput from 'react-phone-number-input';
import UserService from 'services/UserService';
import { REGISTER_USER_PHONE_NUMBER, VERIFY_SMS_CODE } from 'graphql/mutations/users';
import { useMutation } from '@apollo/client';
import useAuthorization from '../../../hooks/useAuthentication';
import validationSchema from './TwoFactorSmsAuth.validator';
import styles from './TwoFactorAuth.module.scss';
import CustomInput from '../../../components/CustomInput/CustomInput';
import Larky from '../../../components/Larky';
import CustomButton from "../../../components/CustomButton/CustomButton";
import {ROUTES, TOKEN_EXPIRED_LOGIN_DISPATCHER} from "../../../Constants";
import {useHistory} from "react-router-dom";

const TwoFactorAuthRegisterSms = ({ displayCallback, userInfo }) => {
  const history = useHistory();
  const [verificationSendWrongMessage, setVerificationSendWrongMessage] = useState(null);
  const [verificationSubmitWrongMessage, setVerificationSubmitWrongMessage] = useState(null);
  const [phoneConfirmation, setPhoneConfirmation] = useState(false);
  const [phoneNumber, setPhoneNumber] = useState(null);
  const [registerPhoneNumberMutation] = useMutation(REGISTER_USER_PHONE_NUMBER);
  const [verifySmsCodeMutation] = useMutation(VERIFY_SMS_CODE);

  const formik = useFormik({
    initialValues: {
      phoneNumber: '',
      phoneNumberVerificationCode: '',
    },
    validateOnChange: false,
    enableReinitialize: true,
    validationSchema,
    // eslint-disable-next-line consistent-return
  });

  const handleClose = () => {
  window.location.href = `/login?dispatcher=${TOKEN_EXPIRED_LOGIN_DISPATCHER}`;
  };

  const isValidPhoneNumber = () => {
    const regexValidatePhone = new RegExp(/\+(9[976]\d|8[987530]\d|6[987]\d|5[90]\d|42\d|3[875]\d|2[98654321]\d|9[8543210]|8[6421]|6[6543210]|5[87654321]| 4[987654310]|3[9643210]|2[70]|7|1)\W*\d\W*\d\W*\d\W*\d\W*\d\W*\d\W*\d\W*\d\W*(\d{1,2})$/);
    const isPhoneValid = regexValidatePhone.test(phoneNumber);
    const isUSPhoneNumber = phoneNumber.slice(0, 2);
    if (isUSPhoneNumber && phoneNumber.length !== 12) {
      setVerificationSendWrongMessage('Phone number is not valid length');
      return false;
    }
    if (isPhoneValid) {
      setVerificationSendWrongMessage(null);
      return true;
    }
    setVerificationSendWrongMessage('Please enter a valid phone number');
    return false;
  };

  const handleRequestCode = async () => {
    try {
      const validatePhoneNumber = isValidPhoneNumber();

      if (!validatePhoneNumber) {
        return null;
      }
      const registerPhoneNumberInput = {
        userId: userInfo.id,
        phoneNumber,
        existingConfirmation: false,
      };
      const phoneNumberSent = await UserService.registerUserPhoneNumber(
        registerPhoneNumberMutation, registerPhoneNumberInput,
      );
      if (phoneNumberSent && phoneNumberSent.ok) {
        setVerificationSendWrongMessage(null);
        setPhoneConfirmation(true);
      }
    } catch (e) {
      setVerificationSendWrongMessage('An error has occurred, please contact us by clicking the support link below');
    }
    return null;
  };

  const handleSubmitCode = async () => {
    try {
      setVerificationSendWrongMessage(null);
      const verifySmsCodeInput = {
        userId: userInfo.id,
        otp: formik.values.phoneNumberVerificationCode,
        phoneNumber,
        existingConfirmation: false,
        rememberMe: false,
      };
      const validatePhoneNumber = isValidPhoneNumber();

      if (!validatePhoneNumber) {
        return null;
      }
      const smsCodeVerification = await UserService.verifyUserSmsCode(
        verifySmsCodeMutation, verifySmsCodeInput,
      );
      if (smsCodeVerification && smsCodeVerification.ok) {
        await useAuthorization.setIsAuthorized();
        const userSet = { ...userInfo, isAuthorized: true };
        displayCallback(userSet, false);
      }
      if (smsCodeVerification && !smsCodeVerification.ok) {
        setVerificationSubmitWrongMessage('Something is not right. Please check the code or click the link below to contact support for further assistance.');
      }
    } catch (e) {
      setVerificationSubmitWrongMessage('Something is not right. Please check the code or click the link below to contact support for further assistance.');
    }
    return null;
  };

  const phoneConfirmationTopHeaderText = () => {
    if (phoneConfirmation) {
      return (
        <>
          <p className={styles['paragraph-title-text']}>Please enter the authentication code sent to your device</p>
            <br />
          <p className={styles['paragraph-title-text']}>
          If you did not receive a code please check the number entered and click
            <em> Resend Confirmation Code</em>
          </p>
        </>
      );
    }
    return (
      <p>
        Please enter a valid phone number to use for authentication
      </p>
    );
  };

  return (
    <div style={{ width: '100%' }}>
      <form>
        <h4>{phoneConfirmationTopHeaderText()}</h4>
        <PhoneInput
          placeholder="Enter phone number"
          value={phoneNumber}
          onChange={setPhoneNumber}
          defaultCountry="US"
          className={styles['phone-input-verification-text-box']}
          onKeyPress={(e) => {
            if (e.key === 'Enter' && !phoneConfirmation) {
              e.preventDefault();
              handleRequestCode();
            }
          }}
        />
        {verificationSendWrongMessage ? <div className={styles['verification-wrong-message']}>{verificationSendWrongMessage}</div> : null}
        <Row className="align-items-center justify-content-center mb-4">
          <Col className="col-auto">
            <Larky.Button
                      type="button"
                      outlined
                      onClick={() => {
                        handleClose();
                      }} > {' '} Cancel
            </Larky.Button>

            <Larky.Button
              type="button"
              onClick={handleRequestCode}
            >
              {' '}
              Send Confirmation Code
            </Larky.Button>
          </Col>
        </Row>
        {phoneConfirmation ? (
          <>
            <h4>Enter the code below to enable SMS two-factor authentication:</h4>
            <CustomInput
              type="text"
              name="phoneNumberVerificationCode"
              placeholder="Input Verification Code"
              value={formik.values.phoneNumberVerificationCode}
              onChange={formik.handleChange}
              onKeyPress={(e) => {
                if (e.key === 'Enter') {
                  e.preventDefault();
                  handleSubmitCode();
                }
              }}
            />
            <Row className="align-items-center justify-content-center mb-4">
              {verificationSubmitWrongMessage ? <div className={styles['verification-wrong-message']}>{verificationSubmitWrongMessage}</div> : null}
              <Col className="col-auto">

                <CustomButton
                      variant="outlined"
                      size="sm"
                      text="Cancel"
                      onClick={() => {
                        handleClose();
                      }}
                />

                <Larky.Button
                  type="button"
                  onClick={handleSubmitCode}
                >
                  {' '}
                  Verify Code
                </Larky.Button>
              </Col>
            </Row>
          </>
        ) : null}
        <Row className="align-items-center justify-content-center mb-4">
          Need help?
          <Col className="col-auto">
            <a data-cy="contact-support" href="mailto:support@larky.com">Contact Support</a>
          </Col>
        </Row>
      </form>
    </div>
  );
};

TwoFactorAuthRegisterSms.propTypes = {
  displayCallback: PropTypes.func,
  userInfo: PropTypes.shape({
    id: PropTypes.string.isRequired,
  }),
};

TwoFactorAuthRegisterSms.defaultProps = {
  displayCallback: () => null,
  userInfo: {},
};

export default TwoFactorAuthRegisterSms;
