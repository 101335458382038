import React from 'react';

// Utils
import {
  formatPercentage,
  formatNumber, formatNumberPercentage,
} from 'Utils';

import {
  Col, Container, Row, Spinner, Table,
} from 'react-bootstrap';

// Context
import PropTypes from 'prop-types';
import trendUpALot from 'assets/img/trend_up_a_lot.png';
import trendFlat from 'assets/img/trend_flat.png';
import trendDownALot from 'assets/img/trend_down_a_lot.png';
import trendUpALotDisabled from 'assets/img/trend_up_a_lot_disabled.png';
import trendUpSomeDisabled from 'assets/img/trend_up_some_disabled.png';
import trendDownSomeDisabled from 'assets/img/trend_down_some_disabled.png';
import styles from '../QuickviewAnalyticsSection.module.scss';
import MessagingReachLoading from './MessagingReachLoading';
import ToolTipIcon from '../../../../components/ToolTipIcon/ToolTipIcon';

/**
 * Component that displays stats about nudges or a specific nudge
 *
 * @param {String} organizationId
 * @param {String} nudgeId
 * @param {String} campaignId
 * @param {Boolean} isModal - adjusts quick analytics to be displayed in a modal by modifying
 * the styling. e.g. reducing fonts
 */
function MessagingReach({
  isLoading,
  tableData,
  tableHeaders,
  hasAnalytics,
  trendToolTipMessage,
  trendToolTipUrl,
}) {
  const determineTrendIcon = (engagementNumber, engagementPercentTrend) => {
    if (engagementNumber === -1) {
      return (
        <div className={`${styles['trend-contents']} ${styles['downward-trend-content']}`}>
          <img className={styles.logo} src={trendDownALot} alt={null} />
          {formatNumber(engagementPercentTrend)}
          %
        </div>
      );
    }
    if (engagementNumber === 0) {
      return (
        <div className={`${styles['trend-contents']} ${styles['flat-trend-content']}`}>
          <img className={styles.logo} src={trendFlat} alt={null} />
          {formatNumber(engagementPercentTrend)}
          %
        </div>
      );
    }
    if (engagementNumber === 1) {
      return (
        <div className={`${styles['trend-contents']} ${styles['upward-trend-content']}`}>
          <img className={styles.logo} src={trendUpALot} alt={null} />
          {formatNumber(engagementPercentTrend)}
          %
        </div>
      );
    }
    return null;
  };

  const renderHeaders = (headers) => headers.map((header, index) => (
    <th className={styles.colInfo} key={header.title}>
      {header.icon && <img className={styles.logo} src={header.icon} />}
      {header.title}
    </th>
  ));

  const displayTrendDisabled = (analyticsType, engagementNumber) => {
    if (analyticsType == 'nudgesReceived') {
      return (
        <>
          <div className={styles['left-half']}>
            {formatNumber(engagementNumber)}
          </div>
          <div className={styles['right-half']}>
            <div className={`${styles['trend-contents']} ${styles['disabled-trend-content']}`}>
              <img className={styles.logo} src={trendUpALotDisabled} alt={null} />
              {formatNumber(10)}
              %
            </div>
          </div>
        </>
      );
    }
    if (analyticsType == 'nudgesTapped') {
      return (
        <>
          <div className={styles['left-half']}>
            {formatNumber(engagementNumber)}
          </div>
          <div className={styles['right-half']}>
            <div className={`${styles['trend-contents']} ${styles['disabled-trend-content']}`}>
              <img className={styles.logo} src={trendUpSomeDisabled} alt={null} />
              {formatNumber(2)}
              %
            </div>
          </div>
        </>
      );
    }
    if (analyticsType == 'tapRate') {
      return (
        <>
          <div className={styles['left-half']}>
            {formatNumber(engagementNumber)}
          </div>
          <div className={styles['right-half']}>
            <div className={`${styles['trend-contents']} ${styles['disabled-trend-content']}`}>
              <img className={styles.logo} src={trendDownSomeDisabled} alt={null} />
              {formatNumber(5)}
              %
            </div>
          </div>
        </>
      );
    }
    if (analyticsType == 'engagementRate') {
      return (
        <>
          <div className={styles['left-half']}>
            {formatNumberPercentage(engagementNumber)}
          </div>
          <div className={styles['right-half']}>
            <div className={`${styles['trend-contents']} ${styles['disabled-trend-content']}`}>
              <img className={styles.logo} src={trendUpSomeDisabled} alt={null} />
              Up
              {' '}
              {formatNumber(8)}
              %
            </div>
          </div>
        </>
      );
    }
  };

  return (
    <>
      {isLoading ? (
        <MessagingReachLoading />
      ) : (
        <>
          <Table className={styles['messaging-reach-table']}>
            <thead>
              <tr>
                {renderHeaders(tableHeaders)}
              </tr>
            </thead>
            <tbody className={styles.analyticsTableRow}>
              <tr>
                <td className={`${styles.rowInfo} ${styles['row-header-messaging-reach']}`}>
                  <b>{tableData.isFinalFlag ? 'Final Week' : 'Last Week'}</b>
                </td>
                <td className={styles['numbers-messaging']}>
                  <Row className={styles['row-messaging']}>
                    <div className={styles['left-half']}>
                      {formatNumber(tableData.lastWeekMembersMessaged)}
                    </div>
                    <div className={styles['right-half']}>
                      {determineTrendIcon(tableData.lastWeekMembersMessagedTrendArrow, tableData.lastWeekMembersMessagedPercentTrend)}
                    </div>
                  </Row>
                </td>
                <td className={styles['numbers-messaging']}>
                  <Row className={styles['row-messaging']}>
                    <div className={styles['left-half']}>
                      {formatNumber(tableData.lastWeekTotalImpressions)}
                    </div>
                    <div className={styles['right-half']}>
                      {determineTrendIcon(tableData.lastWeekTotalImpressionsTrendArrow, tableData.lastWeekTotalImpressionsPercentTrend)}
                    </div>
                  </Row>
                </td>
                <td className={styles['numbers-messaging']}>
                  <Row className={styles['row-messaging']}>
                    <div className={styles['left-half']}>
                      {formatNumber(tableData.lastWeekEngagements)}
                    </div>
                    <div className={styles['right-half']}>
                      {determineTrendIcon(tableData.lastWeekEngagementsTrendArrow, tableData.lastWeekEngagementsPercentTrend)}
                    </div>
                  </Row>
                </td>
                <td className={styles['numbers-messaging']}>
                  <Row className={styles['row-messaging']}>
                    <>
                      <div className={styles['left-half']}>
                        {formatNumberPercentage(tableData.lastWeekEngagementsRateTrend)}
                      </div>
                      <div className={styles['right-half']}>
                        {determineTrendIcon(tableData.lastWeekEngagementsRateTrendArrow, tableData.lastWeekEngagementsRatePercentTrend)}
                      </div>
                    </>
                  </Row>
                </td>
              </tr>
              <tr>
                <td className={`${styles.rowInfo} ${styles['row-header-messaging-reach']}`}>
                  <b>{tableData.isFinalFlag ? 'Final Month' : 'Last Month'}</b>
                </td>
                <td className={styles['numbers-messaging']}>
                  <Row className={styles['row-messaging']}>
                    <div className={styles['left-half']}>
                      {formatNumber(tableData.lastMonthMembersMessaged)}
                    </div>
                    <div className={styles['right-half']}>
                      {determineTrendIcon(tableData.lastMonthMembersMessagedTrendArrow, tableData.lastMonthMembersMessagedPercentTrend)}
                    </div>
                  </Row>
                </td>
                <td className={styles['numbers-messaging']}>
                  <Row className={styles['row-messaging']}>
                    <div className={styles['left-half']}>
                      {formatNumber(tableData.lastMonthTotalImpressions)}
                    </div>
                    <div className={styles['right-half']}>
                      {determineTrendIcon(tableData.lastMonthTotalImpressionsTrendArrow, tableData.lastMonthTotalImpressionsPercentTrend)}
                    </div>
                  </Row>
                </td>
                <td className={styles['numbers-messaging']}>
                  <Row className={styles['row-messaging']}>
                    <div className={styles['left-half']}>
                      {formatNumber(tableData.lastMonthEngagements)}
                    </div>
                    <div className={styles['right-half']}>
                      {determineTrendIcon(tableData.lastMonthEngagementsTrendArrow, tableData.lastMonthEngagementsPercentTrend)}
                    </div>
                  </Row>
                </td>
                <td className={styles['numbers-messaging']}>
                  <Row className={styles['row-messaging']}>
                    <>
                      <div className={styles['left-half']}>
                        {formatNumberPercentage(tableData.lastMonthEngagementsRateTrend)}
                      </div>
                      <div className={styles['right-half']}>
                        {determineTrendIcon(tableData.lastMonthEngagementsRateTrendArrow, tableData.lastMonthEngagementsRatePercentTrend)}
                      </div>
                    </>
                  </Row>
                </td>
              </tr>
              <tr>
                <td className={`${styles.rowInfo} ${styles['row-header-messaging-reach']}`}>
                  <b>{tableData.isFinalFlag ? 'Final 90 days' : 'Last 90 days'}</b>
                  {!hasAnalytics
                    ? <ToolTipIcon url={trendToolTipUrl} toolTipMessage={trendToolTipMessage}/> : null}
                </td>
                <td className={styles['numbers-messaging']}>

                  <Row className={styles['row-messaging']}>
                    {!hasAnalytics ? (
                      displayTrendDisabled('nudgesReceived', tableData.last90dayMembersMessaged)
                    )
                      : (
                        <>
                          <div className={styles['left-half']}>
                            {formatNumber(tableData.last90dayMembersMessaged)}
                          </div>
                          <div className={styles['right-half']}>
                            {determineTrendIcon(tableData.last90dayMembersMessagedTrendArrow, tableData.last90dayMembersMessagedPercentTrend)}
                          </div>
                        </>
                      )}
                  </Row>
                </td>
                <td className={styles['numbers-messaging']}>
                  <Row className={styles['row-messaging']}>
                    {!hasAnalytics ? (
                      displayTrendDisabled('nudgesTapped', tableData.last90dayTotalImpressions)
                    )
                      : (
                        <>
                          <div className={styles['left-half']}>
                            {formatNumber(tableData.last90dayTotalImpressions)}
                          </div>
                          <div className={styles['right-half']}>
                            {determineTrendIcon(tableData.last90dayTotalImpressionsTrendArrow, tableData.last90dayTotalImpressionsPercentTrend)}
                          </div>
                        </>
                      )}
                  </Row>
                </td>
                <td className={styles['numbers-messaging']}>
                  <Row className={styles['row-messaging']}>
                    {!hasAnalytics ? (
                      displayTrendDisabled('tapRate', tableData.last90dayEngagements)
                    )
                      : (
                        <>
                          <div className={styles['left-half']}>
                            {formatNumber(tableData.last90dayEngagements)}
                          </div>
                          <div className={styles['right-half']}>
                            {determineTrendIcon(tableData.last90dayEngagementsTrendArrow, tableData.last90dayEngagementsPercentTrend)}
                          </div>
                        </>
                      )}
                  </Row>
                </td>
                <td className={styles['numbers-messaging']}>
                  <Row className={styles['row-messaging']}>
                    {!hasAnalytics ? (
                      displayTrendDisabled('engagementRate', tableData.last90dayEngagementsPercentTrend)
                    )
                      : (
                        <>
                          <div className={styles['left-half']}>
                            {formatNumberPercentage(tableData.last90dayEngagementsRateTrend)}
                          </div>
                          <div className={styles['right-half']}>
                            {determineTrendIcon(tableData.last90dayEngagementsRateTrendArrow, tableData.last90dayEngagementsRatePercentTrend)}
                          </div>
                        </>
                      )}
                  </Row>
                </td>
              </tr>
            </tbody>
          </Table>
        </>
      )}
    </>
  );
}

MessagingReach.propTypes = {
  organizationId: PropTypes.number,
  nudgeId: PropTypes.string,
  campaignId: PropTypes.string,
  isModal: PropTypes.bool,
  toolTipMessage: PropTypes.string,
  hasAnalytics: PropTypes.bool,
  url: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.bool,
  ]),
  trendToolTipMessage: PropTypes.string,
  trendToolTipUrl: PropTypes.string,
};

MessagingReach.defaultProps = {
  organizationId: null,
  nudgeId: null,
  campaignId: null,
  isModal: false,
  trendToolTipMessage: null,
  hasAnalytics: false,
  trendToolTipUrl: null,
  tableHeaders: [],
};

export default MessagingReach;
