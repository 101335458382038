// Dependencies
import { Machine, assign, interpret } from 'xstate';
import { Userpilot } from 'userpilot';

import { getUser } from 'Utils';
import Analytics from 'libs/Analytics';

import { useNotification } from '../components/Notification/Notification';
import { organizationIdJoin } from '../Utils';

// Services
import UserService from '../services/UserService';

const notification = useNotification();

const loginWithCredentials = async (
  mutation: object,
  email: string,
  password: string,
) => {
  try {
    const loggedInUser = await UserService.login(mutation, { email, password });
    Userpilot.identify(
      loggedInUser.id,
      {
        name: `${loggedInUser.firstName} ${loggedInUser.lastName}`,
        email: loggedInUser.email,
        created_at: '',
        company:
                    {
                      id: loggedInUser.organizations[0].id || null, // Company Unique ID
                      created_at: 'company.created_at', // ISO8601 Date
                    },
      },
    );
    return loggedInUser;
  } catch (error) {
    Analytics.track(Analytics.SIGNED_IN, {});
    // @ts-ignore
    notification.alert(error.message, 'danger');
    throw error;
  }
};

const loginWithTokens = async () => {
  const user = getUser();

  if (!user) {
    throw new Error('NOT_SAVED_USER');
  }

  return user;
};

export const defaultUser = {
  id: undefined,
  email: '',
  firstName: '',
  lastName: '',
  currentOrganization: {
    name: '',
  },
  profilePicture: '',
  environment: '',
};

// eslint-disable-next-line import/prefer-default-export
export const authMachine = Machine(
  {
    id: 'auth',
    initial: 'initial',
    context: {
      user: defaultUser,
      error: null,
    },
    states: {
      initial: {
        id: 'initial',
        invoke: {
          src: (_, evt) => loginWithTokens(),
          onDone: {
            target: 'loggedIn',
            actions: 'onDone',
          },
          onError: {
            target: 'loggedOut',
            actions: 'onError',
          },
        },
      },
      loggedOut: {
        id: 'loggedOut',
        on: { LOGIN: 'authenticating' },
      },
      authenticating: {
        id: 'authenticating',
        invoke: {
          src: (_, evt: any) => loginWithCredentials(
            evt.mutation,
            evt.credentials.email,
            evt.credentials.password,
          ),
          onDone: {
            target: 'selectAuth',
            actions: 'onDone',
          },
          onError: {
            target: 'loggedOut',
            actions: 'onError',
          },
        },
      },
      selectAuth: {
        id: 'selectAuth',
        invoke: {
          src: (_, evt: any) => () => new Promise((res, rej) => {
            const userInfo = getUser();

            const { mfaResult } = userInfo;

            if (!mfaResult) {
              return res(userInfo);
            }

            notification.confirm2faDialog(
              userInfo,
              (err: Error, resultUser: any) => {
                if (err) rej(err);
                if (resultUser) res(resultUser);
              },
            );
          }),
          onDone: {
            target: 'selectOrganizations',
            actions: 'onDone',
          },
          onError: {
            target: 'loggedOut',
            actions: 'onError',
          },
        },
      },
      selectOrganizations: {
        id: 'selectOrganizations',
        invoke: {
          // eslint-disable-next-line no-async-promise-executor
          src: (_, evt: any) => () => new Promise(async (res, rej) => {
            const { organizations } = getUser();

            if (organizations.length === 1) {
              const user = await UserService.setCurrentOrganization(
                organizations[0],
              );
              return res(user);
            }

            notification.selectOrganizationDialog(
              organizations,
              (err: Error, org: any) => {
                if (err) rej(err);
                const user = UserService.setCurrentOrganization(org);
                res(user);
              },
            );
          }),
          onDone: {
            target: 'loggedIn',
            actions: 'onDone',
          },
          onError: {
            target: 'loggedOut',
            actions: 'onError',
          },
        },
      },
      loggedIn: {
        id: 'auth',
        on: {
          LOGOUT: {
            target: 'loggedOut',
            actions: 'logOut',
          },
          SET_USER: {
            target: 'loggedIn',
            actions: assign({
              user: (ctx: any, evt: any) => ({
                ...ctx.user,
                ...evt.user,
              }),
            }),
          },
        },
      },
    },
  },
  {
    actions: {
      onDone: assign<any>({
        error: null,
        user: (_: any, { data }: any) => data,
      }),

      onError: assign<any>({
        user: defaultUser,
        error: (_: any, { data }: any) => data,
      }),

      logOut: assign<any>({ user: defaultUser, error: null }),
    },
  },
);

interpret(authMachine);
