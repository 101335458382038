import React from 'react';
import PropTypes from 'prop-types';

import styles from './RowBadge.module.scss';

const RowBadge = ({ color, children }) => (
  <div className={styles.root}>
    <div className={`${styles.badge} ${styles[color]}`} />
    {children}
  </div>
);

RowBadge.propTypes = {
  color: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
};

export default RowBadge;
