import React from "react"
import ContentLoader from "react-content-loader"
import styles from "../../../Campaigns/components/CampaignGallery/CampaignGallery.module.scss";

const InsightsLoadingCard = (props) => (
    <article className={styles['card-insight']}
             onClick={() => null}
    >
        <ContentLoader
            speed={2}
            width={400}
            height={160}
            viewBox="0 0 400 160"
            backgroundColor="#949494"
            foregroundColor="#c2c2c2"
            {...props}
        >
            <rect x="48" y="8" rx="3" ry="3" width="88" height="6"/>
            <rect x="48" y="26" rx="3" ry="3" width="52" height="6"/>
            <rect x="0" y="56" rx="3" ry="3" width="140" height="6"/>
            <rect x="0" y="72" rx="3" ry="3" width="220" height="6"/>
            <rect x="0" y="88" rx="3" ry="3" width="178" height="6"/>
            <circle cx="20" cy="20" r="20"/>
        </ContentLoader>
    </article>
)

export default InsightsLoadingCard