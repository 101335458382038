/**
 * MyAccount
 *
 * Component that contains user account information
 *
 */
// Dependencies
import React, { useEffect, useState } from 'react';

import { useHistory, useParams } from 'react-router-dom';
import * as Yup from 'yup';
import { useFormik } from 'formik';
import { useMutation, useQuery } from '@apollo/client';
import {useNotification} from "../../components/Notification/Notification";
import {useAuthMachineValue} from "../../contexts/auth-machine.context";
import UserService from "../../services/UserService";
import {UPDATE_USER_MUTATION} from "../../graphql/mutations/users";
import {GET_USER} from "../../graphql/queries/users";
import {Card, Col, Container, Form, Row, Spinner} from "react-bootstrap";
import styles from './MyAccount.module.scss';
import CustomCard from "../../components/CustomCard/CustomCard";
import NoPhotoIcon from 'assets/img/no-photo-icon.svg';
import CustomField from "../../components/CustomField/CustomField";
import CustomInput from "../../components/CustomInput/CustomInput";
import {REQUIRED_FIELD, ROUTES} from "../../Constants";
import Larky from 'components/Larky';
import {getUser, parseRole} from "../../Utils";
import ChangeOrganizationModal from "../../components/Modal/ManageUser/ChangeOrganizationModal/ChangeOrganizationModal";
import ChangePasswordModal from "../../components/Modal/ManageUser/ChangePasswordModal/ChangePasswordModal";
import ProfilePictureModal from "./components/ProfilePictureModal/ProfilePictureModal";

const validationSchema = () => Yup.object({
  firstName: Yup.string().trim().required(REQUIRED_FIELD),
  lastName: Yup.string().trim().required(REQUIRED_FIELD),
  // role: Yup.string()
  //   .when('isAdmin', (val, schema) => (val ? schema : schema.trim().required(REQUIRED_FIELD))),
});

function MyAccount({}) {
  const history = useHistory();
  const notification = useNotification();
  const [uploadProfilePicture, setUploadProfilePicture] = useState(false);
  const [showPasswordModal, setShowPasswordModal] = useState(false);
  const [showOrgModal, setShowOrgModal] = useState(false);
  const [showProfileModal, setShowProfileModal] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const [current, send] = useAuthMachineValue();

  const [user, setUser] = useState({
    id: null,
    firstName: '',
    lastName: '',
    email: '',
    profilePicture: '',
    currentOrganization: {
      name: '',
    },
    organizations: [],
    role: '',
  });

  const [isAdmin, setIsAdmin] = useState(UserService.isAdmin());

  const [updateUserMutation] = useMutation(UPDATE_USER_MUTATION);

  const params = useParams();
  const id = params.userId || current.context.user.id;

  const getUserQuery = useQuery(GET_USER, { variables: { id }, skip: true });

  useEffect(() => {
    const userInfo = getUser()
    setUser(userInfo)
  }, []);

  const setProfilePicture = (profilePicture) => {
    if (current.context.user.id === id) {
      UserService.updateSessionStorageUser('profilePicture', profilePicture);
      send('SET_USER', { user: { profilePicture } });
    }
    Object.assign(user, {
      profilePicture,
    });
  };

  const {
    firstName,
    lastName,
    email,
    profilePicture,
    currentOrganization,
  } = user;

  const parsedRole = parseRole(user.role);

  const formik = useFormik({
    initialValues: {
      firstName,
      lastName,
      role: { value: parsedRole.key, label: parsedRole.value },
      isAdmin,
    },
    validateOnChange: false,
    enableReinitialize: true,
    validationSchema,
    onSubmit: async (values) => {
      try {
        const input = {
          firstName: values.firstName,
          lastName: values.lastName,
          role: parsedRole.key,
        };
        if (isAdmin) {
          Object.assign(input, { role: values.role.value });
        }
        const newUser = await UserService.update(updateUserMutation, id, input);
        send('SET_USER', { user: newUser });
        notification.alert('User updated successfully', 'success');
      } catch ({ message }) {
        notification.alert(message, 'danger');
      }
    },
  });

  const showProfilePopup = () => {
    setShowProfileModal(true);
    setShowOrgModal(false);
    setShowPasswordModal(false);
  };
  const showOrgPopup = () => {
    setShowOrgModal(true);
    setShowProfileModal(false);
    setShowPasswordModal(false);
  };
  const showPasswordPopup = () => {
    setShowPasswordModal(true);
    setShowOrgModal(false);
    setShowProfileModal(false);
  };
  const hidePasswordModalCallback = () => {
    setShowPasswordModal(false);
  }
  const hideOrgModalCallback = () => {
    setShowOrgModal(false);
  };
  const hideProfileModalCallback = () => {
    setShowProfileModal(false);
  };

  return (
    <CustomCard
        title="My Account"
        size="lg"
        isDarkBackground={true}
        isForm
      >
        <Container>
          {isLoading ? (
              <Spinner
                style={{width: '4rem', height: '4rem', margin: '12px auto'}}
                animation="border"
                role="status"
              />
            ) : (
              <>
                <Row style={{marginBottom: '5%'}}>
                <Col>
                  <Card style={{background: '#f8f8f8'}}>
                    <Card.Body>
                      {/*<Card.Title>Card title</Card.Title>*/}
                      {/*<Card.Text>*/}
                      {/*  This is a longer card with supporting text below as a natural*/}
                      {/*  lead-in to additional content. This content is a little bit*/}
                      {/*  longer.*/}
                      {/*</Card.Text>*/}
                      <Col
                        className={`${styles['profile-picture-container']} d-flex flex-column align-items-center justify-content-center`}
                        xs="12"
                        md="auto"
                      >
                        <img
                          className={styles['profile-pic']}
                          src={profilePicture || NoPhotoIcon}
                          alt="My account"
                        />
                        <Larky.Button
                          onClick={() => showProfilePopup()}
                        >
                          Manage profile picture
                        </Larky.Button>
                      </Col>
                    </Card.Body>
                  </Card>
                </Col>
                <Col>
                  <Card style={{background: '#f8f8f8'}}>
                    <Card.Body>
                      {/*<Card.Title>Card title II</Card.Title>*/}
                      {/*<Card.Text>*/}
                      {/*  This is a longer card with supporting text below as a natural*/}
                      {/*  lead-in to additional content. This content is a little bit*/}
                      {/*  longer.*/}
                      {/*</Card.Text>*/}
                      <Form onSubmit={(e) => formik.handleSubmit(e)}
                            style={isAdmin ? '' : {paddingTop: '15%'}}>
                        <CustomField label="First name">
                          <Larky.Form.Input
                            type="text"
                            size="sm"
                            name="firstName"
                            placeholder="My first name"
                            value={formik.values.firstName}
                            onChange={formik.handleChange}
                            error={formik.errors.firstName?.toString()}
                          />
                        </CustomField>
                        <CustomField label="Last name">
                          <Larky.Form.Input
                            type="text"
                            size="sm"
                            name="lastName"
                            placeholder="My last name"
                            value={formik.values.lastName}
                            onChange={formik.handleChange}
                            error={formik.errors.lastName?.toString()}
                          />
                        </CustomField>
                        {isAdmin && (
                          <CustomField label="Role">
                            <CustomInput
                              className={styles['role-input']}
                              type="select"
                              size="sm"
                              options={rolesOptions}
                              value={formik.values.role}
                              onChange={(value) => formik.setFieldValue('role', value)}
                              error={formik.errors.role?.toString()}
                            />
                          </CustomField>
                        )}
                        {isAdmin && (
                          <Col
                            className={`${styles['profile-picture-container']} d-flex flex-column align-items-center justify-content-center`}
                            xs="12" md="auto">
                            <Larky.Button
                              onClick={() => history.push(ROUTES.USERS.MAIN)}
                            >
                              Manage users
                            </Larky.Button>
                          </Col>
                        )}
                        <Col
                          className={`${styles['profile-picture-container']} d-flex flex-column align-items-center justify-content-center`}
                          xs="12" md="auto" style={isAdmin ? '' : {paddingTop: '10%'}}>
                          <Larky.Button
                            type="submit"
                            disabled={formik.isSubmitting}
                          >
                            Save Changes
                          </Larky.Button>
                        </Col>
                      </Form>
                    </Card.Body>
                  </Card>
                </Col>
              </Row>
                <Row>
                  <Col>
                    <Card style={{background: '#f8f8f8'}}>
                      <Card.Body>
                        <CustomField label="Email">
                          <div className={styles.company}>
                            <span>{email}</span>
                          </div>
                        </CustomField>
                        <Col
                          className={`${styles['profile-picture-container']} d-flex flex-column align-items-center justify-content-center`}
                          xs="12"
                          md="auto"
                        >
                          <Larky.Button
                            // onClick={() => history.push('/update-password')}
                            onClick={showPasswordPopup}
                          >
                            Change Password
                          </Larky.Button>
                        </Col>
                      </Card.Body>
                    </Card>
                  </Col>
                  <Col>
                    <Card style={{background: '#f8f8f8'}}>
                      <Card.Body>
                        <CustomField label="Company">
                          <div className={styles.company}>
                            <span>{currentOrganization.name}</span>
                          </div>
                        </CustomField>
                        {params.userId === undefined && (
                          <Col
                            className={`${styles['profile-picture-container']} d-flex flex-column align-items-center justify-content-center`}
                            xs="12"
                            md="auto"
                          >
                            <Larky.Button
                              onClick={showOrgPopup}
                            >
                              Change organization
                            </Larky.Button>
                          </Col>
                        )}
                      </Card.Body>
                    </Card>
                  </Col>
                </Row>
                <Row style={{marginTop: '5%'}}
                     className={`${styles['profile-picture-container']} d-flex flex-column align-items-center justify-content-center`}
                     xs="12"
                     md="auto"
                >
                  <Larky.Button
                    outlined
                    onClick={() => history.push(ROUTES.DASHBOARD.BASE)}
                  >
                    Close
                  </Larky.Button>
                </Row>
              </>
          )}
        </Container>
        <ProfilePictureModal
          show={showProfileModal}
          user={user}
          callback={hideProfileModalCallback}
        />
        <ChangeOrganizationModal
          show={showOrgModal}
          user={user}
          callback={hideOrgModalCallback}
        />
        <ChangePasswordModal
          show={showPasswordModal}
          callback={hidePasswordModalCallback}
          id = {id}
        />
      </CustomCard>
  )

}

export default MyAccount;
