import React from 'react';
import PropTypes from 'prop-types';

import {
  Col,
  Button,
} from 'react-bootstrap';

import styles from './Pagination.module.scss';

const Pagination = ({
  page,
  totalPages,
  startItem,
  endItem,
  totalResults,
  onChange,
}) => (
  <div className={styles.root}>

    <div className={styles['buttons-wrapper']}>
      <span className={styles['actual-page']}>{`${startItem.toLocaleString()}-${endItem.toLocaleString()} of ${totalResults.toLocaleString()}`}</span>
      <Button variant="light" size="sm" onClick={() => onChange(-1)} disabled={page === 1}>&lt;</Button>
      <span className={styles.page}>{page}</span>
      <Button variant="light" size="sm" onClick={() => onChange(1)} disabled={page === totalPages}>&gt;</Button>
      <span>{`of ${totalPages.toLocaleString()}`}</span>
    </div>
  </div>
);

Pagination.propTypes = {
  page: PropTypes.number.isRequired,
  totalPages: PropTypes.number.isRequired,
  startItem: PropTypes.number.isRequired,
  endItem: PropTypes.number.isRequired,
  totalResults: PropTypes.number.isRequired,
  onChange: PropTypes.func.isRequired,
};

export default Pagination;
