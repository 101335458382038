import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { getUser } from '../../../Utils';
import ChooseAuthType from './ChooseAuthType';
import TwoFactorAuthRegisterSms from './TwoFactorAuthRegisterSms';
import TwoFactorAuthRegisterAuthenticator from './TwoFactorAuthRegisterAuthenticator';
import TwoFactorAuthConfirmationSms from './TwoFactorAuthConfirmationSms';
import TwoFactorAuthConfirmationAuthenticator from "./TwoFactorAuthConfirmationAuthenticator";
import useAuthorization from "../../../hooks/useAuthentication";

const TwoFactorAuth = ({ callback }) => {
  const userInfo = getUser();
  const [verificationType, setVerificationType] = useState(null);
  const [phoneNumber, setPhoneNumber] = useState(null);

  useEffect(() => {
    if (userInfo.mfaMethod) {
      setVerificationType(userInfo.mfaMethod);
    }
  }, []);

  useEffect(async () => {
    if (userInfo.rsaPhone) {
      setPhoneNumber(userInfo.rsaPhone);
    }
    if (userInfo.rememberMe) {
      await useAuthorization.setIsAuthorized();
        const userSet = { ...userInfo, isAuthorized: true };
        callback(userSet, false);
    }
  }, []);

  if (userInfo.mfaResult && verificationType === 'authenticator') {
  if (!userInfo.isMfaAuthenticationValid) {
    return (
      <TwoFactorAuthRegisterAuthenticator
        displayCallback={callback}
        verificationType={verificationType}
        userInfo={userInfo}
      />
    );
  } if (userInfo.isMfaAuthenticationValid && !userInfo.rememberMe) {
    return (
      <TwoFactorAuthConfirmationAuthenticator
        displayCallback={callback}
        verificationType={verificationType}
        userInfo={userInfo}
      />
    );
  }
}

  if (userInfo.mfaResult && verificationType === 'sms') {
    return phoneNumber && userInfo.isMfaAuthenticationValid && !userInfo.rememberMe ? (
      <TwoFactorAuthConfirmationSms
        displayCallback={callback}
        verificationType={verificationType}
        userInfo={userInfo}
      />
    ) : (
      <TwoFactorAuthRegisterSms
        displayCallback={callback}
        verificationType={verificationType}
        userInfo={userInfo}
      />
    );
  }

  return <ChooseAuthType callback={setVerificationType} />;
};

TwoFactorAuth.propTypes = {
  callback: PropTypes.func,
};

TwoFactorAuth.defaultProps = {
  callback: () => null,
};

export default TwoFactorAuth;
