import style from "../Notification/Notification.module.scss";
import React, {useState} from "react";
import {getOrgFromDropdownOptions} from "../../Utils";
import {Col, Row} from "react-bootstrap";
import CustomInput from "../CustomInput/CustomInput";
import Larky from 'components/Larky';

function OrganizationSelector({organizations, callback, currentOrganization}) {
  const getDropdownOptions = () => {
    return organizations.map((org) => (
      {label: org.name, value: org}))
  }
  const [orgOptions, setOrgOptions] = useState(getDropdownOptions(organizations));
  const getInitialOption = () => {
    let result = getOrgFromDropdownOptions(currentOrganization, orgOptions);
    return result ? result : orgOptions[0];
  }
  const [selectedOrg, setSelectedOrg] = useState(getInitialOption);

  const submitSelectedOrg = () => {
    callback(selectedOrg.value);
  }

  if (organizations && organizations.length > 5) {
    return (
      <div style={{width: '100%'}}>
        <CustomInput
          type="select"
          name="selectOrganization"
          placeholder="Select..."
          onChange={(org) => {
            setSelectedOrg(org)
          }}
          value={selectedOrg}
          options={orgOptions}
        />
        <Row className="align-items-center justify-content-center mb-4">
          <Col className="col-auto">
            <Larky.Button
              className={style['org-option']}
              type="button"
              onClick={() => callback(selectedOrg.value)}
            >Continue
            </Larky.Button>
          </Col>
        </Row>
      </div>
    );
  } else {
    return organizations.map((org) => (
      <Larky.Button
        className={style['org-option']}
        type="button"
        key={org.id}
        onClick={() => callback(org)}
      >
        {org.name}
      </Larky.Button>
    ));
  }
}

export default OrganizationSelector;
