// Dependencies
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import {
  Redirect, Route, Switch, useHistory,
} from 'react-router-dom';

import { useMutation, useQuery } from '@apollo/client';

// Libs
import Analytics from 'libs/Analytics';

// Components
import CustomTable from 'components/CustomTable/CustomTable';
import PageHeader from 'components/PageHeader/PageHeader';
import { useNotification } from 'components/Notification/Notification';

// Services
import LocationService from 'services/LocationService';

// GraphQL
import { DELETE_LOCATION_MUTATION, UPDATE_LOCATION_MUTATION } from 'graphql/mutations/locations';

import { SEARCH_LOCATIONS } from 'graphql/queries/locations';

// Utils
import {
  archiveManyRows, archiveRow, deleteManyRows, deleteRow, getUserFeature,
} from 'Utils';

// Constants
import { ROUTES } from 'Constants';
import LocationRow from './components/LocationRow/LocationRow';

// Styles
import styles from './Locations.module.scss';
import { useInitialFocus } from '../../hooks/useInitialFocus';

function Locations({ organizationId }) {
  const notification = useNotification();
  const history = useHistory();
  useInitialFocus();

  const [updateLocationMutation] = useMutation(UPDATE_LOCATION_MUTATION);
  const [deleteLocationMutation] = useMutation(DELETE_LOCATION_MUTATION);
  const searchLocationsQuery = useQuery(SEARCH_LOCATIONS, { skip: true });

  const [userHasLocationEditPermission, setUserHasLocationEditPermission] = useState(null);
  const [displayBulkAction, setDisplayBulkAction] = useState(false);

  useEffect(async () => {
    const hasLocationEditPermission = await getUserFeature('geolocations', 2);
    setUserHasLocationEditPermission(hasLocationEditPermission);
  }, []);

  const getData = async (orderBy, sortOrder, start, params) => {
    Object.assign(params, { organizationId });
    return LocationService.all(searchLocationsQuery, orderBy, sortOrder, start, params);
  };

  const routes = [
    {
      path: ROUTES.LOCATIONS.MAIN,
      title: 'My locations',
      subtitle: 'View all of your locations.',
    },
    {
      path: ROUTES.LOCATIONS.ARCHIVED,
      title: 'Archived locations',
      subtitle: 'View all of your archived locations.',
    },
  ];

  const selectActions = [
    {
      key: 'delete_selected',
      text: 'Delete selected',
      action: (data, updateCallback) => {
        deleteManyRows(LocationService,
          notification,
          deleteLocationMutation,
          data,
          updateCallback,
          'Locations');
      },
    },
  ];

  if (history.location.pathname === ROUTES.LOCATIONS.MAIN) {
    selectActions.push({
      key: 'archive_selected',
      text: 'Archive selected',
      action: (data, updateCallback) => {
        archiveManyRows(LocationService,
          notification,
          updateLocationMutation,
          data,
          updateCallback,
          'Locations');
      },
    });
  }

  if (history.location.pathname === ROUTES.LOCATIONS.ARCHIVED) {
    selectActions.push({
      key: 'unarchive_selected',
      text: 'Unarchive selected',
      action: (data, updateCallback) => {
        archiveManyRows(LocationService,
          notification,
          updateLocationMutation,
          data,
          updateCallback,
          'Locations',
          false);
      },
    });
  }
  
  const determineLocationColumns = () => {
    const locationColumns = ['Name', 'Description', 'Created', 'Details']
    if (userHasLocationEditPermission && displayBulkAction) {
      locationColumns.splice(0, 0, 'Select')
    }
    return locationColumns
  }

  const determineLocationFilters = () => {
    const locationFilters = ['sortFilter', 'timeFilter', 'searchQuery']
    if (userHasLocationEditPermission) {
      locationFilters.splice(0, 0, 'selectFilter')
    }
    return locationFilters
  }

  return (
    <Switch>
      {routes.map(({ path, title, subtitle }) => (
        <Route key={path} path={path}>
          <div className={styles.root}>
            <PageHeader
              title={title}
              subtitle={subtitle}
              hasEditPermission={userHasLocationEditPermission}
            />
            <CustomTable
              getData={getData}
              customRow={(data, handleSelect, updateCallback) => (
                <LocationRow
                  key={data.id}
                  data={data}
                  handleSelect={handleSelect}
                  handleArchiveRow={() => archiveRow(
                    LocationService,
                    notification,
                    updateLocationMutation,
                    data,
                    updateCallback,
                    'Location',
                  )}
                  handleDeleteRow={() => deleteRow(
                    LocationService,
                    notification,
                    deleteLocationMutation,
                    data.id,
                    () => {
                      Analytics.trackDeleteLocation(data);
                      updateCallback();
                    },
                    'Location',
                  )}
                  isSelectable={displayBulkAction}
                  hasEditPermission={userHasLocationEditPermission}
                />
              )}
              displayBulkActionCallback={setDisplayBulkAction}
              displayBulkAction={displayBulkAction}
              availableTypes={['archived']}
              columns={determineLocationColumns()}
              searchKeys={['q', 'time', 'sort']}
              enabledFilters={determineLocationFilters()}
              filterAttrs={['created', 'name']}
              selectActions={selectActions}
              isFilterable
              isPaginable
            />
          </div>
        </Route>
      ))}
      <Route render={() => <Redirect to="/" />} />
    </Switch>
  );
}

Locations.propTypes = {
  organizationId: PropTypes.number,
};

Locations.defaultProps = {
  organizationId: null,
};

export default Locations;
