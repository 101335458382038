import React, { useState } from 'react';
import { useFormik } from 'formik';
import { Col, Row } from 'react-bootstrap';
import { useMutation } from '@apollo/client';
import PropTypes from 'prop-types';
import styles from './TwoFactorAuth.module.scss';
import CustomInput from '../../../components/CustomInput/CustomInput';
import Larky from '../../../components/Larky';
import {VERIFY_AUTHENTICATOR_CODE} from 'graphql/mutations/users';
import UserService from 'services/UserService';
import useAuthorization from "../../../hooks/useAuthentication";
import CustomCheckbox from "../../../components/CustomCheckbox/CustomCheckbox";
import {TOKEN_EXPIRED_LOGIN_DISPATCHER} from "../../../Constants";

const TwoFactorAuthConfirmationAuthenticator = ({ displayCallback, userInfo }) => {
  const [verificationWrongMessage, setVerificationWrongMessage] = useState(null);
  const [verifyAuthenticatorCode] = useMutation(VERIFY_AUTHENTICATOR_CODE);
  const [verificationSubmitWrongMessage, setVerificationSubmitWrongMessage] = useState(null);

  const handleRememberMe = async () => {
    await formik.setFieldValue('rememberMe', !formik.values.rememberMe);
  };

  const handleClose = () => {
      window.location.href = `/login?dispatcher=${TOKEN_EXPIRED_LOGIN_DISPATCHER}`;
  };

  const formik = useFormik({
    initialValues: {
      authenticatorVerificationCode: '',
      rememberMe: false
    },
    validateOnChange: false,
    enableReinitialize: true,
    onSubmit: async (values) => {
      try {
        const AuthenticatorCodeInput = {
          userId: userInfo.id,
          authenticatorCode: formik.values.authenticatorVerificationCode,
          rememberMe: formik.values.rememberMe,
        };
        const authenticatorCodeSent = await UserService.verifyUserAuthenticatorCode(
          verifyAuthenticatorCode,AuthenticatorCodeInput
        );


         if (authenticatorCodeSent && authenticatorCodeSent.ok) {
          await useAuthorization.setIsAuthorized();
          const userSet = { ...userInfo, isAuthorized: true };
          displayCallback(userSet, false);
        }
        if (authenticatorCodeSent && !authenticatorCodeSent.ok) {
          setVerificationSubmitWrongMessage('Something is not right. Please check the code or click the link below to contact support for further assistance.');
        }
      } catch (e) {
        setVerificationSubmitWrongMessage('Something is not right. Please check the code or click the link below to contact support for further assistance.');
      }
    },
  });

  const handleSubmitForm = async () => {
    await formik.submitForm();
  };

  return (
    <div style={{ width: '100%' }}>
      <form>
        {/*<h4>Enable Authenticator App</h4>*/}
        {/*<br />*/}
        <h4 style={{ textAlign: 'left', fontSize: '1.25rem', marginTop: '-22px', marginBottom: '15px' }}>
          Please check the authenticator app on your device for a six-digit code and enter it here:
        </h4>
        <CustomInput
          type="text"
          name="authenticatorVerificationCode"
          placeholder="Input Verification Code"
          value={formik.values.authenticatorVerificationCode}
          onChange={formik.handleChange}
          error={formik.errors.authenticatorVerificationCode}
          onKeyPress={(e) => {
                if (e.key === 'Enter') {
                  e.preventDefault();
                  handleSubmitForm();
                }
              }}
        />
        {verificationSubmitWrongMessage ? <div className={styles['verification-wrong-message']}>{verificationSubmitWrongMessage}</div> : null}
        <Row className="align-items-center justify-content-center mb-4">
          {verificationWrongMessage ? <div className={styles['verification-wrong-message']}>{verificationWrongMessage}</div> : null}

          <Col className="col-auto">
                <CustomCheckbox
                  name="rememberMe"
                  value={formik.values.rememberMe}
                  onChange={() => handleRememberMe()}
                  title="Don’t require authentication codes for the rest of the day"
                />
              </Col>


          <Col className="col-auto">

            <Larky.Button
                      type="button"
                      outlined
                      onClick={() => {
                        handleClose();
                      }} > {' '} Cancel
               </Larky.Button>

            <Larky.Button
                  type="button"
                  onClick={handleSubmitForm}
                  isLoading={formik.isSubmitting}
                >
                  {' '}
                  Verify 2FA Code
            </Larky.Button>
          </Col>
        </Row>
        <Row className="align-items-center justify-content-center mb-4">
          Need help?
          <Col className="col-auto">
            <a data-cy="contact-support" href="mailto:support@larky.com">Contact Support</a>
          </Col>
        </Row>
      </form>
    </div>
  );
};

TwoFactorAuthConfirmationAuthenticator.propTypes = {
  displayCallback: PropTypes.func,
  userInfo: PropTypes.shape({
    id: PropTypes.string.isRequired,
  }),
};

TwoFactorAuthConfirmationAuthenticator.defaultProps = {
  displayCallback: () => null,
  userInfo: {},
};

export default TwoFactorAuthConfirmationAuthenticator;
