import React, { useState } from 'react';
import Larky from "../../../components/Larky";
import HubSpotModal from "./HubSpotModal";
import styles from "../../Nudges/components/AnalyticsModal/AnalyticsModal.module.scss";

function DataSourceRow({ data,readOnly }) {
    // Define the modal state and its handlers inside the component
    const [modalIsOpen, setModalIsOpen] = useState(false);
    const openModal = () => setModalIsOpen(true);
    const closeModal = () => setModalIsOpen(false);
    const isDisabled = () => readOnly;

    return (
        <tr>
            <td>{`${data.sourceName}`}</td>
            {/*<td>*/}
            {/*    {(data.allowed) === true ? (*/}
            {/*        <span style={{color:'green'}}>Enabled!</span>*/}
            {/*    ) : (*/}
            {/*        'Not Enabled'*/}
            {/*    )}*/}
            {/*</td>*/}
            <td>
                {(data.isConnected) === true ? (
                    <span style={{color:'green'}}>Connected!</span>
                ) : (
                    'Not Connected'
                )}
            </td>
            <td>
                {(data.oauthUrl && data.isConnected === true && data.allowed === true) && (
                    <Larky.Button
                        className={isDisabled() ? styles['disabled-button'] : null}
                        onClick={!isDisabled() ? openModal: null}>Refresh Connection</Larky.Button>
                )}

                {(data.oauthUrl && data.isConnected === false && data.allowed === true) && (
                    <Larky.Button
                        className={isDisabled() ? styles['disabled-button'] : styles.section}
                        onClick={!isDisabled() ? openModal: null}>Connect</Larky.Button>
                )}

                {(data.oauthUrl && data.allowed === false) && (
                    <Larky.Button className={styles['disabled-button']}>Connect</Larky.Button>
                )}

                <HubSpotModal isOpen={modalIsOpen} onRequestClose={closeModal} hubspoturl={data.oauthUrl} />
            </td>
        </tr>
    );
}

export default DataSourceRow;
