import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { Redirect } from 'react-router-dom';
import UserService from '../services/UserService';

function PrivateRoute({ isLoggedIn, isAuth, children }) {
  useEffect(() => {
    if (!isAuth && isLoggedIn) {
      UserService.logout(false, false);
    }
  }, [isLoggedIn, isAuth]);
  if (!isLoggedIn) {
    return <Redirect to="/login" />;
  }
  return children;
}

PrivateRoute.propTypes = {
  isLoggedIn: PropTypes.bool.isRequired,
  children: PropTypes.node.isRequired,
};

PrivateRoute.defaultProps = {
  isLoggedIn: false,
  isAuth: false
};

export default PrivateRoute;
