import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { Col, Row } from 'react-bootstrap';
import UserService from 'services/UserService';
import { REGISTER_USER_MFA_METHOD } from 'graphql/mutations/users';
import { useMutation } from '@apollo/client';
import { getUser } from '../../../Utils';
import Larky from '../../../components/Larky';
import CustomButton from "../../../components/CustomButton/CustomButton";
import {ROUTES} from "../../../Constants";

const ChooseAuthType = ({ callback }) => {
  const userInfo = getUser();
  const history = useHistory();
  const [registerUserMfaMethod] = useMutation(REGISTER_USER_MFA_METHOD);
  const [confirmationEnabled, setConfirmationEnabled] = useState(true);
  const [show, setShow] = useState(false);

  const handleClose = () => {
    setShow(false);
    callback();
  };

  const handleSubmitForm = async (typeOfTwoFactor) => {
    try {
      const registerVerificationInput = {
        userId: userInfo.id,
        mfaMethod: typeOfTwoFactor,
      };

      const verificationMethodSent = await UserService.registerUserMfaMethod(registerUserMfaMethod, registerVerificationInput);
      if (verificationMethodSent && verificationMethodSent.ok) {
        callback(typeOfTwoFactor);
      }
    } catch (e) {
      console.log(e);
    }
  };

  return (
    <div style={{ width: '100%' }}>
      <form>
        <h4 style={{textAlign: 'center', fontSize: '1.13rem', marginTop: '-22px', marginBottom: '15px'}}>
          For your security, we use two-factor authentication when logging into the nudge® portal.
          We’ll guide you through the short enrollment process. Simply select the method you would like below to get started!
        </h4>

        <h4>Option 1: Use an authenticator app installed on your mobile device</h4>
        <Row className="align-items-center justify-content-center mb-4">
          <Col className="col-auto">
            <Larky.Button
              type="button"
              onClick={() => handleSubmitForm('authenticator')}
            >
              Use an Authenticator App
            </Larky.Button>
          </Col>
        </Row>

        <h4>Option 2: Use SMS text messages for two-factor authentication</h4>
        <Row className="align-items-center justify-content-center mb-4">
          <Col className="col-auto">
            <Larky.Button
              type="button"
              onClick={() => handleSubmitForm('sms')}
            >Use SMS text messages
            </Larky.Button>
          </Col>
        </Row>

        <Row className="align-items-center justify-content-center mb-4">
          Need help?
          <Col className="col-auto">
            <a data-cy="contact-support" href="mailto:support@larky.com">Contact Support</a>
          </Col>
        </Row>
      </form>
    </div>
  );
};

export default ChooseAuthType;
