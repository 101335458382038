// const FILE_NAME = 'Analytics.jsx';
// Note: if you change serverId here, you must also change it in login.html

// Constants
import { VALIDATION } from 'Constants';

const serverId = 'dashboard';

const Analytics = {
  ...VALIDATION.ANALYTICS,
  track(eventName, extraProperties) {
    const user = JSON.parse(sessionStorage.getItem('USER'));
    const environment = sessionStorage.getItem('USER_ENVIRONMENT');
    Object.assign(extraProperties, user, {
      server: serverId,
      environment,
      timestamp: new Date(),
    });
    window.analytics.track(eventName, extraProperties);
  },
  trackApplicationError(error, fileName, functionName) {
    console.log(`Error in function '${functionName}' in file ${fileName}: ${error}`);
    this.track(this.APPLICATION_ERROR, {
      file: fileName,
      function: functionName,
      message: error,
    });
  },
  trackDeleteLocation(location) {
    this.track(
      this.DELETED_LOCATION,
      this.locationToAnalyticsProperties(location, location),
    );
  },
  trackPageView(pageName) {
    this.track(this.VIEWED_PAGE, {
      page_name: pageName,
      page_url: window.location.href,
    });
  },
  locationToAnalyticsProperties(location, locationPost) {
    return {
      location_name: location.name,
      location_latitude: location.latitude,
      location_longitude: location.longitude,
      location_address: location.address,
      location_state: location.state,
      location_city: location.city,
      location_country: location.country,
      location_zip: location.zip,
      location_id: locationPost.id,
    };
  },
};

export default Analytics;
