import React, {
  useState,
  useEffect,
  useCallback,
  useRef,
} from 'react';
import moment from 'moment';
import { useNotification } from '../Notification/Notification';
import { SESSION_EXPIRED_LOGIN_DISPATCHER } from '../../Constants';
import { getUser } from '../../Utils';

const SessionTimeout = () => {
  const notification = useNotification();
  const [events, setEvents] = useState(['click', 'load', 'scroll']);
  // const [second, setSecond] = useState(0);
  const [maxTime, setMaxTime] = useState(60);
  const [maxTimeRemainder, setMaxTimeRemainder] = useState(0);
  const [notifyTime, setNotifyTime] = useState(55);
  const [notified, setNotified] = useState(false);
  const loggedInUser = getUser();

  let timeStamp;
  const warningInactiveInterval = useRef();
  const startTimerInterval = useRef();

  const checkSessionTimeoutFlag = () => {
    if (loggedInUser && loggedInUser.featureFlags) {
      const { featureFlags } = loggedInUser;
      const sessionTimeoutMs = featureFlags.session_timeout;
      if (sessionTimeoutMs) {
        const sessionTimeout = sessionTimeoutMs / 60000;
        const mt = sessionTimeout <= 1 ? 2 : sessionTimeout;
        setMaxTime(mt);
        setNotifyTime(1);
        setMaxTimeRemainder(Math.trunc(mt / 60));
      } else {
        setMaxTime(60);
        setNotifyTime(55);
        setMaxTimeRemainder(1);
      }
    }
  };

  // warning timer
  const warningInactive = (timeString) => {
    clearTimeout(startTimerInterval.current);

    warningInactiveInterval.current = setInterval(() => {
      const diff = moment.duration(moment().diff(moment(timeString)));
      const minPast = diff.minutes();
      const hourPast = diff.hours();
      // if maxTime is greater than 59 we need to take into account that minPast will
      // never equal 60, but instead it will be 0
      const checkHourRemainder = maxTimeRemainder > 0
        ? (hourPast === maxTimeRemainder && minPast === (maxTime % 60)) : minPast === maxTime;

      if (minPast === notifyTime) {
        if (!notified) {
          const resTime = maxTime - notifyTime;
          const delay = 60 * Number(resTime);
          const min = resTime === 1 ? 'minute' : 'minutes';
          const message = `Due to inactivity, this session will expire in ${resTime} ${min}`;
          notification.alert(message, 'warning', delay);
          setNotified(true);
        }
      }

      if (checkHourRemainder) {
        clearInterval(warningInactiveInterval.current);
        sessionStorage.removeItem('lastTimeStamp');
        sessionStorage.clear();
        window.location.href = `/login?dispatcher=${SESSION_EXPIRED_LOGIN_DISPATCHER}`;
      }
    }, 1000);
  };

  // start inactive check
  const timeChecker = () => {
    startTimerInterval.current = setTimeout(() => {
      const storedTimeStamp = sessionStorage.getItem('lastTimeStamp');
      warningInactive(storedTimeStamp);
    }, 60000);
  };

  // reset interval timer
  const resetTimer = useCallback(() => {
    clearTimeout(startTimerInterval.current);
    clearInterval(warningInactiveInterval.current);

    if (loggedInUser) {
      timeStamp = moment();
      sessionStorage.setItem('lastTimeStamp', timeStamp);
      notification.hideAlert();
    } else {
      clearInterval(warningInactiveInterval.current);
      sessionStorage.removeItem('lastTimeStamp');
      setNotified(false);
    }
    timeChecker();
  }, [loggedInUser]);

  useEffect(() => {
    events.forEach((event) => {
      window.addEventListener(event, resetTimer);
    });
    checkSessionTimeoutFlag();

    if (loggedInUser) timeChecker();

    return () => {
      clearTimeout(startTimerInterval.current);
      clearInterval(warningInactiveInterval.current);
      //   resetTimer();
    };
  }, [resetTimer, events, timeChecker]);

  // change fragment to modal and handleclose func to close
  return <></>;
};

export default SessionTimeout;
