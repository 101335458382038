import React from 'react';
import { Spinner } from 'react-bootstrap';

import styles from './InitialLoader.module.scss';

const InitialLoader = () => (
  <div
    id="container"
    className={`centered ${styles.root}`}
  >

    <Spinner
      className={styles.spinner}
      animation="border"
      role="status"
    >
      <span className="sr-only">Loading...</span>
    </Spinner>

  </div>
);


export default InitialLoader;
