import * as Yup from 'yup';

import {
  REQUIRED_FIELD,
  rfc1738UrlRegex,
} from 'Constants';

const validationSchema = () => Yup.object({
  environment: Yup.object().required(REQUIRED_FIELD),
  name: Yup.string().trim().required(REQUIRED_FIELD),
  description: Yup.string().nullable(),
  title: Yup.string()
    .trim()
    .required(REQUIRED_FIELD),
  body: Yup.string()
    .trim()
    .required(REQUIRED_FIELD),
  url: Yup.string().trim().nullable()
    .when('urlLinkType.value', (urlLinkTypeValue, schema) => {
      const processedValue = Array.isArray(urlLinkTypeValue) ? urlLinkTypeValue[0] : urlLinkTypeValue;
      if (processedValue === 'Weblink') {
        return schema.test('urlTestType', 'Please enter a valid URL', function (value) {
          const { url } = this.parent || {};
          const regex = new RegExp(rfc1738UrlRegex);
          return url && url.slice(0, 4) !== 'tel:' ? regex.test(url) : true;
        });
      } else {
        return schema.nullable();
      }
    }),
  daysDelay: Yup.object().nullable().required(REQUIRED_FIELD),
  scheduleTimeHour: Yup.object()
    .nullable()
    .default(null),
  scheduleTimeMinute: Yup.object()
    .nullable()
    .default(null),
  scheduleTimeType: Yup.object()
    .nullable()
    .default(null),
  selectedWeekDays: Yup.array().min(1,REQUIRED_FIELD).of(Yup.object().required(REQUIRED_FIELD)),
});

export default validationSchema;
