import React from 'react';
import PropTypes from 'prop-types';

import {
  ApolloClient,
  ApolloLink,
  ApolloProvider, createHttpLink,
  InMemoryCache,
} from '@apollo/client';

import { createUploadLink } from 'apollo-upload-client';

import { onError } from '@apollo/client/link/error';
import { setContext } from '@apollo/client/link/context';
import { getUser } from 'Utils';

import { SERVICE_URLS, TOKEN_EXPIRED_LOGIN_DISPATCHER } from 'Constants';
import UserService from 'services/UserService';

function ApolloClientProvider({ children }) {
  const API_URL = `${SERVICE_URLS[process.env.NODE_ENV].PROXY}/graphql`;

  const errorLink = onError(({ graphQLErrors, networkError }) => {
    if (graphQLErrors) {
      graphQLErrors.forEach(({
        context,
        message,
        locations,
        path,
      }) => {
        if (context && context.status === 401) {
          sessionStorage.clear();
          window.location.href = `/login?dispatcher=${TOKEN_EXPIRED_LOGIN_DISPATCHER}`;
        }
        console.error(`GraphQL error: Message: ${message}, Location: ${locations}, Path: ${path}`);
      });
    }
    if (networkError) console.error(`Network error: ${networkError}`);
  });

  const { core, push } = UserService.getTokens();
  const user = getUser();

  const authLink = setContext((_, { headers }) => {
    const newHeaders = {
      ...headers,
      'Access-Token-Core': core,
      'Access-Token-Push': push,
    };
    if (user && user.currentOrganization) {
      Object.assign(newHeaders, {
        'User-Organization': user.currentOrganization.id,
      });
    }
    return { headers: newHeaders };
  });

  const uploadLink = createUploadLink({ uri: API_URL });

  const client = new ApolloClient({
    link: ApolloLink.from([errorLink, authLink, uploadLink]),
    cache: new InMemoryCache(),
  });

  return (
    <ApolloProvider client={client}>
      {children}
    </ApolloProvider>
  );
}

ApolloClientProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

export default ApolloClientProvider;
