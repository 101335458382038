// Dependencies
import React, { useEffect, useState, useRef } from 'react';
import PropTypes from 'prop-types';
import { Link, useHistory } from 'react-router-dom';
import { Navbar, Nav, NavDropdown } from 'react-bootstrap';
import { useQuery } from '@apollo/client';

// Constants
import { ROUTES } from 'Constants';
import NoPhotoIcon from 'assets/img/no-photo-icon.svg';

// Services
import UserService from 'services/UserService';

// Context
import { GET_ALL_FEATURE_FLAGS_WITH_USER_ID } from 'graphql/queries/organizations';
import { useAuthMachineValue } from '../../contexts/auth-machine.context.ts';

// Styles
import Logo from './components/Logo/Logo';
import styles from './Navbar.module.scss';
import {getUserFeature} from "../../Utils";

function CustomNavbar({ menuItem,
                        userHasNudgesRead,
                        userHasCampaignsRead,
                        userHasSegmentsRead,
                        userHasLocationsRead,
                        userHasNudgesWrite,
                        userHasCampaignsWrite,
                        userHasSegmentsWrite,
                        userHasLocationsWrite,
                        userHasOrgSpecificAdminFeatures,
                        userHasPartnerManagementFeatures,
                      }) {
  const history = useHistory();
  const {
    NUDGES, LOCATIONS, USERS, SEGMENTS, CAMPAIGNS,
  } = ROUTES;

  const [current, send] = useAuthMachineValue();
  const [dropDownItems, setDropDownItems] = useState([]);
  const { user } = current.context;
  const [userLocal, setUserLocal] = useState(user);
  const [currentOrg, setCurrentOrg] = useState(user.currentOrganization);
  const getAllFeatureFlagsQuery = useQuery(GET_ALL_FEATURE_FLAGS_WITH_USER_ID, { skip: true });
  const mainRef = useRef(null);
  const [isAdmin, setIsAdmin] = useState(false);

  useEffect( () => {
    if (!userLocal || !userLocal.featureFlags) {
      UserService.updateFeatureFlagsWithUserId(getAllFeatureFlagsQuery, send, setUserLocal, user.id)
        .catch((err) => console.log({ err }));
    }
  }, [getAllFeatureFlagsQuery,
            userLocal,
            userHasNudgesRead,
            userHasCampaignsRead,
            userHasSegmentsRead,
            userHasLocationsRead,
            userHasNudgesWrite,
            userHasCampaignsWrite,
            userHasSegmentsWrite,
            userHasLocationsWrite,
            userHasOrgSpecificAdminFeatures,
            userHasPartnerManagementFeatures
  ]);

  useEffect(async() => {
    const { adminUser } = user;
      await setIsAdmin(adminUser);
  }, [])

  useEffect(() => {
    UserService.updateFeatureFlagsWithUserId(getAllFeatureFlagsQuery, send, setUserLocal, user.id)
      .catch((err) => console.log({ err }));
  }, [currentOrg]);

  const buildDropdownMenu = () => {
    const items = [];
    items.push({
      title: 'Campaigns',
      path: CAMPAIGNS.BASE,
      children: [
        { to: CAMPAIGNS.GALLERY, text: 'Campaign gallery' },
        ...(userHasCampaignsWrite ? [{ to: CAMPAIGNS.CREATE, text: 'Create blank campaign' }] : []),
        { to: CAMPAIGNS.MAIN, text: 'My campaigns' },
        { to: CAMPAIGNS.ARCHIVED, text: 'Archived campaigns' },
      ],
    });
    userHasNudgesRead && items.push({
      title: 'Nudges',
      path: NUDGES.BASE,
      children: [
        ...(userHasNudgesWrite ? [{ to: NUDGES.CREATE, text: 'Create a nudge' }] : []),,
        { to: NUDGES.MAIN, text: 'My nudges' },
        { to: NUDGES.DRAFTS, text: 'Nudge drafts' },
        { to: NUDGES.ARCHIVED, text: 'Archived nudges' },
      ],
    });
    userHasSegmentsRead && items.push({
      title: 'Segments',
      path: SEGMENTS.BASE,
      children: [
        ...(userHasSegmentsWrite ? [{ to: SEGMENTS.CREATE, text: 'Create a segment' }] : []),
        { to: SEGMENTS.MAIN, text: 'My segments' },
      ],
    });
    userHasLocationsRead && items.push({
      title: 'Locations',
      path: LOCATIONS.BASE,
      children: [
        ...(userHasLocationsWrite ? [{ to: LOCATIONS.CREATE, text: 'Create a location' }] : []),,
        { to: LOCATIONS.MAIN, text: 'My locations' },
        { to: LOCATIONS.ARCHIVED, text: 'Archived locations' },
      ],
    });
    items.push({
      title: 'My Account',
      path: 'account',
      children: [
        { to: '/account', text: 'Manage account' },
        ...(userHasOrgSpecificAdminFeatures ? [{ to: '/message-capping', text: 'Organization Settings' }] : []),
        ...(userHasPartnerManagementFeatures ? [{ to: ROUTES.USERS.PARTNER_MANAGEMENT, text: 'Partner Management' }] : []),
        {
          onClick: async () => {
            if (await UserService.logout(true, true)) send('LOGOUT', {});
          },
          text: 'Logout',
        },
      ],
    });
    setDropDownItems(items);
  };
  useEffect(() => {
    buildDropdownMenu();
  }, [user, userHasSegmentsRead, userHasLocationsRead, userHasCampaignsRead,
    userHasNudgesRead, userHasSegmentsWrite, userHasLocationsWrite, userHasCampaignsWrite, userHasNudgesWrite
  ]);

  return (
    <Navbar className={styles.root} variant="dark" expand="lg" ref={mainRef} tabIndex="-1">
      <Navbar.Brand className={styles['nav-brand-header']}>
        <Link to="/">
          <Logo />
        </Link>
      </Navbar.Brand>

      <Navbar.Toggle aria-controls="basic-navbar-nav" />

      <Navbar.Collapse className={styles['navbar-collapse']}>
        <Nav className={`ml-auto ${styles['navbar-nav']}`}>
          <Link
            className={`nav-link nav-toplink ${menuItem === 'home' ? 'active' : ''
            }`}
            to="/"
          >
            Dashboard
          </Link>
          {dropDownItems.map(({ title, path, children }) => (
            <NavDropdown
              key={path}
              className={`nav-toplink  ${menuItem === path ? 'active' : ''}`}
              title={title}
              drop="down"
            >
              {children.map(({ to, onClick, text }) => (
                <NavDropdown.Item
                  key={text}
                  onClick={() => (to ? history.push(to) : onClick())}
                >
                  {text}
                </NavDropdown.Item>
              ))}
            </NavDropdown>
          ))}
          <Link className={styles['navbar-avatar']} to="/account">
            <img
              className={styles['avatar-img']}
              src={user.profilePicture || NoPhotoIcon}
              alt="My account"
            />
          </Link>
        </Nav>
      </Navbar.Collapse>
    </Navbar>
  );
}

CustomNavbar.propTypes = {
  menuItem: PropTypes.string.isRequired,
};

export default CustomNavbar;
