import React from 'react';
import PropTypes from 'prop-types';
import { Redirect } from 'react-router-dom';
import UserService from 'services/UserService';

function RoleGuard({ admin, children }) {
  if (admin && !UserService.isAdmin()) {
    return <Redirect to="/" />;
  }
  return children;
}

RoleGuard.propTypes = {
  admin: PropTypes.bool,
  children: PropTypes.node.isRequired,
};

RoleGuard.defaultProps = {
  admin: false,
};

export default RoleGuard;
