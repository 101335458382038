// constants
import { limitList } from 'Constants';

/**
 * gets  a list of options (label, value) for excluded and included members
 * takes list of segments, iterates over all items to determine the group it belongs to
  */
export const getExcludedAndIncludeMembersOptions = (userSegments) => {
  const includedMembers = [];
  const excludedMembers = [];
  if (userSegments.length) {
    userSegments.forEach((segment) => {
      const operation = segment.operation.toLowerCase();
      if (operation === 'include') {
        includedMembers.push({ label: segment.segmentName, value: segment.memberSegmentId });
      } else if (operation === 'exclude') {
        excludedMembers.push({ label: segment.segmentName, value: segment.memberSegmentId });
      }
    });
  }
  return { includedMembers, excludedMembers };
};

/**
 * for the given membership value it looks in the limitList for an object matching the
 * value attribute and returns it. if nothing is found it returns an empty string
*/
export const getSegmentMembershipLimitOption = (membershipLimitValue) => {
  const result = limitList.filter((option) => option.value === membershipLimitValue);
  return result ? result[0] : '';
};
