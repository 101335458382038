import React, {useEffect, useState} from 'react';
import PropTypes from 'prop-types';

import * as Yup from 'yup';
import moment from 'moment';

import {useFormik} from 'formik';
import {useMutation, useQuery} from '@apollo/client';
import {useHistory, useLocation, useParams} from 'react-router-dom';
import {
    Form, Row, Col, Container, Card, DropdownButton, ButtonGroup, Dropdown,
} from 'react-bootstrap';
import {INPUT_DATE_FORMAT, ROUTES} from '../../../../Constants';

// Services
import {useAuthMachineValue} from 'contexts/auth-machine.context.ts';
import OrganizationService from 'services/OrganizationService';

// GraphQL
import {
    GET_ORGANIZATION,
    GET_ORGANIZATION_CATEGORIES
} from '../../../../graphql/queries/organizations';
import Larky from 'components/Larky';
import CustomCard from 'components/CustomCard/CustomCard';
import {useNotification} from 'components/Notification/Notification';

// Styles and Components
import styles from './ManageOrganization.module.scss';
import customTableStyle from '../../../../components/CustomTable/CustomTable.module.scss'
import CustomInput from "../../../../components/CustomInput/CustomInput";
import CustomRadioButtonSwitch from "../../../../components/CustomRadioButtonSwitch/CustomRadioButtonSwitch";
import {CREATE_ORGANIZATION_MUTATION, UPDATE_ORGANIZATION_MUTATION} from "../../../../graphql/mutations/organizations";
import CampaignService from "../../../../services/CampaignService";
import {getUserFeature, determineSelectedButton} from "../../../../Utils";

const ManageOrganization = () => {
    const history = useHistory();
    const [currentAuth] = useAuthMachineValue();
    const {user} = currentAuth.context;
    const partnerOrgId = user && user.currentOrganization ? user.currentOrganization.id : null;
    const {organizationId} = useParams();
    const isManagePage = organizationId ? true : false;

    const [organizationCategoryOptions, setOrganizationCategoryOptions] = useState([]);
    const [userHasPartnerManagementWrite, setUserHasPartnerManagementWrite] = useState(false);
    const [selectedOrganizationType, setSelectedOrganizationType] = useState(null)
    const [p8KeyProvisioned, setp8KeyProvisioned] = useState(null)

    const [nudgeGeoRadioButtons, setNudgeGeoRadioButtons] = useState([
        {
            value: 1,
            label: `Nudge Basic`,
            description: 'Targeted data-driven messaging campaigns supported with basic analytics',
            selected: true
        },
        {
            value: 2,
            label: `Nudge Engage`,
            description: 'Premium offering enabling dynamic campaigns driven by integrated segmentation capabilities and advanced analytics',
            selected: false
        },
    ]);
    const [featureTierRadioButtons, setFeatureTierRadioButtons] = useState([
        {value: 0, label: `Disabled`, selected: true},
        {value: 1, label: 'Enabled', selected: false},
    ]);


    const [deepLinkingRadioButtons, setdeepLinkingRadioButtons] = useState([
        {value: false, label: `Disabled`, selected: true},
        {value:true, label: 'Enabled', selected: false},
    ]);

    const [apnsModeRadioButtons, setApnsModeRadioButtons] = useState([
        {value: false, label: 'Production', selected: true},
        {value: true, label: 'Sandbox', selected: false},
    ]);

    const [initialValues, setInitialValues] = useState({});

    const getOrganizationMessageCategories = useQuery(GET_ORGANIZATION_CATEGORIES, {skip: true})
    const getOrganizationInformation = useQuery(GET_ORGANIZATION, {skip: true})

    const [createOrganizationMutation] = useMutation(CREATE_ORGANIZATION_MUTATION);
    const [updateOrganizationMutation] = useMutation(UPDATE_ORGANIZATION_MUTATION);

    const isDisabled = () => {
        return userHasPartnerManagementWrite ? false : true
    }

    const email = 'support@larky.com';
    const description = `Enhance campaigns with optional location-aware messaging to create greater engagement. Requires app-level integration and additional store submission procedures. Contact <a href="mailto:${email}">support@larky.com</a> for additional details.`;

    const deep_link_description = `Select <i>Enabled</i> if the technical implementation for this client enables deep-linking back into the mobile banking application`;

    const selectedOrgValue = (optionValue) => {
        const orgOption = organizationCategoryOptions.find((option) => {
            return parseInt(optionValue) === option.value
        });
        return orgOption
    }

    const handleSelectOrgCategory = async (optionValue) => {
        const orgCategory = await selectedOrgValue(optionValue)
        await formik.setFieldValue('organizationType', orgCategory && orgCategory.value)
        setSelectedOrganizationType(orgCategory)
    }


    useEffect(async () => {
        const userHasPartnerManagementWrite = await getUserFeature('partner_self_serve', 2)
        await setUserHasPartnerManagementWrite(userHasPartnerManagementWrite)
    }, [user.featureFlags]);

    useEffect(async () => {
        const orgCategoryResponse = await OrganizationService.getOrganizationCategories(getOrganizationMessageCategories);
        await setOrganizationCategoryOptions(orgCategoryResponse)
    }, [])

    useEffect(async () => {
        if (!isManagePage) {
            await handleSelectOrgCategory(6)
        }
    }, [organizationCategoryOptions])

    useEffect(async () => {
        if (isManagePage) {
            const organizationManagementResponse = await OrganizationService.getOrganization(getOrganizationInformation, organizationId);
            const selectedOrgCategory = await selectedOrgValue(organizationManagementResponse.organizationTypeId)
            await setSelectedOrganizationType(selectedOrgCategory)
            await determineSelectedButton(nudgeGeoRadioButtons, setNudgeGeoRadioButtons, organizationManagementResponse.featureTier)
            await determineSelectedButton(featureTierRadioButtons, setFeatureTierRadioButtons, organizationManagementResponse.nudgeGeo)
            await determineSelectedButton(apnsModeRadioButtons, setApnsModeRadioButtons, organizationManagementResponse.useSandbox)
            await determineSelectedButton(deepLinkingRadioButtons, setdeepLinkingRadioButtons, organizationManagementResponse.isDeepLink)
            await setp8KeyProvisioned(organizationManagementResponse.authKey)

            const newInitialValues = {
                organizationName: organizationManagementResponse.name || null,
                organizationId,
                organizationType: selectedOrgCategory && selectedOrgCategory.value,
                organizationFeatureTier: findSelectedObject(nudgeGeoRadioButtons).value,
                organizationNudgeTier: findSelectedObject(featureTierRadioButtons).value,
                teamId: organizationManagementResponse.teamId || null,
                p8KeyId: organizationManagementResponse.keyId || null,
                p8KeyFile: organizationManagementResponse.authKey || null,
                keyName: organizationId.toString(),
                apiKey: organizationManagementResponse.apiKey,
                useSandbox: findSelectedObject(apnsModeRadioButtons).value,
                isDeepLink: findSelectedObject(deepLinkingRadioButtons).value,
                launchDate: organizationManagementResponse.launchDate == null ? null : moment(organizationManagementResponse.launchDate).format(INPUT_DATE_FORMAT),
                partnerOrgId,
            }
            setInitialValues(newInitialValues)
        }
    }, [organizationCategoryOptions])

    const findSelectedObject = (inputArray) => inputArray.find(item => item && item.selected === true);

    const notification = useNotification();

    const formik = useFormik({
        initialValues: {
            organizationName: null,
            organizationId,
            organizationType: 6,
            organizationFeatureTier: 1,
            organizationNudgeTier: 0,
            isDeepLink : false,
            teamId: null,
            p8KeyId: null,
            p8KeyFile: null,
            keyName: 'org name',
            apiKey: null,
            useSandbox: false,
            launchDate: null,
            partnerOrgId,
            ...initialValues,
        },
        validateOnChange: false,
        enableReinitialize: true,
        onSubmit: async () => {
            try {
                const payload = formik.values;
                const formattedLaunchDate = await moment(payload.launchDate, INPUT_DATE_FORMAT).toDate();
                payload.launchDate = formattedLaunchDate;
                if (isManagePage) {
                    const updateOrganizationCall = await OrganizationService.updateOrganization(updateOrganizationMutation, payload);
                    if (updateOrganizationCall && updateOrganizationCall.ok) {
                        history.push(ROUTES.USERS.PARTNER_MANAGEMENT)
                    }
                }
                if (!isManagePage) {
                    const createOrganizationCall = await OrganizationService.createOrganization(createOrganizationMutation, payload)
                    if (createOrganizationCall && createOrganizationCall.ok) {
                        history.push(ROUTES.USERS.PARTNER_MANAGEMENT)
                    }
                }
            } catch (err) {
                notification.alert(err.message, 'danger');
            }
        },
    });

    const handleRadioButtonChange = (value, optionsToChange, setOptionsToChange, formikValueToChange) => {
        formik.setFieldValue(formikValueToChange, value);
        const updatedRadioOptions = optionsToChange.map((option) => {
            if (option.value === value) {
                option.selected = true;
            }
            if (option.value !== value) {
                option.selected = false;
            }
            return option;
        });
        setOptionsToChange(updatedRadioOptions)
    };

    const p8KeyExtraction = (event) => {
        {
            const file = event.target.files[0];
            const reader = new FileReader();

            reader.onload = (e) => {
                const contents = e.target.result; // The contents of the file
                // Extract the desired text from the contents and save it in the form
                formik.setFieldValue('p8KeyFile', contents)
            };
            reader.readAsText(file);
        }
    }

    return (
        <CustomCard
            title="Organization Settings"
            isForm
            isDarkBackground
            size="xxl"
            isLarge
        >
            <Container className={styles['xxl-container']}>
                <Form onSubmit={formik.handleSubmit}>
                    <Row className={styles['org-settings-row']}>
                        <Col>
                            <Card className={styles['org-settings-card']}>
                                <Card.Body>
                                    <CustomInput
                                        type="text"
                                        name="organizationName"
                                        title="Name"
                                        placeholder="Organization Name"
                                        value={formik.values.organizationName}
                                        onChange={formik.handleChange}
                                        error={formik.errors.organizationName}
                                        disabled={isDisabled()}
                                        fieldToEdit="organizationName"
                                        inModal={true}
                                    />
                                    <h5>Organization Settings</h5>
                                    <Row className={styles['row-title-manage-org']}>
                                        <Col className={customTableStyle['category-filter']}>
                                            <Form.Label className={customTableStyle.label}>Organization
                                                Type</Form.Label>
                                            <DropdownButton
                                                className={customTableStyle.dropdown}
                                                as={ButtonGroup}
                                                title={selectedOrganizationType && selectedOrganizationType.label}
                                                onSelect={(value) => handleSelectOrgCategory(value)}
                                            >
                                                {organizationCategoryOptions && (organizationCategoryOptions.map(({
                                                                                                                      value,
                                                                                                                      label
                                                                                                                  }) => (
                                                    <Dropdown.Item
                                                        key={value}
                                                        eventKey={value}
                                                    >
                                                        {label}
                                                    </Dropdown.Item>
                                                )))}
                                            </DropdownButton>
                                        </Col>
                                        <Col className={customTableStyle['category-filter']}>
                                            <Form.Label className={customTableStyle.label}>Launch Date</Form.Label>
                                            <CustomInput
                                                type="datetime"
                                                name="launchDate"
                                                title=""
                                                placeholder="Select..."
                                                dateFormat={INPUT_DATE_FORMAT}
                                                onChange={(date) => {
                                                    typeof (date) === 'string' ? formik.setFieldValue('launchDate', null)
                                                        : formik.setFieldValue('launchDate', date);
                                                }}
                                                value={moment(formik.values.launchDate)}
                                                disabled={isDisabled() || formik.values.scheduleNow}
                                                error={formik.errors.launchDate}
                                            />
                                        </Col>
                                    </Row>
                                    <CustomRadioButtonSwitch
                                        title="Feature Tier"
                                        onChange={(value, optionsToChange, setOptionsToChange, formikValueToChange) => handleRadioButtonChange(value, nudgeGeoRadioButtons, setNudgeGeoRadioButtons, 'organizationFeatureTier')}
                                        options={nudgeGeoRadioButtons}
                                        disabled={isDisabled()}
                                        optionsToChange={nudgeGeoRadioButtons}
                                        handleChangeOptions={setNudgeGeoRadioButtons}
                                        formikValueToChange={'organizationFeatureTier'}
                                    />
                                    <CustomRadioButtonSwitch
                                        title="nudge Geo Messaging Add-on"
                                        onChange={(value, optionsToChange, setOptionsToChange, formikValueToChange) => handleRadioButtonChange(value, featureTierRadioButtons, setFeatureTierRadioButtons, 'organizationNudgeTier')}
                                        options={featureTierRadioButtons}
                                        disabled={isDisabled()}
                                        optionsToChange={featureTierRadioButtons}
                                        handleChangeOptions={setFeatureTierRadioButtons}
                                        description={description}
                                        formikValueToChange={'organizationNudgeTier'}
                                        radioButtonOrientation={'horizontal'}
                                    />

                                    <CustomRadioButtonSwitch
                                        title="Deep Linking"
                                        onChange={(value, optionsToChange, setOptionsToChange, formikValueToChange) => handleRadioButtonChange(value, deepLinkingRadioButtons, setdeepLinkingRadioButtons, 'isDeepLink')}
                                        options={deepLinkingRadioButtons}
                                        disabled={isDisabled()}
                                        optionsToChange={deepLinkingRadioButtons}
                                        handleChangeOptions={setdeepLinkingRadioButtons}
                                        description={deep_link_description}
                                        formikValueToChange={'isDeepLink'}
                                        radioButtonOrientation={'horizontal'}
                                    />


                                </Card.Body>
                            </Card>
                        </Col>

                        {isManagePage && (
                            <Col>
                                <Card className={styles['org-settings-card-full']}>
                                    <Card.Body>
                                        <Row style={{display: 'flex', margin: 0}}>
                                            <Col>
                                                <Row>
                                                    <Col>
                                                        <h5>Larky API Key</h5>
                                                        {formik.values && formik.values.apiKey && (
                                                            <div>{formik.values.apiKey}</div>
                                                        )}
                                                    </Col>
                                                    <Col>
                                                        <h5>Organization Id</h5>
                                                        {formik.values && formik.values.organizationId && (
                                                            <div>{formik.values.organizationId}</div>
                                                        )}
                                                    </Col>
                                                </Row>
                                                <h5>APNS Configuration</h5>
                                                <div>
                                                    For details on how to determine this information please see <a
                                                    href="google.com">this guide</a> in our Help Center.
                                                </div>
                                                <CustomRadioButtonSwitch
                                                    title="APNS Mode"
                                                    onChange={(value, optionsToChange, setOptionsToChange, formikValueToChange) => handleRadioButtonChange(value, apnsModeRadioButtons, setApnsModeRadioButtons, 'useSandbox')}
                                                    options={apnsModeRadioButtons}
                                                    disabled={isDisabled()}
                                                    optionsToChange={apnsModeRadioButtons}
                                                    handleChangeOptions={setApnsModeRadioButtons}
                                                    formikValueToChange={'useSandbox'}
                                                    radioButtonOrientation={'horizontal'}
                                                />
                                            </Col>
                                        </Row>
                                        <Row style={{display: 'flex'}}>
                                            <Col style={{flex: 1}}>
                                                <CustomInput
                                                    type="text"
                                                    name="teamId"
                                                    title="Team Id"
                                                    placeholder="Team Id"
                                                    value={formik.values.teamId}
                                                    onChange={formik.handleChange}
                                                    error={formik.errors.teamId}
                                                    disabled={isDisabled()}
                                                    fieldToEdit="teamId"
                                                    inModal={true}
                                                />
                                            </Col>
                                            <Col style={{flex: 1}}/>
                                        </Row>
                                        <Row style={{display: 'flex'}}>
                                            <Col style={{flex: 1}}>
                                                <CustomInput
                                                    type="text"
                                                    name="p8KeyId"
                                                    title=".p8 Key Id"
                                                    placeholder="p8KeyId"
                                                    value={formik.values.p8KeyId}
                                                    onChange={formik.handleChange}
                                                    error={formik.errors.p8KeyId}
                                                    disabled={isDisabled()}
                                                    fieldToEdit="p8KeyId"
                                                    inModal={true}
                                                />
                                            </Col>
                                            <Col style={{flex: 1}}/>
                                        </Row>
                                        <Row style={{display: 'flex'}}>
                                            <Col style={{flex: 1}}>
                                                <h5>p8 Key:</h5>
                                                <div>{p8KeyProvisioned ? ("Provisioned") : ('Not provisioned')}</div>
                                                <input
                                                    onChange={(event) => p8KeyExtraction(event)}
                                                    type="file"/>
                                            </Col>
                                        </Row>
                                    </Card.Body>
                                </Card>
                            </Col>
                        )}
                    </Row>
                    <Larky.Button
                        outlined
                        onClick={() => history.goBack()}
                    >
                        Close
                    </Larky.Button>
                    {userHasPartnerManagementWrite && (<Larky.Button
                        dataCy="submit"
                        type="submit"
                        onSubmit={formik.onSubmit}
                        disabled={formik.isSubmitting}
                        size="sm"
                    >
                        Save
                    </Larky.Button>)}
                </Form>
            </Container>
        </CustomCard>
    );
}

ManageOrganization.propTypes = {
    organizationId: PropTypes.number,
    inviteSignup: PropTypes.bool,
};

ManageOrganization.defaultProps = {
    organizationId: null,
    inviteSignup: false,
};

export default ManageOrganization;
