// Dependencies
import React from 'react';
import PropTypes from 'prop-types';

// Components
import CustomCard from 'components/CustomCard/CustomCard';
import UpdatePasswordForm from "./UpdatePasswordForm";

function UpdatePassword({ newPassword }) {

  return (
    <CustomCard title="Choose your new password" isForm size="xxl">
      <UpdatePasswordForm newPassword={newPassword}/>
    </CustomCard>
  );
}

UpdatePassword.propTypes = {
  newPassword: PropTypes.bool,
};

UpdatePassword.defaultProps = {
  newPassword: false,
};

export default UpdatePassword;
