import { gql } from '@apollo/client';

export const CREATE_TIME_BASED_NUDGE_MUTATION = gql`
  mutation CreateTimeBasedNudgeMutation($input: TimeBasedNudgeInput!) {
    createTimeBasedNudge(input: $input) {
      ok
    }
  }
`;

export const CREATE_GEOLOCATION_NUDGE_MUTATION = gql`
  mutation CreateGeolocationNudgeMutation($input: GeolocationNudgeInput!) {
    createGeolocationNudge(input: $input) {
      ok
    }
  }
`;

export const UPDATE_TIME_BASED_NUDGE_MUTATION = gql`
  mutation UpdateTimeBasedNudgeMutation($actionId: ID!, $messageId: ID!, $conditionId: ID!,
    $input: UpdateTimeBasedNudgeInput!) {
    updateTimeBasedNudge(actionId: $actionId, messageId: $messageId,
      conditionId: $conditionId, input: $input) {
      ok
    }
  }
`;

export const UPDATE_GEOLOCATION_NUDGE_MUTATION = gql`
  mutation UpdateGeolocationNudgeMutation($actionId: ID!, $messageId: ID!,
    $input: UpdateGeolocationNudgeInput!) {
    updateGeolocationNudge(actionId: $actionId, messageId: $messageId, input: $input) {
      ok
    }
  }
`;

export const ARCHIVE_NUDGE_MUTATION = gql`
  mutation ArchiveNudgeMutation($id: ID!, $input: ArchiveNudgeInput!) {
    archiveNudge(id: $id, input: $input) {
      ok
    }
  }
`;

export const DELETE_NUDGE_MUTATION = gql`
  mutation DeleteNudgeMutation($id: ID!) {
    deleteNudge(id: $id) {
      ok
    }
  }
`;

export const CLONE_NUDGE_MUTATION = gql`
  mutation CloneNudgeMutation($id: ID!) {
    cloneNudge(id: $id) {
      actionId
    }
  }
`;
