import {Col, Modal, Row, Card, Container, Form} from "react-bootstrap";
import PageHeader from "../../../PageHeader/PageHeader";
import React from "react";
import UpdatePasswordForm from "../../../../pages/UpdatePassword/UpdatePasswordForm";
import UserService from '../../../../services/UserService';
import {useHistory} from "react-router-dom";
import {useMutation} from "@apollo/client";
import {useFormik} from "formik";
import { useNotification } from 'components/Notification/Notification';
import { RESET_2FA_MUTATION, FORGOT_PASSWORD_MUTATION } from 'graphql/mutations/users';
import Larky from 'components/Larky';

function Reset2faModal({show, callback, id, loggedInUserid, organizationId}) {
  const history = useHistory();
  const notification = useNotification();

  const [reset2faMutation] = useMutation(RESET_2FA_MUTATION);

  const formik = useFormik({
    initialValues: {
      email: '',
      id: id || '',
    },
    validateOnChange: false,
    enableReinitialize: true,
    onSubmit: async ({ email }) => {
      try {
        await UserService.reset2fa(reset2faMutation, { id, loggedInUserid, organizationId });
        notification.alert('2fa reset successfully', 'success');
        callback();
      } catch (err) {
        notification.alert(err.message, 'danger');
        callback();
      }
    },
  });
  return(
    <Modal show={show} size="md" onHide={callback} animation={false} backdrop="static" style={{top: '10%'}}>
        <Modal.Header closeButton style={{ paddingTop: 0, paddingBottom: 0 }}>
          <Row style={{ paddingLeft: '1em' }}>
            <PageHeader
              title="Reset 2FA"
              subtitle=""
            />
          </Row>
        </Modal.Header>
        <Modal.Body>
          <Form onSubmit={formik.handleSubmit}>
            <Row className="justify-content-center">
            <Col xs="12" sm="8" lg={8} xl={9} style={{paddingBottom: '5%'}}>
              <h4 className="text-center" style={{paddingTop: '5%', paddingBottom: '5%'}}>
                {"This will reset the user's 2FA details and they will be prompted to re-register their device the next time they login to the portal "}
              </h4>
              <Form.Group as={Container} className="mt-4">
          <Row className="align-items-center justify-content-center">
            <Col>
              <Larky.Button
                className="w-100"
                outlined
                size="lg"
                onClick={() => callback()}
              >
                Cancel
              </Larky.Button>
            </Col>
            <Col>
              <Larky.Button
                dataCy="submit"
                className="w-100"
                type="submit"
                size="lg"
                disabled={formik.isSubmitting}
              >
                Reset 2FA
              </Larky.Button>
            </Col>
          </Row>
        </Form.Group>
            </Col>
          </Row>
          </Form>
        </Modal.Body>
    </Modal>
  )
}

export default Reset2faModal;
