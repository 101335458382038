import React from 'react';
import moment from 'moment';
import RowActionsDropdown from '../../../../components/CustomTable/RowActionsDropdown/RowActionsDropdown';

function UserRow({
                     data,
                     currentUser,
                     handleInviteUser,
                 }) {
    const createUserOptionList = () => {
        const listOfOptions = [];
        listOfOptions.push({label: 'View/Edit user', value: 'edit'});

        if (data.inviteUserStatus === 1) {
            listOfOptions.push({label: null, value: null, isLineBreak: true});
            listOfOptions.push({label: 'Reinvite user', value: 'invite'});
        }
        return listOfOptions;
    };

    const inviteStatus = (activeStatus, invitedStatus) => {
        if (activeStatus && invitedStatus === 2) {
            return 'Activated';
        }
        if (activeStatus && invitedStatus === 1) {
            return 'Invited';
        }
        if (!activeStatus) {
            return 'Deactivated';
        }
        return null;
    };
    return (
        <tr>

            <td>{`${data.firstName} ${data.lastName}`}</td>
            <td>{`${data.email}`}</td>
            <td>{data.lastPortalloginUtctimestamp ? moment(data.lastPortalloginUtctimestamp).format('MM/DD/YYYY') : null}</td>
            <td>{data.adminUser ? 'Yes' : 'No'}</td>
            <td>{data.portalRoleText}</td>
            <td>{inviteStatus(data.active, data.inviteUserStatus)}</td>
            <td>
                <RowActionsDropdown
                    editRowPath={`/users/edit/${data.id}`}
                    hasEditPermission
                    onInviteUser={data.inviteUserStatus === 1 ? () => handleInviteUser(data, currentUser) : null}
                    options={createUserOptionList()}
                    placeholder="Actions..."
                />
            </td>
        </tr>
    );
}

export default UserRow;
