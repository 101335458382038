/**
 * CampaignGallery.jsx
 *
 * Component that displays Campaigns that are predefined for clients.
 *
 */
import React from 'react';
import PropTypes from 'prop-types';

// styles
import styles from '../../../Campaigns/components/CampaignGallery/CampaignGallery.module.scss';
import specificCardStyles from './InsightsCard.module.scss'

// components
import {Col, Row} from 'react-bootstrap';

// icons
import checkmarkIcon from 'assets/img/checkmark-icon.png';
import trendDownALot from 'assets/img/trend_down_a_lot.png';
import warningIcon from 'assets/img/warning-icon.png'
import toolTipIcon from "assets/img/tooltipIcon.png";
import calendarIcon from "assets/img/calendar-icon.png"

//constants
import { formatNumber } from 'Utils';

const InsightsCard = ({
                          type,
                          title,
                          description,
                          onClick,
                          iconName,
                          membersMessaged,
                          totalImpressions,
                          engagements
                      }) => {
    const determineIcon = (icon) => {
        if (icon === "green_check_icon") {
            return (<img className={styles['insight-icon']} src={checkmarkIcon}/>)
        }
        if (icon === 'orange_warning_icon') {
            return (<img className={styles['insight-icon']} src={warningIcon}/>)
        }
        if (icon === 'blue_help_icon') {
            return (<img className={styles['insight-icon']} src={toolTipIcon}/>)
        }
        if (icon === 'calendar_icon') {
            return (<img className={styles['insight-icon']} src={calendarIcon}/>)
        }
        return null;
    }

    const determineInsightDescription = () => {
        return type === 1 && membersMessaged ? (
            <>
                <p className={styles['label-value-container']}>
                    <div className={styles['bold-label']}>Members Messaged: </div>
                    {formatNumber(membersMessaged)}</p>
                <p className={styles['label-value-container']}>
                    <div className={styles['bold-label']}>Total Impressions: </div>
                    {formatNumber(totalImpressions)}
                </p>
                <p className={styles['label-value-container']}>
                    <div className={styles['bold-label']}>Engagements: </div>
                    {formatNumber(engagements)}
                </p>
            </>
        ) : (
            <p>{description}</p>
        );

    }

    return type === 1 ? (
            <>
                <article className={styles['card-insight-today']}
                         onClick={() => onClick()}
                >
                    <div className={styles['insight-card-content']}>
                        <div className={styles['card-header']}>
                            <h4>
                                <Row>
                                    <Col className={styles['card-header-arrow']}>
                                        {
                                            determineIcon(iconName)}
                                    </Col>
                                    <Col className={styles['insight-card-header-title']}>
                                        {title}
                                    </Col>
                                </Row>
                            </h4>
                        </div>
                        <div className={styles['card-body-nudge-today']}>
                            {determineInsightDescription()}
                        </div>
                    </div>
                </article>
            </>
        ) :
        (<>
                <article className={styles['card-insight']}
                         onClick={() => onClick()}
                >
                    <div className={styles['insight-card-content']}>
                        <div className={styles['card-header']}>
                            <h4>
                                <Row>
                                    <Col className={styles['card-header-arrow']}>
                                        {
                                            determineIcon(iconName)}
                                    </Col>
                                    <Col className={styles['insight-card-header-title']}>
                                        {title}
                                    </Col>
                                </Row>
                            </h4>
                        </div>
                        <div className={styles['card-body-insight']}>
                            <p>{description} <a style={{'font-weight': 'bold'}}>Click to learn more!</a></p>
                        </div>
                    </div>
                </article>
            </>
        );
};

InsightsCard.propTypes = {
    type: PropTypes.number.isRequired,
    title: PropTypes.string.isRequired,
    description: PropTypes.string.isRequired,
    iconName: PropTypes.string.isRequired,
    onClick: PropTypes.func.isRequired,
    membersMessaged: PropTypes.number,
    totalImpressions: PropTypes.number,
    engagements: PropTypes.number,
};

InsightsCard.defaultProps = {
    type: 0,
    title: '',
    description: '',
    iconName: '',
    onClick: () => null,
};

export default InsightsCard;
