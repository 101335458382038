import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useMutation, useQuery } from '@apollo/client';
import { useHistory } from 'react-router-dom';
import {
  Form, Row, Col, Container, Card,
} from 'react-bootstrap';

// Services
import { useAuthMachineValue } from 'contexts/auth-machine.context.ts';
import OrganizationService from 'services/OrganizationService';

// GraphQL
import {
    GET_ALL_FEATURE_FLAGS_WITH_USER_ID,
    GET_ORGANIZATIONS,
    GET_ORGANIZATION_CATEGORIES
} from '../../graphql/queries/organizations';

import Larky from 'components/Larky';
import CustomCard from 'components/CustomCard/CustomCard';
import CustomSection from 'components/CustomSection/CustomSection';
import { useNotification } from 'components/Notification/Notification';

// Styles
import styles from './PartnerManagement.module.scss';
import { ROUTES } from '../../Constants';
import UserService from '../../services/UserService';
import { GET_USER } from '../../graphql/queries/users';
import {getUser, getUserFeature} from '../../Utils';
import PartnerManagementRow from './components/PartnerManagementRow/PartnerManagementRow';
import CustomTable from '../../components/CustomTable/CustomTable';
import {downloadAnalyticsDB} from "../../DashboardAnalyticsUtils";

function PartnerManagement({ organizationId }) {
  const history = useHistory();
  const [currentAuth] = useAuthMachineValue();
  const { user } = currentAuth.context;

  const [userHasExportAnalytics, setUserHasExportAnalytics] = useState(false);
  const [userHasPartnerManagementWrite, setUserHasPartnerManagementWrite] = useState(false);
  const [exportAnalyticsToolTipText, setExportAnalyticsToolTipText] = useState(false);
  const [exportAnalyticsUrl, setExportAnalyticsUrl] = useState(false);
  const [organizationList, setOrganizationList] = useState([]);

  const getAllFeatureFlagsQuery = useQuery(GET_ALL_FEATURE_FLAGS_WITH_USER_ID, { skip: true });
  const getOrganizationsQuery = useQuery(GET_ORGANIZATIONS, { skip: true });

  const getTableData = async () => {
      const partnerOrganizationList = await OrganizationService.getOrganizations(getOrganizationsQuery, organizationId);
      return partnerOrganizationList.hits;
  }

  const id = user.id || current.context.user.id;

  const getUserQuery = useQuery(GET_USER, { variables: { id }, skip: true });

  const currentUserInfo = getUser()

  useEffect(async () => {
    const hasExportAnalytics = await getUserFeature('export_analytics');
    const userHasPartnerManagementWrite = await getUserFeature('partner_self_serve', 2)
    await setUserHasExportAnalytics(!!hasExportAnalytics);
    await setUserHasPartnerManagementWrite(userHasPartnerManagementWrite)
  }, [user.featureFlags]);

   useEffect(async () => {
    const exportAnalyticsFeatureInfo = await UserService.getFeatureFlagInfoWithUserId(getAllFeatureFlagsQuery, user.id, 'export_analytics');
    setExportAnalyticsToolTipText(exportAnalyticsFeatureInfo.tooltip);
    setExportAnalyticsUrl(exportAnalyticsFeatureInfo.tooltipUrl);
  }, [user.id]);

  const onExportAnalyticsClick = () => {
    if (userHasExportAnalytics) {
      return downloadAnalyticsDB(null);
    }
    if (!userHasExportAnalytics) {
      window.open(exportAnalyticsUrl, '_blank');
    }
    return null;
  };

  const createToolTipText = (textBody, url) => `${exportAnalyticsToolTipText} Click to learn more!`;

  return (
      <CustomCard
        title="My Organizations"
        isForm
        isDarkBackground
        size="xxl"
        isFullScreen
      >
        <Container className={styles['xxl-container']}>
            <CustomSection>
              <Col style={{ flex: 1 }}>
                <Row className={styles['row-container-align-right']}>
                  {/* Will be export and create new org button here */}
                  <div>
                          {userHasPartnerManagementWrite ? (
                            <Larky.Button
                              className={styles['no-left-margin-button']}
                              onClick={() => history.push(ROUTES.USERS.CREATE_ORGANIZATION)}
                            >
                              Create New Organization
                            </Larky.Button>
                          ) : null}
                        </div>
                </Row>
                <CustomTable
                  getData={getTableData}
                  customRow={(data, handleSelect) => (
                    <PartnerManagementRow
                      key={data.id}
                      data={data}
                      handleSelect={handleSelect}
                      currentUser={currentUserInfo}
                      handleManageOrganization={() => history.push(`${ROUTES.USERS.MANAGE_ORGANIZATION}/${data.id}`)}
                    />
                  )}
                  itemsPerPage={10}
                  columns={['Name', 'Org Id', 'Type', 'Launch Date', 'App Users', 'Tier', 'Geo Add-on', 'APNS Configured', 'APNS Mode']}
                  tableType={'partnerManagement'}
                />
              </Col>
            </CustomSection>
              <Row className="justify-content-between" style={{ display: 'flex' }}>
                <Col style={{ flex: 1 }} />
                <Larky.Button
                  outlined
                  onClick={() => history.push(ROUTES.DASHBOARD.BASE)}
                >
                  Close

                </Larky.Button>
                <Col style={{ flex: 1 }} />
              </Row>
        </Container>
      </CustomCard>
    );
}

PartnerManagement.propTypes = {
  organizationId: PropTypes.number,
  inviteSignup: PropTypes.bool,
};

PartnerManagement.defaultProps = {
  organizationId: null,
  inviteSignup: false,
};

export default PartnerManagement;
