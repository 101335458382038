import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import { useFormik } from 'formik';
import { useMutation, useQuery } from '@apollo/client';
import { useHistory, useLocation } from 'react-router-dom';
import {
  Form, Row, Container, Col, Card,
} from 'react-bootstrap';

import UserService from 'services/UserService';

// GraphQL
import { INVITE_USER_MUTATION, INVITE_SIGNUP_MUTATION } from 'graphql/mutations/users';
import { VALIDATE_PASSWORD } from 'graphql/queries/users';

import Larky from 'components/Larky';
import CustomCard from 'components/CustomCard/CustomCard';
import { useNotification } from 'components/Notification/Notification';

import validationSchema from './InviteUser.validation';
import CustomField from '../../../components/CustomField/CustomField';
import CustomCheckbox from '../../../components/CustomCheckbox/CustomCheckbox';
import CustomRadioButton from '../../../components/CustomRadioButton/CustomRadioButton';
import { VIEW_ONLY_OR_EDITOR_OPTIONS } from '../../../Constants';
import { getUser } from '../../../Utils';
import styles from '../../MyAccount/MyAccount.module.scss';
import { GET_PASSWORD_RULES } from '../../../graphql/queries/users';

function InviteUser({ organizationId, inviteSignup }) {
  const history = useHistory();
  const location = useLocation();
  const notification = useNotification();

  const [inviteUserMutation] = useMutation(INVITE_USER_MUTATION);
  const [inviteSignupMutation] = useMutation(INVITE_SIGNUP_MUTATION);
  const getValidatePasswordQuery = useQuery(VALIDATE_PASSWORD, { skip: true });
  const getPasswordRulesQuery = useQuery(GET_PASSWORD_RULES, { skip: true });

  const [passwordInputInFocus, setPasswordInputInFocus] = useState(false);
  
  const [isPasswordValid, setIsPasswordValid] = useState(false);

  const [passwordRules, setPasswordRules] = useState(null);
  const [passwordRulesIncorrectMessage, setPasswordRulesIncorrectMessage] = useState(null);

  const [activeOptionsStatus, setActiveOptionsStatus] = useState(
    [{ value: 1, label: 'View-Only', selected: true },
      { value: 2, label: 'Editor', selected: false }],
  );

  const params = new URLSearchParams(location.search);
  const inviteSignupIdentifier = decodeURIComponent(params.get('invite_signup_identifier') || '');
  const initialFirstName = decodeURIComponent(params.get('first_name') || '');
  const initialLastName = decodeURIComponent(params.get('last_name') || '');
  const initialEmail = decodeURIComponent(params.get('email') || '');

  const userInfo = getUser();

  const loggedInUserid = userInfo && userInfo.id ? userInfo.id : null;

  const formik = useFormik({
    initialValues: {
      firstName: initialFirstName,
      lastName: initialLastName,
      email: '',
      password: '',
      confirmPassword: '',
      organizationId,
      isAdmin: false,
      portalRole: 1,
      inviteSignup,
      loggedInUserid,

    },
    validateOnChange: false,
    enableReinitialize: true,
    validationSchema,
    onSubmit: async ({
      firstName,
      lastName,
      email,
      password,
      isAdmin,
      portalRole,
      loggedInUserid,
    }) => {
      try {
        if (inviteSignup) {
          await UserService.inviteSignup(inviteSignupMutation, {
            inviteSignupIdentifier,
            password,
            firstName,
            lastName,
            isAdmin,
            portalRole,
          });
          notification.alert('Signup successful, login with your new credentials', 'success');
          history.push('/login');
        } else {
          const reinvite = 0
          await UserService.inviteUser(inviteUserMutation, {
            email,
            organizationId,
            firstName,
            lastName,
            isAdmin,
            portalRole,
            loggedInUserid,
            reinvite,
          });
          notification.fullscreenDialog(
            'Success!',
            'You have successfully invited a user to nudge. They will receive an email with instructions on how to sign in.',
            '',
            'Confirm',
            () => history.push('/message-capping'),
          );
        }
      } catch (err) {
        notification.alert(err.message, 'danger');
        console.log(err);
      }
    },
  });

  useEffect(async () => {
    const userPasswordRules = await UserService.getPasswordRules(getPasswordRulesQuery);
    // todo: clean this up (font size and linting error on ruleDesc)
    const passwordRulesMap = userPasswordRules.map((rule) => <h6 className={styles['password-rules-font']} key={rule.ruleID}><li>{rule.ruleDesc}</li></h6>);
    setPasswordRules(passwordRulesMap);
  }, []);

  const handleActiveStatusButtonChange = (value) => {
    formik.setFieldValue('portalRole', value);
    return activeOptionsStatus.map((option) => {
      if (option.value === value) {
        option.selected = true;
      }
      if (option.value !== value) {
        option.selected = false;
      }
      return option;
    });
  };

  const determinePasswordValidation = async (password) => {
    if (password && inviteSignup) {
      try {
        const passwordValidationResponse = await UserService.validatePassword(
        getValidatePasswordQuery,
        password,
        formik.values.firstName || null,
        formik.values.lastName || null,
        inviteSignup ? initialEmail : formik.values.email,
        !inviteSignup ? loggedInUserid : null,
      );

      if (passwordValidationResponse.length
      // TODO add a loop to check if ruleID !== -1 and add a conditional message
      ) {
        const passwordRulesNotMetMessage = 'The password you entered does not meet the criteria shown. Please try again!';
        setPasswordRulesIncorrectMessage(passwordRulesNotMetMessage);
      } else {
        setPasswordRulesIncorrectMessage(null);
      }
      if (!passwordValidationResponse.length) {
        setPasswordRulesIncorrectMessage(null);
      }
    } catch (err) {
        notification.alert(err.message, 'danger');
        console.log(err);
      }
    }

  };

  useEffect(async () => {
    if (passwordInputInFocus === 'out') {
      await determinePasswordValidation(formik.values.password);
    }
  }, [passwordInputInFocus]);

  useEffect(async () => {
    if (!passwordRulesIncorrectMessage) {
      setIsPasswordValid(true);
    } else {
      setIsPasswordValid(false);
    }
  }, [passwordRulesIncorrectMessage]);

  const activeStatusButtonsInput = () => activeOptionsStatus.map((option) => (
    <CustomRadioButton
      key={option.value}
      name="userStatus"
      value={option.value}
      onChange={(value) => handleActiveStatusButtonChange(value)}
      option={option}
      disabled={false}
    />
  ));
  return (
    <CustomCard
      title={inviteSignup ? 'Welcome to the nudge® platform!' : 'Add a new user'}
      subtitle="Fill in the details below to complete your account and begin engaging with account holders:"
      isForm
      size="xxl"
    >

      <Container className={styles['invite-user-container']}>
        <Form onSubmit={formik.handleSubmit}>
          <Row>
            <Col className={styles['my-account-column-flex']}>
              <Card className={styles['invite-user-card']}>
                <Card.Body>
                  <h2>User Information</h2>
                  {inviteSignup && (
                  <Larky.Form.Input
                    type="text"
                    name="firstName"
                    title="First name"
                    placeholder="First Name"
                    value={formik.values.firstName}
                    onChange={formik.handleChange}
                    error={formik.errors.firstName}
                  />
                  )}
                  {inviteSignup && (
                  <Larky.Form.Input
                    type="text"
                    name="lastName"
                    title="Last name"
                    placeholder="Last Name"
                    value={formik.values.lastName}
                    onChange={formik.handleChange}
                    error={formik.errors.lastName}
                  />
                  )}
                  {!inviteSignup && (
                  <Larky.Form.Input
                    type="email"
                    name="email"
                    title="Email"
                    placeholder="example@email.com"
                    value={formik.values.email}
                    onChange={formik.handleChange}
                    error={formik.errors.email}
                  />
                  )}
                  {inviteSignup && (
                  <Larky.Form.Input
                    type="password"
                    name="password"
                    title={inviteSignup ? 'Enter a password' : 'New password'}
                    placeholder="New password"
                    value={formik.values.password}
                    onChange={formik.handleChange}
                    error={!isPasswordValid ? passwordRulesIncorrectMessage : formik.errors.password}
                    setIsFocusedCallback={(boolVal) => {
                      const isInFocus = boolVal ? 'in' : 'out';
                      setPasswordInputInFocus(isInFocus);
                    }}
                  />
                  )}
                  {inviteSignup && (
                  <Larky.Form.Input
                    type="password"
                    name="confirmPassword"
                    title={inviteSignup ? 'Confirm your new password' : 'Confirm new password'}
                    placeholder="Confirm new password"
                    value={formik.values.confirmPassword}
                    onChange={formik.handleChange}
                    error={formik.errors.confirmPassword}
                  />
                  )}
                </Card.Body>
              </Card>
            </Col>
            {inviteSignup
            && (
            <Col className={styles['my-account-column-flex']}>
              <Card className={styles['invite-user-card']}>
                <Card.Body>
                  <h2>Password Rules</h2>
                  {passwordRules}
                </Card.Body>
              </Card>
            </Col>
            )}
            <>
              {!inviteSignup ? (
                <Col>
                  <Card className={styles['invite-user-card']}>
                    <Card.Body>
                      <h2>Permissions</h2>
                      <h5 className={styles['small-header']}>Admin Permission</h5>
                      <h6 className={styles['invite-user-span']}>
                        Admins can add new users, deactivate old users, and assign permission
                      </h6>
                      <div className={styles['invite-user-checkbox-bold']}>
                        <CustomCheckbox
                          title="This new user is an admin"
                          name="permissions"
                          value={formik.values.isAdmin}
                          onChange={() => formik.setFieldValue(
                            'isAdmin', !formik.values.isAdmin,
                          )}
                          size={20}
                          disabled={false}
                        />
                      </div>
                      <br />
                      <h5 className={styles['small-header']}>Editing Permission</h5>
                      <h6 className={styles['invite-user-span']}>
                        Users can either have view-only permission, or be able to create/edit new nudges, campaigns and other elements
                      </h6>
                      {activeStatusButtonsInput()}
                    </Card.Body>
                  </Card>
                </Col>
              ) : null}
            </>
          </Row>
          <Form.Group as={Container} className="mt-4 mb-0">
            <Row className="justify-content-center">
              <Larky.Button
                outlined
                onClick={() => history.goBack()}
              >
                Cancel
              </Larky.Button>
              <Larky.Button
                dataCy="submit"
                type="submit"
                disabled={formik.isSubmitting || (inviteSignup && !isPasswordValid)}
              >
                {inviteSignup ? 'Submit' : 'Send invite'}
              </Larky.Button>
            </Row>
          </Form.Group>
        </Form>
      </Container>
    </CustomCard>
  );
}

InviteUser.propTypes = {
  organizationId: PropTypes.number,
  inviteSignup: PropTypes.bool,
};

InviteUser.defaultProps = {
  organizationId: null,
  inviteSignup: false,
};

export default InviteUser;
