import {
  Col, Container, Modal, Row,
} from 'react-bootstrap';
import React from 'react';
import { useHistory } from 'react-router-dom';
import PropTypes from 'prop-types';
import { useQuery } from '@apollo/client';
import PageHeader from '../../../PageHeader/PageHeader';
import UserService from '../../../../services/UserService';
import { GET_ALL_FEATURE_FLAGS_WITH_USER_ID } from '../../../../graphql/queries/organizations';
import { useAuthMachineValue } from '../../../../contexts/auth-machine.context';
import OrganizationSelector from '../../../OrganizationSelector/OrganizationSelector';

function ChangeOrganizationModal({ show, user, callback }) {
  const [current, send] = useAuthMachineValue();
  const history = useHistory();
  const getAllFeatureFlagsWithIdQuery = useQuery(GET_ALL_FEATURE_FLAGS_WITH_USER_ID, { skip: true });
  const {
    currentOrganization,
    id,
  } = user;

  return (
    <>
      <Modal show={show} size="md" onHide={callback} animation={false} backdrop="static" style={{ top: '10%' }}>
        <Modal.Header closeButton style={{ paddingTop: 0, paddingBottom: 0 }}>
          <Row style={{ paddingLeft: '1em' }}>
            <PageHeader
              title="Change Organization"
              subtitle=""
            />
          </Row>
        </Modal.Header>
        <Modal.Body>
          <h4 className="text-center" style={{ paddingTop: '5%', paddingBottom: '5%' }}>
            Select an organization to continue
          </h4>
          <Row className="justify-content-center">
            <Col xs="12" sm="8" lg={8} xl={9} style={{ paddingBottom: '5%' }}>
              <OrganizationSelector
                organizations={user.organizations}
                callback={
                async (org, err) => {
                  if (!err) {
                    await UserService.setCurrentOrganization(org);
                    await UserService.updateFeatureFlagsWithUserId(
                      getAllFeatureFlagsWithIdQuery,
                      send,
                      null,
                      id,
                    )
                      .catch((err) => console.log(err));
                    // window.location.reload();
                    history.push('/');
                  } else {
                    console.log(err);
                  }
                }
}
                currentOrganization={currentOrganization}
              />
            </Col>
          </Row>
        </Modal.Body>
      </Modal>
    </>
  );
}

ChangeOrganizationModal.propTypes = {
  show: PropTypes.bool.isRequired,
  callback: PropTypes.func,
};

ChangeOrganizationModal.defaultProps = {
  callback: null,
};

export default ChangeOrganizationModal;
