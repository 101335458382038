import React from 'react';
import PropTypes from 'prop-types';

import styles from './CustomSection.module.scss';

function CustomSection({
  className,
  title,
  children,
  margin,
  padding,
  header,
  headerButton,
  subheader,
}) {
  const headerSection = header ? styles.header : styles.root;
  return (
    <div
      className={`${headerSection} ${className}`}
      style={{ margin, padding }}
    >
      <div className={styles.horizontal}>
        {title && <h3>{title}</h3>}
        {headerButton && <>{headerButton}</>}
      </div>
      {subheader ? <h4> { subheader } </h4> : null}
      {children}
    </div>
  );
}

CustomSection.propTypes = {
  className: PropTypes.string,
  title: PropTypes.string,
  children: PropTypes.node.isRequired,
  headerButton: PropTypes.node,
  margin: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  padding: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  header: PropTypes.bool,
  subheader: PropTypes.node,
};

CustomSection.defaultProps = {
  className: '',
  title: null,
  margin: 0,
  padding: 20,
  header: false,
  headerButton: null,
  subheader: null,
};

export default CustomSection;
