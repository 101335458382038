import React, { useEffect, useState } from 'react';

import PropTypes from 'prop-types';
import ReactTooltip from 'react-tooltip';
import icon from '../../assets/img/tooltipIcon.png';
import styles from './ToolTipIcon.module.scss';

const ToolTipIcon = ({ toolTipMessage, url, className, classNameIcon,flag=1 }) => (
  <div
    data-tip={toolTipMessage}
    className={className ? styles[className] : styles['tool-tip-container']}
    onClick={() => {
      window.open(url, '_blank');
    }}
    data-for={toolTipMessage ? null : 'toolTipIcon'}
    id="export-icon"
  >
    <img className={className ? styles[classNameIcon] : styles['tooltip-icon']} src={icon} alt="i" />
      {(flag) ? (<ReactTooltip />) : null}
  </div>
);

ToolTipIcon.propTypes = {
  toolTipMessage: PropTypes.string,
  url: PropTypes.string,
};

ToolTipIcon.defaultProps = {
  toolTipMessage: 'This enhanced functionality is included with nudge® advanced tier. Click to learn more!',
  url: 'https://nudge.larky.com/',
};

export default ToolTipIcon;
