import {Col, Modal, Row, Card} from "react-bootstrap";
import PageHeader from "../../../PageHeader/PageHeader";
import React from "react";
import UpdatePasswordForm from "../../../../pages/UpdatePassword/UpdatePasswordForm";

function ChangePasswordModal({show, callback, id}) {
  return(
    <Modal show={show} size="xl" onHide={callback} animation={false} backdrop="static" style={{top: '10%'}}>
        <Modal.Header closeButton style={{ paddingTop: 0, paddingBottom: 0 }}>
          <Row style={{ paddingLeft: '1em' }}>
            <PageHeader
              title="Change Password"
              subtitle=""
            />
          </Row>
        </Modal.Header>
        <Modal.Body>
          <Row className="justify-content-center">
            <Col xs="12" sm="8" lg={12} xl={12} style={{paddingBottom: '5%'}}>
              <h2 className="text-center" style={{margin: '2.5% 0'}}>
                {"Choose your new password"}
              </h2>
              <UpdatePasswordForm callback={callback} id={id}/>
            </Col>
          </Row>
        </Modal.Body>
    </Modal>
  )
}

export default ChangePasswordModal;
