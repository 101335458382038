import React from 'react';
import ContentLoader from 'react-content-loader';

const PieChartLoadingCard = (props) => (
  <ContentLoader
    backgroundColor="#949494"
    foregroundColor="#c2c2c2"
    viewBox="0 0 400 200"
    height={200}
    width={400}
    {...props}
    style={{ width: '100%' }}
  >
    <rect x="0" y="5" rx="0" ry="0" width="400" height="15" />
    <circle cx="200" cy="120" r="80" />
  </ContentLoader>
);

export default PieChartLoadingCard;
