import * as Yup from 'yup';

import passwordValidator from '../../validators/Password.validator';
import { REQUIRED_FIELD, PASSWORD_VALIDATION } from '../../Constants';


const validationSchema = () => Yup.object({
  currentPassword: Yup.string().when('newPassword', (val, schema) => (val ? schema : schema.trim().required(REQUIRED_FIELD))),
  password: passwordValidator,
  confirmPassword: Yup.string()
      .oneOf([Yup.ref('password')], PASSWORD_VALIDATION)
    .required(REQUIRED_FIELD),
});

export default validationSchema;
