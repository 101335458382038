import React, { Fragment } from 'react'
import ContentLoader from 'react-content-loader'

const MessagingReachLoading = props => {
  const { rows = 3 } = props
  const rowHeight = 90

  return (
    <ContentLoader
        viewBox={`0 0 1500 ${rowHeight * rows}`}
        backgroundColor="#949494"
        foregroundColor="#c2c2c2"
        {...props}>
      {new Array(rows).fill(' ').map((el, index) => {
        const contentVerticalPosition = contentHeight =>
          rows > 1 ? contentHeight + rowHeight * index : contentHeight
        return (
          <Fragment key={index}>
            <rect
              x="100"
              y={`${contentVerticalPosition(20)}`}
              rx="10"
              ry="4"
              width="600"
              height="20"
            />
            <rect
              x="750"
              y={`${contentVerticalPosition(20)}`}
              rx="10"
              ry="4"
              width="600"
              height="20"
            />
          </Fragment>
        )
      })}
    </ContentLoader>
  )
}

export default MessagingReachLoading