import React, { useEffect, useState } from 'react';

import NudgeLogo from 'assets/img/nudge-logo.svg';
import OrganizationService from 'services/OrganizationService';
import { useQuery } from '@apollo/client';
import { GET_ORGANIZATION_PROFILE_PICTURE } from 'graphql/queries/organizations';
import { getUser } from '../../../../Utils';
import styles from './Logo.module.scss';

const Logo = () => {
  const [orgProfilePicture, setOrgProfilePicture] = useState(null);
  const [user, setUser] = useState(null);
  const getOrganizationPictureQuery = useQuery(GET_ORGANIZATION_PROFILE_PICTURE, { skip: true });
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    async function getUserById() {
      setIsLoading(true);
      const localUser = await getUser();
      setUser(localUser);
      const { currentOrganization } = localUser;
      const stringifyOrgId = currentOrganization.id.toString() || '';
      const profilePicture = await OrganizationService.getOrganizationProfilePicture(
        getOrganizationPictureQuery, stringifyOrgId,
      );
      setOrgProfilePicture(profilePicture.url);
      setIsLoading(false);
    }

    getUserById();
  }, [getUser, orgProfilePicture]);

  const styleForOrgTitle = orgProfilePicture ? `${styles['navbar-title']} ${styles['navbar-margin']}` : `${styles['navbar-title']}`;

  return (
    <>
      <div>
        <img className={styles.logo} src={NudgeLogo} alt="Nudge logo" />
        <span className={styles['reg-symbol']}>&reg;</span>
      </div>
      {!isLoading && user ? (
        <>
          <h1 className={styles.gray} />
          <div className={styles['logo-row']}>
            {orgProfilePicture ? (
              <>
                <img className={styles['org-logo']} src={orgProfilePicture || null} alt="Nudge logo" />
              </>
            ) : null}
            <div className={styles['logo-row']}>
              <div className={styleForOrgTitle}>{user.currentOrganization.name}</div>
            </div>
          </div>
        </>
      )
        : null}
    </>
  );
};

export default Logo;
