import { gql } from '@apollo/client';

export const GET_USER = gql`
  query GetUserQuery($id: ID) {
    getUser(id: $id) {
      id
      active
      firstName
      lastName
      email
      adminUser
      portalRole
      lastPortalloginUtctimestamp
      organizations {
        id
        name
        created
        updated
      }
      profilePicture
      role
      created
      updated
    } 
  }
`;

export const GET_USERS = gql`
  query GetUsersQuery($page: Int, $perPage: Int, $includeInactive: Int, $organizationId: Int) {
    getUsers(page: $page, perPage: $perPage, includeInactive: $includeInactive, organizationId: $organizationId) {
      hits {
        id
        firstName
        lastName
        email
        adminUser
        portalRole
        portalRoleText
        active
        inviteUserStatus
        lastPortalloginUtctimestamp
        organizations {
          id
          name
          created
          updated
        }
        profilePicture
        role
        created
        updated
      }
      totalResults
    } 
  }
`;


export const GET_CONDUIT_DATA_SOURCES = gql`
  query GetConduitDataQuery($organizationId: Int) {
    getConduitDataSources(organizationId: $organizationId) {
      hits {
        sourceName,
        allowed
        isConnected,
        oauthUrl
      }
      totalResults
    }
  }
`;


export const VALIDATE_PASSWORD = gql`
  query GetValidatePasswordQuery(
    $userid: String
    $password: String
    $email: String
    $firstName: String
    $lastName: String
   ) {
    validatePassword( userid: $userid, password: $password, email: $email, firstName: $firstName, lastName: $lastName) {
      rules {
          ruleDesc
          ruleID
      }
    }
  }
`;

export const GET_PASSWORD_RULES = gql`
  query GetPasswordRulesQuery
  {
    getPasswordRules{
        rules {
            ruleDesc
            ruleID
        }
    }
  }
`;

export const GET_QRCODE = gql`
  query GetQRCodeQuery ($email: String!, $id: ID)  {
    getQrcode ( email: $email, id: $id ) {
      image
      contentType
      cacheControl
      pragma
      expires
    }
  }
`;
