import React from 'react';

// Utils
import {
  formatPercentage,
  formatNumber, formatNumberPercentage,
} from 'Utils';

import {
  Col, Container, Row, Spinner, Table,
} from 'react-bootstrap';

// Context
import PropTypes from 'prop-types';
import styles from '../../../Home/components/QuickviewAnalyticsSection.module.scss';
import CampaignMessagingReachLoading from '../../../Home/components/MessagingReach/MessagingReachLoading';

/**
 * Component that displays stats about nudges or a specific nudge
 *
 * @param {String} organizationId
 * @param {String} nudgeId
 * @param {String} campaignId
 * @param {Boolean} isModal - adjusts quick analytics to be displayed in a modal by modifying
 * the styling. e.g. reducing fonts
 */
function CampaignMessagingReach({
  isLoading,
  tableData,
  tableHeaders,
}) {
  const renderHeaders = (headers) => headers.map((header, index) => (
    <th className={styles.colInfo} key={header.title}>
      {header.icon && <img className={styles.logo} src={header.icon} />}
      {header.title}
    </th>
  ));

  const renderCampaignMessageDetails = (campaignMessages) => {
    return campaignMessages.map((message, index) => (
      <tr>
        <td>
          <b>{`${index + 1}: ${message.title}`}</b>
        </td>
        <td className={styles['numbers-messaging']}>
          <Row className={styles['row-messaging']}>
            <div className={styles['left-half']}>
              {message.uniqueRecipients}
            </div>
          </Row>
        </td>
        <td className={styles['numbers-messaging']}>
          <Row className={styles['row-messaging']}>
            <div className={styles['left-half']}>
              {message.totalImpressions}
            </div>
          </Row>
        </td>
        <td className={styles['numbers-messaging']}>
          <Row className={styles['row-messaging']}>
            <div className={styles['left-half']}>
              {message.engagements}
            </div>
          </Row>
        </td>
        <td className={styles['numbers-messaging']}>
          <Row className={styles['row-messaging']}>
            <div className={styles['left-half']}>
              {formatNumberPercentage(message.engagementsRate)}
            </div>
          </Row>
        </td>

      </tr>
    ));
  };

  const renderCampaignDetails = (campaignDetails) => {
    return (
      <tr>
        <td className={`${styles.rowInfo} ${styles['row-header-messaging-reach']}`}>
          <b>Overall Campaign</b>
        </td>
        <td className={styles['numbers-messaging']}>
          <Row className={styles['row-messaging']}>
            <div className={styles['left-half']}>
              {formatNumber(campaignDetails.overallUniqueRecipients)}
            </div>
          </Row>
        </td>
        <td className={styles['numbers-messaging']}>
          <Row className={styles['row-messaging']}>
            <div className={styles['left-half']}>
              {formatNumber(campaignDetails.overallTotalImpressions)}
            </div>
          </Row>
        </td>
        <td className={styles['numbers-messaging']}>
          <Row className={styles['row-messaging']}>
            <div className={styles['left-half']}>
              {formatNumber(campaignDetails.overallTotalEngagements)}
            </div>
          </Row>
        </td>
        <td className={styles['numbers-messaging']}>
          <Row className={styles['row-messaging']}>
            <div className={styles['left-half']}>
              {formatNumberPercentage(campaignDetails.overallEngagementRate)}
            </div>
          </Row>
        </td>

      </tr>
    );
  };

  return (
    <>
      {isLoading ? (
        <CampaignMessagingReachLoading />
      ) : (
        <>
          <Table className={styles['messaging-reach-table']}>
            <thead>
              <tr>
                {renderHeaders(tableHeaders)}
              </tr>
            </thead>
            <tbody className={styles.analyticsTableRow}>
              {renderCampaignDetails(tableData)}
            </tbody>
            <td className={`${styles.rowInfo} ${styles['row-header-messaging-reach']}`}>
              <b>Messages</b>
            </td>
            <tbody className={styles.analyticsTableRow}>
              {renderCampaignMessageDetails(tableData && tableData.campaignNudgeAnalytics)}
            </tbody>
          </Table>
        </>
      )}
    </>
  );
}

CampaignMessagingReach.propTypes = {
  organizationId: PropTypes.number,
  nudgeId: PropTypes.string,
  campaignId: PropTypes.string,
  isModal: PropTypes.bool,
  toolTipMessage: PropTypes.string,
  hasAnalytics: PropTypes.bool,
  url: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.bool,
  ]),
  trendToolTipMessage: PropTypes.string,
  trendToolTipUrl: PropTypes.string,
};

CampaignMessagingReach.defaultProps = {
  organizationId: null,
  nudgeId: null,
  campaignId: null,
  isModal: false,
  trendToolTipMessage: null,
  hasAnalytics: false,
  trendToolTipUrl: null,
  tableHeaders: [],
};

export default CampaignMessagingReach;
