/**
 * CampaignMessageReorderModal
 *
 * Modal component that contains component to reorder campaign messages (nudges)
 *
 */
import React, { useEffect } from 'react';
import { Button, Modal, Row } from 'react-bootstrap';
import PropTypes from 'prop-types';
import { useMutation } from '@apollo/client';
import CustomButton from 'components/CustomButton/CustomButton';
import CampaignMessagesReorder from '../CampaignMessagesReorder/CampaignMessagesReorder';
import styles from '../../../../components/Notification/Notification.module.scss';
import { REORDER_CAMPAIGN_NUDGES_MUTATION } from '../../../../graphql/mutations/campaigns';
import CampaignService from '../../../../services/CampaignService';
import PageHeader from '../../../../components/PageHeader/PageHeader';

function CampaignMessageReorderModal({
  campaignId,
  show,
  setShow,
  data,
  onSuccessCallback,
}) {
  const [records, setRecords] = React.useState(data);
  const [reorderCampaignNudgesMutation] = useMutation(REORDER_CAMPAIGN_NUDGES_MUTATION);

  useEffect(() => {
    setRecords(data);
  }, [data]);

  const createRecordsPayload = () => {
    const campaignNudgeOrder = records.map((record, index) => {
      const sequenceNum = index + 1;
      return {
        nudgeId: record.actionId,
        sequenceNum,
      };
    });
    return {
      campaignId,
      campaignNudgeOrder,
    };
  };

  const handleClose = () => {
    setShow(false);
    setRecords(data);
  };

  const handleSave = async () => {
    const payload = createRecordsPayload();
    const resp = await CampaignService.reorderCampaignNudges(
      reorderCampaignNudgesMutation, campaignId, payload,
    );
    if (resp) {
      // run only if response was successful
      onSuccessCallback(true);
      handleClose();
    }
  };

  return (
    <>
      <Modal show={show} size="lg" onHide={handleClose} animation={false} backdrop="static">
        <Modal.Header closeButton style={{ paddingTop: 0, paddingBottom: 0 }}>
          <Row style={{ paddingLeft: '1em' }}>
            <PageHeader
              title="Reorder Campaign nudges"
              subtitle=""
            />
          </Row>
        </Modal.Header>
        <Modal.Body>
          <h4 style={{ marginBottom: '1rem' }}>Drag and drop nudges into the correct sequence</h4>
          <CampaignMessagesReorder tableData={{ records, setRecords }} />
        </Modal.Body>
        <Modal.Footer>
          <CustomButton
            variant="outlined"
            size="md"
            onClick={() => handleClose()}
            text="Cancel"
          />
          <CustomButton
            variant="contained"
            size="md"
            onClick={() => handleSave(true)}
            text="Save"
          />
        </Modal.Footer>
      </Modal>
    </>
  );
}
CampaignMessageReorderModal.propTypes = {
  campaignId: PropTypes.string.isRequired,
  show: PropTypes.bool.isRequired,
  setShow: PropTypes.func.isRequired,
  onSuccessCallback: PropTypes.func.isRequired,
  data: PropTypes.instanceOf(Array).isRequired,
};

export default CampaignMessageReorderModal;
