import moment from 'moment';

import Analytics from 'libs/Analytics';

// Components
import { useNotification } from 'components/Notification/Notification';

// Utils
import { formatPercentage } from 'Utils';

// Constants
import {
  NUMBER_OF_DAYS_TO_QUERY,
  NUMBER_OF_DAYS_TO_SHOW_ON_GRAPH,
} from 'Constants';
import React, { useEffect, useState } from 'react';
import {
  GET_NUDGES_RECEIVED_COUNT,
  GET_NUDGES_TAPPED_COUNT,
} from 'graphql/queries/nudges';

import { useQuery } from '@apollo/client';
import { getAnalyticsDB } from '../../../DashboardAnalyticsUtils';
import calculateTrend, { calculateTapRate } from './QuickviewAnalyticsSection.utils';

const ANALYTICS_PAGE = 'Home';
const FILE_NAME = 'useQuickAnalytics.jsx';

const defaultGraphData = {
  datasets: [
    {
      // label: 'Nudges Received',
      type: 'bar',
      borderWidth: 2,
      order: 2,
      yAxisID: 'y2',
      backgroundColor: 'rgba(41, 150, 229, 1)',
      hoverBackgroundColor: 'rgba(41, 150, 229, 0.7)',
      hoverBorderColor: 'rgba(255,99,132,1)',
      data: [],
    },
    {
      // label: 'Nudges Tapped',
      order: 1,
      yAxisID: 'y',
      type: 'line',
      fill: false,
      borderWidth: 6,
      backgroundColor: 'rgba(98, 217, 10, 1)', // #62d90a
      borderColor: 'rgba(98, 217, 10, 1)',
      hoverBackgroundColor: 'rgba(98, 217, 10, 0.8)',
      hoverBorderColor: 'rgba(255,99,132,1)',
      data: [],
    },
  ],
  labels: [],
};

const defaultTableData = {
  lastWeekMembersMessaged: 0,
  lastWeekMembersMessagedPercentTrend: 0,
  lastWeekMembersMessagedTrendArrow: 0,
  lastWeekTotalImpressions: 0,
  lastWeekTotalImpressionsPercentTrend: 0,
  lastWeekTotalImpressionsTrendArrow: 0,
  lastWeekEngagements: 0,
  lastWeekEngagementsPercentTrend: 0,
  lastWeekEngagementsTrendArrow: 0,
  lastWeekEngagementsRatePercentTrend: 0,
  lastWeekEngagementsRateTrend: 0.0,
  lastWeekEngagementsRateTrendArrow: 0,
  lastMonthMembersMessaged: 0,
  lastMonthMembersMessagedPercentTrend: 0,
  lastMonthMembersMessagedTrendArrow: 0,
  lastMonthTotalImpressions: 0,
  lastMonthTotalImpressionsPercentTrend: 0,
  lastMonthTotalImpressionsTrendArrow: 0,
  lastMonthEngagements: 0,
  lastMonthEngagementsPercentTrend: 0,
  lastMonthEngagementsTrendArrow: 0,
  lastMonthEngagementsRatePercentTrend: 0,
  lastMonthEngagementsRateTrend: 0,
  lastMonthEngagementsRateTrendArrow: 1,
  last90dayMembersMessaged: 0,
  last90dayMembersMessagedPercentTrend: 10,
  last90dayMembersMessagedTrendArrow: 0,
  last90dayTotalImpressions: 0,
  last90dayTotalImpressionsPercentTrend: 0,
  last90dayTotalImpressionsTrendArrow: 0,
  last90dayEngagements: 0,
  last90dayEngagementsPercentTrend: 0,
  last90dayEngagementsTrendArrow: 0,
  last90dayEngagementsRatePercentTrend: 0,
  last90dayEngagementsRateTrend: 0,
  last90dayEngagementsRateTrendArrow: 0,
  locationPermissionRate: null,
  locationPermissionRateTrend: null,
  locationPermissionRateTrendArrow: null,
  notificationPermissionRate: null,
  notificationPermissionRateTrend: null,
  notificationPermissionRateTrendArrow: null,
};

function useQuickAnalytics(organizationId, nudgeId = null, campaignId = null) {
  const [isLoading, setIsLoading] = useState(true);
  const notification = useNotification();
  const [graphData, setGraphData] = useState(defaultGraphData);
  const [tableData, setTableData] = useState(defaultTableData);
  const getNudgesReceivedQuery = useQuery(GET_NUDGES_RECEIVED_COUNT, { skip: true });
  const getNudgesTappedQuery = useQuery(GET_NUDGES_TAPPED_COUNT, { skip: true });

  const sumPropertyValue = (items, prop) => items.reduce((a, b) => a + b[prop], 0);

  const calculateGraphData = (data) => {
    const newGraphData = { ...graphData };
    const nudgesReceivedToShow = data.nudgesReceived.slice(-NUMBER_OF_DAYS_TO_SHOW_ON_GRAPH);
    const nudgesTappedToShow = data.nudgesTapped.slice(-NUMBER_OF_DAYS_TO_SHOW_ON_GRAPH);

    newGraphData.labels = nudgesReceivedToShow.map((x) => moment(x.date).format('MM/DD'));

    newGraphData.datasets[0].data = nudgesReceivedToShow.map((x) => x.count);
    newGraphData.datasets[1].data = nudgesTappedToShow.map((x) => x.count);

    // removeEmptyEnds(newGraphData.datasets, newGraphData.labels);
    setGraphData(newGraphData);
  };

  useEffect(() => {
    async function fetch() {
      try {
        const newGraphData = { ...graphData };
        const { datasets: [deliveredData, tappedData], labels } = newGraphData;
        const numberOfDaysToQuery = 60;
        const {timeZone} = Intl.DateTimeFormat().resolvedOptions()
        if (deliveredData.data.length === 0
          || tappedData.data.length === 0
          || labels.length === 0) {
          const nudges = await getAnalyticsDB(
            organizationId,
            notification,
            getNudgesReceivedQuery,
            getNudgesTappedQuery,
            numberOfDaysToQuery,
            'count',
            nudgeId,
            campaignId,
            timeZone,
          );
          calculateGraphData(nudges);
        }
        setIsLoading(false);
      } catch (err) {
        Analytics.trackApplicationError(err.message, FILE_NAME, 'fetch');
        console.log(err);
      }
    }
    fetch();
    Analytics.trackPageView(ANALYTICS_PAGE);
  }, []);

  return { graphData, tableData, isLoading };
}
export default useQuickAnalytics;
