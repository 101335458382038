/* eslint-disable jsx-a11y/label-has-associated-control */
import React from 'react';
import PropTypes from 'prop-types';

import sytles from './CustomCheckbox.module.scss';

function CustomCheckbox({
  name,
  value,
  onChange,
  size,
  disabled,
  title
}) {
  return (
    <label className={sytles.root}>
      <input
        type="checkbox"
        name={name}
        checked={value}
        onChange={() => onChange(!value)}
        disabled={disabled}
      />
      <span className={sytles.checkmark} style={{ width: size, height: size }} />
      {title}
    </label>
  );
}

CustomCheckbox.propTypes = {
  name: PropTypes.string,
  value: PropTypes.bool.isRequired,
  onChange: PropTypes.func.isRequired,
  size: PropTypes.number,
  disabled: PropTypes.bool,
  title: PropTypes.string,
};

CustomCheckbox.defaultProps = {
  name: '',
  size: 24,
  disabled: false,
  title: null
};

export default CustomCheckbox;
