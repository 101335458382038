import React from 'react';
import { Col, Row } from 'react-bootstrap';

import FaqIcon from 'assets/img/faq-icon.svg';
import NudgeTwoLineLogo from 'assets/img/nudge-two-line-logo.svg';
import styles from './Footer.module.scss';

function Footer() {
  const onClick = () => {
    window.open('https://larky.zendesk.com/hc/en-us', '_blank');
  };

  const copyRight = `© Copyright ${new Date().getFullYear()} Larky, All Rights Reserved.`

  return (
    <Row className={`${styles.root} m-0 align-items-center justify-content-center justify-content-sm-between`}>
      <Col className="mb-4 mb-sm-0 p-0 d-flex justify-content-center" xs={12} md="auto">
        <img src={NudgeTwoLineLogo} height={70} width={138} alt="Nudge two line logo" />
      </Col>
      <Col className="mb-4 mb-sm-0" xs={12} md="auto">
        <div className={styles['footer-text']}>
          {copyRight}
        </div>
      </Col>
      <Col className="d-flex justify-content-center p-0" xs={12} md="auto">
        <img
          role="presentation"
          className={styles['faq-img']}
          height={40}
          width={50}
          src={FaqIcon}
          alt="FAQ icon"
          onClick={onClick}
          onKeyDown={() => null}
        />
      </Col>
    </Row>
  );
}

export default Footer;
