import React from 'react';
import PropTypes from 'prop-types';

import CustomCard from 'components/CustomCard/CustomCard';
import CustomButton from 'components/CustomButton/CustomButton';

import styles from '../Notification.module.scss';
import fullscreenAlertStyles from './FullscreenAlert.module.scss';

function FullscreenAlert({
  title, subtitle, handleClose,
}) {
  return (
    <div className={`${styles['full-screen']} ${fullscreenAlertStyles.root}`}>
      <CustomCard
        className={fullscreenAlertStyles['custom-card']}
        title={title}
        subtitle={subtitle}
        showLogo={false}
      >
        <div>
          <CustomButton
            className={styles.button}
            variant="contained"
            size="sm"
            text="Close"
            onClick={handleClose}
            color="error"
          />
        </div>
      </CustomCard>
    </div>
  );
}

FullscreenAlert.propTypes = {
  title: PropTypes.string.isRequired,
  subtitle: PropTypes.string,
  handleClose: PropTypes.func,
};

FullscreenAlert.defaultProps = {
  subtitle: null,
  handleClose: () => { },
};

export default FullscreenAlert;
