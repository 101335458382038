// Dependencies
import React from 'react';
import styles from './CustomField.module.scss';
import {
  Card, Col, Container, Form, Row, Spinner,
} from 'react-bootstrap';

interface PropTypesInterface {
  label: string,
  children: JSX.Element
}

const CustomField = ({ label, children }: PropTypesInterface) => (
  <div className={styles.root}>
      <Col>
          <h6>{label}</h6>
    <div className={styles.children}>{children}</div>
      </Col>
  </div>
);

export default CustomField;
