import { gql } from '@apollo/client';

export const CREATE_SEGMENT_MUTATION = gql`
    mutation CreateSegmentMutation($input: UserSegmentInput!) {
        createUserSegment(input: $input){
            segment { id }
        }
    }
`;

export const CREATE_SYSTEM_SEGMENT_MUTATION = gql`
    mutation CreateSystemSegmentMutation($input: SystemSegmentInput!){
        createSystemSegment(input: $input){
            segment { 
                id
                segmentName
                memberCounts

             }
        }
    }
`;

export const CREATE_FILE_DATA_SEGMENT_MUTATION = gql`
    mutation CreateFileDataSegmentMutation($input: FileSegmentInput!) {
        createFileDataSegment(input: $input){
            segment { id }
        }
    }
`;

export const UPDATE_USER_SEGMENT_MUTATION = gql`
    mutation UpdateUserSegmentMutation($segmentId: ID!,$input: UserSegmentInput!){
        updateUserSegment(segmentId: $segmentId, segmentInput: $input){
            ok
        }
    }
`;

export const DELETE_SEGMENT_MUTATION = gql`
  mutation DeleteSegmentMutation($id: ID!) {
    deleteSegment(id: $id) {
      ok
    }
  }
 `;
