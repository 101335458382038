import React, { Fragment } from 'react';
import PropTypes from 'prop-types';

import CustomCard from 'components/CustomCard/CustomCard';
import CustomDialogCard from 'components/CustomDialogCard/CustomDialogCard';
import CustomButton from 'components/CustomButton/CustomButton';

import styles from '../Notification.module.scss';

function FullDialog({
  title,
  subtitle,
  content,
  fullDialog,
  children,
  italicizeTitle,
  size,
  background,
}) {

  return (
    <Fragment>
      {
        fullDialog ? (
          <CustomDialogCard
            title={title}
            subtitle={subtitle}
            content={content}
            italicizeTitle={italicizeTitle}
            size={size}
            background={background}
          >
            {children}
          </CustomDialogCard>
        ) : (
          <CustomCard title={title} subtitle={subtitle} italicizeTitle={italicizeTitle}>
            {children}
          </CustomCard>
        )}
    </Fragment>
  );
}

function FullscreenDialog({
  title,
  subtitle,
  content,
  primaryBtnText,
  primaryBtnOnClick,
  secondaryBtnText,
  secondaryBtnOnClick,
  children,
  fullDialog,
  italicizeTitle,
  size,
  background,
}) {

  return (
    <div className={styles['full-screen']}>
      <FullDialog
        title={title}
        subtitle={subtitle}
        content={content}
        fullDialog={fullDialog}
        italicizeTitle={italicizeTitle}
        size={size}
        background={background}
      >
        {children}
        <div>
          {secondaryBtnText && (
            <CustomButton
              dataCy="secondary"
              className={styles.button}
              variant="outlined"
              size="sm"
              text={secondaryBtnText}
              onClick={secondaryBtnOnClick}
            />
          )}

          {primaryBtnText && (
            <CustomButton
              dataCy="primary"
              className={styles.button}
              variant="contained"
              size="sm"
              text={primaryBtnText}
              onClick={primaryBtnOnClick}
            />
          )}
        </div>
      </FullDialog>
    </div>
  );
}

FullscreenDialog.propTypes = {
  title: PropTypes.string,
  subtitle: PropTypes.string,
  content: PropTypes.string,
  primaryBtnText: PropTypes.string,
  primaryBtnOnClick: PropTypes.func,
  secondaryBtnText: PropTypes.string,
  secondaryBtnOnClick: PropTypes.func,
  children: PropTypes.node,
  fullDialog: PropTypes.bool,
  italicizeTitle: PropTypes.bool,
  size: PropTypes.string,
  background: PropTypes.string,
};

FullscreenDialog.defaultProps = {
  title: null,
  subtitle: null,
  content: null,
  primaryBtnText: null,
  primaryBtnOnClick: null,
  secondaryBtnText: null,
  secondaryBtnOnClick: null,
  children: null,
  fullDialog: false,
  italicizeTitle: false,
  size: 'sm',
  background: null,
};

export default FullscreenDialog;
