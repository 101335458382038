import React, { useState, useEffect, useRef, forwardRef } from 'react';
import PropTypes from 'prop-types';
import Select from 'react-select';
import Datetime from 'react-datetime';
import 'react-datetime/css/react-datetime.css';
import ReactTooltip from 'react-tooltip';
import EmojiTextInput from '../EmojiTextInput/EmojiTextInput';
import styles from './CustomInput.module.scss';

const CustomInput = forwardRef(({
  type,
  id,
  name,
  value,
  title,
  description,
  placeholder,
  onChange,
  onKeyPress,
  options,
  isMulti,
  dateFormat,
  timeFormat,
  children,
  error,
  disabled,
  size,
  className,
  showEmoji,
  emojiCallback,
  fieldToEdit,
  inModal,
  setIsFocusedCallback,
  align,
}, ref) => {
  const [displayEmojiBoard, setDisplayEmojiBoard] = useState(false);
  const [cursorPosition, setCursorPosition] = useState(null);
  const [inputElements, setInputElements] = useState([]);

  const setInputRef = (index) => (el) => {
    if (el && !inputElements[index]) {
      setInputElements((prev) => {
        const newElements = [...prev];
        newElements[index] = el;
        return newElements;
      });
    }
  };

  useEffect(() => {
    if (inputElements[0] && typeof inputElements[0].focus === 'function') {
      inputElements[0].focus();
    }
  }, [inputElements]);

  useEffect(() => {
    const timer = setTimeout(() => {
      if (inputElements[0] && typeof inputElements[0].focus === 'function') {
        const input = inputElements[0];
        input.focus();
        if (cursorPosition !== null) {
          input.setSelectionRange(cursorPosition, cursorPosition);
        }
      }
    }, 0);

    return () => clearTimeout(timer);
  }, [cursorPosition, displayEmojiBoard]);

  const handleEmojiClick = async (emoji) => {
    if (inputElements[0]) {
      const input = inputElements[0];
      const { selectionStart, selectionEnd } = input;
      const startValue = value.substring(0, selectionStart);
      const endValue = value.substring(selectionEnd);
      const newValue = startValue + emoji + endValue;

      await emojiCallback(fieldToEdit, emoji);
      onChange({ target: { name, value: newValue } });

      const newCursorPosition = selectionStart + emoji.length;
      setCursorPosition(newCursorPosition);
    }
  };

  const renderInput = (index) => {
    switch (type) {
      case 'text':
      case 'email':
      case 'password':
        return (
          <input
            ref={setInputRef(index)}
            id={id}
            type={type}
            name={name}
            value={value || ''}
            placeholder={placeholder}
            onChange={onChange}
            onKeyPress={onKeyPress}
            disabled={disabled}
            className={displayEmojiBoard ? `${styles['fixed-height']}` : null}
            onFocus={() => setIsFocusedCallback && setIsFocusedCallback(true)}
            onBlur={() => setIsFocusedCallback && setIsFocusedCallback(false)}
          />
        );
      case 'textarea':
        return (
          <textarea
            ref={setInputRef(index)}
            name={name}
            rows="4"
            value={value || ''}
            placeholder={placeholder}
            onChange={onChange}
            disabled={disabled}
            onFocus={() => setIsFocusedCallback && setIsFocusedCallback(true)}
            onBlur={() => setIsFocusedCallback && setIsFocusedCallback(false)}
          />
        );
      case 'datetime':
        return (
          <Datetime
            ref={(el) => { if (el && el.input) setInputElements((prev) => [...prev, el.input]); }}
            name={name}
            value={value}
            placeholder={placeholder}
            onChange={onChange}
            inputProps={{ placeholder, disabled }}
            dateFormat={dateFormat}
            timeFormat={timeFormat}
            theme={(theme) => ({
              ...theme,
              borderRadius: styles.borderRadius,
              colors: {
                ...theme.colors,
                text: styles.primaryColor,
                primary: styles.primaryColor,
              },
            })}
          />
        );
      case 'select':
        return (
          <Select
            ref={(el) => { if (el && el.input) setInputElements((prev) => [...prev, el.input]); }}
            className={`${styles.children} ${className} select-identifier`}
            isMulti={isMulti}
            placeholder={placeholder}
            value={value}
            onChange={onChange}
            options={options}
            closeMenuOnSelect={!isMulti}
            name={name}
            inputId={id}
            styles={align ? ({
              control: (provided) => ({
                ...provided,
                borderColor: styles.borderColor,
                bottom: '25px'
              }),
            }) : ({
              control: (provided) => ({
                ...provided,
                borderColor: styles.borderColor,
              }),
            })}
            theme={(theme) => ({
              ...theme,
              borderRadius: styles.borderRadius,
              colors: {
                ...theme.colors,
                text: styles.primaryColor,
                primary: styles.primaryColor,
              },
            })}
            isDisabled={disabled}
          />
        );
      default:
        return <div className={styles.children}>{children}</div>;
    }
  };

  return (
    <div className={`${styles.root} ${styles[size]}`}>
      {title && <h5>{title}</h5>}
      <div className={styles['title-container']}>
        {description && <h6 className={showEmoji ? styles['input-90-width'] : null}>{description}</h6>}
        <div
          className={styles['cursor-pointer']}
          onClick={() => setDisplayEmojiBoard(!displayEmojiBoard)}
        >
          {showEmoji ? (
            <span
              className={styles['emoji-display-container']}
              data-tip
              data-for={displayEmojiBoard ? null : 'emojiTip'}
              id="emoji-title"
              role="img"
              aria-label="emoji"
            >
              😀
              <ReactTooltip id="emojiTip" place="top" effect="solid">
                Add emojis to your messages!
              </ReactTooltip>
              {displayEmojiBoard ? <span className={styles['emoji-arrow']}>▲</span>
                : <span className={styles['emoji-arrow']}>▼</span>}
            </span>
          ) : null}
        </div>
      </div>
      <div className={displayEmojiBoard ? styles.full : null}>
        {displayEmojiBoard && (
          <EmojiTextInput
            className={displayEmojiBoard ? `${styles['input-40-width']}` : null}
            callback={handleEmojiClick}
            valueToChange={fieldToEdit}
            displayBoardCallback={setDisplayEmojiBoard}
            displayBoard={displayEmojiBoard}
            inModal={inModal}
          />
        )}
        {renderInput(0)} {/* Render the first input */}
      </div>
      {error && <span className={styles.error}>{error}</span>}
    </div>
  );
});

const optionItemShape = PropTypes.shape({ label: PropTypes.string, value: PropTypes.any });

CustomInput.propTypes = {
  type: PropTypes.string,
  id: PropTypes.string,
  name: PropTypes.string,
  value: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
    optionItemShape,
    PropTypes.arrayOf(optionItemShape),
  ]),
  title: PropTypes.string,
  description: PropTypes.string,
  placeholder: PropTypes.string,
  onChange: PropTypes.func,
  onKeyPress: PropTypes.func,
  options: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
    optionItemShape,
    PropTypes.arrayOf(optionItemShape),
  ]),
  isMulti: PropTypes.bool,
  dateFormat: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
  timeFormat: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
  children: PropTypes.node,
  error: PropTypes.string,
  disabled: PropTypes.bool,
  size: PropTypes.string,
  className: PropTypes.string,
  showEmoji: PropTypes.bool,
  emojiCallback: PropTypes.func,
  fieldToEdit: PropTypes.string,
  inModal: PropTypes.bool,
  setIsFocusedCallback: PropTypes.func,
  align: PropTypes.bool,
};

CustomInput.defaultProps = {
  id: null,
  type: null,
  name: null,
  title: null,
  value: null,
  description: null,
  placeholder: null,
  onChange: () => null,
  onKeyPress: () => null,
  options: [],
  isMulti: false,
  dateFormat: false,
  timeFormat: false,
  children: null,
  error: null,
  disabled: false,
  size: 'lg',
  className: '',
  showEmoji: false,
  emojiCallback: () => null,
  fieldToEdit: '',
  inModal: false,
  setIsFocusedCallback: () => null,
  align: false,
};

export default CustomInput;
