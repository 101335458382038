/* eslint-disable jsx-a11y/label-has-associated-control */
import React from 'react';
import PropTypes from 'prop-types';
import {Row, Col} from 'react-bootstrap';

import styles from './CustomRadioButtonSwitch.module.scss';
import CustomRadioButton from "../CustomRadioButton/CustomRadioButton";

function CustomRadioButtonSwitch({
                                     name,
                                     title,
                                     description,
                                     onChange,
                                     disabled,
                                     size,
                                     options,
                                     formikValueToChange,
                                     handleChangeOptions,
                                     handleSubsetChange,
                                     radioButtonOrientation
                                 }) {

    const subsetOptionLayout = (option => {
        return option.subsetOptions.map((subsetOption) => (
                <>
                    <input
                        className={styles.input}
                        type="radio"
                        name={name}
                        value={subsetOption.value}
                        onChange={() => handleSubsetChange(subsetOption.value)}
                        checked={subsetOption.selected}
                        disabled={disabled}/>
                    <label className={styles['subset-label']}>{subsetOption.label}</label>
                </>
            )
        )
    })
    const optionLayout = () => {
        return options.map((option) => (
            <>
                <Col>
                    <div className={styles['label-container']}>
                        <input
                            className={styles.input}
                            type="radio"
                            name={name}
                            value={option.value}
                            onChange={() => onChange(option.value, options, handleChangeOptions, formikValueToChange)}
                            checked={option.selected}
                            disabled={disabled}/>
                        <label className={styles.label}>{option.label}</label>
                    </div>
                    <Row className={styles['option-description']}>
                        {option && option.description && <h8>{option.description}</h8>}
                    </Row>

                    {option.subsetOptions ? (
                        <div className={styles['label-container-subset']}>
                            {subsetOptionLayout(option)}
                        </div>
                    ) : null}
                </Col>
            </>))
    }
    return (<div className={`${styles.root} ${styles[size]}`}>
        {title && <h5>{title}</h5>}
        {description && <h8 dangerouslySetInnerHTML={{ __html: description }} />}
        <div className={!radioButtonOrientation ? styles.radioInput : styles[`radioInput-${radioButtonOrientation}`]}>
            {optionLayout()}
        </div>
    </div>);
}

const optionItemShape = PropTypes.shape(
    {label: PropTypes.string, value: PropTypes.value, selected: PropTypes.bool},
);

CustomRadioButtonSwitch.propTypes = {
    name: PropTypes.string,
    option: optionItemShape.isRequired,
    title: PropTypes.string,
    description: PropTypes.string,
    onChange: PropTypes.func.isRequired,
    size: PropTypes.string,
    disabled: PropTypes.bool,
};

CustomRadioButtonSwitch.defaultProps = {
    name: '',
    title: null,
    description: null,
    size: 'lg',
    disabled: false,
};

export default CustomRadioButtonSwitch;
