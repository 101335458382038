import React, { useState } from 'react';
import PropTypes from 'prop-types';

import { Modal, Button } from 'react-bootstrap';

import styles from '../Notification.module.scss';

function CustomDialog({
  title,
  body,
  handleClose,
  primaryBtnText,
  secondBtnText,
}) {
  const [show, setShow] = useState(true);
  const handleClick = async (value) => {
    setShow(false);
    handleClose(value);
  };
  return (
    <Modal show={show} onHide={() => handleClick(false)}>
      <Modal.Header closeButton>
        <Modal.Title>{title}</Modal.Title>
      </Modal.Header>
      <Modal.Body>{body}</Modal.Body>
      <Modal.Footer>
        {secondBtnText && (
        <Button
          className={styles['second-btn']}
          onClick={() => handleClick(false)}
        >
          {secondBtnText}
        </Button>
        )}
        {primaryBtnText && (
        <Button
          className={styles['primary-btn']}
          onClick={() => handleClick(true)}
        >
          {primaryBtnText}
        </Button>
        )}
      </Modal.Footer>
    </Modal>
  );
}

CustomDialog.propTypes = {
  title: PropTypes.string.isRequired,
  body: PropTypes.string.isRequired,
  handleClose: PropTypes.func.isRequired,
  primaryBtnText: PropTypes.string,
  secondBtnText: PropTypes.string,
};

CustomDialog.defaultProps = {
  primaryBtnText: null,
  secondBtnText: null,
};

export default CustomDialog;
