import { gql } from '@apollo/client';

export const UPDATE_MESSAGE_CAPPING_MUTATION = gql`
  mutation UpdateMessageCappingMutation($organizationId: Int!,
    $input: MessageCappingInput!) {
    updateMessageCapping(organizationId: $organizationId, input: $input) {
      ok
    }
  }
`;

export const UPDATE_ORGANIZATION_PROFILE_PICTURE_MUTATION = gql`
  mutation UpdateOrganizationProfilePictureMutation($organizationId: String!, $file: Upload!) {
    updateOrganizationProfilePicture(organizationId: $organizationId, file: $file) {
      UrlResponse {
        url
      }
    }
  }
`;

export const DELETE_ORGANIZATION_PROFILE_PICTURE_MUTATION = gql`
  mutation deleteOrganizationProfilePictureMutation($fileUrl: String!) {
    deleteOrganizationProfilePicture(fileUrl: $fileUrl) {
      ok
    }
  }
`;

export const UPDATE_ORGANIZATION_MUTATION = gql`
  mutation updateOrganizationMutation($input: OrganizationInput!) {
    updateOrganization(input: $input) {
      ok
    }
  }
`;

export const CREATE_ORGANIZATION_MUTATION = gql`
  mutation createOrganizationMutation($input: OrganizationInput!) {
    createOrganization(input: $input) {
      ok
    }
  }
`;