import * as Yup from 'yup';

import {
  DATE_VALIDATION,
  NUDGE_TYPES,
  REQUIRED_FIELD,
  rfc1738UrlRegex,
} from 'Constants';

const validationSchema = () => Yup.object({
  environment: Yup.object().required(REQUIRED_FIELD),
  name: Yup.string().trim().required(REQUIRED_FIELD),
  description: Yup.string()
    .trim()
    .nullable()
    .default(null),
  messageCategory: Yup.object()
    .nullable()
    .default(null)
    .required(REQUIRED_FIELD),
  title: Yup.string()
    .trim()
    .nullable()
    .default(null)
    .when('isDraft', {
            is: (isDraft) => !isDraft,
            then: () => Yup.string().required(REQUIRED_FIELD),
            otherwise: () => Yup.string().notRequired()}),
  body: Yup.string()
    .trim()
    .nullable()
    .default(null)
    .when('isDraft', {
            is: (isDraft) => !isDraft,
            then: () => Yup.string().required(REQUIRED_FIELD),
            otherwise: () => Yup.string().notRequired()}),
   url: Yup.string().trim().nullable()
    .when('urlLinkType.value', (urlLinkTypeValue, schema) => {
      const processedValue = Array.isArray(urlLinkTypeValue) ? urlLinkTypeValue[0] : urlLinkTypeValue;
      if (processedValue === 'Weblink') {
        return schema.test('urlTestType', 'Please enter a valid URL', function (value) {
          const { url } = this.parent || {};
          const regex = new RegExp(rfc1738UrlRegex);
          return url && url.slice(0, 4) !== 'tel:' ? regex.test(url) : true;
        });
      } else {
        return schema.nullable();
      }
    }),
  scheduleDateTime: Yup.date()
  .nullable()
  .default(null)
  .when(['nudgeType', 'isDraft'], {
    is: (nudgeType, isDraft) => nudgeType === NUDGE_TYPES.SCHEDULED_ACTION.ID,
    then: (schema) => schema
      .when('scheduleNow', (scheduleNow, schema) => {
        return scheduleNow
          ? schema
          : schema
              .min(new Date(), DATE_VALIDATION)
              .when('isDraft', (isDraft, schema) => (
                isDraft ? schema : schema.required(REQUIRED_FIELD)
              ));
      })
      .test('is-future-date', 'Date-time must be in the future', function (value) {
        const { isDraft, scheduleNow } = this.parent;
        return scheduleNow || (value && value > new Date());
      }),
    otherwise: () => Yup.date().nullable().default(null),
  }),
  segmentId: Yup.object()
    .nullable()
    .default(null)
    .when('isDraft', {
            is: (isDraft) => !isDraft,
            then: () => Yup.object().required(REQUIRED_FIELD),
            otherwise: () => Yup.object().notRequired()}),
  selectedLocations: Yup.array()
  .nullable()
  .default(null)
  .when('nudgeType', {
    is: NUDGE_TYPES.GEOFENCE.ID,
    then: (schema) => schema.min(1, "Choose at least one location").of(Yup.object().required("Choose at least one location")),
    otherwise: (schema) => schema
  }),
  radius: Yup.object()
    .nullable()
    .default(null)
    .when('nudgeType', {
      is: (nudgeType) => nudgeType === NUDGE_TYPES.GEOFENCE.ID,
      then: () => Yup.object().when('isDraft', (isDraft, schema) => (
        isDraft ? schema : schema.required(REQUIRED_FIELD)
      )),
    }),
  dateTimeRangeStart: Yup.date()
  .nullable()
  .default(null)
  .when(['nudgeType', 'isDraft'], {
    is: (nudgeType, isDraft) => nudgeType === NUDGE_TYPES.GEOFENCE.ID && !isDraft,
    then: (schema) => schema.when('dateTimeRangeStartNow', {
      is: (dateTimeRangeStartNow) => !dateTimeRangeStartNow,
      then: (schema) => schema.required('Select a starting date for this nudge or choose Today'),
    }),
    otherwise: (schema) => schema,
  }),
  dateTimeRangeEnd: Yup.date()
    .nullable()
    .default(null)
    .when(['nudgeType', 'dateTimeRangeEndNever', 'isDraft'], {
      is: (nudgeType, dateTimeRangeEndNever, isDraft) => {
        return nudgeType === NUDGE_TYPES.GEOFENCE.ID &&
          !dateTimeRangeEndNever &&
          !isDraft;
      },
      then: (schema) => schema
        .min(Yup.ref('dateTimeRangeStart'), 'Must be after start date')
        .required('Select an ending date for this nudge or choose Never end'),
      otherwise: (schema) => schema.nullable().default(null),
    }),
  selectedWeekDays: Yup.array()
  .nullable()
  .default(null)
  .when('isDraft', (isDraft, schema) => (
    isDraft ? schema : schema.required(REQUIRED_FIELD)
  ))
  .when(['nudgeType', 'isDraft'], {
    is: (nudgeType, isDraft) => nudgeType === NUDGE_TYPES.GEOFENCE.ID && !isDraft,
    then: (schema) => schema.min(1, REQUIRED_FIELD).of(Yup.object().required(REQUIRED_FIELD)),
    otherwise: (schema) => schema,
  }),
  timeRangeStart: Yup.date()
  .nullable()
  .default(null)
  .when('nudgeType', {
    is: NUDGE_TYPES.GEOFENCE.ID,
    then: (schema) => schema.when('allDay', {
      is: false, // Only apply further validation if allDay is false
      then: () => Yup.date().when('isDraft', {
        is: (isDraft) => !isDraft,
        then: () => Yup.date().required(REQUIRED_FIELD),
        otherwise: () => Yup.date().notRequired(),
      }),
    }),
    otherwise: (schema) => schema,
  }),
 timeRangeEnd: Yup.date()
  .nullable()
  .default(null)
  .when(['allDay', 'isDraft', 'timeRangeStart'], {
    is: (allDay, isDraft, timeRangeStart) => {
      return !allDay && !isDraft && timeRangeStart;
    },
    then: (schema) => {
      return schema
        .min(Yup.ref('timeRangeStart'), 'Must be after start time')
        .required('End time is required')
        .test('is-after-start-time', 'Must be after start time', function(value) {
          const { timeRangeStart } = this.parent;
          return value && timeRangeStart && value > timeRangeStart;
        });
    },
    otherwise: (schema) => {
      return schema.nullable().default(null);
    },
  }),
  scheduleTimeHour: Yup.object()
    .nullable()
    .default(null),
  scheduleTimeMinute: Yup.object()
    .nullable()
    .default(null),
  scheduleTimeType: Yup.object()
    .nullable()
    .default(null),
  delay: Yup.object()
    .required(REQUIRED_FIELD),
});

export default validationSchema;
