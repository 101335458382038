/**
 * CampaignMessageReorderModal
 *
 * Modal component that contains component to reorder campaign messages (nudges)
 *
 */
import React, { useEffect, useState } from 'react';
import {useHistory} from "react-router-dom";
import { Button, Modal, Row } from 'react-bootstrap';
import PropTypes from 'prop-types';
import { useMutation } from '@apollo/client';
import CustomButton from 'components/CustomButton/CustomButton';
import CustomInput from '../../../../../components/CustomInput/CustomInput'
import CampaignService from '../../../../../services/CampaignService';
import PageHeader from '../../../../../components/PageHeader/PageHeader';
import {useFormik} from "formik";
import validationSchema from "./CampaignTemplateEditModal.validatior";
import {UPDATE_CAMPAIGN_TEMPLATE, CREATE_TEMPLATE_FROM_CAMPAIGN_MUTATION} from '../../../../../graphql/mutations/campaigns'
import moment from "moment";
import {ROUTES} from '../../../../../../src/Constants';
import CreateOrUpdateCampaign from "../../CreateOrUpdateCampaign/CreateOrUpdateCampaign";

function CampaignTemplateEditModal({
  templateInfo,
  show,
  setShow,
  callback,
  isCreate,
  campaignId,
  updatedBy,
  saveCallback,
  temporaryFlag
}) {
  const history = useHistory();
  const [formValues, setFormValues] = useState(templateInfo);
  const [updateCampaignTemplateMutation] = useMutation(UPDATE_CAMPAIGN_TEMPLATE);
  const [createTemplateFromCampaign] = useMutation(CREATE_TEMPLATE_FROM_CAMPAIGN_MUTATION);

  const {id, title, description, cadence, notes, isDraft} = templateInfo;

  const formikDefaultValues = {
    title: title,
    description: description,
    cadence: cadence,
    notes: notes,
    isDraft: isDraft
  };

  const formik = useFormik({
    initialValues: {
      ...formikDefaultValues
    },
    validateOnChange: false,
    enableReinitialize: true,
    validationSchema,
    // eslint-disable-next-line consistent-return
    onSubmit: async (values) => {
      try {
        if (isCreate) {
          await saveCallback()
          await handleCreate()
        }
        else {
          await handleSave()
          setShow(false)
        }
      } catch (e) {
        // notification.alert(e.message, 'danger');
      }
    },
  });

  const templateValues = {...formik.values, updatedBy, temporaryFlag}

  const handleClose = async () => {
    let resp = null;
    resp = await CampaignService.deleteCampaignTemplateWork(
      id, formik.values, formikDefaultValues,'Erase', callback, setShow
    );
    if (resp) {
      callback(null)
      setShow(false)
    }
    // setShow(false)
  };

  const handleSave = async () => {
    let resp = null;
    resp = await CampaignService.updateCampaignTemplate(
      updateCampaignTemplateMutation, id, templateValues, 'save', updatedBy
    );
    if (resp) {
      setShow(false)
      // handleClose();
      callback(null)
      history.push(`${ROUTES.CAMPAIGNS.GALLERY}`);
    }
  };

  const handleCreate = async () => {
    let resp = null;
    resp = await CampaignService.createTemplateFromCampaign(
      createTemplateFromCampaign, campaignId, templateValues, 'create', updatedBy
    );
    if (resp) {
      setShow(false)
      history.push(`${ROUTES.CAMPAIGNS.GALLERY}`);
    }
    else {
      setShow(false)
    }
  };

  return (
    <>
      <Modal show={show} size="lg" onHide={() => setShow(false)} animation={false} backdrop="static">
        <Modal.Header closeButton style={{ paddingTop: 0, paddingBottom: 0 }}>
          <Row style={{ paddingLeft: '1em' }}>
            <PageHeader
              title="Edit Campaign Template"
              subtitle=""
            />
          </Row>
        </Modal.Header>
        <Modal.Body>
          <h4 style={{ marginBottom: '1rem' }}>Input Campaign Template Fields</h4>
             <CustomInput
                type="text"
                name="title"
                title="Title"
                value={formik.values.title}
                onChange={formik.handleChange}
                error={formik.errors.title}
                // disabled={isDisabled()}
             />
          <CustomInput
                type="textarea"
                name="description"
                title="Description"
                value={formik.values.description}
                onChange={formik.handleChange}
                error={formik.errors.description}
                // disabled={isDisabled()}
             />
          <CustomInput
                type="text"
                name="cadence"
                title="Cadence"
                value={formik.values.cadence}
                onChange={formik.handleChange}
                error={formik.errors.cadence}
                // disabled={isDisabled()}
             />
          <CustomInput
                type="textarea"
                name="notes"
                title="Notes"
                value={formik.values.notes}
                onChange={formik.handleChange}
                error={formik.errors.notes}
                // disabled={isDisabled()}
             />
        </Modal.Body>
        <Modal.Footer>
          <CustomButton
            variant="outlined"
            size="md"
            onClick={() => handleClose()}
            text="Cancel"
          />
          <CustomButton
            variant="contained"
            size="md"
            onClick={() => {
              formik.handleSubmit()
            }}
            text="Save"
          />
        </Modal.Footer>
      </Modal>
    </>
  );
}
CampaignTemplateEditModal.propTypes = {
  templateInfo: PropTypes.object.isRequired,
  show: PropTypes.bool.isRequired,
  setShow: PropTypes.func.isRequired,
  callback: PropTypes.func.isRequired,
  isCreate: PropTypes.bool.isRequired,
  campaignId: PropTypes.string,
  updatedBy: PropTypes.string,
  saveCallback: PropTypes.func.isRequired,
  temporaryFlag: PropTypes.bool.isRequired
};

CampaignTemplateEditModal.defaultProps = {
  templateInfo: {},
  show: false,
  setShow: () => {},
  callback: () => {},
  isCreate: false,
  campaignId: '',
  updatedBy: '',
  saveCallback: () => {},
  temporaryFlag: false
};

export default CampaignTemplateEditModal;
