import React from 'react';
import PropTypes from 'prop-types';

import { Form } from 'react-bootstrap';
import Header from './Header';

const Input = ({
  name,
  label,
  type,
  error,
  onChange,
  value,
  placeholder,
  size,
  title,
  description,
  textarea,
  inputProps,
  disabled,
  setIsFocusedCallback,
}) => (
  <Form.Group controlId={name}>
    {Header({ label, title, description })}

    <Form.Control
      as={textarea ? 'textarea' : 'input'}
      disabled={disabled}
      type={type}
      isInvalid={!!error}
      name={name}
      placeholder={placeholder}
      value={value}
      size={size}
      onChange={onChange}
      data-cy={name}
      onFocus={() => {
            setIsFocusedCallback ? setIsFocusedCallback(true) : null;
          }}
      onBlur={() => {
            setIsFocusedCallback ? setIsFocusedCallback(false) : null;
          }}
      {...inputProps}
    />

    <Form.Control.Feedback type="invalid">
      {error}
    </Form.Control.Feedback>
  </Form.Group>
);

Input.propTypes = {
  name: PropTypes.string,
  label: PropTypes.string,
  type: PropTypes.string,
  error: PropTypes.string,
  onChange: PropTypes.func,
  value: PropTypes.string.isRequired,
  placeholder: PropTypes.string,
  size: PropTypes.string,
  title: PropTypes.string,
  description: PropTypes.string,
  textarea: PropTypes.bool,
  inputProps: PropTypes.objectOf(PropTypes.any),
  disabled: PropTypes.bool,
};

Input.defaultProps = {
  name: '',
  label: '',
  type: 'text',
  error: null,
  placeholder: '',
  size: null,
  title: '',
  description: '',
  textarea: false,
  inputProps: {},
  disabled: false,
  onChange: () => { },
};

export default Input;
