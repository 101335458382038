import * as Yup from 'yup';

import { REQUIRED_FIELD } from 'Constants';

const validationSchema = () => Yup.object({
  name: Yup.string().trim().required(REQUIRED_FIELD),
  description: Yup.string().trim(),
  includedMembers: Yup.array()
    .nullable()
    .default(null)
    .when('dataType', {
      is: (dataType) => dataType === 'segment',
      then: () => Yup.array().min(1,REQUIRED_FIELD).of(Yup.object().required(REQUIRED_FIELD)),
    }),
  fileSelected: Yup.array()
    .nullable()
    .default(null)
    .when(['dataType', 'isUpdate'], {
      is: (dataType, isUpdate) => dataType === 'file' && !isUpdate,
      then: () => Yup.array().required('A file must be selected'),
    }),
  excludedMembers: Yup.array().nullable(),
  membershipLimit: Yup.object().nullable(),
});

export default validationSchema;
