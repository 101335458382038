import * as Yup from 'yup';
import { REQUIRED_FIELD } from 'Constants';

const validationSchema = () => Yup.object({
  name: Yup.string().trim().required(REQUIRED_FIELD),
  description: Yup.string().trim(),
  inclSegmentId: Yup.object()
    .nullable()
    .default(null),
  startDate: Yup.date()
    .nullable()
    .default(null),
  endDate: Yup.date()
  .nullable()
  .default(null)
  .when('startDate', {
    is: (startDate) => !!startDate && startDate !== 'null',
    then: (schema) => schema.min(Yup.ref('startDate'), 'Must be after start date'),
    otherwise: (schema) => schema.nullable().default(null),
  }),
});

export default validationSchema;
