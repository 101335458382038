import React from 'react';
import PropTypes from 'prop-types';

import { Alert } from 'react-bootstrap';

function CustomAlert({
  message,
  variant,
  onClose,
}) {
  return (
    <>
      <Alert
        style={{
          position: 'fixed',
          top: 8,
          right: 8,
          marginLeft: 16,
          minWidth: 160,
          zIndex: 2,
          transition: 'opacity 1s ease-out',
        }}
        variant={variant}
        onClose={onClose}
      >
        {message}
      </Alert>
    </>
  );
}

CustomAlert.propTypes = {
  message: PropTypes.string.isRequired,
  variant: PropTypes.string,
  onClose: PropTypes.func,
};

CustomAlert.defaultProps = {
  onClose: () => { },
  variant: '',
};

export default CustomAlert;
