// Dependencies
import Analytics from 'libs/Analytics';

// Components
import { useNotification } from 'components/Notification/Notification';

// Utils
import { getUser } from 'Utils';

// Services
import { ROLES, PAGINATION } from 'Constants';
import moment from 'moment';

const FILE_NAME = 'UserService.js';

class UserService {
  /**
   * User login
   * @param {Object} mutation
   * @param {{ email: String, password: String }} input
   * @returns {Promise}
   */
  static async login(mutation, input) {
    try {
      const { data } = await mutation({ variables: { input } });

      let {
        coreToken,
        pushToken,
        user,
        environment,
        coreUrl
      } = data.login.authResponse;

      sessionStorage.setItem('CORE', coreToken);
      sessionStorage.setItem('PUSH', pushToken);

      // used by SessionTimeout
      const timeStamp = moment();
      sessionStorage.setItem('lastTimeStamp', timeStamp);
      Object.assign(user, { environment, coreUrl });

      sessionStorage.setItem('USER', JSON.stringify(user));

      const response = UserService.setCurrentOrganization(user.organizations[0]);
      return response;
    } catch (err) {
      Analytics.trackApplicationError(err.message, FILE_NAME, 'login');
      sessionStorage.clear();
      throw new Error(err.message);
    }
  }

  /**
   * Find user by id
   * @param {Object} query
   * @param {String} id
   * @returns {Promise}
   */
  static async index(query, id) {
    const { data } = await query.refetch({ id });
    return data.getUser;
  }

  /**
   * User update
   * @param {Object} mutation
   * @param {String} id
   * @param {{ firstName: String, lastName: String, role: (String | null) }} input
   */
  static async update(mutation, id, input) {
    const { data } = await mutation({ variables: { id, input } });
    const { firstName, lastName } = data.updateUser.user;

    const user = getUser();
    Object.assign(user, { firstName, lastName });
    sessionStorage.setItem('USER', JSON.stringify(user));

    return user;
  }

  /**
   * Update Other user
   * @param {Object} mutation
   * @param {String} id {Object} input
   */

  static async updateOtherUser(mutation, id, input) {
    const { data } = await mutation({ variables: { id, input } });
    return data.updateOtherUser;
  }

  /**
   * Delete user
   * @param {Object} mutation
   * @param {String} id
   */
  static async delete(mutation, id) {
    const notification = useNotification();
    const des = await notification.fullscreenDialogConfirm(
      'Are you sure you want to delete this user?',
      'Delete User',
    );

    if (des) {
      const { data } = await mutation({ variables: { id } });
      return data.deleteUser;
    }

    return null;
  }

  static async getOtherUser(query, id) {
    const { data } = await query.refetch({ id });
    return data.getUser;
  }

  /**
   * Update User profile picture
   * @param {Object} mutation
   * @param {String} id
   * @param {File} file
   */
  static async updateProfilePicture(mutation, id, file) {
    const { data } = await mutation({ variables: { id, file } });
    const { fileResponse } = data.updateProfilePicture;

    return fileResponse.url;
  }

  /**
   * Delete User profile picture
   * @param {Object} mutation
   * @param {String} fileUrl
   */
  static async deleteProfilePicture(mutation, fileUrl) {
    const notification = useNotification();
    const des = await notification.fullscreenDialogConfirm(
      'Are you sure you want to delete this profile picture?',
      'Delete Picture',
    );

    if (des) {
      const { data } = await mutation({ variables: { fileUrl } });
      return data.deleteProfilePicture;
    }

    return null;
  }

  /**
   * Update password
   * @param {Object} mutation
   * @param {{ email: String, currentPassword: String,
   * password: String, confirmPassword: String }} input
   * @returns {Promise}
   */
  static async updatePassword(mutation, input) {
    const { data } = await mutation({ variables: { input } });
    return data.updatePassword;
  }

  // /**
  //  * Active Status Change
  //  * @param {Object} mutation
  //  * @param {{ id: }},
  //  * password: String, confirmPassword: String }} input
  //  * @returns {Promise}
  //  */
  // static async updatePassword(mutation, input) {
  //   const { data } = await mutation({ variables: { input } });
  //   return data.updatePassword;
  // }

  /**
   * Forgot password
   * @param {Object} mutation
   * @param {{ email: String }} input
   * @returns {Promise}
   */
  static async forgotPassword(mutation, input) {
    const { data } = await mutation({ variables: { input } });
    return data.forgotPassword;
  }

  /**
   * Forgot password
   * @param {Object} mutation
   * @param {{ email: String }} input
   * @returns {Promise}
   */
  static async forgotOtherUserPassword(mutation, input) {
    const { data } = await mutation({ variables: { input } });
    return data.forgotPassword;
  }

  /**
   * Deactivate or Activate User
   * @param {Object} mutation
   * @param {{ email: String }} input
   * @returns {Promise}
   */
  static async activateOrDeactivateOtherUser(mutation, input) {
    const { data } = await mutation({ variables: { input } });
    return data.activateOrDeactivateOtherUser;
  }

  /**
   * Reset 2FA
   * @param {Object} mutation
   * @param {{ id: String, loggedInUserId: String }} input
   * @returns {Promise}
   */
  static async reset2fa(mutation, input) {
    const { data } = await mutation({ variables: { input } });
    return data.reset2fa;
  }

  /**
   * New password
   * @param {Object} mutation
   * @param {{ forgotPasswordIdentifier: String,
   * password: String, confirmPassword: String }} input
   * @returns {Promise}
   */
  static async newPassword(mutation, input) {
    const { data } = await mutation({ variables: { input } });
    return data.newPassword;
  }

  /**
   * Invite user
   * @param {Object} mutation
   * @param {{ email: String, organizationId: String,
   * firstName: String, lastName: String }} input
   * @returns {Promise}
   */
  static async inviteUser(mutation, input) {
    const { data } = await mutation({ variables: { input } });
    return data.inviteUser;
  }

  /**
   * Invite signup
   * @param {Object} mutation
   * @param {{ inviteSignupIdentifier: String, password: String,
   * firstName: String, lastName: String }} input
   * @returns {Promise}
   */
  static async inviteSignup(mutation, input) {
    const { data } = await mutation({ variables: { input } });
    return data.inviteSignup;
  }

  /**
   * Set All Feature Flags
   * @param {Object} query
   * @param {Object} input
   */
  static async setAllFeatureFlagsWithUserId(query, userId) {
    const user = await getUser();
    if (user.organizations) {
      const { currentOrganization, id } = user;
      const { data } = await query.refetch({
        organizationId: currentOrganization.id,
        id: user.id,
      });
      const featureFlagsData = data.getAllFeatureFlagsWithUserId;
      const featureFlags = {};
      featureFlagsData.forEach((item) => {
        if (item.feature) featureFlags[item.feature] = item.value;
      });
      user.featureFlags = featureFlags;
      sessionStorage.setItem('USER', JSON.stringify(user));
    }
    return user;
  }

    /**
   * Set All Feature Flags
   * @param {Object} query
   * @param {Object} input
   */
  static async getFeatureFlagInfoWithUserId(query, userId, featureName) {
    const user = await getUser();
    if (user.organizations) {
      const { currentOrganization, id } = user;
      const { data } = await query.refetch({
        organizationId: currentOrganization.id,
        id: user.id,
      });
      const featureFlagsData = data.getAllFeatureFlagsWithUserId;
      for (let i = 0; i < featureFlagsData.length; i++) {
        if (featureFlagsData[i].feature === featureName) {
          return featureFlagsData[i];
        }
      }
    }
    return null;
  }

  static async updateFeatureFlagsWithUserId(query, authMachineSend, setter = null, userIdPassed) {
    try {
      const response = await UserService.setAllFeatureFlagsWithUserId(query, { id: userIdPassed });
      const data = await response;

      if (setter) {
        setter(data);
      }
      // update context's user
      try {
        await authMachineSend('SET_USER', { user: data });
      } catch (e) {
        console.log(e);
      }
    } catch (e) {
      console.log(e, 'error in update feature flags with id');
    }
  }

  /**
   * User logout
   * @param {Boolean} [redirect = true]
   * @param {Boolean} [showConfirm = true]
   */
  static async logout(redirect = true, showConfirm = true) {
    const notification = useNotification();
    let des = true;

    if (showConfirm) {
      des = await notification.confirm();
    }

    if (des) {
      Analytics.track(Analytics.LOGGED_OUT, {});
      sessionStorage.clear();

      if (redirect) {
        window.location.href = '/login';
      }
    }
    return showConfirm && des;
  }

  /**
   * Set current organization
   * @param {Object} organization
   * @returns {Object} user
   */
  static setCurrentOrganization(organization) {
    const user = getUser();
    user.currentOrganization = Object.assign(organization, { id: Number(organization.id) });
    sessionStorage.setItem('USER', JSON.stringify(user));
    return user;
  }

  /**
   * Update session storage user
   * @param {String} fieldName
   * @returns {Any} field
   */
  static updateSessionStorageUser(fieldName, field) {
    const user = getUser();
    Object.assign(user, { [fieldName]: field });
    sessionStorage.setItem('USER', JSON.stringify(user));
  }

  /**
   * Retrieve list of users for a given org
   * @param {Page} integer
   * @param {query} query name
   * @param {orgId} Int for an org
   * @param {includeInactiveUsers} Boolean
   */
  static async getOrganizationUserList(
    query,
    page,
    organizationId,
    includeInactive = 1,
  ) {
    const { data } = await query.refetch({
      page,
      perPage: 10,
      includeInactive,
      organizationId,
    });
    return data.getUsers;
  }

  static async getConduitData(
    query,
    organizationId,
  ) {
    const { data } = await query.refetch({
      organizationId,
    });
    return data.getConduitDataSources.hits;
  }

  static async VerifyOrgHubspotCallback(mutation, input) {
    const { data } = await mutation({ variables: { input } });
    return data.verifyHubspotCallback;
  }

  /**
   * validate Password submission
   */
  static async validatePassword(query, password, firstName, lastName, email, userid) {
    const { data, error } = await query.refetch({ password, firstName, lastName, email, userid });
    return data.validatePassword.rules;
  }

    /**
   * validate Password submission
   */
  static async getPasswordRules(query) {
    const { data, error } = await query.refetch();
    console.log(data.getPasswordRules.rules);
    return data.getPasswordRules.rules;
  }

  /**
   * Check if user is Admin
   */
  static isAdmin() {
    const user = getUser();
    return user && ROLES.map(({ key }) => key).indexOf(user.role) > 0;
  }

  /**
   * Return core and push tokens
   */
  static getTokens() {
    return {
      core: sessionStorage.getItem('CORE'),
      push: sessionStorage.getItem('PUSH'),
    };
  }

  static getCurrentMfaStatus() {
    const user = getUser();
    user.currentOrganization = Object.assign(organization, { id: Number(organization.id) });
    // user.role = Object.assign(user, { role: organization.role });
    sessionStorage.setItem('USER', JSON.stringify(user));
    return user;
  }

  static async registerUserPhoneNumber(mutation, input) {
    const { data } = await mutation({ variables: { input } });
    return data.registerUserPhoneNumber;
  }

  static async registerUserMfaMethod(mutation, input) {
    const { data } = await mutation({ variables: { input } });
    return data.registerUserMfaMethod;
  }

  static async verifyUserSmsCode(mutation, input) {
    const { data } = await mutation({ variables: { input } });
    return data.verifySmsCode;
  }

  static async verifyUserAuthenticatorCode(mutation, input) {
    const { data } = await mutation({ variables: { input } });
    return data.verifyAuthenticatorCode;
  }

}

export default UserService;
